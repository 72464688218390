import { Filter } from 'ducks/projectListViews'
import { Chip, styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import restClient from 'restClient'
import { mapFilterArrayToFilterObject } from '../utils'

interface PropTypes {
  orgId: number | undefined
  viewSelected: number
  viewIndex: number
  onChangeCount: (count: number | null) => void
  filters: Filter[]
}

const StyledChip = styled(Chip)({
  height: 18,
  fontSize: 10,
  borderRadius: 3,
})

const restClientInstance = restClient(window.API_ROOT + '/api')

const ViewButtonCount: React.FC<PropTypes> = ({ orgId, viewSelected, viewIndex, onChangeCount, filters }) => {
  const [count, setCount] = useState<number | null>(null)

  useEffect(() => {
    const filterObject = mapFilterArrayToFilterObject(filters)
    restClientInstance('GET_LIST', 'projects/view_count', {
      filter: filterObject,
    })
      .then((response) => {
        setCount(response.data.count)
      })
      .catch((e) => {
        setCount(null)
      })
  }, [filters])

  useEffect(() => {
    onChangeCount(count)
  }, [count])

  return (
    <>
      {count !== null ? (
        <StyledChip
          label={count}
          color={viewIndex === viewSelected ? 'info' : 'default'}
          style={{ color: viewIndex === viewSelected ? undefined : COLOR_PALETTE.iconColor }}
        />
      ) : (
        <Skeleton width={25} />
      )}
    </>
  )
}
export default ViewButtonCount
