import { authSelectors } from 'ducks/auth'
import { projectListViewSelectors, ProjectView, ViewType } from 'ducks/projectListViews'
import { List } from 'elements/react-admin/List'
import { Box, styled } from 'opensolar-ui'
import { getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { useEffect, useMemo, useState } from 'react'
import { FunctionField, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import Pagination from '../projects3/elements/Pagination'
import ProjectActionsButton from './elements/ProjectActionsButton'
import ProjectDatagrid from './elements/ProjectDatagrid'
import KanbanView from './kanban'
import ProjectsHeader from './ProjectsHeader'
import SearchToolbar from './SearchToolbar'
import { ListColumnBase, ProjectsListColumn, PROJECTS_LIST_COLUMNS_DEFAULT } from './views/utils'
import ViewToolbar from './ViewToolbar'

const ProjectList = styled(List)({
  padding: 0,
  width: '100%',
  '& .Ra-List-Main': {
    overflowX: 'auto',
  },
})

const StyledBox = styled(Box)({
  backgroundColor: 'white',
})

const KanbanBox = styled(Box, { name: 'KanbanBox' })({
  backgroundColor: 'unset',
})

const HeaderWrapper = styled('div')({
  width: '100%',
})

const ProjectsTable = (props) => {
  const history = useHistory()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const [isTableView, setTableView] = useState(true) // TODO: remove this, rely on view.view_type instead
  const defaultCols = useMemo(() => PROJECTS_LIST_COLUMNS_DEFAULT(translate), [])
  const [savedColData, setColData] = useState<undefined | ListColumnBase[]>(appStorage.getJSON('projects_columns'))
  const views: ProjectView[] = useSelector(projectListViewSelectors.getProjectListViewData)
  const defaultView = useSelector(projectListViewSelectors.getDefaultViewId)?.toString()
  const currView = useMemo(() => views.find((x) => x.id.toString() === props.viewId), [props.viewId, views])
  const columns: ProjectsListColumn[] | undefined = useMemo(() => {
    if (savedColData) {
      let returnCols = savedColData.map((x) => {
        const findColData = defaultCols.find((colData) => colData.id === x.id)
        return { ...(findColData || {}), enabled: x.enabled } as ProjectsListColumn
      })
      //add missing defaultCols if any
      const missingCols = defaultCols.filter((x) => !returnCols.map((y) => y.id).includes(x.id))
      missingCols.forEach((missingCol) => {
        returnCols.push(missingCol)
      })
      return returnCols
    }
    return defaultCols
  }, [savedColData])
  const displayCols = useMemo(() => columns?.filter((x) => x.enabled), [columns])
  const handleViewChange = (newViewIndex) => {
    const newView = views?.[newViewIndex]
    if (newView) {
      history.push('/projects/view/' + newView.id)
      setTableView(newView.view_type === ViewType.Table)
    }
  }
  useEffect(() => {
    if (currView) setTableView(currView.view_type === ViewType.Table)
  }, [currView])

  if (!currView && defaultView && props.viewId !== defaultView) return <Redirect to={'/projects/view/' + defaultView} />
  return (
    <ProjectList
      id={orgId}
      perPage={10}
      hasCreate={false}
      resource={'projects'}
      basePath={'projects'}
      queryOptions={{ blockRequest: !isTableView }} // disable loading when in kanban view
      title={
        <ProjectHeader
          handleViewChange={handleViewChange}
          viewSettings={{
            selectedView: currView ? views.indexOf(currView) : 0, //TODO: rework selectedView/handleViewChange func downstream to use view.id instead of array index
            isTableView,
            setTableView,
            columns,
            setColData,
          }}
        />
      }
      breadCrumbs={<></>}
      hasSearch={false}
      hasBulkActions={false}
      //@ts-ignore
      pagination={isTableView ? <Pagination /> : <></>}
      component={isTableView ? StyledBox : KanbanBox}
      {...props}
    >
      {isTableView && displayCols ? (
        <ProjectDatagrid>
          {displayCols.map((column: ProjectsListColumn) =>
            column.hideOnTable ? null : (
              <FunctionField key={'col-' + column.id} label={column.display} {...column.props} />
            )
          )}
          <FunctionField source="" textAlign={'right'} render={(record) => <ProjectActionsButton project={record} />} />
        </ProjectDatagrid>
      ) : (
        <KanbanView displayCols={displayCols} currView={currView} isTableView={isTableView} />
      )}
    </ProjectList>
  )
}

const ProjectHeader = (props) => {
  const classes = getProjectSectionStyles()
  return (
    <HeaderWrapper className={classes.column}>
      <ProjectsHeader {...props} />
      <ViewToolbar {...props} />
      <SearchToolbar {...props} />
    </HeaderWrapper>
  )
}

export default ProjectsTable
