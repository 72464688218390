import { orgSelectors } from 'ducks/orgs'
import Button from 'elements/button/Button'
import CustomField from 'elements/field/CustomField'
import { renderInput } from 'pages/auth/common'
import { useNotify, useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useVtacService from 'services/vtac/useVtacService'
import { composeValidators, emailFormat, required } from 'validations'
import { HardwareSupplierFilterKeyType } from '../type'

const VtacConnectForm = () => {
  const initialValues = {
    email: '',
  }
  const translate = useTranslate()
  const vtacService = useVtacService()
  const history = useHistory()
  const notify = useNotify()
  const country = useSelector(orgSelectors.getOrgIso2) || 'GB'

  const handleSubmit = async (values) => {
    const distributor: HardwareSupplierFilterKeyType = country === 'GB' ? 'vtac_uk' : 'vtac_pl'
    const { username, password } = values

    const response = await vtacService.getToken({
      username,
      password,
      distributor,
    })

    if (response?.status_code === 500) {
      notify(`Could not connect V-Tac customer: ${response.content?.message} `, 'warning')
    } else if (response?.status_code === 401) {
      notify(
        'Incorrect V-TAC user account email or password. Please try again, or register for a V-TAC account',
        'warning'
      )
    } else if (response?.token) {
      notify('Customer connected successfully', 'info')
      history.push(`/shop/after_connect/${distributor}`)
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting }) => {
        return (
          <>
            <CustomField
              name="username"
              type="email"
              validate={composeValidators(required, emailFormat)}
              component={renderInput}
              label={translate('username')}
              fullWidth={true}
              inputProps={{
                id: 'vtac-connect-form-email',
              }}
            />
            <CustomField
              name="password"
              type="password"
              validate={required}
              component={renderInput}
              label={translate('password')}
              fullWidth={true}
              inputProps={{
                id: 'vtac-connect-form-password',
              }}
            />
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={submitting}
              loading={submitting}
            >
              Connect to V-Tac
            </Button>
          </>
        )
      }}
    />
  )
}

export default VtacConnectForm
