import { BottomNavigation, BottomNavigationAction, Theme, useMediaQuery } from '@material-ui/core'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import { useViewShow } from 'ducks/viewMode'
import { UiSwitch } from 'elements/UiSwitch'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getRoleFromState } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import useBottomNavigationIcons from './hooks/useBottomNavigationIcons'
import useNavigationLinks, { HEADER_NAV_UI_KEY } from './hooks/useNavigationLinks'

const useStyles = makeOpenSolarStyles<{ isNav3Enabled?: boolean }>((theme) => ({
  root: {
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    width: '100%',
    height: 50,
    [theme.breakpoints.down('sm')]: { gap: '0 !important' },
    gap: ({ isNav3Enabled }: { isNav3Enabled?: boolean }) => (isNav3Enabled ? '12px' : 0),
  },
  action: {
    marginTop: 2,
    '&$selected': {
      marginTop: 0,
      color: 'rgb(0,0,0)',
      borderTop: '2px solid rgba(0,0,0)',
    },
    '& .MuiBottomNavigationAction-label.Mui-selected': {
      fontSize: '0.75rem',
    },
  },

  selected: {},
  iconheight: { lineHeight: 'none' },
}))

const ZONE_PATH_MAP: { [key: string]: string } = {
  crm: '/projects',
  home: '/home',
  control: '/control',
  cashflow: '/cashflow',
  partnerzone: '/zone',
  shop: '/shop',
}

const BottomNavigationItem3 = styled(BottomNavigationAction)<{ isNearmapSkin: boolean }>(({ isNearmapSkin }) => ({
  flex: '0 1 auto',
  marginTop: 0,
  padding: '12px 20px !important',
  '& .MuiBottomNavigationAction-label': {
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: '550',
    lineHeight: '20px',
  },
  '& .MuiBottomNavigationAction-wrapper': {
    color: '#13171A',
    columnGap: 8,
    flexDirection: 'row',
  },
  '& svg': {
    height: 20,
    width: 20,
  },
  '&:hover': {
    background: '#F5F8FD',
  },
  '&.Mui-selected': {
    borderTop: 'none !important',
    '&::after': {
      background: isNearmapSkin ? '#3448FF' : '#FFD800',
      bottom: '0',
      content: '""',
      height: '2px',
      position: 'absolute',
      width: '100%',
    },
  },
}))

const BottomNavigationMobile: FC = () => {
  const isNav3Enabled = useFeatureFlag('nav_3', 'on')

  const classes = useStyles({ isNav3Enabled })
  const dispatch = useDispatch()
  const history = useHistory()
  const translate = useTranslate()

  const navigationLinks = useNavigationLinks()
  const getBottomNavigationIcon = useBottomNavigationIcons()

  const zone = useSelector((state: any) => state.zone)
  const isCustomer = useSelector((state) => !Boolean(getRoleFromState(state)))
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const viewAsCustomer = useSelector((state: any) => state.viewAsCustomer)

  const handleClick = useCallback((event, newValue: string) => {
    event.preventDefault()
    const newPathName = ZONE_PATH_MAP[newValue]
    if (window.projectForm?.mutators.getFormDirtyFields().length > 0) {
      dispatch(
        setStudioSavePrompt({
          show_prompt: true,
          redirect: newPathName,
          prompt_heading: `Leave Studio?`,
        })
      )
    } else {
      history.push(newPathName)
    }
  }, [])

  if (!isMobile || isCustomer || viewAsCustomer) return null

  return (
    <UiSwitch uiKey={HEADER_NAV_UI_KEY}>
      <BottomNavigation value={zone} onChange={handleClick} showLabels className={classes.root}>
        {navigationLinks.map((navigationLink) => (
          <BottomNavItem
            classes={{ root: classes.action, selected: classes.selected }}
            icon={getBottomNavigationIcon(navigationLink.value)}
            label={translate(navigationLink.label)}
            uiKey={navigationLink.uiKey}
            value={navigationLink.value}
          />
        ))}
      </BottomNavigation>
    </UiSwitch>
  )
}

const BottomNavItem = ({ uiKey, ...rest }) => {
  const show = useViewShow(uiKey)

  const isNav3Enabled = useFeatureFlag('nav_3', 'on')
  const isNearmapSkin = useSelector((state: any) => state.theme === 'nearmap')
  const NavLinkComponent = isNav3Enabled
    ? (props) => <BottomNavigationItem3 isNearmapSkin={isNearmapSkin} {...props} />
    : BottomNavigationAction

  return !show ? <></> : <NavLinkComponent {...rest} />
}

export default BottomNavigationMobile
