import appStorage from 'storage/appStorage'
import { StudioSystemType } from 'types/global'
import { MountingID, NativeMountingID } from 'types/mounting'
import { nativeRailSystems } from './constants'
import { StructuralOptions } from './types'
import { saveFastenerData } from './utils'

const KEY_TOKEN = 'token'

export type CallbackType = (structuralOptions: StructuralOptions) => void

export function getStructuralOptionsMCS(system: StudioSystemType, mountingSystem, callback: CallbackType): void {
  const mountingName = system?.mounting
  if (!mountingName) return
  var structuralOptions: StructuralOptions = {}
  const form = window.projectForm
  // Check if necessary fields are filled in for MCS
  var missingFields = getMissingFields(form)
  var dirtyFields = form.mutators.getFormDirtyFields().includes('custom_data')
  if (missingFields.includes('fixing_diameter') || missingFields.includes('fixing_length_screwed_through_timber')) {
    const roofIndex = mountingSystem.input.roof.roofIndex
    saveFastenerData(system, mountingSystem.getFastener(), roofIndex)
    missingFields = missingFields.filter(
      (field) => field !== 'fixing_length_screwed_through_timber' && field !== 'fixing_diameter'
    )
  }
  if (missingFields.length) {
    window.WorkspaceHelper.addProjectErrorToReduxStore({
      message: `Cannot calculate MCS structural assessment, please input all required fields and save your project`,
      key: `${mountingName.toUpperCase()}_INPUT_WARNING`,
      severity: 'warning',
      systemId: system.uuid,
      source: 'plugin',
      category: 'mounting',
      options: {},
    })
    callback(structuralOptions)
  } else if (dirtyFields) {
    window.WorkspaceHelper.addProjectErrorToReduxStore({
      message: `Please save your project and recalculate to use the most up to date values for the MCS structural assessment`,
      key: `${mountingName.toUpperCase()}_INPUT_WARNING`,
      severity: 'info',
      systemId: system.uuid,
      source: 'plugin',
      category: 'mounting',
      options: {},
    })
    callback(structuralOptions)
  } else {
    window.WorkspaceHelper.removeProjectErrorFromReduxStore(
      `${mountingName.toUpperCase()}_INPUT_WARNING`,
      system.uuid,
      'plugin'
    )
    calculateAndSetStructuralOptions(system, structuralOptions, callback)
  }
}

function getMissingFields(form) {
  var missingFields: string[] = []
  var missingRoofFields: string[] = []
  const values = form.getState().values?.custom_data?._mcs
  if (!values) return ['all']
  var requiredValues = [
    'topography_type',
    'wind_zone',
    'snow_zone',
    'terrain_category',
    'distance_from_sea',
    'altitude',
  ]
  if (values.topography_type === 'Escarpments or Cliffs') {
    requiredValues.push(...['topography_zone', 'topography_slope'])
  } else if (values.topography_type === 'Hills or Ridge') {
    requiredValues.push(...['topography_slope'])
  }
  requiredValues.forEach((requiredValue) => {
    if (!values[requiredValue] || (typeof values[requiredValue] === 'string' && values[requiredValue].length === 0))
      missingFields.push(requiredValue)
  })
  const roofs = values?.roof_facets
  if (roofs) {
    roofs.forEach((roof_facet) => {
      let requiredRoofValues = [
        'mounting_weight',
        'azimuth',
        'module_quantity',
        'roof_structure_type',
        'slope',
        'ridge_height',
        'fixing_length_screwed_through_timber',
        'fixing_diameter',
        'fixing_qty',
      ]
      if (roof_facet.roof_structure_type === 'rafters_pitched') {
        requiredRoofValues.push(...['longest_clear_rafter_span', 'rafter_breadth', 'rafter_depth', 'rafter_spacing'])
      }
      requiredRoofValues.forEach((requiredValue) => {
        if (
          !roof_facet[requiredValue] ||
          (typeof roof_facet[requiredValue] === 'string' && roof_facet[requiredValue].length === 0)
        )
          missingRoofFields.push(requiredValue)
      })
    })
  } else {
    missingFields.push('all')
  }
  return [...missingFields, ...missingRoofFields]
}

function calculateAndSetStructuralOptions(
  system: StudioSystemType,
  structuralOptions: StructuralOptions,
  callback: CallbackType
): void {
  const org_id = appStorage.getNumber('org_id')
  const mountingName = system?.mounting as NativeMountingID
  const url = `${window.API_ROOT}/api/orgs/${org_id}/projects/${window.projectForm.getState().values.id}/systems/${
    system.uuid
  }/structural/calculate_mcs/`
  const options = {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + window.getStorage().getItem(KEY_TOKEN),
      Accept: 'application/json',
    },
  }
  fetch(url, options)
    .then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw Error()
    })
    .then((data) => {
      structuralOptions.structuralRegs = 'mcs'
      structuralOptions.moduleGroups = {}
      let totalMinFastenerCount = 0
      const windLoadingResults = data.result[0].wind_loading_on_each_module_group
      for (const moduleGroup in windLoadingResults.module_groups) {
        const moduleGroupIndex = moduleGroup.split('mg_')[1]
        const moduleGroupResults = windLoadingResults.module_groups[moduleGroup]
        const maxUplift = moduleGroupResults.wind_uplift_force_edge
        const pullOutForce = moduleGroupResults.pull_out_force_per_fixing
        const minFastenerCount = Math.ceil(maxUplift / pullOutForce)
        structuralOptions.moduleGroups[moduleGroupIndex] = { minFastenerCount }
        totalMinFastenerCount += minFastenerCount
      }
      let mountingID = system.mounting as MountingID
      window.WorkspaceHelper.addProjectErrorToReduxStore({
        message: `MCS wind loading assessment passed: ${totalMinFastenerCount} or more fasteners added`,
        key: `${mountingID.toUpperCase()}_STRUCTURAL_RESULT`,
        severity: 'success',
        systemId: system.uuid,
        source: 'plugin',
        category: 'structural',
        options: {},
      })

      if (['metasole', 'variosole'].includes(mountingName)) {
        window.WorkspaceHelper.addProjectErrorToReduxStore({
          message: `Our snow and wind loading calculations follow MCS guidelines, for more thorough static calculations please select the Renusol Configurator from the mounting system dropdown or consult a certified professional`,
          key: `${mountingName.toUpperCase()}_STRUCTURAL_INFO`,
          severity: 'info',
          systemId: system.uuid,
          source: 'plugin',
          category: 'mounting',
          options: {},
        })
      } else if (nativeRailSystems.includes(mountingName)) {
        window.WorkspaceHelper.addProjectErrorToReduxStore({
          message: `Our snow and wind loading calculations follow MCS guidelines, for more thorough static calculations please consult a certified professional`,
          key: `${mountingName.toUpperCase()}_STRUCTURAL_INFO`,
          severity: 'info',
          systemId: system.uuid,
          source: 'plugin',
          category: 'mounting',
          options: {},
        })
      }
      callback(structuralOptions)
    })
    .catch((err) => {
      callback(structuralOptions)
    })
}
