import { useTracking } from 'contexts/eventsTracking/TrackingContext'
import { useCallback } from 'react'
import { usePublicFeatureConfig } from './usePublicFeatureConfig'

interface UseTrackCustomHandlerParams {
  eventKey: string
  eventType: string
  eventName: string
  handler: (...args: any[]) => void
}

const useTrackCustomHandler = ({ eventKey, eventName, eventType, handler }: UseTrackCustomHandlerParams) => {
  const logEvent = useTracking()
  const featureConfig: Record<string, any> = usePublicFeatureConfig('event_config')

  const trackCustomHandler = useCallback(
    (...args: any[]) => {
      logEvent({ eventKey, eventName, eventType, additionalData: {}, eventConfig: featureConfig?.backends })
      handler(...args)
    },
    [eventKey, eventType, handler, logEvent]
  )

  return trackCustomHandler
}

export default useTrackCustomHandler
