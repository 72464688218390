import { FormHelperText, LinearProgress } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { CameraIcon, FileIcon, FormControl, FormLabel, styled, UploadIcon } from 'opensolar-ui'
import { LoadingOverlay } from 'projectSections/sections/manage3/details/elements/filesAndTransactions/files/common/styles/styles'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import React, { useState } from 'react'
import { FileField, FileInput, FunctionField, useNotify } from 'react-admin'
import { DropEvent } from 'react-dropzone'
import { Field, FieldInputProps } from 'react-final-form'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { RootState } from 'types/state'
import { doNotTranslate } from 'util/misc'
import { ListCalloutBox } from '../elements/ListCalloutBox'
import InlineTooltip from './EnaFields'

export type UploadStatus = 'initial' | 'uploading' | 'uploadFailed' | 'uploadComplete'

export interface UploadTips {
  title: string
  listItems: string[]
}

interface FileInputStaticData {
  isRequired: boolean
  isMultiple: boolean
  showImgExample: boolean
  placeholderIcon: React.ReactNode
  title: string | null
  tooltip: string | null
  uploadTips: UploadTips | null
  fileTypesAccepted: string
}

type FileInputFieldName = 'exportLimitSLDAttachment' | 'cutOutImages' | 'additionalAttachments'

const FILE_INPUT_STATIC_DATA: Record<FileInputFieldName, FileInputStaticData> = {
  exportLimitSLDAttachment: {
    isRequired: true,
    isMultiple: false,
    showImgExample: false,
    placeholderIcon: <UploadIcon />,
    title: 'Export Limit SLD Attachment',
    tooltip:
      'Please upload a single line diagram of the export limit device. This should show the main components of the export limit device and how they are connected. File must be less than 4MB in size.',
    uploadTips: null,
    fileTypesAccepted: '.pdf,.jpg,.jpeg,.png,.webp,.bmp,.tiff,.tif,.docx,.doc,.xlsx,.xls,.csv,.tsv',
  },
  cutOutImages: {
    isRequired: true,
    isMultiple: false,
    showImgExample: true,
    placeholderIcon: <CameraIcon />,
    title: 'Cut-Out Image',
    tooltip: null,
    uploadTips: {
      title: 'You must upload one image per application. Only one is allowed.',
      listItems: [
        'Is no more than 4 mb',
        'Is clear and in focus',
        'Is taken within 1m of the cut-out',
        'Contains the whole cut-out',
        'Is centred on the cut-out',
        'Is appropriately lit - use your flash if not',
        'PDFs are not accepted. Please convert to an image file such as PNG or JPEG',
      ],
    },
    fileTypesAccepted: '.png,.jpeg,.jpg',
  },
  additionalAttachments: {
    isRequired: false,
    isMultiple: true,
    showImgExample: false,
    placeholderIcon: <UploadIcon />,
    title: 'Additional Attachment(s)',
    tooltip: null,
    uploadTips: {
      title: 'Please upload any supplementing information relevant to your application.',
      listItems: [
        'A data-sheet is required for any new device which you have defined manually in the new devices section.',
        'You can upload any number of files, but each must be less than 4MB in size.',
        'This may include technical data sheets related to the devices selected, or any single line diagrams.',
        'Please name your files (prior to upload) in a way that makes it clear what they are',
        'If you need to upload larger files, try zipping them and uploading the zip.',
        'No SVGs permitted. Please convert to PNG or JPEG',
      ],
    },
    fileTypesAccepted: '.pdf,.jpg,.jpeg,.png,.webp,.bmp,.tiff,.tif,.docx,.doc,.xlsx,.xls,.csv,.tsv',
  },
}

const ExamplesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '0px 10px 10px',
}))

const Example = styled('div')(({ theme }) => ({
  width: '50%',
  overflow: 'hidden',
  padding: '0px 10px 10px',
  '&>img': {
    width: '100%',
    borderRadius: 8,
  },
}))

export const FilesField: React.FunctionComponent<any> = (props) => {
  const { path, fieldName } = props
  const [isUploading, setIsUploading] = useState(false)
  const projectId = useSelector((state: RootState) => state.projectId)
  const orgId = useSelector(authSelectors.getOrgId)
  const fileInputStaticData: FileInputStaticData = FILE_INPUT_STATIC_DATA[fieldName]
  const maxSizeInBytes = 4 * 1024 * 1024

  const notify = useNotify()

  const uploadFile = async (file) => {
    try {
      const formData = new FormData()
      formData.append('field_name', fieldName)
      formData.append('file', file)
      const url = `${window.API_ROOT}/api/orgs/${orgId}/projects/${projectId}/integration_file_upload/ena/`
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + appStorage.getToken(),
        },
      })
      if (response.status === 200) {
        return await response.json()
      }
    } catch (error) {
      notify(`Error uploading file ${file.name}.`, 'warning')
      return false
    }
  }

  const updateFiles = ({
    filesAccepted,
    filesRejected,
    isMultiple,
    input,
    event,
  }: {
    filesAccepted: File[]
    filesRejected: File[]
    isMultiple: boolean
    input: FieldInputProps<any, HTMLElement>
    event: DropEvent
  }) => {
    event.preventDefault()

    try {
      const uploadResponses = filesAccepted.map(async (file) => await uploadFile(file))
      Promise.all(uploadResponses).then((res) => {
        const uploadedRecords: any = res.filter(Boolean)
        if (isMultiple) {
          input.onChange([...(input.value || []), ...uploadedRecords])
        } else if (!!uploadedRecords.length) {
          input.onChange(uploadedRecords[0])
        } else {
          input.onChange(null)
        }
        if (!!filesRejected.length) {
          notify(
            `Error uploading file(s):
            ${filesRejected
              .map((f: any) => ` ${f.path} ${f.size > maxSizeInBytes && '(Maximum size exceeded)'}`)
              .toString()}`,
            'warning'
          )
        }
        setIsUploading(false)
      })
    } catch (error) {
      notify('Something went wrong', 'error')
    }
  }

  return (
    <div>
      {isUploading && (
        <LoadingOverlay>
          <LinearProgress variant="indeterminate" />
        </LoadingOverlay>
      )}
      {fileInputStaticData?.uploadTips && (
        <div style={{ marginBottom: 10 }}>
          <ListCalloutBox
            isCheckBullet
            listItems={fileInputStaticData?.uploadTips.listItems}
            title={fileInputStaticData?.uploadTips.title}
          >
            {fileInputStaticData.showImgExample && (
              <ExamplesContainer>
                <Example>
                  <p>Good image:</p>
                  <img src={`${window.PUBLIC_URL}/images/ena_good_img_eg.png`} />
                </Example>
                <Example>
                  <p>Bad image:</p>
                  <img src={`${window.PUBLIC_URL}/images/ena_bad_img_eg.png`} />
                </Example>
              </ExamplesContainer>
            )}
          </ListCalloutBox>
        </div>
      )}
      <FileInputContainer>
        <Field
          required={fileInputStaticData.isRequired}
          name={path}
          validate={(value) => {
            if (fileInputStaticData.isRequired && (!value || value.length === 0)) {
              return doNotTranslate('This field is required')
            }
          }}
        >
          {({ input, meta: { error, modified } }) => {
            return (
              <FormControl
                fullWidth
                required={fileInputStaticData.isRequired}
                margin="dense"
                error={modified && !!error}
              >
                {fileInputStaticData.title && (
                  <LabelContainer>
                    <FormLabel required={fileInputStaticData.isRequired}>
                      {fileInputStaticData.title}
                      {fileInputStaticData.tooltip && <InlineTooltip tooltip={fileInputStaticData.tooltip} />}
                    </FormLabel>
                  </LabelContainer>
                )}
                <StyledFileInput
                  source={path}
                  accept={fileInputStaticData.fileTypesAccepted}
                  placeholder={
                    <FileInputPlaceholderContainer>
                      <FileInputPlaceholderIcon>{fileInputStaticData.placeholderIcon}</FileInputPlaceholderIcon>
                      <FileInputHelperText>
                        {doNotTranslate('Drag & drop or')}
                        <span> {doNotTranslate('browse files')}</span>
                      </FileInputHelperText>
                    </FileInputPlaceholderContainer>
                  }
                  multiple={fileInputStaticData.isMultiple}
                  label={''}
                  options={{
                    onDrop: (filesAccepted, filesRejected, event) => {
                      setIsUploading(true)
                      updateFiles({
                        event,
                        filesAccepted,
                        filesRejected,
                        isMultiple: fileInputStaticData.isMultiple,
                        input,
                      })
                    },
                    noDragEventsBubbling: true,
                  }}
                  maxSize={maxSizeInBytes}
                >
                  <FunctionField
                    render={(file) => {
                      const size = file?.rawFile?.size && file.rawFile.size / 1000000
                      return (
                        <SelectedFileContainer>
                          <StyledFileIcon size={24} />
                          <p>{file?.rawFile?.path ? `Uploading ${file?.rawFile?.path}` : file?.fileUrl}</p>
                          {size && <span>{size.toFixed(2)}MB</span>}
                          <FileField />
                        </SelectedFileContainer>
                      )
                    }}
                  />
                </StyledFileInput>
                {error && <FormHelperText error={modified && !!error}>{error}</FormHelperText>}
              </FormControl>
            )
          }}
        </Field>
      </FileInputContainer>
    </div>
  )
}

export const FileInputPlaceholder = () => {
  return (
    <FileInputPlaceholderContainer>
      <FileInputPlaceholderIcon>
        <UploadIcon />
      </FileInputPlaceholderIcon>
      <FileInputHelperText>
        {doNotTranslate('Drag & drop or')}
        <span> {doNotTranslate('browse files')}</span>
      </FileInputHelperText>
    </FileInputPlaceholderContainer>
  )
}

interface SelectedFileRowProps {
  name: string
  size?: string
}

export const SelectedFileRow: React.FunctionComponent<SelectedFileRowProps> = ({ name, size, children }) => {
  return (
    <SelectedFileContainer>
      <StyledFileIcon size={24} />
      <p>{name}</p>
      {size && <span>{size} MB</span>}
      {children}
    </SelectedFileContainer>
  )
}

const StyledFileInput = styled(FileInput)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    display: 'none !important',
  },
}))

const FileInputContainer = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  marginBottom: -12,
  '& .previews > div': {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignContent: 'baseline',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 12,
    paddingLeft: 12,
    border: '2px solid',
    borderColor: theme.palette.grey[300],
  },
}))

const LabelContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  marginTop: 10,
  marginBottom: -15,
  flexDirection: 'row',
  '& .OSUI-FormLabel-root': {
    width: '100%',
  },
}))

const FileInputPlaceholderContainer = styled('div')(({ theme }) => ({
  border: '3px dashed',
  borderColor: theme.palette.grey[300],
  backgroundColor: 'white',
  margin: -7,
  borderRadius: 5,
  padding: 24,
  textAlign: 'center',
}))

const FileInputPlaceholderIcon = styled('div')(({ theme }) => ({
  '& svg': {
    width: 50,
    height: 50,
    marginTop: 10,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
    padding: 8,
  },
}))

const FileInputHelperText = styled('p')(({ theme }) => ({
  '& span': {
    color: COLOR_PALETTE.blue3,
  },
}))

const SelectedFileContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  '& p': {
    fontWeight: 500,
  },
  '& span': {
    color: theme.palette.grey[700],
    fontWeight: 400,
    fontSize: 12,
  },
}))

const StyledFileIcon = styled(FileIcon)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: 4,
  borderRadius: 5,
  color: theme.palette.grey[700],
}))
