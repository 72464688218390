import { orgSelectors } from 'ducks/orgs'
import { useNotify } from 'ra-core'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import composeUrlWithQueryParams from '../../../util/fetch/composeUrlWithQueryParams'

interface FetchParameters {
  parameters: {
    codes?: string[]
    search?: string
    limit?: number
  }
  onReady: (data: any) => void
}

const restClientInstance = restClient(window.API_ROOT + '/api')

// This should be used responsibily. The components v2 endpoint is optimized for lookups against codes, and
// it's not super likely that we'll have hundreds of different component types in a single project but I
// guess it's possible.
const useFetchComponentsData = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const orgId = useSelector(orgSelectors.getOrg)?.id || 0
  const notify = useNotify()

  const fetchComponentsData = ({ parameters, onReady }: FetchParameters) => {
    if (!parameters || Object.keys(parameters).length === 0) {
      return {}
    }

    setIsFetching(true)

    if (parameters.codes && parameters.codes?.length > 100) {
      console.warn('Calling components v2 endpoint with more than 100 codes. This is not recommended.')
    }

    const url = composeUrlWithQueryParams({
      baseUrl: `orgs/${orgId}/componentsv2/`,
      optionalParams: parameters,
    })

    restClientInstance('CUSTOM_GET', 'custom', {
      url: url,
    })
      .then((response) => {
        if (response?.data) {
          onReady(response.data)
        }
      })
      .catch((err: any) => {
        notify('There was an error when fetching component data.')
        console.error(err)
        console.log(err, err)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  return { fetchComponentsData, isFetching }
}

export default useFetchComponentsData
