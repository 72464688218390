import { BackendLogger } from 'types/events'
import { amplitudeLogger } from './amplitudeLogger'
import { ga4Logger } from './ga4Logger'

export const backendRegistry: Record<string, BackendLogger> = {
  amplitude: amplitudeLogger,
  ga4: ga4Logger,
} as const

export type BackendKey = keyof typeof backendRegistry

export const getBackendLogger = (platform: string): BackendLogger | undefined => backendRegistry[platform]
