import { Dialog, Typography } from '@material-ui/core'
import { Button, styled, TextField } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useState } from 'react'
interface PropTypes {
  isVisible: boolean
  onClose: () => void
  onSubmit: (name: string) => void
  viewName: string
}

const StyledDialog = styled(Dialog, { name: 'ViewEditNameDialogMain' })({
  position: 'absolute',
  alignSelf: 'center',
  margin: 'auto',
  padding: 0,
})

const StyledWrapper = styled('div', { name: 'ViewEditNameDialogWrapper' })({
  width: 624,
  minHeight: 269,
  borderRadius: 8,
  padding: 10,
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'space-between',
  alignSelf: 'center',
})

const SearchField = styled(TextField, { name: 'ViewEditNameDialogSearch' })({
  margin: 0,
  width: 400,
  maxWidth: '90%',
  marginRight: 10,
})

const Divider = styled('div', { name: 'ViewEditNameDialogDivider' })(({ theme }) => ({
  borderTop: '1px solid ' + theme.palette.grey[300],
  width: '100%',
  marginBottom: 10,
}))

const ButtonWrapper = styled('div', { name: 'ViewEditNameDialogButton' })({
  flexDirection: 'row',
  gap: 10,
  display: 'flex',
  alignSelf: 'flex-end',
})

const ViewEditNameDialog: React.FC<PropTypes> = ({ isVisible, onClose, onSubmit, viewName }) => {
  const [name, setName] = useState<string>(viewName)
  const translate = useTranslate()
  return (
    <StyledDialog
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
      }}
      PaperProps={{
        style: {
          boxShadow: 'none', // Removes the shadow for this specific dialog
        },
      }}
      open={isVisible}
      onClose={onClose}
    >
      <StyledWrapper>
        <div>
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            {translate('Rename View')}
          </Typography>
          <Divider />
          <Typography variant="body1" style={{ marginBottom: 4, marginTop: 30 }}>
            {translate('View Name')}
          </Typography>
          <SearchField
            label={false}
            size="small"
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            placeholder={translate('Search')}
          />
        </div>
        <ButtonWrapper>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {translate('Discard')}
          </Button>
          <Button variant="contained" color="primary" onClick={() => onSubmit(name)}>
            {translate('Confirm')}
          </Button>
        </ButtonWrapper>
      </StyledWrapper>
    </StyledDialog>
  )
}
export default ViewEditNameDialog
