import lodash from 'lodash'
import { AccessRightsType } from 'types/global'
import { PermissionsState } from 'types/permissions'
import type { PermissionSet } from 'types/roles'
import { PermissionKey } from 'types/roles'
import { RootState } from 'types/state'
import { getCachedFunctionReturnValue } from 'util/misc'
import { authSelectors, SET_ORG_ID } from './auth'

export const PROJECT_PERMISSIONS_UPDATE = 'PROJECT_PERMISSIONS_UPDATE'
export const PROJECT_PERMISSIONS_CLEAR = 'PROJECT_PERMISSIONS_CLEAR'
export const INHERIT_PROJECT_PERMISSIONS = 'INHERIT_PROJECT_PERMISSIONS'
export const SET_PERMISSION_OVERRIDES = 'SET_PERMISSION_OVERRIDES'
export const SET_DESIGN_PERMISSION_OVERRIDES = 'SET_DESIGN_PERMISSION_OVERRIDES'

const defaultState: PermissionsState = {
  permissionsCache: {},
  projectPermissions: undefined,
  projectPermissionsCache: undefined,
  permissionOverrides: {},
  designPermissionOverrides: {},
}

interface SetPermissionOverridesAction {
  type: typeof SET_PERMISSION_OVERRIDES
  payload: Partial<PermissionSet>
}

/**
 *
 * @param blockedPermissions
 * e.g. ['design', 'projects.view', 'projects.edit']
 * Note that nested permission resource key is not support atm e.g 'design.payment_options.view'
 */
export const setBlockedPermissions = (blockedPermissions: string[]): SetPermissionOverridesAction => {
  const permissionOverrides: Partial<PermissionSet> = {}
  blockedPermissions.forEach((key) => {
    const parts = key.split('.')
    const resourceKey = parts[0] // Note: nested permission resource key not supported
    const specificRight = ['edit', 'view', 'create', 'delete'].find((right) => parts[parts.length - 1] === right)
    const disableEntireResource = !specificRight

    if (disableEntireResource) {
      permissionOverrides[resourceKey] = {
        view: false,
        create: false,
        edit: false,
        delete: false,
      }
    } else {
      permissionOverrides[resourceKey] = {
        [specificRight]: false,
      }
    }
  })
  return {
    type: SET_PERMISSION_OVERRIDES,
    payload: permissionOverrides,
  }
}

export function permissionsReducer(
  previousState: PermissionsState | undefined,
  { type, payload }: { type: string; payload: any }
) {
  if (!previousState) {
    previousState = lodash.cloneDeep(defaultState)
  }
  switch (type) {
    case SET_PERMISSION_OVERRIDES:
      return {
        ...previousState,
        permissionOverrides: payload,
      }

    case SET_DESIGN_PERMISSION_OVERRIDES:
      return {
        ...previousState,
        designPermissionOverrides: payload,
      }

    case SET_ORG_ID:
      // Clear all permissions caches when org switches
      return {
        ...previousState,
        projectPermissions: defaultState.projectPermissions,
        projectPermissionsCache: defaultState.projectPermissionsCache,
        permissionsCache: lodash.cloneDeep(defaultState.permissionsCache),
      }

    case PROJECT_PERMISSIONS_UPDATE:
      previousState.projectPermissions =
        payload?.permissions !== undefined
          ? // Order is matter to ensure the permissionOverrides will override the rolePermissions
            lodash.defaultsDeep({}, previousState.permissionOverrides, payload.permissions)
          : undefined
      previousState.projectPermissionsCache = {}
      break

    case PROJECT_PERMISSIONS_CLEAR:
      previousState.projectPermissions = undefined
      previousState.projectPermissionsCache = undefined
      break

    case INHERIT_PROJECT_PERMISSIONS:
      previousState.projectPermissionsCache = {}
      break
  }

  return previousState
}

const getCachedPermissionValue = getCachedFunctionReturnValue(
  (permissionOverrides: Partial<PermissionSet>, rolePermissions: PermissionSet): PermissionSet => {
    // Order is matter to ensure the permissionOverrides will override the rolePermissions
    return lodash.defaultsDeep({}, permissionOverrides, rolePermissions)
  }
)

export const getAccessRightsByKey = ({
  permissions,
  key,
}: {
  permissions?: Partial<PermissionSet> //PermissionSet Like
  key: string
}): AccessRightsType => {
  if (permissions?.[key]) {
    const setting = permissions[key]
    return {
      allowView: !!setting.view,
      allowCreate: !!setting.create,
      allowEdit: !!setting.edit,
      allowDelete: !!setting.delete,
    }
  } else {
    return { allowView: false, allowCreate: false, allowEdit: false, allowDelete: false }
  }
}

export const permissionsSelectors = {
  getPermissionsSetting: (state: RootState): PermissionSet | undefined => {
    const role = authSelectors.getCurrentRole(state)
    if (!role) return undefined
    return getCachedPermissionValue(state.permissions.permissionOverrides, role.permissions)
  },
  getPermissionByKey: (key?: PermissionKey) => {
    return (state: RootState): AccessRightsType => {
      if (!key) {
        return { allowView: true, allowCreate: true, allowEdit: true, allowDelete: true }
      }

      if (!permissionsSelectors.getIsPermissionsLoaded(state)) {
        return { allowView: false, allowCreate: false, allowEdit: false, allowDelete: false }
      }

      if (state.permissions.permissionsCache[key]) {
        return state.permissions.permissionsCache[key]
      }
      const permissions = permissionsSelectors.getPermissionsSetting(state)
      let ret: AccessRightsType
      if (permissions?.[key]) {
        const setting = permissions[key]
        //convert 0|1 to boolean value, avoid unexpected 0,1 add to react render
        ret = {
          allowView: !!setting.view,
          allowCreate: !!setting.create,
          allowEdit: !!setting.edit,
          allowDelete: !!setting.delete,
        }
      } else {
        ret = { allowView: false, allowCreate: false, allowEdit: false, allowDelete: false }
      }
      state.permissions.permissionsCache[key] = ret

      return ret
    }
  },
  getProjectPermissionsSetting: (state: RootState) => {
    if (!permissionsSelectors.getProjectPermissionsLoaded(state)) {
      throw new Error('Project permissions not loaded, consider using `getPermissionByKey`')
    }
    // This fallback behaviour is for the 'inherited' case, which happens for non-shared projects
    return permissionsSelectors.getSafeProjectPermissionsSetting(state)
  },
  getSafeProjectPermissionsSetting: (state: RootState) => {
    // This fallback behaviour is for the 'inherited' case, which happens for non-shared projects
    if (state.permissions.projectPermissions) {
      return state.permissions.projectPermissions
    } else {
      return permissionsSelectors.getPermissionsSetting(state)
    }
  },
  getIsPermissionsLoaded: (state: RootState): boolean => {
    const role = authSelectors.getCurrentRole(state)
    return !!role
  },
  getProjectPermissionsLoaded: (state: RootState): boolean => {
    return !!state.permissions.projectPermissionsCache
  },
  getProjectPermissionByKey: (key?: PermissionKey, skipError?: boolean) => {
    return (state: RootState): AccessRightsType => {
      if (!permissionsSelectors.getProjectPermissionsLoaded(state)) {
        if (skipError)
          return {
            allowView: false,
            allowCreate: false,
            allowEdit: false,
            allowDelete: false,
          }
        throw new Error('Project permissions not loaded, consider using `getPermissionByKey`')
      }

      if (!key) {
        return { allowView: true, allowCreate: true, allowEdit: true, allowDelete: true }
      }

      if (!permissionsSelectors.getIsPermissionsLoaded(state)) {
        return { allowView: false, allowCreate: false, allowEdit: false, allowDelete: false }
      }

      if (state.permissions.projectPermissionsCache?.[key]) {
        return state.permissions.projectPermissionsCache[key]
      }

      const permissions = permissionsSelectors.getProjectPermissionsSetting(state)
      const ret = getAccessRightsByKey({ permissions, key })

      if (state.permissions.projectPermissionsCache) state.permissions.projectPermissionsCache[key] = ret
      return ret
    }
  },
  getDesignPermissionOverrides: (state: RootState) => {
    return state.permissions.designPermissionOverrides
  },
  hasAssessRight: (state: RootState) => {
    const accessRightsSetting = permissionsSelectors.getPermissionsSetting(state)
    return (key: PermissionKey | PermissionKey[] | undefined) => {
      if (!key) return true
      else if (Array.isArray(key))
        return key.every((k) => accessRightsSetting && accessRightsSetting[k] && accessRightsSetting[k]['view'])
      else if (typeof key === 'string') {
        if (accessRightsSetting && accessRightsSetting[key] && accessRightsSetting[key]['view']) return true
      } else return false
    }
  },
}
