import { Button, CopyLeftOutlineIcon, DeleteOutlineIcon, styled } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useField, useForm } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import {
  DATASE_AND_DEVICECLASS_CONFIG,
  DeviceClass,
  DEVICECLASS_TO_SCHEMA_CONFIG,
  DeviceConfig,
  DeviceSchemaId,
  DeviceType,
  EnaLctDataset,
  ENA_LCTs,
  LctId,
  SectionId,
} from '../consts'
import { ListCalloutBox } from '../elements/ListCalloutBox'
import { DeviceSearchField } from './DeviceSearchField'
import { EnaSelectField } from './EnaFields'
import { EnaInputData, parseInputSchema, SchemaFields } from './SchemaFields'

interface DeviceFormProps extends DevicesArrayFieldProps {
  devicePathWithIndex: string
  index: number
  fields: any
  lcts: LctId[]
}
interface InitialDeviceSearchConfig {
  staticDowndownOptions: DeviceType[] | null
  deviceSearchLabel: string
}

export const DEVICE_SEARCH_INITIAL_CONFIG: Record<'existingDevices' | 'devicesToInstall', InitialDeviceSearchConfig> = {
  existingDevices: {
    staticDowndownOptions: ['BATTERY', 'EVCP_DC', 'EVCP_AC', 'V2G_INVERTER', 'HP', 'SOLAR_PV'],
    deviceSearchLabel: 'Select LCT',
  },
  devicesToInstall: {
    staticDowndownOptions: null,
    deviceSearchLabel: 'Select LCT',
  },
}

interface DeviceSetupProps {
  isManualMode: boolean
  deviceType: DeviceType
  schema: any
}
interface DeviceSetup {
  enaLctDataset: EnaLctDataset
  deviceClass: DeviceClass
  deviceSchemaId: DeviceSchemaId
  isManualMode: boolean
  deviceType: DeviceType
  deviceSchema: Object
  hasManualMode: boolean
}

const DeviceForm: React.FC<DeviceFormProps> = (props) => {
  const { devicePathWithIndex, fieldName: devicesSectionId, schema, lcts, index, fields } = props
  const {
    input: { value: currentDevice },
  } = useField(devicePathWithIndex, {
    subscription: { value: true, dirty: true, touched: true },
  })
  const [deviceSetup, setDeviceSetup] = useState<DeviceSetup | null>(null)
  const form = useForm()
  interface OptionsAndLabel {
    deviceTypeOptions: DeviceType[]
    deviceSearchLabel: string
  }

  const { deviceTypeOptions, deviceSearchLabel }: OptionsAndLabel = useMemo(() => {
    const deviceTypeOptions =
      DEVICE_SEARCH_INITIAL_CONFIG[devicesSectionId].staticDowndownOptions || lcts.map((i) => ENA_LCTs[i].deviceType)

    if (
      devicesSectionId === 'devicesToInstall' &&
      deviceTypeOptions.includes('V2G_INVERTER') &&
      !deviceTypeOptions.includes('EVCP_AC')
    ) {
      deviceTypeOptions.push('EVCP_AC')
    }
    const { deviceSearchLabel } = DEVICE_SEARCH_INITIAL_CONFIG[devicesSectionId]
    return { deviceTypeOptions, deviceSearchLabel }
  }, [lcts, devicesSectionId])

  const getDeviceConfig = ({ isManualMode, deviceType, schema }: DeviceSetupProps): DeviceSetup => {
    const isRegisteredFormType = isManualMode ? 'notRegistered' : 'registered'
    const deviceConfig: DeviceConfig = DATASE_AND_DEVICECLASS_CONFIG[isRegisteredFormType][devicesSectionId][deviceType]
    const deviceSchemaId: DeviceSchemaId = DEVICECLASS_TO_SCHEMA_CONFIG[deviceConfig.deviceClass]
    const deviceSchema = schema?.items?.oneOf.find((s) => s.title === deviceSchemaId) as EnaInputData
    const hasManualMode = deviceSchemaId !== 'GenerationDeviceToInstallV1'
    return { ...deviceConfig, deviceSchemaId, isManualMode, deviceType, deviceSchema, hasManualMode }
  }

  useEffect(() => {
    if (!deviceSetup && currentDevice?.deviceType && currentDevice.deviceClass) {
      const setup = getDeviceConfig({
        isManualMode: currentDevice.deviceClass.includes('NOT_REGISTERED'),
        deviceType: currentDevice.deviceType,
        schema,
      })
      setDeviceSetup(setup)
    }
  }, [])

  const onDeviceChange = (deviceType: DeviceType) => {
    const setup = getDeviceConfig({ isManualMode: false, deviceType, schema })
    form.change(devicePathWithIndex, {
      enaLctDataset: setup.enaLctDataset,
      deviceType: deviceType,
      deviceClass: setup.deviceClass,
      supplementingInfo: { deviceSysRefSearchResult: {} },
    })
    setDeviceSetup(setup)
  }

  const setManualMode = useCallback((deviceType) => {
    const setup = getDeviceConfig({ isManualMode: true, deviceType, schema })
    form.change(devicePathWithIndex, {
      enaLctDataset: setup.enaLctDataset,
      deviceType: deviceType,
      deviceClass: setup.deviceClass,
    })
    setDeviceSetup(setup)
  }, [])

  const onRemoveSelection = () => {
    setDeviceSetup(null)
  }

  return (
    <div style={{ width: '100%', marginTop: -16, display: 'flex', flexDirection: 'column' }}>
      <EnaSelectField
        parsedSchema={{
          ...(parseInputSchema(schema) as EnaInputData),
          title: deviceSearchLabel,
          selectOptions: deviceTypeOptions,
        }}
        path={`${devicePathWithIndex}.deviceType`}
        customOnChange={onDeviceChange}
      />
      {!deviceSetup?.isManualMode && deviceSetup?.deviceType && (
        <DeviceSearchField
          deviceType={deviceSetup.deviceType}
          enaLctDataset={deviceSetup.enaLctDataset}
          fieldName={'deviceSysRef'}
          parentPath={devicePathWithIndex}
          key={`${devicePathWithIndex}-${deviceSetup.deviceType}-${deviceSetup.enaLctDataset}`}
          onReset={onRemoveSelection}
        />
      )}
      {!deviceSetup?.isManualMode && deviceSetup?.hasManualMode && (
        <div style={{ marginTop: -10 }}>
          <Button
            type="button"
            variant="text"
            color="info"
            size="small"
            onClick={() => setManualMode(deviceSetup.deviceType)}
          >
            Enter device manually
          </Button>
        </div>
      )}
      <SchemaFields
        schema={deviceSetup?.deviceSchema}
        parentPath={devicesSectionId}
        fieldName={devicePathWithIndex}
        index={index}
        key={devicePathWithIndex + deviceSetup?.deviceClass}
      />

      <div style={{ marginTop: 25, marginBottom: 15, display: 'flex', flexDirection: 'row', gap: 10 }}>
        <Button
          onClick={() => fields.remove(index)}
          variant={'contained'}
          color="error"
          startIcon={<DeleteOutlineIcon size={22} />}
          type="button"
        >
          Delete
        </Button>

        <Button
          onClick={() => fields.push({ ...currentDevice })}
          variant={'outlined'}
          startIcon={<CopyLeftOutlineIcon size={15} />}
          type="button"
        >
          Duplicate
        </Button>
      </div>
    </div>
  )
}

interface DevicesArrayFieldProps {
  fieldName: Extract<SectionId, 'existingDevices' | 'devicesToInstall'>
  schema: any
}

export const DevicesArrayField: React.FC<DevicesArrayFieldProps> = (props) => {
  const {
    input: { value: lcts },
  } = useField('lcts', { subscription: { value: true, dirty: true, touched: true } })
  const {
    input: { value: devicesToInstall },
  } = useField('devicesToInstall', { subscription: { value: true, dirty: true, touched: true } })

  const isInstallNewDevicesStep = props.fieldName === 'devicesToInstall'
  const requiresExtraInverter = isInstallNewDevicesStep && lcts.includes('EVV2G')

  const getMissingDevices = (lcts, devicesToInstall): LctId[] => {
    const lctsRequired: LctId[] = requiresExtraInverter ? [...lcts, 'EVCP_AC'] : lcts //Ena documentation requires that if a Vehicle to Grid device is selected in lcts then a 'EVCP_AC' device must be added as well.
    const devicesSelected: DeviceType[] = devicesToInstall.map((i) => i.deviceType)
    return lctsRequired.filter((i) => !devicesSelected.includes(ENA_LCTs[i].deviceType))
  }

  const missingLcts: LctId[] = isInstallNewDevicesStep ? getMissingDevices(lcts, devicesToInstall) : []

  return (
    <div>
      {requiresExtraInverter && (
        <div style={{ marginBottom: 20 }}>
          <ListCalloutBox
            title={'Applications including a Vehicle to Grid (V2G) inverter expect an EV Charger (AC)'}
            variant="warning"
            listItems={[
              'Each V2G_INVERTER must be paired with an EVCP_AC device.',
              'After selecting your V2G_INVERTER, please ensure there is an EVCP_AC device with the with the same model / system reference as this V2G inverter.',
              'If you cannot find a matching EVCP_AC in the database, please enter it manually.',
            ]}
          />
        </div>
      )}
      <FieldArray
        name={props.fieldName}
        validate={(v) => {
          if (isInstallNewDevicesStep) {
            const selectedDevices = v || []
            const missingDevices = getMissingDevices(lcts, selectedDevices)
            return !!missingDevices.length
          }
        }}
      >
        {({ fields }) => (
          <div>
            {fields.map((devicePathWithIndex, index) => {
              return (
                <DeviceRow key={devicePathWithIndex + index}>
                  <div>
                    <AccordionCard
                      defaultExpanded={true}
                      name={devicePathWithIndex}
                      title={'Device Details'}
                      content={DeviceForm}
                      contentProps={{
                        devicePathWithIndex,
                        index,
                        fields,
                        lcts,
                        ...props,
                      }}
                    />
                  </div>
                </DeviceRow>
              )
            })}
            <Button
              type={'button'}
              variant={'outlined'}
              color={'secondary'}
              onClick={() =>
                fields.push({
                  deviceClass: null,
                  supplementingInfo: { deviceSysRefSearchResult: {} },
                })
              }
            >
              + Add Device
            </Button>
          </div>
        )}
      </FieldArray>

      {Boolean(isInstallNewDevicesStep && missingLcts.length) && (
        <div style={{ marginTop: 20 }}>
          <ListCalloutBox
            title={'You must add at least 1 of each of the following devices'}
            variant="error"
            listItems={missingLcts.map((i) => `${ENA_LCTs[i].deviceType} (${ENA_LCTs[i].label})`)}
          />
        </div>
      )}
    </div>
  )
}

export const DeviceRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  paddingBottom: 16,
  gap: 10,
  '& > *': {
    display: 'flex',
  },
  '& > div:nth-child(1)': {
    flex: 1,
  },
  '& > div:nth-child(2) > button': {
    marginTop: 7,
  },
  '& .MuiAccordion-root': {
    width: '100%',
  },
}))

// Getting deviceTypeOptions for devicesToInstall from the selected lcts instead of the discriminator.
// This is because using the discriminator in the schema returns ALL the deviceTypes that match the current applicationClass, not just the device types that correspond to the selected LCTS
// If the user selects a deviceType that doesn't match the selected lcts the application fails, so we need to ensure that only applicable device types are shown without using the discriminator (this is what ENA does in their portal application as well)
