export type SectionId =
  | 'continueApplication'
  | 'lcts'
  | 'installerCustomerDetails'
  | 'supplyDetails'
  | 'existingDevices'
  | 'devicesToInstall'
  | 'cutOutImages'
  | 'additionalAttachments'
  | 'reviewAndSubmit'

export type SchemaId = 'GenerationGeneralV1' | 'GenerationDemandGeneralV1' | 'DemandGeneralV1'
export type LctId = 'BATTERY' | 'EVCP_DC' | 'EVCP_AC' | 'EVV2G' | 'HP' | 'SOLAR_PV'
export type DeviceType = 'BATTERY' | 'EVCP_DC' | 'EVCP_AC' | 'V2G_INVERTER' | 'HP' | 'SOLAR_PV'
export type ApplicationClass = 'GENERATION_GENERAL' | 'DEMAND_GENERAL' | 'GENERATION_DEMAND_GENERAL'
export type EnaLctDataset = 'TTR' | 'EVAC' | 'EVDC' | 'HP'

export type DeviceSchemaId =
  | 'GenerationDeviceToInstallV1'
  | 'DemandDatabaseToInstallGeneralV1'
  | 'DemandNotDatabaseToInstallGeneralV1'
  | 'DemandDatabaseToInstallHPV1'
  | 'DemandNotDatabaseToInstallHPV1'
  | 'ExistingGenDevicesInDatabaseV1'
  | 'ExistingGenDevicesNotInDatabaseV1'
  | 'ExistingDemandDevicesInDatabaseV1'
  | 'ExistingDemandDevicesNotInDatabaseV1'

export type DeviceClass =
  | 'GEN_DEVICE_GENERAL_ENA_REGISTERED'
  | 'DEMAND_DEVICE_GENERAL_ENA_REGISTERED'
  | 'DEMAND_DEVICE_GENERAL_NOT_REGISTERED'
  | 'DEMAND_DEVICE_HP_ENA_REGISTERED'
  | 'DEMAND_DEVICE_HP_NOT_REGISTERED'
  | 'EXISTING_GEN_ENA_REGISTERED'
  | 'EXISTING_GEN_NOT_REGISTERED'
  | 'EXISTING_DEMAND_ENA_REGISTERED'
  | 'EXISTING_DEMAND_NOT_REGISTERED'

export enum EnaSubmissionStep {
  'Draft Started' = 0,
  'In Review' = 1,
  'Outcome' = 2,
}

export const SCHEMA_MAPPING: Record<ApplicationClass, SchemaId> = {
  GENERATION_GENERAL: 'GenerationGeneralV1',
  DEMAND_GENERAL: 'DemandGeneralV1',
  GENERATION_DEMAND_GENERAL: 'GenerationDemandGeneralV1',
}

export const DEVICECLASS_TO_SCHEMA_CONFIG: Record<DeviceClass, DeviceSchemaId> = {
  GEN_DEVICE_GENERAL_ENA_REGISTERED: 'GenerationDeviceToInstallV1',
  DEMAND_DEVICE_GENERAL_ENA_REGISTERED: 'DemandDatabaseToInstallGeneralV1',
  DEMAND_DEVICE_GENERAL_NOT_REGISTERED: 'DemandNotDatabaseToInstallGeneralV1',
  DEMAND_DEVICE_HP_ENA_REGISTERED: 'DemandDatabaseToInstallHPV1',
  DEMAND_DEVICE_HP_NOT_REGISTERED: 'DemandNotDatabaseToInstallHPV1',
  EXISTING_GEN_ENA_REGISTERED: 'ExistingGenDevicesInDatabaseV1',
  EXISTING_GEN_NOT_REGISTERED: 'ExistingGenDevicesNotInDatabaseV1',
  EXISTING_DEMAND_ENA_REGISTERED: 'ExistingDemandDevicesInDatabaseV1',
  EXISTING_DEMAND_NOT_REGISTERED: 'ExistingDemandDevicesNotInDatabaseV1',
}

export type DeviceConfig = {
  enaLctDataset: EnaLctDataset
  deviceClass: DeviceClass
}

type DeviceGroup = Record<DeviceType, DeviceConfig>

type DeviceCategory = {
  existingDevices: DeviceGroup
  devicesToInstall: DeviceGroup
}

type DataAndDeviceClassConfig = {
  registered: DeviceCategory
  notRegistered: DeviceCategory
}

export const DATASE_AND_DEVICECLASS_CONFIG: DataAndDeviceClassConfig = {
  registered: {
    existingDevices: {
      SOLAR_PV: {
        enaLctDataset: 'TTR',
        deviceClass: 'EXISTING_GEN_ENA_REGISTERED',
      },
      BATTERY: {
        enaLctDataset: 'TTR',
        deviceClass: 'EXISTING_GEN_ENA_REGISTERED',
      },
      V2G_INVERTER: {
        enaLctDataset: 'TTR',
        deviceClass: 'EXISTING_GEN_ENA_REGISTERED',
      },
      HP: {
        enaLctDataset: 'HP',
        deviceClass: 'EXISTING_DEMAND_ENA_REGISTERED',
      },
      EVCP_AC: {
        enaLctDataset: 'EVAC',
        deviceClass: 'EXISTING_DEMAND_ENA_REGISTERED',
      },
      EVCP_DC: {
        enaLctDataset: 'EVDC',
        deviceClass: 'EXISTING_DEMAND_ENA_REGISTERED',
      },
    },
    devicesToInstall: {
      SOLAR_PV: {
        enaLctDataset: 'TTR',
        deviceClass: 'GEN_DEVICE_GENERAL_ENA_REGISTERED',
      },
      BATTERY: {
        enaLctDataset: 'TTR',
        deviceClass: 'GEN_DEVICE_GENERAL_ENA_REGISTERED',
      },
      V2G_INVERTER: {
        enaLctDataset: 'TTR',
        deviceClass: 'GEN_DEVICE_GENERAL_ENA_REGISTERED',
      },
      HP: {
        enaLctDataset: 'HP',
        deviceClass: 'DEMAND_DEVICE_HP_ENA_REGISTERED',
      },
      EVCP_AC: {
        enaLctDataset: 'EVAC',
        deviceClass: 'DEMAND_DEVICE_GENERAL_ENA_REGISTERED',
      },
      EVCP_DC: {
        enaLctDataset: 'EVDC',
        deviceClass: 'DEMAND_DEVICE_GENERAL_ENA_REGISTERED',
      },
    },
  },
  notRegistered: {
    existingDevices: {
      SOLAR_PV: {
        enaLctDataset: 'TTR',
        deviceClass: 'EXISTING_GEN_NOT_REGISTERED',
      },
      BATTERY: {
        enaLctDataset: 'TTR',
        deviceClass: 'EXISTING_GEN_NOT_REGISTERED',
      },
      V2G_INVERTER: {
        enaLctDataset: 'TTR',
        deviceClass: 'EXISTING_GEN_NOT_REGISTERED',
      },
      HP: {
        enaLctDataset: 'HP',
        deviceClass: 'EXISTING_DEMAND_NOT_REGISTERED',
      },
      EVCP_AC: {
        enaLctDataset: 'EVAC',
        deviceClass: 'EXISTING_DEMAND_NOT_REGISTERED',
      },
      EVCP_DC: {
        enaLctDataset: 'EVDC',
        deviceClass: 'EXISTING_DEMAND_NOT_REGISTERED',
      },
    },
    devicesToInstall: {
      SOLAR_PV: {
        enaLctDataset: 'TTR',
        deviceClass: 'GEN_DEVICE_GENERAL_ENA_REGISTERED',
      },
      BATTERY: {
        enaLctDataset: 'TTR',
        deviceClass: 'GEN_DEVICE_GENERAL_ENA_REGISTERED',
      },
      V2G_INVERTER: {
        enaLctDataset: 'TTR',
        deviceClass: 'GEN_DEVICE_GENERAL_ENA_REGISTERED',
      },
      HP: {
        enaLctDataset: 'HP',
        deviceClass: 'DEMAND_DEVICE_HP_NOT_REGISTERED',
      },
      EVCP_AC: {
        enaLctDataset: 'EVAC',
        deviceClass: 'DEMAND_DEVICE_GENERAL_NOT_REGISTERED',
      },
      EVCP_DC: {
        enaLctDataset: 'EVDC',
        deviceClass: 'DEMAND_DEVICE_GENERAL_NOT_REGISTERED',
      },
    },
  },
}

export interface EnaLct {
  label: string
  applicationClass: ApplicationClass
  deviceType: DeviceType
}

export const ENA_LCTs: Record<LctId, EnaLct> = {
  SOLAR_PV: {
    label: 'Solar Panel',
    deviceType: 'SOLAR_PV',
    applicationClass: 'GENERATION_GENERAL',
  },
  BATTERY: {
    label: 'Battery',
    applicationClass: 'GENERATION_DEMAND_GENERAL',
    deviceType: 'BATTERY',
  },
  HP: {
    label: 'Heat Pump',
    applicationClass: 'DEMAND_GENERAL',
    deviceType: 'HP',
  },
  EVV2G: {
    label: 'Vehicle to Grid (V2G)',
    applicationClass: 'GENERATION_DEMAND_GENERAL',
    deviceType: 'V2G_INVERTER',
  },
  EVCP_AC: {
    label: 'EV Charger (AC)',
    applicationClass: 'DEMAND_GENERAL',
    deviceType: 'EVCP_AC',
  },
  EVCP_DC: {
    label: 'EV Charger (DC)',
    applicationClass: 'DEMAND_GENERAL',
    deviceType: 'EVCP_DC',
  },
}

export interface EnaStaticData {
  sectionId: SectionId
  stepLabel: string
  title: string
  subtitle: string
}

export const ENA_STATIC_DATA: EnaStaticData[] = [
  {
    sectionId: 'lcts',
    stepLabel: '',
    title: 'What are you installing?',
    subtitle: 'You can select one or multiple technologies',
  },
  {
    sectionId: 'installerCustomerDetails',
    stepLabel: 'Basic Details',
    title: 'Basic Details',
    subtitle: 'This section of the form captures basic detail about yourself (the installer) and the customer',
  },
  {
    sectionId: 'supplyDetails',
    stepLabel: 'Property Supply',
    title: 'Property Supply Details',
    subtitle: 'This section of the form captures information about the supply at the customer’s address',
  },
  {
    sectionId: 'existingDevices',
    stepLabel: 'Existing Devices',
    title: 'Existing Devices',
    subtitle:
      'This section allows you to register any existing devices on the property. You should also list any existing devices which are being replaced as part of this application.',
  },
  {
    sectionId: 'devicesToInstall',
    stepLabel: 'New Devices',
    title: 'New Devices',
    subtitle:
      "Tell us what devices you'd like to install! You can enter one or multiple devices in a single application. Many devices are already registered on ENA's Low Carbon Technology asset registers. You can search for your device on these registers below!",
  },
  {
    sectionId: 'cutOutImages',
    stepLabel: 'Cut-Out Image Upload',
    title: 'Cut-Out Image Upload',
    subtitle:
      "Please use this part of the application process to upload an image of the property's cut-out. This is required.",
  },
  {
    sectionId: 'additionalAttachments',
    stepLabel: 'Supporting Files',
    title: 'Supporting Files',
    subtitle: 'Use this section to upload any additional files that are relevant to your application',
  },
  {
    sectionId: 'reviewAndSubmit',
    stepLabel: 'Review and Submit',
    title: 'Review and Submit',
    subtitle: 'One final check before submitting!',
  },
]
