import { orgSelectors } from 'ducks/orgs'
import { DISTRIBUTOR_ACCOUNT_MANAGER, DISTRIBUTOR_DEFAULT_VALUES } from 'pages/ordering/checkoutv3/preOrder/constants'
import usePreOrderService from 'pages/ordering/checkoutv3/preOrder/hooks/usePreOrderService'
import PreOrderPresenter from 'pages/ordering/checkoutv3/preOrder/PreOrderPresenter'
import { AccountManagerType, ContactInfoType, DeliveryAddressInfoType } from 'pages/ordering/checkoutv3/preOrder/types'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'

export const PreOrderPresenterContext = createContext<PreOrderPresenter | undefined>(undefined)
export const SystemContext = createContext<any>(undefined)
export const DistributorDataContext = createContext<any>(undefined)
export const ContactInfoContext = createContext<{ [key: string]: ContactInfoType }>({})
export const DeliveryAddressInfoContext = createContext<{ [key: string]: DeliveryAddressInfoType }>({})
export const AccountManagerContext = createContext<{ [key: string]: AccountManagerType | undefined }>({})
export const TotalItemCountContext = createContext<number>(0)

const restClientInstance = restClient(window.API_ROOT + '/api')

const ResendOrderProvider = ({ children, orderId }) => {
  const orgId = useSelector(orgSelectors.getOrg)?.id || 0
  const service = usePreOrderService()
  const [distributorData, setDistributorData] = useState<{ [key: string]: any }>({})
  const [contactInfo, setContactInfo] = useState<{ [key: string]: ContactInfoType }>({})
  const [deliveryAddressInfo, setDeliveryAddressInfo] = useState<{ [key: string]: DeliveryAddressInfoType }>({})
  const [accountMangerInfo, setAccountManagerInfo] = useState<{ [key: string]: AccountManagerType | undefined }>(
    DISTRIBUTOR_ACCOUNT_MANAGER
  )
  const [totalItemCount, setTotalItemCount] = useState<number>(0)
  const [system, setSystem] = useState<any>(undefined)

  useEffect(() => {
    const getOrderData = async () => {
      const { data } = await restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/orders/${orderId}/`,
      })
      const orderDistributor = data?.distributor
      const orderData = data?.original_order_data
      const codes = orderData?.items?.map((item) => item.code)
      if (!codes) {
        return
      }

      const { data: componentsData } = await restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/componentsv2/?codes=${codes.join(',')}&limit=${codes.length}`,
        params: {
          codes: codes.join(','),
        },
      })
      const lineItems: OrderLineItem[] = componentsData?.map((item) => {
        const orderDataItem = orderData?.items.find((odItem) => odItem.code === item.code)
        const orderingData = item?.ordering_v2?.find(({ distributor }) => distributor === orderDistributor)
        const orderLineItem = new OrderLineItem({
          componentType: item.type,
          otherComponentType: orderingData?.other_component_type,
          status: 'loaded',
          code: item.code,
          selectedDistributor: orderDistributor,
          data: {
            code: item.code,
            component_type: item.type,
            description: orderingData?.description,
            short_description: orderingData?.short_description || '',
            distributors: orderingData ? [orderingData] : [],
            data: JSON.stringify({}),
            id: item.id,
            manufacturer_name: orderingData?.manufacturer,
            title: orderingData?.title || '',
            image_url: orderingData?.image_url,
          },
          confirmed: true,
          quantity: orderDataItem?.quantity || 0,
        })
        return orderLineItem
      })

      setContactInfo({
        general: orderData?.contactInfo,
        [orderDistributor]: orderData?.contactInfo,
      })
      setDeliveryAddressInfo({
        general: orderData?.shippingInfo?.address,
        [orderDistributor]: orderData?.shippingInfo?.address,
      })
      setTotalItemCount(orderData.totalItems)
      setDistributorData({
        [orderDistributor]: {
          orderId,
          beforeDiscountTotal: orderData?.beforeDiscountTotal,
          afterDiscountTotal: orderData?.afterDiscountTotal,
          totalItems: orderData?.totalItems,
          shippingInfo: {
            ...DISTRIBUTOR_DEFAULT_VALUES[orderDistributor]['shippingInfo'],
          },
          items: lineItems,
          subtotalPrice: orderData?.subtotalPrice,
          totalPrice: orderData?.totalPrice,
          totalTax: orderData?.totalTax,
          projectIds: orderData?.projectIds,
        },
      })
    }
    getOrderData()
  }, [])

  const preorderPresenter = useMemo(
    () =>
      new PreOrderPresenter(
        service,
        setSystem,
        setDistributorData,
        setContactInfo,
        setDeliveryAddressInfo,
        setAccountManagerInfo
      ),
    []
  )

  return (
    <TotalItemCountContext.Provider value={totalItemCount}>
      <AccountManagerContext.Provider value={accountMangerInfo}>
        <DeliveryAddressInfoContext.Provider value={deliveryAddressInfo}>
          <ContactInfoContext.Provider value={contactInfo}>
            <DistributorDataContext.Provider value={distributorData}>
              <PreOrderPresenterContext.Provider value={preorderPresenter}>
                {children}
              </PreOrderPresenterContext.Provider>
            </DistributorDataContext.Provider>
          </ContactInfoContext.Provider>
        </DeliveryAddressInfoContext.Provider>
      </AccountManagerContext.Provider>
    </TotalItemCountContext.Provider>
  )
}

export default ResendOrderProvider
