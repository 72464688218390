import { AccessRightsType } from './global'
import { PermissionSet, PermissionType, PermissionKey as RolePermissionKey } from './roles'

export const DESIGN_PERMISSION_KEYS = ['systems', 'systems.buildablePanels', 'systems.panels', 'systems.inverters', 'systems.batteries', 'views','views.viewBox', 'views.imagery' ] as const // TODO: move to constants
export const ROOT_DESIGN_PERMISSION_KEY = 'design' as const
export type DesignPermissionKey = typeof DESIGN_PERMISSION_KEYS[number] | typeof ROOT_DESIGN_PERMISSION_KEY

export const DESIGN_PERMISSION_TO_ROLE_PERMISSION_KEY_MAP = {
  design: 'design',
  'systems': undefined,
  'systems.panels': undefined,
  'systems.buildablePanels': 'design_panels_build',
  'systems.inverters': undefined, 
  'systems.batteries': undefined, 
  'views':undefined,
  'views.viewBox': undefined, 
  'views.imagery':undefined
} satisfies { [key in DesignPermissionKey]: RolePermissionKey|undefined }

export type DesignPermissionOverrides = { [key in DesignPermissionKey]?: PermissionType }
export interface PermissionsState {
  projectPermissions: PermissionSet | undefined
  permissionsCache: Record<string, AccessRightsType>
  projectPermissionsCache: Record<string, AccessRightsType> | undefined
  permissionOverrides: Partial<PermissionSet>
  designPermissionOverrides: DesignPermissionOverrides
}
