import { MountingID } from 'types/mounting'
import { SpacingRuleSet } from '../layout_adjusters/BasicSpacingLayoutAdjusterAbstract'
import {
  Fastener,
  Item,
  MountingCalcInput,
  MountingCalcResult,
  MountingComponentType,
  PanelBlock,
  RailComponent,
  RailType,
} from '../types'
import { addItems, createMountingItem, getMatchingComponents } from '../utils'
import { PostProcessorAbstract } from './PostProcessorAbstract'

export abstract class RoofHookAdderAbstract extends PostProcessorAbstract {
  abstract spacingRuleSet: SpacingRuleSet
  abstract railOverhang: number // used to determine where the first and last roof hook should go. They will be placed {railOverhang} mm from the end of the rail

  abstract getRailType(input: MountingCalcInput): RailType

  abstract chooseHook(input: MountingCalcInput, panelBlock: PanelBlock, rail: RailComponent): Item[]

  abstract chooseFastener(hook: Item[], input?: MountingCalcInput): Fastener

  process(resultBeforeCuttingRails: MountingCalcResult): MountingCalcResult {
    const structuralRegs = this.input?.options?.structuralOptions?.structuralRegs
    const roofHooks: Item[] = []
    const checkMCSWindLoading = structuralRegs === 'mcs'

    if (this.getRailType(this.input) === 'mini') {
      // Mini rails are not cut and spliced spliced together, so the roof hook or anchor spacing is static relative to the rail
      roofHooks.push(...this.getHooksForMiniRail(resultBeforeCuttingRails, checkMCSWindLoading))
    } else {
      // Standard continuous rail
      // Be aware, this function is currently called _before_ cutting and splicing the rails
      roofHooks.push(...this.getHooksDefault(resultBeforeCuttingRails, checkMCSWindLoading))
    }

    return addItems(resultBeforeCuttingRails, roofHooks)
  }

  getHooksDefault(result: MountingCalcResult, checkWindLoading: boolean = false): Item[] {
    var roofHooks: Item[] = []

    // Calculate number of roof hooks that can fit on the rails using the inputted roof hook spacing
    result.panelBlocks.forEach((panelBlock, blockIndex) => {
      let fastenerCount = 0
      const rails = getMatchingComponents(result, {
        type: MountingComponentType.RAIL,
        blockIndex,
      }) as RailComponent[]

      rails.forEach((rail) => {
        const endOfRailSpacing = this.railOverhang
        var roofHookSpacing = this?.input?.options?.mountingSpacing as number
        const railLengthExclOverhang = rail.length - endOfRailSpacing * 2
        let roofHookCount = Math.floor(railLengthExclOverhang / roofHookSpacing) + 1
        if (roofHookCount < 2) roofHookCount = 2 // Some rails might be short, impose at least 2 hooks per rail
        const hookData = this.chooseHook(this.input, panelBlock, rail)

        const fastenerData = this.chooseFastener(hookData, this.input)
        roofHooks.push(
          ...this.getSpacedHooksOnRail(result, blockIndex, hookData, fastenerData, rail, roofHookCount, roofHookSpacing)
        )
        fastenerCount += fastenerData.qtyPerRoofHook * roofHookCount
      })

      if (checkWindLoading) this.checkWindLoading(fastenerCount, blockIndex)
    })
    return roofHooks
  }

  checkWindLoading(fastenerCount: number, blockIndex: number) {
    let moduleGroupStructuralOptions = this.input.options.structuralOptions?.moduleGroups
    if (moduleGroupStructuralOptions) {
      let minFastenerCount = moduleGroupStructuralOptions[blockIndex].minFastenerCount
      if (fastenerCount < minFastenerCount) {
        let mountingID = window.editor.selectedSystem.mounting as MountingID
        window.WorkspaceHelper.addProjectErrorToReduxStore({
          message: `MCS wind loading assessment failed: please reduce the mounting spacing to increase the number of roof hooks and fasteners`,
          key: `${mountingID.toUpperCase()}_STRUCTURAL_RESULT`,
          severity: 'warning',
          systemId: window.editor.selectedSystem.uuid,
          source: 'plugin',
          category: 'structural',
          options: {},
        })
        window.WorkspaceHelper.removeProjectErrorFromReduxStore(
          `${mountingID.toUpperCase()}_STRUCTURAL_INFO`,
          window.editor.selectedSystem.uuid,
          'plugin'
        )
      }
    }
  }

  getSpacedHooksOnRail(
    result: MountingCalcResult,
    blockIndex: number,
    hookItems: Item[],
    fastenerData: Fastener,
    rail: RailComponent,
    roofHookQty: number,
    roofHookSpacing: number
  ): Item[] {
    let roofHooks: Item[] = []
    if (!result.fasteners[blockIndex]) result.fasteners[blockIndex] = {}
    if (!result.fasteners[blockIndex][fastenerData.name])
      result.fasteners[blockIndex][fastenerData.name] = {
        qty: 0,
        diameter: fastenerData.diameter,
        length: fastenerData.length,
      }
    let top = rail.direction === 'horizontal' ? rail.top : 0
    let left = rail.direction === 'horizontal' ? 0 : rail.left
    for (let i = 0; i < roofHookQty; i++) {
      for (let hookIndex = 0; hookIndex < hookItems.length; hookIndex++) {
        roofHooks.push(createMountingItem(hookItems[hookIndex], { left, top, blockIndex }))
      }
      result.fasteners[blockIndex][fastenerData.name].qty += fastenerData.qtyPerRoofHook
      roofHooks.push(...this.getFastenerItems(fastenerData, left, top, blockIndex))
      if (rail.direction === 'horizontal') {
        left += roofHookSpacing
      } else {
        top += roofHookSpacing
      }
    }
    return roofHooks
  }

  getFastenerItems(fastener: Fastener, left: number, top: number, blockIndex: number) {
    var items: Item[] = []
    const fastenerItem = fastener.includedInRoofHookProduct
      ? null
      : {
          name: fastener.name,
          components: fastener.components,
        }
    if (fastenerItem) {
      for (let fastenerQty = 0; fastenerQty < fastener.qtyPerRoofHook; fastenerQty++) {
        items.push(createMountingItem(fastenerItem, { left, top, blockIndex }))
      }
    }
    return items
  }

  getHooksForMiniRail(result: MountingCalcResult, checkWindLoading: boolean = false): Item[] {
    var roofHooks: Item[] = []

    // Calculate number of roof hooks that can fit on the rails using the inputted roof hook spacing
    result.panelBlocks.forEach((panelBlock, blockIndex) => {
      let fastenerCount = 0
      const rails = getMatchingComponents(result, {
        type: MountingComponentType.RAIL,
        blockIndex,
      }) as RailComponent[]

      rails.forEach((rail) => {
        const hook = this.chooseHook(this.input, panelBlock, rail)
        const fastenerData = this.chooseFastener(hook, this.input)

        if (!result.fasteners[blockIndex]) result.fasteners[blockIndex] = {}
        if (!result.fasteners[blockIndex][fastenerData.name])
          result.fasteners[blockIndex][fastenerData.name] = {
            qty: 0,
            diameter: fastenerData.diameter,
            length: fastenerData.length,
          }

        const startOfRail = {
          top: rail.direction === 'horizontal' ? rail.top : 0,
          left: rail.direction === 'horizontal' ? 0 : rail.left,
        }
        const endOfRail = {
          top: rail.direction === 'horizontal' ? rail.top : rail.length,
          left: rail.direction === 'horizontal' ? rail.length : rail.left,
        }

        // If there are hook components, we add 2x (hooks or anchors) per rail, one on either side
        if (hook.length) {
          hook.forEach((hookItem) => {
            roofHooks.push(createMountingItem(hookItem, { left: startOfRail.left, top: startOfRail.top, blockIndex }))
            roofHooks.push(...this.getFastenerItems(fastenerData, startOfRail.left, startOfRail.top, blockIndex))
          })
          hook.forEach((hookItem) => {
            roofHooks.push(createMountingItem(hookItem, { left: endOfRail.left, top: endOfRail.top, blockIndex }))
            roofHooks.push(...this.getFastenerItems(fastenerData, endOfRail.left, endOfRail.top, blockIndex))
          })
          fastenerCount += fastenerData.qtyPerRoofHook * 2
          result.fasteners[blockIndex][fastenerData.name].qty += fastenerData.qtyPerRoofHook * 2

          // If there are no hook components (eg. for metal sheet roofs), we assume the fasteners attach the rail directly to the roof instead
        } else {
          roofHooks.push(...this.getFastenerItems(fastenerData, startOfRail.left, startOfRail.top, blockIndex))
          fastenerCount += fastenerData.qtyPerRoofHook
          result.fasteners[blockIndex][fastenerData.name].qty += fastenerData.qtyPerRoofHook
        }
      })

      if (checkWindLoading) this.checkWindLoading(fastenerCount, blockIndex)
    })
    return roofHooks
  }
}
