import moment from 'moment'
import { StarIcon, styled } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { FunctionFieldType } from 'ra-ui-materialui/src/field/FunctionField'
import { ContactsBlock } from 'resources/projects/projectsList/ContactsBlock'
import StageSelectDropdown from 'resources/projects/projectsList/StageSelectDropdown'
import { formatDate } from 'util/date'
import AddressDisplay from '../elements/AddressDisplay'
import FavoriteButton from '../elements/FavoriteButton'
import NotesDisplay from '../elements/NotesDisplay'
import PriorityDropdown from '../elements/PriorityDropdown'
import ProjectType from '../elements/ProjectType'
import TagsDisplay from '../elements/TagsDisplay'
import TeamDisplay from '../elements/TeamDisplay'

const StarIconStyled = styled(StarIcon)({
  paddingLeft: 8,
})

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export interface ListColumnBase {
  id: string
  enabled: boolean
}
export interface ProjectsListColumn extends ListColumnBase {
  display: string
  props: FunctionFieldType<any>
  required?: boolean
  hideOnKanban?: boolean
  hideOnTable?: boolean
}

export const PROJECTS_LIST_COLUMNS_DEFAULT = (translate: Function): ProjectsListColumn[] => [
  {
    id: 'starred',
    display: 'Starred',
    props: {
      label: (
        <Row>
          <StarIconStyled color={COLOR_PALETTE.orange} />
        </Row>
      ),
      render: (record) => <FavoriteButton record={record} />,
    },
    enabled: true,
    required: true,
    hideOnKanban: true,
  },
  {
    id: 'site_photo',
    display: 'Site Photo',
    props: { render: (record) => <></> },
    enabled: true,
    hideOnTable: true,
  },
  {
    id: 'address',
    display: 'Address',
    props: {
      render: (record) => <AddressDisplay record={record} />,
    },
    enabled: true,
    required: true,
  },
  {
    id: 'id',
    display: 'Project ID',
    props: {
      render: (record) => record.id,
    },
    enabled: true,
  },
  {
    id: 'contact',
    display: 'Contact',
    props: {
      render: (record) => !record.is_lite && <ContactsBlock contactsData={record.contacts_data} />,
    },
    enabled: true,
  },
  {
    id: 'stage',
    display: 'Stage',
    props: {
      render: (record) => (!record.is_lite ? <StageSelectDropdown project={record} /> : null),
    },
    enabled: true,
    hideOnKanban: true,
  },
  {
    id: 'priority',
    display: 'Priority',
    props: {
      render: (record) => !record.is_lite && <PriorityDropdown record={record} />,
    },
    enabled: true,
  },
  {
    id: 'assignee',
    display: 'Assignee',
    props: {
      render: (record) => <TeamDisplay teamMember={record.assigned_role_data} />,
    },
    enabled: true,
  },
  {
    id: 'project_type',
    display: 'Project Type',
    props: {
      render: (record) => {
        return <ProjectType record={record} />
      },
    },
    enabled: true,
  },
  {
    id: 'lead_source',
    display: 'Lead Source',
    props: {
      render: (record) => record.lead_source || '－',
    },
    enabled: false,
    hideOnKanban: true,
  },
  {
    id: 'tags',
    display: 'Tags',
    props: {
      render: (record) => <TagsDisplay tags={record.tags_data} />,
    },
    enabled: false,
  },
  {
    id: 'notes',
    display: 'Notes',
    props: {
      render: (record) => <NotesDisplay notes={record.notes} />,
    },
    enabled: false,
    hideOnKanban: true,
  },
  {
    id: 'created_date',
    display: 'Date Created',
    props: {
      render: (record) => (record.created_date ? formatDate(record.created_date) : '－'),
    },
    enabled: false,
  },
  {
    id: 'modified_date',
    display: 'Last Updated',
    props: {
      render: (record) => (record.modified_date ? moment(record.modified_date).fromNow() : '－'),
    },
    enabled: false,
    hideOnKanban: true,
  },
]
