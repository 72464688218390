import PrioritySquare from 'elements/PrioritySquare'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { PRIORITY_CHOICES } from 'resources/projects/choices'
import ProjectSelectDropdown from 'resources/projects/projectsList/ProjectSelectDropdown'

const ChoiceSpan = styled('span')({
  //possibly move this as a BaseTheme style?
  fontSize: 14,
  fontWeight: 500,
  display: 'flex',
  alignItems: 'center',
})

const PRIORITY_COLORS = {
  1: 'info',
  2: 'warning',
  3: 'error',
}
const PriorityDropdown = ({ record }) => {
  const translate = useTranslate()
  return (
    <ProjectSelectDropdown
      choices={PRIORITY_CHOICES}
      source="priority"
      project={record}
      color={PRIORITY_COLORS[record.priority]}
      renderValue={(choice) => `● ${translate(choice.name)}`}
      renderChoiceValue={(choice) => (
        <ChoiceSpan>
          <PrioritySquare priority={choice.id} />
          {translate(choice.name)}
        </ChoiceSpan>
      )}
    />
  )
}

export default PriorityDropdown
