import { styled } from 'opensolar-ui'
import { ReactNode } from 'react'
import type { DraggableLocation, DraggableProvided, DraggableStateSnapshot, DropResult } from 'react-beautiful-dnd'
import { DragDropContext } from 'react-beautiful-dnd'

type BoardProps<T> = {
  render: (item: T | undefined, index: number) => ReactNode
  groups: {
    [key: string]: T[]
  }
  onDragComplete: (result: DropResult) => void
  isDragDisabled?: (item: T) => boolean
  columnRender: (renderCard: Function, isDragDisabled?: (item: T) => boolean) => ReactNode
}

const Container = styled('div', { name: 'Container' })({
  display: 'flex',
  gap: 25,
})

const VirtualBoard = <T,>({ render, groups, onDragComplete, columnRender, isDragDisabled }: BoardProps<T>) => {
  const renderChild = (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    item: T | undefined,
    index: number,
    style?: any
  ) => {
    const disableDrag = !item || (isDragDisabled && isDragDisabled(item))
    const containerStyle = { ...provided.draggableProps.style, ...(style || {}) }
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...(disableDrag ? {} : provided.dragHandleProps)}
        style={containerStyle}
      >
        {render(item, index)}
      </div>
    )
  }

  const handleDrag = (result) => {
    if (!result.destination) return
    const source: DraggableLocation = result.source
    const destination: DraggableLocation = result.destination
    if (source.droppableId === destination.droppableId) return
    if (onDragComplete) onDragComplete(result)
  }
  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Container>{columnRender(renderChild, isDragDisabled)}</Container>
    </DragDropContext>
  )
}

export default VirtualBoard
