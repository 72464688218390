import { Tooltip, makeStyles } from '@material-ui/core'
import { CheckCircle, InfoOutlined } from '@material-ui/icons'
import { FormControl } from 'opensolar-ui'
import { SelectInput } from 'ra-ui-materialui'
import React, { useMemo, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { parseIntegrationJson } from 'util/misc'
import { LightReachVendorMap } from './types'
import { useValidateLightReachEquipment } from './utils'

type PropTypes = {
  systemUuid: string | null
  icon: JSX.Element
  label: string
  selectedProduct: string | null
  manufacturerChoices
  skuChoices
  manufacturerMatch?: string | null
  skuMatch?: string | null
  vendors: LightReachVendorMap | undefined
}

const useStyles = makeOpenSolarStyles((theme) => ({
  productContainer: {
    background: '#FBFAFC',
    padding: '5px 35px 10px 20px',
    borderRadius: 8,
    margin: '15px 0',
  },

  productHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  productHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },

  selectedProduct: {
    background: '#F1F1F1',
    padding: '2px 8px',
    borderRadius: 5,
  },

  approvedLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    color: theme.alert_success,
  },

  icon: {
    fontSize: 16,
  },

  selectField: {
    width: '100%',
    margin: '10px 0',
  },

  selectLabel: {
    marginTop: 5,
    marginBottom: 0,
  },

  errorMsg: {
    color: theme.alertIcon_error,
    marginTop: '-5px',
    fontSize: 'smaller',
  },
}))

const useCustomStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgb(230, 37, 0) !important',
      borderWidth: '2px',
    },
  },
}))

const NONE = 'None'

const LightReachEquipmentDropdown: React.FC<PropTypes> = ({
  systemUuid,
  icon,
  label,
  selectedProduct,
  manufacturerChoices,
  skuChoices,
  manufacturerMatch,
  skuMatch,
  vendors,
}) => {
  const classes = useStyles()
  const errorClass = useCustomStyles()

  const system = window.editor.getSystems().find((sys) => sys.uuid === systemUuid)
  const newIntegrationJSON = parseIntegrationJson(system?.integration_json) || {}
  const validationErrors = useValidateLightReachEquipment(systemUuid, vendors)

  const [manufacturer, setManufacturer] = useState<string>(manufacturerMatch || '')
  const [sku, setSku] = useState<string>(skuMatch || '')

  const isApproved =
    !validationErrors.includes(`${label}_manufacturer`) &&
    !validationErrors.includes(`${label}_product_code`) &&
    Boolean(manufacturer)

  if (manufacturer !== '') {
    skuChoices = skuChoices?.filter((sku) => sku.manufacturer === manufacturer)
    skuChoices = [{ id: NONE, modelNumber: NONE }, ...skuChoices]
  }

  const field = useMemo(() => {
    switch (label) {
      case 'Modules':
        return { manuf: 'panelManufacturer', model: 'panelModel' }

      case 'Inverters':
        return { manuf: 'inverterManufacturer', model: 'inverterModel' }

      case 'Batteries':
        return { manuf: 'storageManufacturer', model: 'storageModel' }

      case 'Mounting':
        return { manuf: 'mountingManufacturer', model: '' }

      default:
        return { manuf: '', model: '' }
    }
  }, [label])

  const onFieldChange = (fieldName: string, newVal: string) => {
    if (newVal === NONE) newVal = ''
    if (!newIntegrationJSON.lightreach) {
      newIntegrationJSON.lightreach = {}
    }
    if (!newIntegrationJSON.lightreach[fieldName]) {
      newIntegrationJSON.lightreach[fieldName] = ''
    }
    newIntegrationJSON.lightreach[fieldName] = newVal

    if (fieldName.includes('Manufacturer')) {
      setManufacturer(newVal)
      newIntegrationJSON.lightreach[field.model] = ''
    } else {
      setSku(newVal)
    }
  }

  const manufacturerChoicesWithEmpty = useMemo(() => {
    if (manufacturerChoices && manufacturerChoices.length > 0) {
      if (label === 'Mounting') {
        return [{ name: NONE }, ...manufacturerChoices]
      } else {
        return [NONE, ...manufacturerChoices]
      }
    }

    return []
  }, [label, manufacturerChoices])

  return (
    <div className={classes.productContainer}>
      <div className={classes.productHeaderWrapper}>
        <div className={classes.productHeader}>
          {icon}
          <h3>{label}</h3>
          <p className={classes.selectedProduct}>{selectedProduct} </p>
          <Tooltip title="Go to Design Studio and choose a replacement item from a list of Lightreach approved hardware">
            <InfoOutlined className={classes.icon} />
          </Tooltip>
        </div>
        {isApproved && (
          <div className={classes.approvedLabel}>
            <CheckCircle className={classes.icon} />
            <span>Approved</span>
          </div>
        )}
      </div>

      <FormControl
        sx={{ m: 1, width: '45%' }}
        classes={validationErrors.includes(`${label}_manufacturer`) ? errorClass : ''}
      >
        <p className={classes.selectLabel}>Manufacturer</p>
        <SelectInput
          name={`${label}_manufacturer`}
          id={`${label}_manufacturer`}
          source=""
          label=""
          variant="outlined"
          size="small"
          choices={manufacturerChoicesWithEmpty?.map((manuf) => {
            if (label === 'Mounting') {
              return {
                id: manuf.name,
                name: manuf.name,
              }
            } else {
              return {
                id: manuf,
                name: manuf,
              }
            }
          })}
          value={manufacturer}
          className={classes.selectField}
          onChange={(e) => onFieldChange(field.manuf, e.target.value)}
        />
        {validationErrors.includes(`${label}_manufacturer`) && (
          <p className={classes.errorMsg}>LightReach approved manufacturer required.</p>
        )}
      </FormControl>

      {label !== 'Mounting' && (
        <FormControl
          sx={{ m: 1, width: '45%' }}
          classes={validationErrors.includes(`${label}_product_code`) ? errorClass : ''}
        >
          <p className={classes.selectLabel}>Product Code/Sku</p>
          <SelectInput
            name={`${label}_product_code`}
            id={`${label}_product_code`}
            source=""
            label=""
            size="small"
            choices={skuChoices?.map((sku) => {
              return {
                id: sku.id,
                name: sku.modelNumber,
              }
            })}
            value={sku}
            variant="outlined"
            className={classes.selectField}
            onChange={(e) => onFieldChange(field.model, e.target.value)}
          />
          {validationErrors.includes(`${label}_product_code`) && (
            <p className={classes.errorMsg}>LightReach approved hardware required</p>
          )}
        </FormControl>
      )}
    </div>
  )
}

export default LightReachEquipmentDropdown
