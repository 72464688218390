import { useMediaQuery } from '@material-ui/core'
import { BaseThemeType, styled } from 'opensolar-ui'
import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'

const StageRow = styled('div', { name: 'StageRow' })({
  display: 'flex',
  gap: 25,
})

const StageColumn = styled('div', { name: 'StageColumn' })({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  flex: 1,
})

const SkeletonWrapper = styled('div', { name: 'SkeletonWrapper' })({
  width: '100%',
})

const pageContainers = [50, 200, 200, 200]

const KanbanSkeleton: React.FunctionComponent = () => {
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))
  const dummyArr = useMemo(() => Array.from(Array(isMobile ? 3 : 5).keys()), [isMobile])
  if (!dummyArr) return null
  return (
    <StageRow style={{ overflow: 'hidden' }}>
      {dummyArr.map((x, j) => (
        <StageColumn key={'skeleton-outer-' + j}>
          {pageContainers.map((height, i) => {
            if (typeof height === 'number')
              return (
                <SkeletonWrapper key={'skeleton-' + i} style={{ height: height }}>
                  <Skeleton height={height} />
                </SkeletonWrapper>
              )
            return <></>
          })}
        </StageColumn>
      ))}
    </StageRow>
  )
}
export default KanbanSkeleton
