import { Tooltip } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useTranslateParse from 'util/useTranslateParse'

const useStyles = makeOpenSolarStyles((theme) => ({
  infoIcon: {
    fontSize: 18,
    color: theme.greyMid1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
}))

type PropTypes = {
  label: string
}

const CreditCardCapTooltip: React.FC<PropTypes> = ({ label }) => {
  const classes = useStyles()

  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const currencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  const orgCountry = useSelector(orgSelectors.getOrgIso2)

  const capAmount = useMemo(() => {
    if (orgCountry === 'AU') {
      return `${currencySymbol}10,000`
    } else {
      return `${currencySymbol}6,000`
    }
  }, [orgCountry])

  const tooltipText = translateParse(
    'Customers can use cards to pay for amounts under %{capAmount}. Customers making payments over %{capAmount} will be automatically presented with an account to account payment method. If you need to enable card payments more than %{capAmount}, you can request a higher limit by clicking  <a>here<a>.',
    {
      a: (link: string) => (
        <a href="https://support.opensolar.com/hc/en-us/requests/new" target="_blank" rel="noreferrer">
          {link}
        </a>
      ),
    },
    { capAmount }
  )

  return (
    <div className={classes.row}>
      {translate(label)}
      <Tooltip title={tooltipText} interactive={true}>
        <Info className={classes.infoIcon} />
      </Tooltip>
    </div>
  )
}
export default CreditCardCapTooltip
