import { makeStyles } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Alert } from 'opensolar-ui'
import DocumentTable from 'pages/cashFlow/configuration/documents/DocumentTable'
import { DocumentPageContext, useGetCashFlowDocuments } from 'pages/cashFlow/configuration/documents/utils'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

type PropTypes = {}

const useStyles = makeStyles(() => ({
  alert: {
    margin: '15px 15px',
  },
}))

const DocumentRequestSection: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [documents, isDocumentsLoading, refreshDocuments] = useGetCashFlowDocuments()
  const org = useSelector(orgSelectors.getOrg)
  const orgHasCashFlow = useSelector(orgSelectors.getCashFlowIsActive)

  const isProactiveDocumentRequest = useMemo(() => {
    // Assumption that document request is a proactive document request
    const isBankDocOrId =
      !!documents?.length &&
      documents.every(({ docType }) => ['bankDocument', 'companyRepresentativeId', 'owner1Id'].includes(docType))
    const hasNoUnderwriterNotes = documents.every(({ docUnderwriterNotes }) => !docUnderwriterNotes)

    const result = isBankDocOrId && hasNoUnderwriterNotes && !orgHasCashFlow

    return result
  }, [documents, orgHasCashFlow])

  const isUSProactiveDocRequest = isProactiveDocumentRequest && org?.country?.iso2 === 'US'

  if (isDocumentsLoading) return <LoadingDots />

  return (
    <>
      {/* Show this text section only for US Proactive or no doc request yet*/}
      {(!documents?.length || isUSProactiveDocRequest) && (
        <p>
          {translate(
            "Selling solar and getting paid is about to be easier. Your application is being reviewed. You'll hear back in 1-2 business days. Be ready to quickly provide any additional documents we request to activate CashFlow."
          )}
        </p>
      )}
      {!!documents?.length && (
        <>
          {isUSProactiveDocRequest ? (
            <Alert severity="info" className={classes.alert}>
              {/* Optional document upload message (only for US). AU and UK proactive document uploads are required */}
              <span>
                {translate(
                  'To speed up the review process, you can proactively upload the following documents commonly requested:'
                )}
              </span>
            </Alert>
          ) : (
            <Alert severity="warning" className={classes.alert}>
              <span>
                {translate(
                  'The following documents are required to complete your application. Please upload them as soon as possible to avoid delays in your approval.'
                )}
              </span>
            </Alert>
          )}
          <DocumentPageContext.Provider value={{ refreshDocuments }}>
            <DocumentTable documents={documents} isLoading={isDocumentsLoading} />
          </DocumentPageContext.Provider>
        </>
      )}
    </>
  )
}

export default DocumentRequestSection
