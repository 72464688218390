import { makeStyles, withStyles } from '@material-ui/core'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { SCHEDULE_OPTIONS, SINGLE_TIER_OPTIONS } from 'resources/utilityTariffs/common'
import { Theme } from 'types/themes'
import { currencySymbolForCountry } from 'util/misc'
import { FixedRateItemSummary, RateItemSummary } from './tariffSummaryDialog/RateItemSummary'
import { getAdjustmentsFactor, getItemsByCriteria, getTaxAdjustmentFactor } from './tariffSummaryDialog/utils'

const PopoverTooltip = withStyles(() => ({
  tooltip: {
    minWidth: 250,
    maxWidth: 'none',
  },
}))(InfoTooltip)

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  sectionTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    margin: 0,
    marginBottom: 10,
  },
  container: {
    '& hr': {
      margin: '10px 0',
    },
  },
  tooltipStyle: {
    paddingRight: 5,
  },
}))
const rateOptions = SCHEDULE_OPTIONS.concat(SINGLE_TIER_OPTIONS)
const TariffSummaryDialog = ({ tariffData }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formState = useFormState()
  const projectCountry = formState.values['country_iso2']
  const currencySymbol = currencySymbolForCountry(projectCountry)
  const parsedTariffData = useMemo(() => {
    if (tariffData) {
      let parsedTariffData = { ...tariffData }
      rateOptions.forEach((rateType) => {
        parsedTariffData[rateType] = getItemsByCriteria(tariffData?.[rateType] || [])
      })
      return parsedTariffData
    }
  }, [tariffData])
  const energyChargeAdjustmentFactor = useMemo(() => getAdjustmentsFactor(tariffData, 'energy_charges'), [tariffData])
  const fixedChargeAdjustmentFactor = useMemo(() => getAdjustmentsFactor(tariffData, 'fixed_charges'), [tariffData])
  const demandChargeAdjustmentFactor = useMemo(() => getAdjustmentsFactor(tariffData, 'demand_charges'), [tariffData])
  const shippingChargeAdjustmentFactor = useMemo(() => getAdjustmentsFactor(tariffData, 'shipping_charges'), [
    tariffData,
  ])
  const taxAdjustmentFactor = useMemo(() => getTaxAdjustmentFactor(tariffData), [tariffData])
  const emptyDisplay =
    !(parsedTariffData?.energy_charges?.length > 0) &&
    !(parsedTariffData?.demand_charges?.length > 0) &&
    !(parsedTariffData?.fixed_charges?.length > 0) &&
    !(parsedTariffData?.pv_capacity_charges?.length > 0) &&
    !(parsedTariffData?.controlled_load_charges?.length > 0) &&
    !(parsedTariffData?.export_credits?.length > 0) &&
    !(parsedTariffData?.shipping_charges?.length > 0)

  return (
    <PopoverTooltip
      className={classes.tooltipStyle}
      title={
        emptyDisplay ? (
          translate('Tariff pricing details is not set.')
        ) : (
          <>
            <div className={classes.container}>
              {parsedTariffData?.energy_charges?.length > 0 ? (
                <>
                  <p className={classes.sectionTitle}>{translate('Energy Charges')}</p>
                  <RateItemSummary
                    rateItems={parsedTariffData?.energy_charges}
                    priceAdjustmentFactor={energyChargeAdjustmentFactor}
                    taxAdjustmentFactor={taxAdjustmentFactor}
                  />
                </>
              ) : (
                <></>
              )}
              {parsedTariffData?.shipping_charges?.length > 0 ? (
                <>
                  <p className={classes.sectionTitle}>{translate('Shipping Charges')}</p>
                  <RateItemSummary
                    rateItems={parsedTariffData?.shipping_charges}
                    priceAdjustmentFactor={shippingChargeAdjustmentFactor}
                    taxAdjustmentFactor={taxAdjustmentFactor}
                  />
                </>
              ) : (
                <></>
              )}
              {parsedTariffData?.demand_charges?.length > 0 ? (
                <>
                  <p className={classes.sectionTitle}>{translate('Demand Charges')}</p>
                  <RateItemSummary
                    rateItems={parsedTariffData?.demand_charges}
                    priceAdjustmentFactor={demandChargeAdjustmentFactor}
                    taxAdjustmentFactor={taxAdjustmentFactor}
                  />
                </>
              ) : (
                <></>
              )}
              {parsedTariffData?.fixed_charges?.length > 0 ? (
                <>
                  <p className={classes.sectionTitle}>{translate('Fixed Charges')}</p>
                  <FixedRateItemSummary
                    rateItems={parsedTariffData?.fixed_charges}
                    priceAdjustmentFactor={fixedChargeAdjustmentFactor}
                    taxAdjustmentFactor={taxAdjustmentFactor}
                  />
                </>
              ) : (
                <></>
              )}
              {parsedTariffData?.pv_capacity_charges?.length > 0 ? (
                <>
                  <p className={classes.sectionTitle}>{translate('PV Capacity Charges')}</p>
                  <FixedRateItemSummary
                    rateItems={parsedTariffData?.pv_capacity_charges}
                    taxAdjustmentFactor={taxAdjustmentFactor}
                  />
                </>
              ) : (
                <></>
              )}
              {parsedTariffData?.controlled_load_charges?.length > 0 ? (
                <>
                  <p className={classes.sectionTitle}>{translate('Controlled Load Charges')}</p>
                  <RateItemSummary
                    rateItems={parsedTariffData?.controlled_load_charges}
                    taxAdjustmentFactor={taxAdjustmentFactor}
                  />
                </>
              ) : (
                <></>
              )}
              {parsedTariffData?.export_credits?.length > 0 ? (
                <>
                  <p className={classes.sectionTitle}>{translate('Export Credits')}</p>
                  <RateItemSummary rateItems={parsedTariffData?.export_credits} />
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        )
      }
      placement="bottom-start"
    />
  )
}
export default TariffSummaryDialog
