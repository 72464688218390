import Launch from '@material-ui/icons/Launch'
import React from 'react'
import { CommercialRequirementsErrorType } from 'reducer/project/projectErrorsReducer'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { eventEmitter } from './Emitter'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    margin: '5px 0',
  },
  title: {
    verticalAlign: 'middle',
  },
  icon: {
    verticalAlign: 'middle',
    width: 14,
    height: 14,
    margin: 1,
  },
  link: {
    cursor: 'pointer',
  },
}))

const CommercialRequirementsError = ({
  section,
  error,
}: {
  section: string | undefined
  error: CommercialRequirementsErrorType
}) => {
  const classes = useStyles()

  const currentUrl = window.location.href
  let lastPart: string | undefined = ''

  const getUpdatedUrl = (newSection) => {
    const url = new URL(currentUrl)
    const pathParts = url.hash.split('/')
    lastPart = pathParts.pop()
    pathParts.push(newSection)

    return `${url.origin}${pathParts.join('/')}`
  }

  const paymentsUrl = getUpdatedUrl('payments')

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()

    if (lastPart !== 'payments') {
      window.location.href = paymentsUrl

      setTimeout(() => {
        eventEmitter.emit('openEditInvoiceDialog')
      }, 500)
    }
    eventEmitter.emit('openEditInvoiceDialog')
  }

  const [intro, link, end] = error.message.split('%')
  return (
    <span>
      {intro}
      <a className={classes.link} onClick={handleClick}>
        <span className={classes.title} onClick={handleClick}>
          {link}
        </span>
        <Launch className={classes.icon} />
      </a>
      {end}
    </span>
  )
}

export default CommercialRequirementsError
