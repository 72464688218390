import { ClickAwayListener, Tooltip, withStyles } from '@material-ui/core'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded'
import { authSelectors } from 'ducks/auth'
import { loadProjectViews, ProjectView, removeProjectView, updateProjectView } from 'ducks/projectListViews'
import { Button, CopyLeftOutlineIcon, DeleteOutlineIcon, EditOutlineIcon, IconButton, styled } from 'opensolar-ui'
import ConfirmModal from 'projectSections/sections/manage3/common/ConfirmModal'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useNotify, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ViewEditNameDialog from '../elements/ViewEditNameDialog'
import { deleteViews, editViews, saveViews } from '../utils'

const PopoverTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFF',
    padding: 0,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
  },
}))(Tooltip)

const MenuWrapper = styled('div', { name: 'ViewButtonDropdownMenuWrapper' })({
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
})

const Divider = styled('div', { name: 'ViewButtonDropdownDivider' })({
  borderTop: '1px solid ' + COLOR_PALETTE.lightGrey,
  width: '100%',
  marginBottom: 10,
})

const ButtonStyled = styled(Button, { name: 'ViewButtonDropdownButton' })({
  justifyContent: 'flex-start',
})

interface PropTypes {
  isSelected: boolean
  onViewDropdown: () => void
  isUnsavedView: boolean | undefined
  views: ProjectView[]
  selectedView: number
  handleViewChange: (index: number) => void
}

export enum ViewButtonDropdownActionType {
  Rename = 'rename',
  Duplicate = 'duplicate',
  Delete = 'delete',
  Save = 'save',
}

const ACTIONS = [
  {
    label: 'Rename View',
    action: ViewButtonDropdownActionType.Rename,
    icon: <EditOutlineIcon variant={2} />,
  },
  {
    label: 'Duplicate View',
    action: ViewButtonDropdownActionType.Duplicate,
    icon: <CopyLeftOutlineIcon />,
  },
  {
    label: 'Delete View',
    action: ViewButtonDropdownActionType.Delete,
    icon: <DeleteOutlineIcon size={18} color={COLOR_PALETTE.darkRed} />,
  },
]

const ViewButtonDropdown: React.FC<PropTypes> = ({
  onViewDropdown,
  isSelected,
  isUnsavedView,
  views,
  selectedView,
  handleViewChange,
}) => {
  const translate = useTranslate()
  const notify = useNotify()
  const dispatch = useDispatch()
  const orgId = useSelector(authSelectors.getOrgId)
  const [open, openDropdown] = useState(false)
  const [isVisibleEditName, setIsVisibleEditName] = useState<boolean>(false)
  const [isVisibleDeleteView, setIsVisibleDeleteView] = useState<boolean>(false)

  const editView = (payload, viewId) => {
    editViews(payload, viewId, orgId)
      .then((response) => {
        setIsVisibleEditName(false)
        dispatch(loadProjectViews(orgId))
      })
      .catch((error) => {
        notify('Failed to edit view', 'error')
      })
  }

  const renameView = (name) => {
    const copyOfViews = [...views]
    let newView = { ...copyOfViews[selectedView || 0] }
    newView.name = name
    if (newView.not_saved) {
      dispatch(updateProjectView(newView))
      setIsVisibleEditName(false)
    } else {
      editView(newView, newView.id)
    }
  }

  const deleteView = (viewId) => {
    deleteViews(viewId, orgId)
      .then((response) => {
        setIsVisibleDeleteView(false)
        handleViewChange(0)
        dispatch(loadProjectViews(orgId))
      })
      .catch((error) => {
        notify('Failed to delete view', 'error')
      })
  }

  const saveView = (payload) => {
    saveViews(payload, orgId)
      .then((response) => {
        dispatch(loadProjectViews(orgId))
      })
      .catch((error) => {
        notify('Failed to save view', 'error')
      })
  }

  const onClickDropdown = (action) => {
    if (action === ViewButtonDropdownActionType.Rename) {
      setIsVisibleEditName(true)
    } else if (action === ViewButtonDropdownActionType.Duplicate) {
      let newView = views[selectedView]
      newView.name = 'New View'
      newView.order = (views[views.length - 1]?.order || 0) + 1
      saveView(newView)
    } else if (action === ViewButtonDropdownActionType.Delete) {
      let newView = views[selectedView]
      if (newView.not_saved) {
        dispatch(removeProjectView(newView.id))
        handleViewChange(0)
        setIsVisibleDeleteView(false)
      } else {
        setIsVisibleDeleteView(true)
      }
    } else if (action === ViewButtonDropdownActionType.Save) {
      const copyOfViews = [...views]
      let newView = copyOfViews.filter((item) => !!item.not_saved)[0]
      delete newView.not_saved
      saveView(newView)
    }
  }

  return (
    <>
      <PopoverTooltip
        placement="bottom-end"
        open={open}
        title={
          <ClickAwayListener onClickAway={() => openDropdown(false)}>
            <MenuWrapper>
              {isUnsavedView && (
                <>
                  <ButtonStyled
                    variant="text"
                    color="default"
                    onClick={() => {
                      onClickDropdown(ViewButtonDropdownActionType.Save)
                      openDropdown(false)
                    }}
                  >
                    {translate('Save Changes to View')}
                  </ButtonStyled>
                  <Divider />
                </>
              )}
              {ACTIONS.map(({ label, action, icon }) => (
                <>
                  {(action !== 'duplicate' || !isUnsavedView) && (
                    <ButtonStyled
                      key={action}
                      variant="text"
                      color={action === 'delete' ? 'error' : 'default'}
                      startIcon={icon}
                      onClick={() => {
                        onClickDropdown(action)
                        openDropdown(false)
                      }}
                    >
                      {translate(label)}
                    </ButtonStyled>
                  )}
                </>
              ))}
            </MenuWrapper>
          </ClickAwayListener>
        }
        interactive
      >
        <IconButton
          onClick={() => {
            openDropdown(!open)
            if (onViewDropdown) onViewDropdown()
          }}
          variant="squared"
          color={'default'}
          style={{ width: 18, height: 18, borderRadius: 4 }}
        >
          <KeyboardArrowDownRoundedIcon />
        </IconButton>
      </PopoverTooltip>
      {isVisibleEditName && (
        <ViewEditNameDialog
          viewName={views[selectedView].name}
          onClose={() => setIsVisibleEditName(false)}
          onSubmit={(name) => {
            renameView(name)
          }}
          isVisible={isVisibleEditName}
        />
      )}
      <ConfirmModal
        open={isVisibleDeleteView}
        setOpen={setIsVisibleDeleteView}
        handleSubmit={() => deleteView(views[selectedView].id)}
        title={translate('Delete View')}
        mainText={translate('Are you sure you want to delete this view?')}
        subText={translate('This action is permanent and cannot be undone.')}
        submitBtnLabel={translate('Yes, Delete')}
        submitBtnProps={{ variant: 'contained', color: 'error' }}
      />
    </>
  )
}
export default ViewButtonDropdown
