import { PERFORMANCE_CALCULATOR_ID, PERFORMANCE_CALCULATORS_MCS } from 'constants/calculators'

export const withinMCSTolerance = (
  kwStc: number = 0,
  annualUsage: number = 0,
  annualGeneration: number = 0
): boolean => {
  return !(kwStc > 50 || annualUsage > 5999 || annualGeneration > 5999 || annualGeneration < 1)
}

export type BatteryControlScheme = {
  id: string
  name: string
  nzOnly?: boolean
  mcsCompatible?: boolean
  isStandard?: boolean
}
const BATTERY_CONTROL_SCHEMES = [
  {
    id: 'solar_charged',
    name: 'Self-consumption',
    mcsCompatible: true,
    isStandard: true,
  },
  {
    id: 'minimize_grid_costs_tou_no_grid_charging',
    name: 'Minimize Grid Import Cost',
    isStandard: true,
  },
  {
    id: 'maximize_savings',
    name: 'Maximize Savings',
    mcsCompatible: true,
    isStandard: true,
  },
  {
    id: 'peak_demand_shaving',
    name: 'Peak Demand Shaving',
    isStandard: true,
  },
  {
    id: 'nz_optimized',
    name: 'NZ Optimized',
    nzOnly: true,
  },
] as const satisfies readonly BatteryControlScheme[]

export type BatteryControlSchemeId = typeof BATTERY_CONTROL_SCHEMES[number]['id']

export const MAPPED_BATTERY_CONTROL_SCHEMES = BATTERY_CONTROL_SCHEMES.reduce((mapping, bcs) => {
  mapping[bcs.id] = bcs
  return mapping
}, {}) as {[key in BatteryControlSchemeId]: BatteryControlScheme}

 // @ts-ignore
const STANDARD_BATTERY_CONTROL_SCHEMES = BATTERY_CONTROL_SCHEMES.filter(({ isStandard }) => isStandard)
 // @ts-ignore
const NZ_BATTERY_CONTROL_SCHEMES = BATTERY_CONTROL_SCHEMES.filter(({ isStandard, nzOnly }) => isStandard || nzOnly)
 // @ts-ignore
const MCS_BATTERY_CONTROLSCHEMES = BATTERY_CONTROL_SCHEMES.filter(({ mcsCompatible }) => mcsCompatible)

export const getBCSOptions = (countryIso2: string, performanceCalculator: PERFORMANCE_CALCULATOR_ID): BatteryControlScheme[] => {
  if (performanceCalculator === PERFORMANCE_CALCULATORS_MCS) {
    return MCS_BATTERY_CONTROLSCHEMES
  }
  if (countryIso2 === 'NZ') {
    return NZ_BATTERY_CONTROL_SCHEMES
  }
  return STANDARD_BATTERY_CONTROL_SCHEMES
}

export const DEFAULT_BATTERY_CONTROL_SCHEME = 'solar_charged'
