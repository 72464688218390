import { FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { featureConfigSelectors, getPublicFeatureConfig } from 'ducks/featureConfig'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import Alert from 'elements/Alert'
import _ from 'lodash'
import Confirm from 'projectSections/elements/dialog/Confirm'
import ProjectWarningBox from 'projectSections/sections/common/ProjectWarningBox'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  defaultFixedRate,
  defaultPeriod,
  defaultScheduledRate,
  DEFAULT_TARIFF_DATA,
  DEFAULT_UNITS,
} from 'resources/utilityTariffs/common'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import useTranslateParse from 'util/useTranslateParse'
import { TARIFF_FIELDS } from '../parseTariffInitialValues'
import { useCountrySpecificTariffFields } from './hooks'
import SimpleFlatRateContent from './SimpleFlatRateContent'
import UtilityTariffCurrentContent from './UtilityTariffCurrentContent'
import UtilityTariffProposedContent from './UtilityTariffProposedContent'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  modeInput: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  tariffInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}))

const DEFAULT_SIMPLE_TARIFF = {
  ...DEFAULT_TARIFF_DATA,
  inputType: 'simple',
  energy_charges: [defaultScheduledRate(defaultPeriod(false, 'energy_charges'), false)],
  export_credits: [defaultScheduledRate(defaultPeriod(false, 'export_credits'), false)],
  fixed_charges: [defaultFixedRate(DEFAULT_UNITS['fixed_charges'])],
}

const ElectricityRateContent = () => {
  const form = useForm()
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const classes = useStyles()
  const formState = useFormState()
  const formValues = formState.values
  const dirtyFields = form.mutators.getFormDirtyFields()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2) || ''
  const dispatch = useDispatch()
  const [inputTypeChange, setInputTypeChange] = useState<string | undefined>(undefined)
  const { allowView, allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('energy_tariff'))

  const showCountrySpecificFields = useCountrySpecificTariffFields(countryIso2)

  dispatch(getPublicFeatureConfig('default_tariff_fields'))

  const countrySpecificDefaults = useSelector((state: RootState) =>
    featureConfigSelectors.getFeatureConfigData(state, 'default_tariff_fields')
  )

  const defaultSimpleTariffData = _.cloneDeep(DEFAULT_SIMPLE_TARIFF)

  if (countryIso2 && showCountrySpecificFields) {
    defaultSimpleTariffData.export_credits = countrySpecificDefaults
      ? countrySpecificDefaults[countryIso2].export_credits
      : []
  }

  // Show warning ONLY if the precision of the guessed tariff is at the country-level in the US. This means we don't have a tariff that matches
  // the project location at zip or state level precision, nor is it user specified (i.e., manually set by the user).
  // TODO: Can we make this more generic such that this error is shown for all countries around the world instead of just the US?
  const showMissingUSTariffWarning =
    formValues.utility_tariff_or_guess &&
    countryIso2 === 'US' &&
    !['zip', 'state', 'user_specified'].includes(formValues.utility_tariff_or_guess?.precision)

  useEffect(() => {
    if (showMissingUSTariffWarning) {
      logAmplitudeEvent('tariff_warning_shown', {
        context: 'missing US tariff',
        source: 'Energy Page',
        locality: formValues?.locality,
        state: formValues?.state,
        country_iso2: formValues?.country_iso2,
      })
    }
  }, [showMissingUSTariffWarning])

  const inputType = useMemo(() => {
    const tariffData = formValues.utility_tariff_current_custom
    if (tariffData?.inputType && tariffData?.inputType === 'simple') {
      return 'simple'
    } else {
      return 'advanced'
    }
  }, [formValues])

  const defaultTariffs = useMemo(() => {
    return {
      utility_tariff_current_custom: _.cloneDeep(formValues.utility_tariff_current_custom),
      utility_tariff_proposed_custom: _.cloneDeep(formValues.utility_tariff_proposed_custom),
      utility_tariff_current_data: _.cloneDeep(formValues.utility_tariff_current_data),
      utility_tariff_proposed_data: _.cloneDeep(formValues.utility_tariff_proposed_data),
      utility_tariff_proposed: _.cloneDeep(formValues.utility_tariff_proposed),
    }
  }, [])
  const handleInputChange = (value) => {
    if (value === 'simple') {
      if (!(defaultTariffs.utility_tariff_current_custom?.inputType === 'simple')) {
        form.mutators.updateField('utility_tariff_current_custom', defaultSimpleTariffData)
      } else {
        if (dirtyFields.includes('utility_tariff_current_custom'))
          form.mutators.markFieldAsClean('utility_tariff_current_custom')
      }
    } else if (value === 'advanced' && defaultTariffs.utility_tariff_current_custom?.inputType === 'simple') {
      form.mutators.markFieldAsDirty('utility_tariff_current_custom')
      form.mutators.updateField('utility_tariff_current_custom', null)
    } else {
      form.mutators.updateField('utility_tariff_current_custom', defaultTariffs.utility_tariff_current_custom)
    }
  }
  const handleTariffReset = (value) => {
    form.mutators.updateField('utility_tariff_current_custom', defaultTariffs.utility_tariff_current_custom)
    form.mutators.updateField('utility_tariff_proposed_custom', defaultTariffs.utility_tariff_proposed_custom)
    form.mutators.updateField('utility_tariff_current_data', defaultTariffs.utility_tariff_current_data)
    form.mutators.updateField('utility_tariff_proposed_data', defaultTariffs.utility_tariff_proposed_data)
    form.mutators.updateField('utility_tariff_proposed', defaultTariffs.utility_tariff_proposed)

    handleInputChange(value)
    if (!!inputTypeChange) setInputTypeChange(undefined)
  }

  if (!allowView) return <p>{translate('No permission.')}</p>

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ProjectWarningBox categories={['utility-tariff']} />
        {showMissingUSTariffWarning && (
          <Alert severity="warning">
            {
              <div>
                {translateParse(
                  "We were not able to find a utility rate for this project location and have currently assigned a generic US rate for this project. If there are utilities or rate schedules that you'd like to see added, please send us the rate sheets at <a>support@opensolar.com<a> and we'll prioritize them. If you can't wait, you also have the option to click 'Create New Tariff' below, and add the rate schedule yourself.",
                  {
                    a: (label: string) => (
                      <a
                        style={{ color: 'rgb(24, 144, 255)' }}
                        href="mailto:support@opensolar.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {label}
                      </a>
                    ),
                  }
                )}
              </div>
            }
          </Alert>
        )}
      </Grid>
      <RadioGroup
        name="input-mode"
        value={inputType}
        onChange={(e) => {
          const value = e.target.value
          const compareSimpleTariff = defaultTariffs.utility_tariff_current_custom || defaultSimpleTariffData
          if (
            (value === 'simple' && TARIFF_FIELDS.some((x) => dirtyFields.includes(x))) ||
            (value === 'advanced' && !_.isEqual(formValues.utility_tariff_current_custom, compareSimpleTariff))
          ) {
            setInputTypeChange(value)
          } else {
            handleInputChange(value)
          }

          logAmplitudeEvent('tariff_input_switch', {
            input_type: value,
          })
        }}
      >
        <div className={classes.modeInput}>
          <div>
            <FormControlLabel
              value={'advanced'}
              control={<Radio disabled={!allowEdit} />}
              label={translate('Select or Create Tariff')}
            />
          </div>
          <div>
            <FormControlLabel
              value={'simple'}
              control={<Radio disabled={!allowEdit} />}
              label={translate('Simple Flat Rate')}
            />
          </div>
        </div>
      </RadioGroup>
      <Confirm
        open={inputTypeChange !== undefined}
        header={{ title: translate('Switching tariff input type will discard all existing tariff changes.') }}
        content={translate(
          'You will lose all your progress in the current tariff input mode. Do you wish to continue?'
        )}
        buttons={[
          {
            children: 'Cancel',
            onClick: () => {
              setInputTypeChange(undefined)
            },
          },
          {
            color: 'primary',
            children: 'Continue',
            onClick: () => {
              handleTariffReset(inputTypeChange)
            },
          },
        ]}
      />
      <hr className="light" />
      {inputType === 'advanced' ? (
        <div className={classes.tariffInputContainer}>
          <UtilityTariffCurrentContent />
          <hr className="light" />
          <UtilityTariffProposedContent />
        </div>
      ) : (
        <SimpleFlatRateContent />
      )}
    </Grid>
  )
}
export default ElectricityRateContent
