import { FormControlLabel, FormHelperText, MenuItem } from '@material-ui/core'
import { InfoRounded } from '@material-ui/icons'
import { format, parse } from 'date-fns'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { FormControl, FormLabel, Radio, RadioGroup, Select, styled, TextField } from 'opensolar-ui'
import { Field } from 'react-final-form'
import { required as fieldRequired } from 'validations'
import { EnaInputData } from './SchemaFields'
interface SelectInputProps extends EnaInputData {
  title: string
  selectOptions: string[]
}

interface SelectProps {
  parsedSchema: SelectInputProps
  path: string
  customOnChange?: (v) => void
  disabled?: boolean
}

export const EnaSelectField: React.FC<SelectProps> = ({ parsedSchema, path, customOnChange, disabled }) => {
  const {
    required = true,
    title,
    tooltip,
    placeholderText = 'Select options...',
    selectOptions,
    helperText,
  } = parsedSchema
  return (
    <div style={{ paddingTop: 4, paddingBottom: 4, boxSizing: 'border-box' }}>
      {parsedSchema && (
        <Field required={required} name={path} validate={fieldRequired}>
          {({ input, meta: { error } }) => {
            const { onChange, ...rest } = input
            return (
              <FormControl fullWidth required={required} margin="dense">
                <span>
                  <FormLabel required={required}>
                    {title}
                    {tooltip && <InlineTooltip tooltip={tooltip} />}
                  </FormLabel>
                </span>
                <Select
                  {...rest}
                  onChange={(e) => {
                    if (customOnChange) {
                      customOnChange(e.target.value)
                    }
                    onChange(e)
                  }}
                  value={input?.value || ''}
                  displayEmpty
                  fullWidth
                  disabled={disabled}
                >
                  <MenuItem disabled value="">
                    <em>{placeholderText}</em>
                  </MenuItem>
                  {Object.keys(selectOptions).map((o) => (
                    <MenuItem key={o} value={selectOptions[o]}>
                      {selectOptions[o]}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{error && helperText}</FormHelperText>
              </FormControl>
            )
          }}
        </Field>
      )}
    </div>
  )
}

interface RadioProps extends EnaInputData {
  title: string
  radioOptions: Record<string, any>
}

interface RadioGroupProps {
  parsedSchema: RadioProps
  path: string
  customOnChange?: (v) => void
  disabled?: boolean
}

export const EnaRadioGroup: React.FC<RadioGroupProps> = ({ parsedSchema, path }) => {
  return (
    <div style={{ boxSizing: 'border-box', overflow: 'hidden' }}>
      <Field
        required={parsedSchema.required}
        name={path}
        parse={(v) => {
          return parsedSchema.radioOptions[v]
        }}
        validate={(v) => !parsedSchema.required || v === undefined}
      >
        {({ input, meta: { error } }) => (
          <FormControl {...input} {...error} component="fieldset" fullWidth margin="dense">
            <span>
              <FormLabel style={{ whiteSpace: 'normal' }} required={parsedSchema.required}>
                {parsedSchema.title}
              </FormLabel>
              <InlineTooltip tooltip={parsedSchema.tooltip} />
            </span>
            <RadioGroup>
              {Object.keys(parsedSchema.radioOptions).map((o) => (
                <FormControlLabel
                  value={o}
                  key={o}
                  control={<Radio checked={input.value === parsedSchema.radioOptions[o]} />}
                  label={o}
                />
              ))}
            </RadioGroup>
            {error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        )}
      </Field>
    </div>
  )
}

interface EnaInput {
  path: string
  parsedSchema: EnaInputData
}

export const EnaTextField: React.FunctionComponent<EnaInput & any> = (props) => {
  const { parsedSchema, path } = props
  const validator = (value) => {
    if (parsedSchema.required && (!value || value === '')) {
      return 'Required'
    } else if (parsedSchema.validation && value && value !== '' && !new RegExp(parsedSchema.validation).test(value)) {
      return parsedSchema?.errorMessage
    }
  }
  return (
    <div style={{ paddingTop: 16, paddingBottom: 16, boxSizing: 'border-box', width: '100%' }}>
      {parsedSchema && (
        <Field required={parsedSchema.required} validate={validator} name={path}>
          {({ input, meta: { touched, error } }) => (
            <>
              <TextField
                label={
                  <span>
                    {parsedSchema.title} <InlineTooltip tooltip={parsedSchema.tooltip} />
                  </span>
                }
                placeholder={parsedSchema?.placeholderText || ''}
                error={!!touched && !!error}
                helperText={touched && error}
                fullWidth
                type={parsedSchema?.dataType}
                required={parsedSchema.required}
                disabled={parsedSchema.disabled}
                {...input}
              />
            </>
          )}
        </Field>
      )}
    </div>
  )
}

export const EnaDateField: React.FunctionComponent<EnaInput> = ({ parsedSchema, path }) => {
  const validator = (value: string) => {
    if (parsedSchema.required && (!value || value === '')) {
      return 'Required'
    }
    return undefined
  }
  return (
    <div style={{ paddingTop: 16, paddingBottom: 16, boxSizing: 'border-box', width: '100%' }}>
      {parsedSchema && (
        <Field name={path} validate={validator}>
          {({ input, meta: { touched, error } }) => (
            <>
              <TextField
                label={
                  <span>
                    {parsedSchema.title}
                    {parsedSchema.tooltip && <InlineTooltip tooltip={parsedSchema.tooltip} />}
                  </span>
                }
                type="date"
                error={touched && Boolean(error)}
                helperText={touched && error ? error : ''}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                required={parsedSchema.required}
                {...input}
                value={input.value ? format(new Date(input.value), 'yyyy-MM-dd') : ''}
                onChange={(e) => {
                  const value = e.target.value
                  input.onChange(value ? format(parse(value, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') : '')
                }}
              />
            </>
          )}
        </Field>
      )}
    </div>
  )
}

const InlineTooltip: React.FC<any> = ({ tooltip }: { tooltip?: string }) => {
  return <>{tooltip && <StyledTooltip title={tooltip} size={'small'} customIcon={<InfoRounded />} />}</>
}

export default InlineTooltip

const StyledTooltip = styled(InfoTooltip)(({ theme }) => ({
  fontSize: 19,
  position: 'absolute',
  right: 0,
  cursor: 'help',
}))
