import { Grid } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import { BooleanInput, NumberInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import useStyle from './styles'

const ManualPRCInputs = () => {
  const formState = useFormState()
  const classes = useStyle()
  const isEnabled = formState.values.prc_manual_override

  return (
    <div className={Boolean(isEnabled) ? classes.containerWithBoxShadow : classes.container}>
      <BooleanInput
        className={classes.toggleField}
        label="Manually Override Incentive Settings"
        name={'prc_manual_override'}
        source="prc_manual_override"
      />
      <DependentInput dependsOn={'prc_manual_override'} value={true}>
        <Grid container direction="row">
          <Grid item xs={12} lg={6} xl={3}>
            <CustomField
              className={classes.defaultFieldStyle}
              label="Demand Coefficient"
              name={'demand_coefficient'}
              source="demand_coefficient"
              endAdornment="kW/kWh"
              component={NumberInput}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <CustomField
              className={classes.defaultFieldStyle}
              label="Firmness Factor"
              name={'firmness_factor'}
              source="firmness_factor"
              component={NumberInput}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <CustomField
              className={classes.defaultFieldStyle}
              label="Hours per Day"
              name={'peak_reduction_hours'}
              endAdornment="hours/day"
              source="peak_reduction_hours"
              component={NumberInput}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <CustomField
              className={classes.defaultFieldStyle}
              label="System Lifetime (Modelled)"
              name={'modelled_system_lifetime'}
              endAdornment="years"
              source="modelled_system_lifetime"
              component={NumberInput}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <CustomField
              className={classes.defaultFieldStyle}
              label="Network Loss Factor"
              name={'network_loss_factor'}
              source="network_loss_factor"
              component={NumberInput}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <CustomField
              className={classes.defaultFieldStyle}
              label="kW Offset per Certificate"
              name={'kw_offset_per_certificate'}
              source="kw_offset_per_certificate"
              endAdornment="kW/certificate"
              component={NumberInput}
            />
          </Grid>
        </Grid>
      </DependentInput>
    </div>
  )
}

export default ManualPRCInputs
