import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { ComponentVersionsInherit } from 'opensolar-ui'
import { useLocation } from 'react-router-dom'
import PreOrderPage from './checkoutv3/preOrder'
import { ContentBg } from './checkoutv3/preOrder/styles'
import ResendOrderPage from './checkoutv3/resendOrder'

const CheckoutPage = () => {
  const location = useLocation()
  const locationState: any = location.state
  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <ContentBg>
        {locationState?.orderId ? (
          <ResendOrderPage orderId={locationState?.orderId} source="global" />
        ) : (
          <PreOrderPage />
        )}
      </ContentBg>
    </ComponentVersionsInherit>
  )
}

export default CheckoutPage
