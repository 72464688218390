import { ClickAwayListener, makeStyles, Tooltip, withStyles } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { InfoOutlined } from '@material-ui/icons'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { Button, IconButton } from 'opensolar-ui'
import { AlertBoxList } from 'projectSections/errors/AlertBoxList'
import { eventEmitter } from 'projectSections/errors/Emitter'
import { ProjErrorFilters } from 'projectSections/errors/useFilterProjectErrors'
import { useSharingErrorFilter } from 'projectSections/errors/useSharingErrorFilter'
import { useSortedProjectErrors } from 'projectSections/errors/useSortedProjectErrors'
import { FIELDS_BY_PAGES } from 'projectSections/form/fields'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectErrorType } from 'reducer/project/projectErrorsReducer'

interface PropTypes {
  section: string
  // filters?: ProjectWarningBoxPropsTypes[]
  // hasError: boolean
  // hasWarning: boolean
  wrapperStyle?: CSSProperties
}

const useStyles = makeStyles(() => ({
  icon: {
    width: 20,
    height: 20,
  },
  errorIcon: {
    color: 'rgb(230, 37, 0)',
  },
  warningIcon: {
    color: '#FFA500',
  },
  iconButton: {
    border: 'none',
    background: 'none',
    padding: '0 0 10px 0',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
}))

const PopoverTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFF',
    padding: 15,
    borderRadius: 5,
    boxShadow: '1px 2px 9px #4d4d4d',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
  },
  arrow: {
    color: '#FFF',
  },
}))(Tooltip)

const shareWarningPages = ['info', 'energy', 'design', 'payments', 'online-proposal', 'manage']

const notInfoFilter = (e: ProjectErrorType): boolean => e.severity && e.severity !== 'info'

const AlertsPopover: React.FC<PropTypes> = ({ section, wrapperStyle }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { errors: formErrors, submitErrors } = useFormState()

  const [open, openAlerts] = useState(false)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)

  const togglePopover = () => {
    openAlerts((prevOpen) => !prevOpen)
  }

  useEffect(() => {
    const handleClosePopOver = () => openAlerts(false)
    eventEmitter.on('openEditInvoiceDialog', handleClosePopOver)

    return () => {
      eventEmitter.off('openEditInvoiceDialog', handleClosePopOver)
    }
  }, [])

  // These errors won't show up in the tooltip, might need a rethink
  const hasOtherErrors = useMemo(() => {
    if (FIELDS_BY_PAGES[section]) {
      for (let i = 0; i < FIELDS_BY_PAGES[section].length; i++) {
        const field = FIELDS_BY_PAGES[section][i]
        if (formErrors?.[field] || submitErrors?.[field]) {
          return true
        }
      }
    }
    return false
  }, [section])

  const sharingFilter = useSharingErrorFilter(notInfoFilter, section)

  const allFilters = useMemo(() => {
    let ret: ProjErrorFilters[] = []
    if (section === 'design') {
      ret.push({
        sources: ['lastCalc', 'payments'],
        filter: notInfoFilter,
      })
    }
    if (section === 'payments') {
      ret.push({
        sources: ['payment_cashflow', 'lastCalc', 'payments', 'commercial_requirements'], //
        filter: notInfoFilter,
      })
    }
    if (section && shareWarningPages.includes(section))
      ret.push({
        sources: ['sharing'],
        filter: sharingFilter,
      })
    const fields = FIELDS_BY_PAGES[section]
    if (fields) {
      ret.push({
        fields,
        filter: notInfoFilter,
      })
    }
    return ret
  }, [section])

  const sortedErrors = useSortedProjectErrors(allFilters)
  const highestSeverity = sortedErrors[0]?.severity

  if (!highestSeverity && !hasOtherErrors) return <></>

  return (
    <PopoverTooltip
      open={open}
      title={
        <ClickAwayListener onClickAway={() => openAlerts(false)}>
          <div>
            <AlertBoxList sharingSection={section} errors={sortedErrors} />
            <Button variant="contained" onClick={togglePopover} fullWidth={true}>
              {translate('Close')}
            </Button>
          </div>
        </ClickAwayListener>
      }
      arrow
      interactive
      disableHoverListener
      onClose={() => openAlerts(false)}
    >
      <IconButton onClick={togglePopover} className={classes.iconButton} style={wrapperStyle}>
        <InfoOutlined
          className={`${classes.icon} ${
            hasOtherErrors || highestSeverity === 'error'
              ? classes.errorIcon
              : highestSeverity === 'warning'
              ? classes.warningIcon
              : ''
          }`}
        />
      </IconButton>
    </PopoverTooltip>
  )
}

export default AlertsPopover
