import { useCallback, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTracking } from '../contexts/eventsTracking/TrackingContext'
import { usePublicFeatureConfig } from './usePublicFeatureConfig'
import useUiKey from './useUiKey'

interface UseTrackComponentParams {
  eventKey: string
  eventName: string
  description?: string
  props?: Record<string, any>
  state?: Record<string, any>
}

interface AdditionalData {
  eventName?: string
  description?: string
  uiKey?: string
  [key: string]: any
}

const useTrackComponent = ({
  eventKey,
  eventName: defaultEventName,
  description: defaultDescription,
  props = {},
  state = {},
}: UseTrackComponentParams) => {
  const logEvent = useTracking()

  const uiKey = useUiKey()

  const featureConfig = usePublicFeatureConfig('event_config')

  const trackInteraction = useCallback(
    (interactionType: string, additionalData: AdditionalData = {}) => {
      const { eventName = defaultEventName, description = defaultDescription, ...rest } = additionalData

      logEvent({
        eventKey,
        eventType: interactionType,
        eventName,
        description,
        additionalData: {
          uiKey,
          ...rest,
        },
        eventConfig: featureConfig?.backends,
      })
    },
    [logEvent, eventKey, defaultEventName, defaultDescription, uiKey, featureConfig]
  )

  const previousValues = useRef({ ...props, ...state })

  useEffect(() => {
    const combinedValues = { ...props, ...state }

    Object.keys(combinedValues).forEach((key) => {
      if (combinedValues[key] !== previousValues.current[key]) {
        trackInteraction('property_change', {
          key,
          description: defaultDescription,
          eventName: defaultEventName,
          oldValue: previousValues.current[key],
          newValue: combinedValues[key],
        })
      }
    })

    previousValues.current = combinedValues
  }, [props, state, trackInteraction])

  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.5,
    onChange: (inView) => {
      if (inView) {
        trackInteraction('view')
      }
    },
  })

  return { trackInteraction, ref }
}

export default useTrackComponent
