import { Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { PAYMENTS_PAGE_TITLE_HELPCENTER_URL } from 'constants/links'
import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'
import RecalculateBtn from '../common/RecalculateBtn'
import ConvertToCashFlowButton from './ConvertToCashFlowButton'
import SystemChip from './SystemChip'

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '15px',
  },
  left: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '0px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '8px',
    },
  },
  titleText: {
    fontSize: '20px',
    fontWeight: 200,
    marginRight: '10px',
  },
  middle: {
    flex: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  right: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
  },
}))

type PropTypes = {}

const PaymentsPageTitle: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()

  const systems: StudioSystemType[] = window.editor?.getSystems()
  const project = useFormState()?.values
  const cashFlowActiveOnOrg = useSelector(orgSelectors.getCashFlowIsActive)

  const isSold = useMemo(() => {
    return Boolean(project?.system_sold) && Boolean(project?.payment_option_sold)
  }, [project?.system_sold, project?.payment_option_sold])

  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>
        <div className={classes.titleText}>{translate('Payment Options')}</div>
        <Tooltip
          title={
            <div>
              <span>
                {translate('See help center payment articles here to learn more about setting up payment options')}
              </span>
              <br />
              <a href={PAYMENTS_PAGE_TITLE_HELPCENTER_URL} target="_blank" rel="noopener noreferrer">
                {translate('Click to learn more')}
              </a>
            </div>
          }
          interactive={true}
          arrow
          placement="top"
          style={{ cursor: 'pointer' }}
        >
          <InfoOutlined htmlColor="rgb(117, 117, 117)" fontSize="small" />
        </Tooltip>
      </div>
      <div className={classes.middle}>
        {systems.map((system, i) => (
          <SystemChip key={system.uuid} system={system} index={i} />
        ))}
      </div>
      <div className={classes.right}>
        <ConvertToCashFlowButton />
        {((!isSold && cashFlowActiveOnOrg) || !cashFlowActiveOnOrg) && <RecalculateBtn />}
      </div>
    </div>
  )
}
export default PaymentsPageTitle
