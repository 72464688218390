"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.studio = void 0;
var action_1 = require("../core/action");
var signal_1 = require("../core/signal");
var state_1 = require("../core/state");
exports.studio = {
    // State
    availableImagery: new state_1.DeepState(undefined),
    views: new state_1.ArrayState([]),
    // Actions
    autoDesignRunAndLoad: new action_1.Action(),
    autoDesignRunAndLoadFromEquipment: new action_1.Action(),
    setComponents: new action_1.Action(),
    removeObject: new action_1.Action(),
    getLoadedData: new action_1.Action(),
    getSelectedSystemData: new action_1.Action(),
    getSystemImageUrl: new action_1.Action(),
    executeCommand: new action_1.Action(),
    executeCommandFromJson: new action_1.Action(),
    callFunction: new action_1.Action(),
    // Signals
    windowResize: new signal_1.Signal(),
    objectSelected: new signal_1.Signal(),
    objectAdded: new signal_1.Signal(),
    objectChanged: new signal_1.Signal(),
    objectRemoved: new signal_1.Signal(),
    sceneLoaded: new signal_1.Signal(),
    queueProcessed: new signal_1.Signal(),
};
