import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { premiumProductsSelectors } from 'ducks/premiumProducts'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { useAcceptTermsAndActivateProduct, useGetPremiumProductByTitle } from 'hooks/usePremiumProducts'
import { useNotify } from 'ra-core'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

type AcceptTermsProps = {
  open: boolean
  onClose: () => void
  onAccept?: () => void
  terms: string
  hideDismiss?: boolean
  isEnableAndAccept?: boolean // for use when we want to open the terms dialog and enable the product as well as accept terms when user clicks accept
}

interface EnableAndAcceptTermsProps extends Omit<AcceptTermsProps, 'terms'> {
  productTitle: string
  isEnableAndAccept: true
}

const PremiumProductTermsDialog: React.FC<AcceptTermsProps | EnableAndAcceptTermsProps> = (props) => {
  const acceptTerms = useAcceptTermsAndActivateProduct()
  const notify = useNotify()
  const productsLoading = useSelector(premiumProductsSelectors.getIsLoading)
  const availableProducts = useSelector(premiumProductsSelectors.getProducts)

  const getProductByTitle = useGetPremiumProductByTitle()

  const isEnableAndAcceptProps = (
    props: AcceptTermsProps | EnableAndAcceptTermsProps
  ): props is EnableAndAcceptTermsProps => {
    const { isEnableAndAccept = false, productTitle } = props as Partial<EnableAndAcceptTermsProps>
    return isEnableAndAccept && typeof productTitle === 'string'
  }

  const dialogProps = useMemo(() => {
    if (!isEnableAndAcceptProps(props)) {
      return props
    } else if (productsLoading || !availableProducts) {
      return null
    } else {
      const product = getProductByTitle(props.productTitle)
      if (!product) {
        notify('Something went wrong', 'error')
        return null
      } else {
        const onAccept = () => {
          acceptTerms(product, props?.onAccept)
        }
        const onClose = () => {
          logAmplitudeEvent('premium_product_terms_dismissed', {})
          props.onClose()
        }
        const terms = product.terms
        return { ...props, onAccept, onClose, terms }
      }
    }
  }, [productsLoading, availableProducts])

  return (
    <>
      {dialogProps && (
        <Dialog open={props.open} onClose={dialogProps.onClose}>
          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: dialogProps.terms }} />
          </DialogContent>
          <DialogActions>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {!dialogProps?.hideDismiss && (
                <div style={{ margin: '20px' }}>
                  <ProUXButton type="secondary" label="Dismiss" onClick={dialogProps.onClose} />
                </div>
              )}
              {dialogProps.onAccept && (
                <div id="i_igree_nearmap_term" style={{ margin: '20px' }}>
                  <ProUXButton type="primary" label="I Agree" onClick={dialogProps.onAccept} />
                </div>
              )}
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default PremiumProductTermsDialog
