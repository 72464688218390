import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { FormApi } from 'final-form'
import { useSendPvfActivationEmail, useUpdatePremiumProducts } from 'hooks/usePremiumProducts'
import React from 'react'
import { useTranslate } from 'react-admin'
import { Link } from 'react-router-dom'
import { PremiumProductDetailType } from 'types/global'
import PremiumProductFormRow from './PremiumProductFormRow'

type PropTypes = {
  form: FormApi
}

const PremiumProductsForm: React.FC<PropTypes> = (props) => {
  const products = props.form?.getState().values as PremiumProductDetailType[]
  const translate = useTranslate()
  const updatePremiumProducts = useUpdatePremiumProducts()
  const sendActivationEmail = useSendPvfActivationEmail()

  const saveUpdate = () => {
    let activeURLs: string[] = []
    props.form?.getState().values.forEach((prod: PremiumProductDetailType, i: number) => {
      if (prod.is_active) activeURLs.push(prod.url)
      if (prod.is_active !== props.form.getState()?.initialValues[i]?.is_active) {
        if (prod.title === 'PVF' && prod.is_active) {
          sendActivationEmail()
        }
        if (prod.is_active) logAmplitudeEvent('premium_product_enabled', { title: prod.title })
        else logAmplitudeEvent('premium_product_disabled', { title: prod.title })
      }
    })
    updatePremiumProducts(activeURLs)
  }

  if (!products || products?.length === 0 || Object.keys(products)?.length === 0 || !Array.isArray(products)) {
    return (
      <div>
        <h2>{translate('Please activate your wallet first')}</h2>
        <p>
          {translate('Premium imagery products are only available once you have activated your wallet.')}{' '}
          <Link to="/wallet">{translate('You can activate your wallet here.')}</Link>
        </p>
      </div>
    )
  }

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead className="Ra-Datagrid-Thead">
            <TableRow>
              <TableCell style={{ width: '15%', backgroundColor: 'rgba(230, 230, 230, 0.85)' }}>
                {translate('Provider')}
              </TableCell>
              <TableCell style={{ width: '60%', backgroundColor: 'rgba(230, 230, 230, 0.85)' }}>
                {translate('Description')}
              </TableCell>
              <TableCell style={{ width: '20%', backgroundColor: 'rgba(230, 230, 230, 0.85)' }}>
                {translate('Price')}
              </TableCell>
              <TableCell style={{ width: '5%', backgroundColor: 'rgba(230, 230, 230, 0.85)' }}>
                {translate('Active')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="datagrid-body">
            {products?.map((prod: PremiumProductDetailType, i: number) => (
              <PremiumProductFormRow key={i} product={prod} i={i} saveUpdate={saveUpdate} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default PremiumProductsForm
