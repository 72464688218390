import { Chip } from 'opensolar-ui'
import React from 'react'
import { TextField, TextInput, useTranslate } from 'react-admin'
import { formatTimeStringBaseOnLocale } from 'util/misc'
import { previewInfo } from '../../common/utils'

import { FileTagsChipsInput } from '../../common/formFields/FileTagsChipsInput'
import { TableRowBtns } from '../../common/menus/TableRowBtns'
import { TagsContainer } from '../../common/styles/styles'
import { EditableField } from '../elements/editInline/EditInlineField'
import { EditabledFieldSelectedType } from '../ProjectFilesList'
import { SelectTitle, TableViewStyledDatagrid } from './tableViewStyles'

interface TableViewDatagridProps {
  editableFieldSelected: EditabledFieldSelectedType
  setEditableFieldSelected: (selectedField: EditabledFieldSelectedType) => void
}

const TitleField = (props) => {
  const { fileType } = previewInfo(props.record)
  const fieldId = `${props.record.id}-title`
  const { record, ...rest } = props
  return (
    <EditableField
      record={record}
      fieldId={fieldId}
      editComponent={<TextInput multiline={true} source="title" variant="outlined" label={false} autoFocus />}
      selectComponent={
        <SelectTitle>
          <img src={`${window.PUBLIC_URL}/images/doc_icons/${fileType}.svg`} alt={`${fileType} icon`} />
          <p>{record.title}</p>
        </SelectTitle>
      }
      fieldName={'title'}
      {...rest}
    />
  )
}

const TagsField = (props) => {
  const translate = useTranslate()
  const { record, ...rest } = props
  const tags = record?.file_tags_data || []
  const fieldId = `${record.id}-file_tags_data`
  return (
    <EditableField
      editComponent={<FileTagsChipsInput />}
      selectComponent={
        <>
          {tags.length ? (
            <TagsContainer>
              {tags.map((t) => (
                <Chip key={t.id} color={'info'} label={translate(t.title)} />
              ))}
            </TagsContainer>
          ) : (
            <p>{translate('No tags found')}</p>
          )}
        </>
      }
      fieldId={fieldId}
      record={record}
      fieldName={'file_tags_data'}
      {...rest}
    />
  )
}

export const TableViewDatagrid: React.FC<TableViewDatagridProps> = (props) => {
  return (
    <TableViewStyledDatagrid bulkActionsDisabled={!!props.editableFieldSelected} {...props}>
      <TitleField source={'title'} {...props} />
      <TagsField label={'Document Tags'} source={'file_tags_data'} sortable={false} {...props} />
      <TextField
        source="filesize"
        overrideValueFunc={(value) => {
          // const size = value / 10000
          const size = value / 1000000
          return `${size.toFixed(2)} MB`
        }}
      />
      <TextField source="modified_date" overrideValueFunc={(value) => formatTimeStringBaseOnLocale(value)} />
      <TableRowBtns disabled={!!props.editableFieldSelected} />
    </TableViewStyledDatagrid>
  )
}
