import { orgSelectors } from 'ducks/orgs'
import { useNotify, useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { COUNTRIES_WITHOUT_DEBIT_CARD_SURCHARGING, US_STATES_FOR_POST_DEPOSIT_DEFAULT_ACH_PAYMENT } from '../constants'
import { CashFlowConfiguration, PaymentMethodRecordType } from '../types'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const useGetCashFlowConfigurations = (
  orgId: number | undefined,
  page: number,
  limit: number = 10,
  isUpdated?: boolean
) => {
  const [results, setResults] = useState<CashFlowConfiguration[]>([])
  useEffect(() => {
    if (orgId) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/payments/configuration/`,
      })
        .then((res) => {
          setResults(res.data)
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }, [orgId, page, limit, isUpdated])

  return results
}

export const sanitizeCashFlowConfigForm = (vals) => {
  vals.payment_milestone_configurations?.forEach((milestone, i) => {
    // make sure the last milestone has set_value_to_remaining_amount_due=true but no others do
    // and if this is a deposit_only config set_value_to_remaining_amount_due must be false
    if (i + 1 < vals.payment_milestone_configurations.length || vals.deposit_only) {
      vals.payment_milestone_configurations[i].set_value_to_remaining_amount_due = false
    } else {
      vals.payment_milestone_configurations[i].set_value_to_remaining_amount_due = true
      vals.payment_milestone_configurations[i].percentage = null
      vals.payment_milestone_configurations[i].min_fixed = null
      vals.payment_milestone_configurations[i].max_fixed = null
    }

    if (milestone.remove_from_percentage_calcs) {
      if (i === 0) {
        // if the first milestone has remove_from_percentage_calcs set to true, remove percentage, min and max
        milestone.percentage = null
        milestone.min_fixed = null
        milestone.max_fixed = null
      } else {
        // if this is not the first milestone then remove_from_percentage_cals should be false and fixed_amount should be null
        // those fields are only allowed on the first milestone
        milestone.remove_from_percentage_cals = false
        milestone.fixed_amount = null
      }
    } else {
      milestone.fixed_amount = null
    }

    // don't allow any milestone other than the first to be marked as a deposit
    if (i > 0 && milestone.is_deposit) {
      milestone.is_deposit = false
    }

    // add payment_number to each milestone before submitting to make sure things like re-sorting or deleting milestones doesn't end up
    // leading to incorrect payment numbers being submitted
    vals.payment_milestone_configurations[i].payment_number = i + 1
  })
  return vals
}

export const useGetPaymentMethodChoices = () => {
  const [countryChoices, setCountryChoices] = useState<{ id: string; name: string; max_payment_amount: number }[]>([])

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const orgCountry = useSelector(orgSelectors.getOrgIso2)
  const translate = useTranslate()
  const notify = useNotify()

  useEffect(() => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/payment_methods/`,
    })
      .then((res) => {
        setCountryChoices(
          res.data
            ?.filter((pmtMethod) => pmtMethod.is_available_for_configurations)
            ?.map((pmtMethod) => ({
              id: pmtMethod.payment_method_type,
              name: getTranslatedPaymentMethodLabel(translate, pmtMethod.payment_method_type),
              max_payment_amount: pmtMethod.max_payment_amount,
            }))
        )
      })
      .catch((err) => {
        notify(translate('Unable to fetch available payment methods'), 'warning')
      })
  }, [orgCountry])

  return countryChoices
}

export const useGetPaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodRecordType[]>([])

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const orgCountry = useSelector(orgSelectors.getOrgIso2)
  const translate = useTranslate()
  const notify = useNotify()

  useEffect(() => {
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/payment_methods/`,
    })
      .then((res) => {
        setPaymentMethods(res.data)
      })
      .catch((err) => {
        notify(translate('Unable to fetch available payment methods'), 'warning')
      })
  }, [orgCountry])

  return paymentMethods
}

export const getDefaultPaymentMethodsForCountry = (countryIso2: string, isDeposit: boolean, businessState: string) => {
  if (countryIso2 === 'GB') {
    if (isDeposit)
      return [
        'credit_card_standard',
        'credit_card_premium',
        'credit_card_international',
        'local_bank_transfer',
        'apple_google_pay',
      ]
    else return ['local_bank_transfer']
  } else if (countryIso2 === 'AU') {
    return [
      'credit_card_standard',
      'credit_card_premium',
      'credit_card_international',
      'becs_direct_debit',
      'apple_google_pay',
    ]
  } else if (countryIso2 === 'US') {
    if (isDeposit || !US_STATES_FOR_POST_DEPOSIT_DEFAULT_ACH_PAYMENT.includes(businessState)) {
      return ['credit_card_standard', 'credit_card_premium', 'credit_card_international', 'ach', 'apple_google_pay']
    } else {
      // Ref: https://github.com/open-solar/opensolar-todo/issues/11707
      // Default to ACH if their business is in state in the US where pros cannot surcharge
      return ['ach']
    }
  }
  return []
}

export const getIsSurchargingAllowed = (countryIso2: string | undefined) => {
  const surcharingCountries = ['US', 'AU']
  return countryIso2 && surcharingCountries.includes(countryIso2)
}

export const showDebitCardSurchargeWarning = (
  surchargingEnabled: boolean,
  paymentMethods: any,
  orgCountry: string | undefined
) => {
  if (!orgCountry) return false
  if (!surchargingEnabled || !COUNTRIES_WITHOUT_DEBIT_CARD_SURCHARGING.includes(orgCountry)) return false
  if (
    paymentMethods.includes('debit_card_standard') ||
    paymentMethods.includes('debit_card_premium') ||
    paymentMethods.includes('debit_card_international')
  )
    return true
}

export const showCreditCardTooltipCap = (paymentMethods: any, hasPaymentCap: boolean) => {
  if (!paymentMethods || !hasPaymentCap) return false
  if (
    paymentMethods.includes('credit_card_standard') ||
    paymentMethods.includes('credit_card_premium') ||
    paymentMethods.includes('credit_card_international') ||
    paymentMethods.includes('debit_card_standard') ||
    paymentMethods.includes('debit_card_premium') ||
    paymentMethods.includes('debit_card_international') ||
    paymentMethods.includes('apple_google_pay')
  )
    return true
}

export const getHasPaymentCap = (paymentMethods: any, orgCountry: string | undefined) => {
  let capAmount = 6000
  if (orgCountry === 'AU') {
    capAmount = 10000
  }

  return paymentMethods?.every(
    (choice) =>
      choice.id === 'ach' ||
      choice.id === 'sepa_credit' ||
      choice.id === 'becs_direct_debit' ||
      choice.max_payment_amount === capAmount
  )
}

export const showAppleGooglePaySurchargeWarning = (surchargingEnabled: boolean, paymentMethods: any) => {
  if (!surchargingEnabled) return false
  return paymentMethods.includes('apple_google_pay')
}

export const checkAutoApplyConfigExists = (data) => {
  let id
  if (data) {
    const config = data.find((config) => config.auto_apply === true)
    if (config) {
      id = config.id
    }
  }
  return id
}

export const getTranslatedPaymentMethodLabel = (translateFn: Function, pmt_type: string) => {
  switch (pmt_type) {
    case 'local_bank_transfer':
      return translateFn('Local Bank Transfer')
    case 'credit_card_standard':
      return translateFn('Credit Card - Standard')
    case 'credit_card_premium':
      return translateFn('Credit Card - Premium')
    case 'credit_card_international':
      return translateFn('Credit Card - International')
    case 'debit_card_standard':
      return translateFn('Debit Card - Standard')
    case 'debit_card_premium':
      return translateFn('Debit Card - Premium')
    case 'debit_card_international':
      return translateFn('Debit Card - International')
    case 'ach':
      return 'ACH'
    case 'apple_google_pay':
      return translateFn('Apple/Google Pay')
    case 'becs_direct_debit':
      return translateFn('Direct Debit')
    case 'sepa_credit':
      return translateFn('Open Banking')
    default:
      return translateFn('Offline')
  }
}
