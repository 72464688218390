import { orgSelectors } from 'ducks/orgs'
import { Chip, FormControl, MenuItem, Select, styled } from 'opensolar-ui'
import { FC } from 'react'
import { useSelector } from 'react-redux'

interface PropTypes {
  workflow: number | null
  setWorkflow: (workflow: number | undefined) => void
}

const Wrapper = styled(FormControl, { name: 'Wrapper' })({
  marginRight: 10,
})

const InputDisplay = styled('div', { name: 'InputDisplay' })({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})

const WorkflowDropdown: FC<PropTypes> = ({ workflow, setWorkflow }) => {
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)

  if (!orgWorkflows?.length || !workflow) return null
  return (
    <Wrapper variant="outlined" color={'secondary'}>
      <Select
        value={workflow}
        onChange={(e) => {
          const value: any = e.target.value
          setWorkflow(value)
        }}
        renderValue={() => {
          const item = orgWorkflows?.find((choice) => choice.id === workflow)
          if (!item) return ''
          return (
            <InputDisplay>
              <Chip label="Workflow" /> <span>{item.title}</span>
            </InputDisplay>
          )
        }}
      >
        {orgWorkflows?.map((x, i) => (
          <MenuItem key={'workflow' + i} value={x.id}>
            {x.title}
          </MenuItem>
        ))}
      </Select>
    </Wrapper>
  )
}

export default WorkflowDropdown
