import { Button, InformationCircleIcon, LockOutlineIcon, Typography, WarningOutlineIcon } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC, memo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type Props = {
  title: string
  subtitle?: string
  severity: Severities
  onSubmit?: () => void
  onSubmitLabel?: string
  fontWeight?: 'normal' | 'bold'
}

type Severities = 'success' | 'info' | 'error'

type StyleProps = {
  severity: Severities
}

const severityPalette = {
  success: {
    borderColor: '#91DDAD',
    backgroundColor: '#EBFBF1',
    fontColor: '#016626',
    icon: LockOutlineIcon,
  },
  info: {
    borderColor: '#D6E0F7',
    backgroundColor: '#F5F8FD',
    fontColor: '#113B98',
    icon: InformationCircleIcon,
  },
  error: {
    borderColor: '#FAA4A4',
    backgroundColor: '#FEEBEB',
    fontColor: '#961616',
    icon: WarningOutlineIcon,
  },
}

const useStyles = makeOpenSolarStyles((theme) => ({
  container: (props: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: severityPalette[props.severity].borderColor,
    borderRadius: 4,
    padding: '8px',
    background: severityPalette[props.severity].backgroundColor,
    alignItems: 'flex-start',
  }),
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconContainer: {
    marginRight: '8px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0px 0px 30px',
  },
}))

const Alert: FC<Props> = ({ title, subtitle, severity, onSubmit, onSubmitLabel, fontWeight = 'bold' }) => {
  const translate = useTranslate()
  const classes = useStyles({ severity })
  const Icon = severityPalette[severity].icon
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.iconContainer}>
          <Icon width="24" height="24" color={severityPalette[severity].fontColor} />
        </div>
        <div className={classes.textContainer}>
          <Typography
            textVariant={fontWeight === 'normal' ? 'body1' : 'body1Bold'}
            colorHex={severityPalette[severity].fontColor as `#${string}`}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography textVariant="body1" colorHex={severityPalette[severity].fontColor as `#${string}`}>
              {subtitle}
            </Typography>
          )}
        </div>
      </div>
      {onSubmit && (
        <div className={classes.footerContainer}>
          <Button variant="contained" color="default" onClick={onSubmit}>
            {onSubmitLabel}
          </Button>
        </div>
      )}
    </div>
  )
}

export default memo(Alert)
