import { useCallback } from 'react'
import addComponentSetToSystem from '../../wizardUtils/addComponentSetToSystem'
import type { WizardComponentLikeType } from '../../wizardUtils/types'
import useMaybeActivateAndReload from '../../wizardUtils/useMaybeActivateAndReload'
import type { BSACustomerPriorityKeyType, EssentialBackupMetrics } from '../utility/types'

type SelectInverterSetProps = {
  systemUuid: string
  componentsSet: WizardComponentLikeType[]
  customerPriority?: BSACustomerPriorityKeyType
  essentialBackupMetrics?: EssentialBackupMetrics
}

const clearSystemBatteries = ({ systemUuid }: { systemUuid: string }) => {
  const system = systemUuid && window.editor.objectByUuid(systemUuid)
  const components = system?.batteries() || []
  var commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()
  components.forEach((component) => {
    window.editor.execute(new window.RemoveObjectCommand(component, true, false, commandUUID))
  })
}

const updateSystemBDASettings = ({
  systemUuid,
  essentialBackupMetrics,
}: {
  systemUuid: string
  essentialBackupMetrics?: EssentialBackupMetrics
}) => {
  const system = systemUuid && window.editor.objectByUuid(systemUuid)

  window.editor.execute(new window.SetValueCommand(system, 'essential_backup_metrics', essentialBackupMetrics || {}))
}

const updateSystemBatteryControlScheme = ({
  systemUuid,
  customerPriority,
}: {
  systemUuid: string
  customerPriority?: BSACustomerPriorityKeyType
}) => {
  const system = systemUuid && window.editor.objectByUuid(systemUuid)
  // Change `self_consumption` to `solar_charged`, since it has a different value for battery control scheme
  // Use `solar_charged` as default if empty
  const batteryControlScheme = customerPriority === 'self_consumption' ? 'solar_charged' : customerPriority
  window.editor.execute(
    new window.SetValueCommand(system, 'battery_control_scheme', batteryControlScheme || 'solar_charged')
  )
}

const useSelectBatteryDesignAssistant = ({ onClose }: { onClose(): void }) => {
  const maybeActivateAndReload = useMaybeActivateAndReload()

  return useCallback(
    async ({ systemUuid, componentsSet, customerPriority, essentialBackupMetrics }: SelectInverterSetProps) => {
      await maybeActivateAndReload({
        componentsSet,
      })

      updateSystemBatteryControlScheme({ systemUuid, customerPriority })

      updateSystemBDASettings({ systemUuid, essentialBackupMetrics })

      clearSystemBatteries({ systemUuid })

      addComponentSetToSystem({ systemUuid, componentsSet })

      onClose()
    },
    [maybeActivateAndReload, onClose]
  )
}

export default useSelectBatteryDesignAssistant
