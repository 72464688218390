const styles = {
  pro: {
    container: {
      margin: '0px 20px',
      fontSize: 12,
      color: '#a2a2a2',
      justifyContent: 'center',
    },
    wrapper: {
      // minWidth: '30%',
    },
  },
  customer: {
    container: {
      margin: '0px 20px',
      fontSize: 12,
      color: '#a2a2a2',
    },
    wrapper: {
      display: 'inline',
    },
  },
}
const Annotations = ({ annotations }) => {
  if (Boolean(!annotations)) {
    return null
  }

  var annotationsUniqueSorted = window.OsAnnotation.uniqueSortedWithAutoLabels(annotations)

  if (!annotationsUniqueSorted.length) {
    return null
  }

  return (
    <div style={styles.customer.container}>
      {annotationsUniqueSorted.map((annotation, index) => {
        return (
          <span key={annotation.timestamp + '_' + index} style={{ whiteSpace: 'nowrap' }}>
            <div
              className={annotation.ephemeral ? 'annotation-ephemeral' : 'clickable-annotation'}
              style={{ display: 'inline', cursor: 'default', background: '#ffffff', padding: '2px 5px' }}
            >
              <span>{annotation.label || index + 1}</span>
            </div>
            <span
              style={{
                wordBreak: 'break-word',
                whiteSpace: 'normal',
                paddingLeft: 5,
                paddingRight: 15,
                lineHeight: '20px',
              }}
            >
              {annotation.description}
            </span>
          </span>
        )
      })}
    </div>
  )
}

export default Annotations
