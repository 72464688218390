var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { FieldTitle, useInput } from 'ra-core';
import { useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Switch } from 'opensolar-ui';
import InputHelperText from './InputHelperText';
import InputPropTypes from './InputPropTypes';
import sanitizeRestProps from './sanitizeRestProps';
// const defaultFormControlLabelStyle = { margin: '24px 40px 16px 0' }
var BooleanInput = function (_a) {
    var format = _a.format, label = _a.label, fullWidth = _a.fullWidth, helperText = _a.helperText, onBlur = _a.onBlur, onChange = _a.onChange, onFocus = _a.onFocus, options = _a.options, disabled = _a.disabled, parse = _a.parse, resource = _a.resource, source = _a.source, validate = _a.validate, _b = _a.labelPlacement, labelPlacement = _b === void 0 ? 'start' : _b, formControlLabelStyle = _a.formControlLabelStyle, endAdornment = _a.endAdornment, rest = __rest(_a, ["format", "label", "fullWidth", "helperText", "onBlur", "onChange", "onFocus", "options", "disabled", "parse", "resource", "source", "validate", "labelPlacement", "formControlLabelStyle", "endAdornment"]);
    var _c = useInput(__assign({ format: format, onBlur: onBlur, onChange: onChange, onFocus: onFocus, parse: parse, resource: resource, source: source, type: 'checkbox', validate: validate }, rest)), id = _c.id, _d = _c.input, finalFormOnChange = _d.onChange, type = _d.type, value = _d.value, checked = _d.checked, inputProps = __rest(_d, ["onChange", "type", "value", "checked"]), isRequired = _c.isRequired, _e = _c.meta, error = _e.error, touched = _e.touched;
    var handleChange = useCallback(function (event, value) {
        finalFormOnChange(value);
    }, [finalFormOnChange]);
    //it's a little bit tricky here, if component wrapped by <Field/> the 'value' prop presenting field's actual value otherwise 'checked' contains the actual value
    var finalValue = value || checked;
    return (_jsxs(FormGroup, __assign({}, sanitizeRestProps(rest), { children: [_jsx(FormControlLabel, { style: formControlLabelStyle, control: _jsxs(_Fragment, { children: [_jsx(Switch, __assign({ id: id, color: "secondary", onChange: handleChange }, inputProps, options, { disabled: disabled, checked: Boolean(finalValue) })), endAdornment && (_jsx(Tooltip, __assign({ title: endAdornment }, { children: _jsx(IconButton, __assign({ size: "small" }, { children: _jsx(HelpOutlineIcon, { fontSize: "small" }) })) })))] }), labelPlacement: labelPlacement, label: _jsx(FieldTitle, { label: label, source: source, resource: resource, isRequired: isRequired }) }), _jsx(FormHelperText, __assign({ error: !!error }, { children: _jsx(InputHelperText, { touched: touched, error: error, helperText: helperText }) }))] })));
};
BooleanInput.propTypes = __assign(__assign({}, InputPropTypes), { options: PropTypes.any, disabled: PropTypes.bool });
BooleanInput.defaultProps = {
    options: {},
};
export default BooleanInput;
