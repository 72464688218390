import { getPremiumProducts, premiumProductsSelectors } from 'ducks/premiumProducts'
import { ControlBreadCrumbs } from 'elements/BreadCrumbs'
import React, { useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { AccessRightsType } from 'types/global'
import PremiumProductsForm from './PremiumProductsForm'

type PropTypes = {
  accessRights: AccessRightsType
  isGlobal: boolean
  match: {
    isExact: boolean
    params: { orgId: string } | undefined
    path: string
    url: string
  }
}

export const PremiumProductsList: React.FC<PropTypes> = (props) => {
  const dispatch = useDispatch()
  const translate = useTranslate()

  useEffect(() => {
    dispatch(getPremiumProducts())
  }, [])

  const products = useSelector(premiumProductsSelectors.getProducts) || []

  const onSubmit = () => {
    console.log('submit')
  }

  return (
    <div style={{ padding: '8px 24px 60px 24px' }}>
      <ControlBreadCrumbs currentPage="Connect" />
      <h1 style={{ fontSize: '20px', margin: '20px 0px', fontWeight: 500 }}>{translate('Connect')}</h1>
      <Form onSubmit={onSubmit} render={PremiumProductsForm} initialValues={products}></Form>
    </div>
  )
}

export default PremiumProductsList
