import { CircularProgress } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import CloseDialogButton from 'elements/button/CloseDialogButton'
import { PaymentRequestType } from 'myenergy/dialogs/checkout/cashFlow/types'
import { useGetPaymentRequestData } from 'myenergy/dialogs/checkout/cashFlow/utils'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from 'opensolar-ui'
import SurchargingNotSupportedWarning from 'pages/cashFlow/configuration/paymentMilestones/SurchargingNotSupportedWarning'
import SurchargingTooltip from 'pages/cashFlow/configuration/paymentMilestones/SurchargingTooltip'
import {
  getIsSurchargingAllowed,
  getTranslatedPaymentMethodLabel,
  useGetPaymentMethods,
} from 'pages/cashFlow/configuration/utils'
import { useNotify, useTranslate } from 'ra-core'
import { useMemo, useState } from 'react'
import { BooleanInput, SelectArrayInput } from 'react-admin'
import { Form, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { updatePaymentRequestSettings } from '../utils'

interface WrapperPropTypes {
  paymentRequestId: number
  projectId: number
  closeDialog: () => void
}

interface PropTypes extends WrapperPropTypes {
  paymentRequest: PaymentRequestType
}

const useStyles = makeOpenSolarStyles((theme) => ({
  mainContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '0% 5% 10% 5%',
  },
  surchargingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  surchargeDisclaimerWrapper: {
    marginLeft: '15px',
  },
}))

const EditPaymentOptionsDialogWrapper: React.FC<PropTypes> = (props) => {
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const translate = useTranslate()
  const classes = useStyles()
  const formState = useFormState()
  const notify = useNotify()

  const paymentMethodsOnOrg = useGetPaymentMethods()

  const orgCountry = useSelector(orgSelectors.getOrg)?.country?.iso2

  const surchargingIsAllowed = useMemo(() => {
    return getIsSurchargingAllowed(orgCountry)
  }, [orgCountry])

  const ineligiblePaymentMethods = useMemo(() => {
    if (!paymentMethodsOnOrg?.length) {
      return []
    }

    return paymentMethodsOnOrg.filter((method) => {
      if (method.max_payment_amount !== null && props.paymentRequest.payment_amount > method.max_payment_amount) {
        return true
      } else if (
        method.min_payment_amount !== null &&
        props.paymentRequest.payment_amount < method.min_payment_amount
      ) {
        return true
      }
      return false
    })
  }, [paymentMethodsOnOrg, props.paymentRequest.payment_amount])

  const paymentMethodChoices = useMemo(() => {
    return paymentMethodsOnOrg?.map((method) => ({
      id: method.payment_method_type,

      name: getTranslatedPaymentMethodLabel(translate, method.payment_method_type),
    }))
  }, [paymentMethodsOnOrg])

  const onSave = () => {
    if (props.paymentRequest.payment_request_id) {
      setIsSaving(true)
      updatePaymentRequestSettings(
        props.paymentRequest.org_id,
        props.paymentRequest.payment_request_id,
        formState.values.accepted_payment_methods,
        formState.values.surcharging_enabled
      )
        .then((res) => {
          notify('Payment settings updated', 'success')
          props.closeDialog()
        })
        .catch((err) => {
          notify(err || 'Error updating payment settings', 'warning')
        })
        .finally(() => {
          setIsSaving(false)
        })
    }
  }

  return (
    <Dialog open={true} onClose={props.closeDialog}>
      <DialogTitle>
        {translate('Adjust Payment Settings')} - #{props.paymentRequest.invoice_number}
        <CloseDialogButton onClose={props.closeDialog} />
      </DialogTitle>
      <DialogContent>
        <div className={classes.mainContent}>
          <p>
            {translate(
              'Use the form below to edit the payment collection settings for this invoice. Changing these settings will not result in any notifications being sent to the customer.'
            )}
          </p>

          <SelectArrayInput
            label="Accepted Payment Methods"
            source={`accepted_payment_methods`}
            choices={paymentMethodChoices}
            fullWidth={true}
          />
          {ineligiblePaymentMethods?.length ? (
            <Alert severity="warning">
              <div>
                {translate(
                  'The following payment methods are not available for the requested amount and will not be shown to the customer:'
                )}
                <ul>
                  {ineligiblePaymentMethods.map((method) => (
                    <li>{getTranslatedPaymentMethodLabel(translate, method.payment_method_type)}</li>
                  ))}
                </ul>
              </div>
            </Alert>
          ) : null}
          {surchargingIsAllowed && (
            <div className={classes.surchargingContainer}>
              <BooleanInput label="Enable Surcharging?" source={`surcharging_enabled`} fullWidth={true} />
              <div className={classes.surchargeDisclaimerWrapper}>
                <SurchargingTooltip />
              </div>
            </div>
          )}
          <SurchargingNotSupportedWarning
            surchargingEnabled={formState.values.surcharging_enabled}
            acceptedPaymentMethods={formState.values.accepted_payment_methods}
            orgCountry={orgCountry}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={onSave} color="primary" variant="contained" disabled={isSaving}>
            <>{isSaving ? <CircularProgress /> : translate('Save')}</>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

const EditPaymentOptionsDialog: React.FC<WrapperPropTypes> = (props) => {
  const user = useSelector(authSelectors.getCurrentUser)
  const { isLoading, paymentRequestData, errorMsg } = useGetPaymentRequestData(
    { paymentRequestId: `${props.paymentRequestId}`, projectId: `${props.projectId}` },
    user
  )

  if (!paymentRequestData) return null
  const paymentRequest = paymentRequestData.payment_request

  return (
    <Form
      onSubmit={() => {}}
      initialValues={{
        accepted_payment_methods: paymentRequest?.payment_methods || [],
        surcharging_enabled: Boolean(paymentRequest?.surcharging_enabled),
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <EditPaymentOptionsDialogWrapper {...props} paymentRequest={paymentRequest} />
      )}
    />
  )
}
export default EditPaymentOptionsDialog
