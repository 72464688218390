import { GENERATE_DOCUMENT, GENERATE_DOCUMENT_FAILURE, GENERATE_DOCUMENT_SUCCESS } from 'ducks/generateDocument'
import { SEND_INVITATIONS, SEND_INVITATIONS_FAILURE, SEND_INVITATIONS_SUCCESS } from 'ducks/invitation'
import { SELECT_TARIFF, SELECT_TARIFF_FAILURE, SELECT_TARIFF_SUCCESS } from 'ducks/selectTariff'
import { SET_STAGE_FAILURE, SET_STAGE_SUCCESS } from 'ducks/stage'
import { STAR_ADD_FAILURE, STAR_ADD_SUCCESS, STAR_REMOVE_FAILURE, STAR_REMOVE_SUCCESS } from 'ducks/stars'
import { REFRESH_VIEW, showNotification } from 'react-admin'
import { all, call, put, takeEvery } from 'redux-saga/effects'

function translate(str) {
  return window.translate ? window.translate(str) : str
}

export default function* customersSaga() {
  yield all([
    takeEvery('SET_STUDIO_MODE', function* ({ payload }) {
      if (window.editor) {
        const designMode = payload || 'hidden'
        yield call(window.editor.setDesignMode.bind(window.editor), designMode)
      }
    }),
    takeEvery(SEND_INVITATIONS, function* () {
      yield put(showNotification('resources.customers.notification.send_invitation'))
    }),
    takeEvery(SEND_INVITATIONS_SUCCESS, function* () {
      yield put(showNotification('resources.customers.notification.send_invitation_success'))
    }),
    takeEvery(SEND_INVITATIONS_FAILURE, function* ({ error }) {
      yield put(
        showNotification(
          error, //'resources.reviews.notification.send_invitation_error',
          'warning'
        )
      )
      console.error(error)
    }),
    takeEvery(GENERATE_DOCUMENT, function* () {
      yield put(
        showNotification(
          translate('Document generation started. We will notify you when the document generation is complete.')
        )
      )
    }),
    takeEvery(GENERATE_DOCUMENT_SUCCESS, function* (context) {
      const url = context.payload?.data?.url
      if (url) {
        yield put(
          showNotification(translate('Document generation successful'), 'success', {
            buttons: [
              {
                label: translate('Download File'),
                action: () => window.open(url, '_blank'),
                icon: 'CloudDownload',
              },
            ],
            autoHideDuration: 7000,
          })
        )
      }
    }),
    takeEvery(GENERATE_DOCUMENT_FAILURE, function* ({ error }) {
      yield put(
        showNotification(
          error, //'resources.reviews.notification.send_invitation_error',
          'warning'
        )
      )
      console.error(error)
    }),

    takeEvery(SELECT_TARIFF, function* () {
      yield put(showNotification(translate('Saving Tariff...')))
    }),
    takeEvery(SELECT_TARIFF_SUCCESS, function* () {
      yield put(showNotification(translate('Saving Tariff Complete. Reloading...')))
      yield put({
        type: REFRESH_VIEW,
      })
    }),
    takeEvery(SELECT_TARIFF_FAILURE, function* ({ error }) {
      yield put(
        showNotification(
          error, //'resources.reviews.notification.send_invitation_error',
          'warning'
        )
      )
      console.error(error)
    }),

    takeEvery(STAR_ADD_SUCCESS, function* () {
      yield put(showNotification('Star added'))
    }),
    takeEvery(STAR_ADD_FAILURE, function* ({ error }) {
      yield put(showNotification('Star not added', 'warning'))
      console.error(error)
    }),
    takeEvery(STAR_REMOVE_SUCCESS, function* () {
      yield put(showNotification('Star removed'))
    }),
    takeEvery(STAR_REMOVE_FAILURE, function* ({ error }) {
      yield put(showNotification('Star not removed', 'warning'))
      console.error(error)
    }),
    takeEvery(SET_STAGE_SUCCESS, function* () {
      yield put(showNotification('Stage updated'))
      yield put({
        type: REFRESH_VIEW,
      })
    }),
    takeEvery(SET_STAGE_FAILURE, function* ({ error }) {
      yield put(showNotification('Stage not updated', 'warning'))
      console.error(error)
    }),
  ])
}
