import { Slider } from '@material-ui/core'
import { Alert } from 'opensolar-ui'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import Panel, { StudioPanelContext } from '../Designer/Panel'
import { refreshPanelGeneric } from '../Studio/Utils'

import { CustomNumberField } from 'elements/field/CustomNumberField'
import { DistanceNumberField } from 'elements/field/DistanceNumberField'
import { getMeasurementsFromState, metersToFeet, titleCase, trimDecimalPlaces } from '../util/misc'

const MEASUREMENT_INDICES = {
  0: {
    key: 'width',
    label: 'Width',
  },
  1: {
    key: 'height',
    label: 'Length',
  },
}

class PanelGround extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      opacity: 1.0,
      ground: null,
      allowEdit: true,
    }

    var injectState = props.state ? props.state : null
    if (props.obstruction) {
      injectState = this.stateFromObject(props.ground)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
  }

  componentDidMount() {
    this.props.useStudioSignalsLazy(this.refreshPanel, ['objectChanged', 'objectSelected', 'sceneGraphChanged'], this)
  }

  convertValueUnits(value) {
    return this.props.measurements === 'imperial' ? metersToFeet(value) : value
  }

  stateFromObject(ground) {
    if (!ground || ground.type !== 'OsGround') {
      return { visible: false }
    }

    var rotationZPositive = (-ground.rotation.z * window.THREE.Math.RAD2DEG + 360.0) % 360
    var rotationZRoundedPositive = Math.round(rotationZPositive * 100) / 100

    return {
      visible: true,
      ground: ground,
      width: trimDecimalPlaces(this.convertValueUnits(ground.size[0] * ground.scale.x), 3),
      height: trimDecimalPlaces(this.convertValueUnits(ground.size[1] * ground.scale.y), 3),
      rotationZ: rotationZRoundedPositive,
      opacity: ground.material.opacity,
    }
  }

  applyWidthOrHeight = (value, index) => {
    const scaleFactor = value / this.state.ground.size[index]
    window.editor.execute(
      new window.SetScaleCommand(this.state.ground, new window.THREE.Vector3(scaleFactor, scaleFactor, 1))
    )
  }

  applyRotationZ = (rawValue) => {
    if (rawValue === '') {
      return
    }
    const ground = this.state.ground
    const value = parseFloat(rawValue) * window.THREE.Math.DEG2RAD
    const rotation = ground.rotation.clone()
    rotation.z = -1 * value
    window.editor.execute(new window.SetRotationCommand(ground, rotation))
  }

  applyOpacity = (value) => {
    this.state.ground.setOpacity(value)
  }

  refreshPanel() {
    refreshPanelGeneric.call(this, 'OsGround')
  }

  render() {
    if (this.state.visible !== true) {
      return null
    } else {
      const { rotationZ, opacity } = this.state
      var ground = this.state.ground
      var { translate } = this.props

      return (
        <Panel
          showTools={this.state.allowEdit}
          selectedObject={this.state.ground}
          title="Ground"
          summary={null}
          content={null}
          feature={
            <StudioPanelContext.Provider value={{ context: this, object: ground }}>
              <div>
                <div style={{ marginBottom: ground.groundType === 'upload' ? 20 : 0 }}>
                  {translate('Ground Type')}: {titleCase(ground.groundType)}
                  {ground.groundType === 'upload' && (
                    <Alert severity="info" style={{ marginBottom: 10, marginTop: 10 }}>
                      {translate(
                        'Position, scale, and orient your custom imagery using the satellite imagery as reference. To close this panel and finish adjustments, click the Close button below or right-click custom imagery then click "Deselect Ground".'
                      )}
                    </Alert>
                  )}
                </div>
                {ground.groundType === 'upload' &&
                  Object.keys(MEASUREMENT_INDICES).map((index) => {
                    const { key, label } = MEASUREMENT_INDICES[index]
                    const value = this.state[key]
                    return (
                      <DistanceNumberField
                        disabled={!this.state.allowEdit}
                        name={`ground-image-${key}`}
                        label={label}
                        value={value}
                        minValue={0.001}
                        maxDecimalPlaces={3}
                        minErrorMsg={translate('Supply a value greater than 0.')}
                        onChange={(newValue) => {
                          this.applyWidthOrHeight(newValue, index)
                        }}
                        measurementStd={this.props.measurements}
                        convertTo="metric"
                      />
                    )
                  })}

                {ground.groundType === 'upload' && (
                  <CustomNumberField
                    disabled={!this.state.allowEdit}
                    name="ground-image-rotation"
                    label="Bearing"
                    value={rotationZ}
                    minValue={0}
                    maxValue={360}
                    maxDecimalPlaces={2}
                    resettable={true}
                    resetValue={0}
                    endAdornment="°"
                    onChange={(newValue) => {
                      this.applyRotationZ(newValue)
                    }}
                  />
                )}

                {ground.groundType === 'upload' && (
                  <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: 85 }}>{translate('Opacity')}</div>
                    <Slider
                      disabled={!this.state.allowEdit}
                      style={{
                        width: 150,
                        marginTop: -6,
                        marginBottom: 0,
                      }}
                      sliderStyle={{ marginTop: 4, marginBottom: 0 }}
                      value={opacity}
                      defaultValue={1}
                      step={0.01}
                      min={0}
                      max={1}
                      onChange={(_event, newValue) => {
                        this.setState({ opacity: newValue })
                        this.applyOpacity(newValue)
                      }}
                    />
                    <div style={{ clear: 'both' }} />
                  </div>
                )}
              </div>
            </StudioPanelContext.Provider>
          }
        />
      )
    }
  }
}

PanelGround.propTypes = {
  state: PropTypes.object,
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect(
    (state) => ({
      measurements: getMeasurementsFromState(state),
    }),
    {}
  )
)(PanelGround)
