import { makeStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { setStudioSavePrompt } from 'ducks/studioPrompt'
import { useViewShow } from 'ducks/viewMode'
import NavigationLink from 'elements/navigation/NavigationLink'
import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { OpenSolarThemeType } from 'Themes'
import { useFeatureFlag } from 'util/split'

export const useNavLinkStyles = makeStyles<
  OpenSolarThemeType,
  { badgeSeverity?: undefined | 'info' | 'warning' | 'error' | 'success' | 'info-secondary' }
>((theme: OpenSolarThemeType) => ({
  navLinkActive: {
    fontWeight: 500,
    padding: '16px 25px',
    opacity: 1,
  },

  navLinkActivexs: {
    fontWeight: 500,
    padding: '18px 10px',
    opacity: 1,
  },
  navLink: {
    padding: '16px 25px',
  },
  navLinkSmall: {
    padding: '18px 18px',
    fontSize: 12,
  },
  navLinkxs: {
    padding: '18px 10px',
    fontSize: 12,
  },
  text: {
    color: 'inherit !important',
    textDecoration: 'none',
    display: 'inline-block',
    letterSpacing: 0.5,
    opacity: 0.87,
    '&:hover': {
      opacity: 0.98,
    },
    '&:visited': {
      color: 'inherit',
    },
  },
  labelRow: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    height: '8px',
    width: '8px',
    marginLeft: '5px',
    borderRadius: '8px',
    backgroundColor: ({ badgeSeverity }) => {
      switch (badgeSeverity) {
        case 'info':
          return 'rgba(35, 87, 137, 0.95)'
        case 'warning':
          return 'rgba(255,160,0,0.95)'
        case 'error':
          return 'rgba(193, 42, 46, 0.95)'
        case 'success':
          return 'rgba(66, 195, 17, 0.95)'
        case 'info-secondary':
          return 'rgba(225, 218, 3, 0.95)'
        default:
          return 'transparent'
      }
    },
  },
}))

export interface StyledNavLinkProps {
  activeStyle?: string
  badgeSeverity?: 'info' | 'warning' | 'error' | 'success'
  children?: React.ReactNode
  className?: string
  label: string
  layout?: number
  to: string
  uiKey?: string
}

const StyledNavLink: React.FC<StyledNavLinkProps> = ({ children, to, uiKey, layout = 3, label, ...props }) => {
  const classes = useNavLinkStyles({ badgeSeverity: props.badgeSeverity })
  const history = useHistory()
  const dispatch = useDispatch()
  const show = useViewShow(uiKey)
  const translate = useTranslateWithVariable()
  const projectSection = useSelector((state: any) => state.project?.section)

  const isNearmapSkin = useSelector((state: any) => state.theme === 'nearmap')
  const isNav3Enabled = useFeatureFlag('nav_3', 'on')
  const NavLinkComponent = isNav3Enabled ? NavigationLink : NavLink

  const onClick = (e) => {
    e.preventDefault()
    if (!window.disableAutoSave && window.projectForm?.mutators.getFormDirtyFields().length > 0) {
      dispatch(
        setStudioSavePrompt({
          show_prompt: true,
          redirect: to,
          prompt_heading: translate('Leave Project to %{label}?', { label: translate(label) }),
        })
      )
      if (to === '/shop') logAmplitudeEvent('hardware_navigation_button', {})
    } else {
      history.push(to)
      if (to === '/shop') logAmplitudeEvent('hardware_navigation_button', {})
    }
  }
  const activeStyle = layout >= 3 ? classes.navLinkActive : classes.navLinkActivexs
  const style = layout >= 3 ? classes.navLink : layout === 2 ? classes.navLinkxs : classes.navLinkSmall
  const classNames = [classes.text, style]

  const navLinkProps = !isNav3Enabled
    ? {
        activeClassName: props.activeStyle || activeStyle,
        className: props.className || classNames.join(' '),
      }
    : {
        accentColor: isNearmapSkin ? '#3448FF' : undefined,
      }

  if (!show) return <></>

  return (
    <NavLinkComponent
      isActive={(match) => {
        if (projectSection === 'explore') {
          return to === '/home'
        }
        return match
      }}
      to={to}
      onClick={onClick}
      {...navLinkProps}
    >
      {children || (
        <div className={classes.labelRow}>
          {translate(label)}
          {props?.badgeSeverity && <div className={classes.badge}></div>}
        </div>
      )}
    </NavLinkComponent>
  )
}

export default StyledNavLink
