import PropTypes from 'prop-types'
import { Component } from 'react'
import { withTranslate } from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withStudioSignals from 'Studio/signals/withStudioSignals'
import { closablePanels } from 'util/closablePanels'
import Panel from '../Designer/Panel'

import aframe_dormer_step_1 from './images/aframe_dormer_step_1.svg'
import aframe_dormer_step_2 from './images/aframe_dormer_step_2.svg'
import aframe_dormer_step_3 from './images/aframe_dormer_step_3.svg'
import aframe_step_1 from './images/aframe_step_1.svg'
import aframe_step_2 from './images/aframe_step_2.svg'
import aframe_step_3 from './images/aframe_step_3.svg'
import hip_dormer_step_1 from './images/hip_dormer_step_1.svg'
import hip_dormer_step_2 from './images/hip_dormer_step_2.svg'
import hip_dormer_step_3 from './images/hip_dormer_step_3.svg'
import hip_step_1 from './images/hip_step_1.svg'
import hip_step_2 from './images/hip_step_2.svg'
import hip_step_3 from './images/hip_step_3.svg'
import shed_dormer_step_1 from './images/shed_dormer_step_1.svg'
import shed_dormer_step_2 from './images/shed_dormer_step_2.svg'
import shed_dormer_step_3 from './images/shed_dormer_step_3.svg'
import shed_step_1 from './images/shed_step_1.svg'
import shed_step_2 from './images/shed_step_2.svg'
import shed_step_3 from './images/shed_step_3.svg'

var CONTENT = {
  aframe_title: 'Create Simple A-Frame Roof',
  aframe_body: 'Quickly and easily make an a-frame roof by following these instructions.',
  aframe_title_step_1: 'Step 1',
  aframe_body_step_1: 'Click on one of the outside corners of the roof.',
  aframe_title_step_2: 'Step 2',
  aframe_body_step_2: 'Click on another outside corner to make a line parallel to the ridge line.',
  aframe_title_step_3: 'Step 3',
  aframe_body_step_3: 'Click on the next outside corner of the roof.',
  aframe_title_edit: 'Simple A-Frame Roof',
  aframe_body_edit:
    'Set the parameters of the roof by either entering in numbers or by clicking and dragging points and lines on the roof.',
  aframe_slope_edit: 'Slope',
  aframe_number_of_stories_edit: 'Number of stories',
  aframe_slope_override_edit: 'Override each facet',
  aframe_slope_edit_1: 'Slope 1',
  aframe_slope_edit_2: 'Slope 2',
  aframe_slope_edit_3: 'Slope 3',
  aframe_slope_edit_4: 'Slope 4',
  hip_title: 'Create Simple Hip Roof',
  hip_body: 'Quickly and easily make a hip roof by following these instructions.',
  hip_title_step_1: 'Step 1',
  hip_body_step_1: 'Click on one of the outside corners of the roof.',
  hip_title_step_2: 'Step 2',
  hip_body_step_2: 'Click on another outside corner to make a line parallel to the ridge line.',
  hip_title_step_3: 'Step 3',
  hip_body_step_3: 'Click on the next outside corner of the roof.',
  hip_title_edit: 'Simple Hip Roof',
  hip_body_edit:
    'Set the parameters of the roof by either entering in numbers or by clicking and dragging points and lines on the roof.',
  hip_slope_edit: 'Slope',
  hip_number_of_stories_edit: 'Number of stories',
  hip_slope_override_edit: 'Override each facet',
  hip_slope_edit_1: 'Slope 1',
  hip_slope_edit_2: 'Slope 2',
  hip_slope_edit_3: 'Slope 3',
  hip_slope_edit_4: 'Slope 4',
  shed_title: 'Create Simple Shed Roof',
  shed_body: 'Quickly and easily make a shed roof by following these instructions.',
  shed_title_step_1: 'Step 1',
  shed_body_step_1: 'Click on one of the ridge corners.',
  shed_title_step_2: 'Step 2',
  shed_body_step_2: 'Click on the other ridge corner.',
  shed_title_step_3: 'Step 3',
  shed_body_step_3: 'Click on another corner.',
  shed_title_edit: 'Simple Shed Roof',
  shed_body_edit: 'Set the parameters of the roof by entering in numbers.',
  shed_slope_edit: 'Slope',
  shed_slope_description_edit:
    'To set the way that the building is sloping, right click on the lowest edge and then set it as a gutter.',
  shed_number_of_stories_edit: 'Number of stories',
  aframe_dormer_title: 'Create A-Frame Dormer',
  aframe_dormer_body: 'Quickly and easily make an a-frame dormer by following these instructions.',
  aframe_dormer_title_step_1: 'Step 1',
  aframe_dormer_body_step_1: 'Click on the point where the ridge of the dormer meets the roof.',
  aframe_dormer_title_step_2: 'Step 2',
  aframe_dormer_body_step_2: 'Click on the point where the ridge line meets the edge of the dormer.',
  aframe_dormer_title_step_3: 'Step 3',
  aframe_dormer_body_step_3: 'Click on the point where one of the rakes meets the gutter of the dormer.',
  aframe_dormer_title_edit: 'A-Frame Dormer',
  aframe_dormer_body_edit: 'Set the slope of the dormer by entering a single value, or overriding for each facet.',
  aframe_dormer_slope_edit: 'Slope',
  aframe_dormer_slope_override_edit: 'Override each facet',
  aframe_dormer_slope_edit_1: 'Slope 1',
  aframe_dormer_slope_edit_2: 'Slope 2',
  hip_dormer_title: 'Create Hip Dormer',
  hip_dormer_body: 'Quickly and easily make a hip dormer by following these instructions.',
  hip_dormer_title_step_1: 'Step 1',
  hip_dormer_body_step_1: 'Click on the point where the ridge of the dormer meets the roof.',
  hip_dormer_title_step_2: 'Step 2',
  hip_dormer_body_step_2: 'Click on the point where the ridge line meets the two hips.',
  hip_dormer_title_step_3: 'Step 3',
  hip_dormer_body_step_3: 'Click on the point where one of the hips meets the gutter of the dormer.',
  hip_dormer_title_edit: 'Hip Dormer',
  hip_dormer_body_edit: 'Set the slope of the dormer by entering a single value, or overriding for each facet.',
  hip_dormer_slope_edit: 'Slope',
  hip_dormer_slope_override_edit: 'Override each facet',
  hip_dormer_slope_edit_1: 'Slope 1',
  hip_dormer_slope_edit_2: 'Slope 2',
  hip_dormer_slope_edit_3: 'Slope 3',
  shed_dormer_title: 'Create Shed Dormer',
  shed_dormer_body: 'Quickly and easily make an shed dormer by following these instructions.',
  shed_dormer_title_step_1: 'Step 1',
  shed_dormer_body_step_1: 'Click on a point where one of the edges of the dormer meets the roof.',
  shed_dormer_title_step_2: 'Step 2',
  shed_dormer_body_step_2: 'Click on the point where the edge line meets the gutter of the dormer.',
  shed_dormer_title_step_3: 'Step 3',
  shed_dormer_body_step_3: 'Click on the other corner of the dormer gutter.',
  shed_dormer_title_edit: 'Shed Dormer',
  shed_dormer_body_edit: 'Set the slope of the dormer by entering the value.',
  shed_dormer_slope_edit: 'Slope',
}

var IMAGES = {
  aframe_step_1: aframe_step_1,
  aframe_step_2: aframe_step_2,
  aframe_step_3: aframe_step_3,
  hip_step_1: hip_step_1,
  hip_step_2: hip_step_2,
  hip_step_3: hip_step_3,
  shed_step_1: shed_step_1,
  shed_step_2: shed_step_2,
  shed_step_3: shed_step_3,
  aframe_dormer_step_1: aframe_dormer_step_1,
  aframe_dormer_step_2: aframe_dormer_step_2,
  aframe_dormer_step_3: aframe_dormer_step_3,
  hip_dormer_step_1: hip_dormer_step_1,
  hip_dormer_step_2: hip_dormer_step_2,
  hip_dormer_step_3: hip_dormer_step_3,
  shed_dormer_step_1: shed_dormer_step_1,
  shed_dormer_step_2: shed_dormer_step_2,
  shed_dormer_step_3: shed_dormer_step_3,
}

const name = 'PanelSequence'

class PanelSequence extends Component {
  constructor(props) {
    super(props)
    var state = {
      visible: false,
      object: null,
    }

    var injectState = props.state ? props.state : null
    if (props.object) {
      injectState = this.stateFromObject(props.object)
    }

    //Override with any supplied state - for use in storybook
    if (injectState) {
      for (var key in injectState) {
        state[key] = injectState[key]
      }
    }

    this.state = state
    this.hash = null
  }

  setState(value) {
    console.log('PanelSequence: ', value)
    super.setState(value)
  }

  componentDidMount() {
    closablePanels.add(name, this.close, this)
    this.props.useStudioSignalsLazy(this.refreshPanel, ['sequenceUpdated'], this)

    this.props.useStudioSignals(
      ({ label, objectType, cancelFunction }) => {
        if (label && objectType === 'OsStructure') {
          this.setState({
            ...this.state,
            cancelFunction: cancelFunction,
          })
        }
      },
      ['placementModeChanged'],
      this
    )
  }

  componentWillUnmount() {
    closablePanels.remove(name)
  }

  getHash(newState) {
    if (newState) {
      return JSON.stringify(newState)
    }
    var stateForHash = {}
    Object.keys(this.state).map((key) =>
      key === 'object'
        ? (stateForHash.object_uuid = this.state.object ? this.state.object.uuid : null)
        : (stateForHash[key] = this.state[key])
    )
    return JSON.stringify(stateForHash)
  }

  stateFromObject(object) {
    if (!object || !object.mode) {
      return { visible: false }
    }
    var _state = {
      visible: true,
      ...object,
    }

    return _state
  }

  close = (preventCancel) => {
    console.log('close')
    if (preventCancel !== true && this.state.cancelFunction) {
      if (typeof this.state.cancelFunction === 'function') {
        try {
          this.state.cancelFunction()
        } catch (e) {
          console.log('Error calling cancelFunction', e)
        }
      } else {
        console.log('this.state.cancelFunction not a function:', this.state.cancelFunction)
      }
    }

    this.setState({
      cancelFunction: null,
    })
  }

  refreshPanel() {
    var newState = this.stateFromObject(window.editor.controllers.Sequence.getState())
    var newHash = this.getHash(newState)
    if (this.hash !== newHash) {
      this.hash = newHash
      this.setState(newState)
    }
  }

  render() {
    if (this.state.visible !== true) {
      return null
    } else {
      const { mode, points } = this.state
      const step = points.length + 1

      const { translate } = this.props
      return (
        <Panel
          onClose={this.close}
          showTools={false}
          selectedObject={null}
          title={`${translate(CONTENT[mode + '_title'])} - ${translate(CONTENT[mode + '_title_step_' + step])}`}
          summary={null}
          content={null}
          feature={
            <div>
              {step === 1 && <div>{translate(CONTENT[mode + '_body'])}</div>}
              <p>{translate(CONTENT[mode + '_body_step_' + step])}</p>
              <img src={IMAGES[mode + '_step_' + step]} style={{ display: 'block', width: '80%', margin: 'auto' }} />
            </div>
          }
        />
      )
    }
  }
}

PanelSequence.propTypes = {
  state: PropTypes.object,
}

export default compose(
  withTranslate,
  withStudioSignals,
  connect((state) => ({}), {})
)(PanelSequence)
