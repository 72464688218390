import { makeStyles } from '@material-ui/core'
import { orgsActions, orgSelectors } from 'ducks/orgs'
import Lottie from 'lottie-react'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useTranslateParse from 'util/useTranslateParse'
import CheckAnimation from './CheckAnimation.json'
import DocumentRequestSection from './DocumentRequestSection'

type PropTypes = {
  closeDialog: () => void
}

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    lineHeight: 1.5,
    margin: 'auto',
  },

  header: {
    fontWeight: 500,
    fontSize: '1.5rem',
  },

  closeBtn: {
    borderColor: theme.greyLight1,
    fontSize: 13,
    margin: '1rem 0',
  },

  greenText: {
    color: theme.alert_success,
    display: 'flex',
    gap: 10,
    fontWeight: 500,
  },

  icon: {
    height: 19,
  },

  topContainer: {
    padding: '1rem 7rem',
  },

  link: {
    color: '#4272DD !important',
    fontWeight: 500,
    cursor: 'pointer',
  },

  bottomContainer: {
    background: theme.greyLight1,
    padding: '1rem 7rem',
  },

  teamContainer: {
    textAlign: 'center',
    fontWeight: 500,
    paddingTop: 24,
    marginBottom: '2rem',
  },

  team: {
    height: 70,
  },

  animationWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },

  checkAnimation: {
    width: '10% !important',
  },
}))

const useDialogStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: 0,
      background: 'red',
    },
  },
}))

const UnderReview: React.FC<PropTypes> = (props) => {
  const orgCountry: string = useSelector(orgSelectors.getOrgIso2)?.toString() || ''

  const classes = useStyles()
  const translate = useTranslate()
  const translateParse = useTranslateParse()
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(orgsActions.reloadOrg())
    props.closeDialog()
  }

  const currentDate = new Date()
  let month = currentDate.getMonth() + 1
  let year = currentDate.getFullYear()

  const SCHEDULING_LINK_MAP = {
    US: `https://calendly.com/opensolar-cashflow/cashflow-training-onboarding-clone?month=${year}-${month}`,
    default: `https://calendly.com/opensolar-cashflow/cashflow-training-onboarding-clone?month=${year}-${month}`,
  }

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <div className={classes.animationWrapper}>
          <Lottie animationData={CheckAnimation} loop={false} className={classes.checkAnimation} />
        </div>
        <h1 className={classes.header}>{translate('Thank you for submitting your CashFlow registration!')}</h1>

        {/* <div className={classes.greenText}>
          <img src={`${window.PUBLIC_URL}/images/cashflow/pending_actions.svg`} className={classes.icon} />
          <span>{translate('Your application is being reviewed')}</span>
        </div> */}

        <DocumentRequestSection />
      </div>

      <div className={classes.bottomContainer}>
        <p>{translate('In the meantime, you can:')}</p>
        <ul>
          <li>
            {translateParse('Learn more in the <a>CashFlow Help Center<a>.', {
              a: (label: string) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://support.opensolar.com/hc/en-us/categories/9535153058447"
                  className={classes.link}
                >
                  {label}
                </a>
              ),
            })}
          </li>
          <li>
            {translateParse(
              'Schedule an <a>Onboarding Training<a> (optional) to get personalized help customizing your payment process with a CashFlow team member.',
              {
                a: (label: string) => (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={SCHEDULING_LINK_MAP[orgCountry]}
                    className={classes.link}
                  >
                    {label}
                  </a>
                ),
              }
            )}
          </li>
        </ul>

        <div className={classes.teamContainer}>
          <img
            src={`${window.PUBLIC_URL}/images/cashflow/Cashflow Support Team.svg`}
            className={classes.team}
            alt="Cashflow Support Team"
          />
          <p>{translate('Our dedicated team is here to help you in creating a better payment experience.')}</p>
        </div>
      </div>
    </div>
  )
}

export default UnderReview
