import { Stepper, styled } from 'opensolar-ui'

export const StyledStepper = styled(Stepper)(({ theme }) => ({
  padding: 0,
}))

export const StepContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  padding: '32px 18px',
}))

export const StepInputsContainer = styled('div')(({ theme }) => ({}))

export const LabelAndInputContainer = styled('div')(({ theme }) => ({
  border: '3px solid blue',
}))
