import { PERFORMANCE_CALCULATORS_MCS_AND_SAM } from 'constants/calculators'
import type { StudioSystemType } from 'types/studio/items'
import type { SystemMCSInfo } from '../types'
import { getDynamicCalculatorData, getOpenSolarCalculatorData } from '../utils'
import DynamicCalculatorPanel from './DynamicCalculatorPanel'

const AutoMCSPanel = ({
  system,
  isWithinMCSGuidelines,
}: {
  system: StudioSystemType
  isWithinMCSGuidelines: boolean
}): JSX.Element | null => {
  if (system.calculator !== PERFORMANCE_CALCULATORS_MCS_AND_SAM) return null

  const { consumption, output, mcs_self_consumption_calculator_override } = system
  let { battery_control_scheme } = system
  const kwStc = system.kwStc() ?? 0
  const annualUsage = consumption?.usage_annual_proposed ?? 0
  const annualOutput = output?.annual ?? 0
  const hasBattery = system.batteries().length > 0

  if (!hasBattery) battery_control_scheme = 'solar_charged'

  let mcsInformation: SystemMCSInfo | null = null
  if (mcs_self_consumption_calculator_override === 1 || mcs_self_consumption_calculator_override === undefined)
    mcsInformation = getDynamicCalculatorData(kwStc, annualUsage, annualOutput, battery_control_scheme)
  if (mcs_self_consumption_calculator_override === 2)
    mcsInformation = getOpenSolarCalculatorData(isWithinMCSGuidelines, battery_control_scheme)

  if (!mcsInformation) return null

  const mcsAlertData = {
    ...mcsInformation,
    mcs_self_consumption_calculator_override,
  }

  return <DynamicCalculatorPanel {...mcsAlertData} />
}

export default AutoMCSPanel
