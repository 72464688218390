import { authSelectors } from 'ducks/auth'
import { rolesSelectors } from 'ducks/auth_roles'
import { ReduxActionType } from 'types/global'
import { AttributeMapType, initSplit } from 'util/split'

export const SPLIT_INIT_LOADING = 'SPLIT_INIT_LOADING'
export const SPLIT_INIT_SUCCESS = 'SPLIT_INIT_SUCCESS'
export const SPLIT_INIT_ERROR = 'SPLIT_INIT_ERROR'
// export const SAVE_ENABLED_FEATURES = 'SAVE_ENABLED_FEATURES'
// export const SAVE_DISABLED_FEATURES = 'SAVE_DISABLED_FEATURES'

export type SPLIT_NAMES =
  | 'os_wallet'
  | 'save_replay'
  | 'pmt_migration'
  | 'dividend_integration'
  | 'send_us_direct_app_link'
  | 'studio_design_on_obliques'
  | 'studio_facet_area'
  | 'studio_export_dxf'
  | 'studio_next'
  | 'concurrent_editing_protection'
  | 'sungage_rate_change'
  | 'sunlight_rate_change'
  | 'mosaic_rate_change'
  | 'dividend_rate_change'
  | 'loanpal_rate_change'
  | 'dividend_change_order'
  | 'unsaved_data_recovery'
  | 'connected_orgs'
  | 'custom_forms'
  | 'finco_proposal_promo'
  | 'solkunder'
  | 'lightreach_integration'
  | 'electrical_slots'
  | 'segen_auth_redirect'
  | 'ads'
  | 'hardware_ordering'
  | 'inventory_management'
  | 'payments_tab'
  | 'hardware_3_0_projects_tab'
  | 'hardware_3_0_projects_tab_post_order'
  | 'hardware_3_0_projects_tab_inventory'
  | 'finance_ghost_card'
  | 'google_solar_api'
  | 'google_sunroof'
  | 'mounting_Variosole'
  | 'mounting_SolarRoofPro'
  | 'mounting_Esdec'
  | 'mounting_MSTrapezeProLine'
  | 'mounting_MSPitchedProLine'
  | 'studio_tabs_mounting'
  | 'hardware_filter_v2'
  | 'enable_inverter_wizard'
  | 'new_design_tabs'
  | 'cash_flow'
  | 'mounting_Fastensol'
  | 'city_plumbing'
  | 'ux_version_3'
  | 'single_line_diagram'
  | 'hardware_new_sidebar'
  | 'hardware_dialog_v2'
  | 'pvf_integration'
  | 'non_spatial_facets'
  | 'non_spatial_facets_auto_create'
  | 'project_list_v3'
  | 'manage_shop'
  | 'hardware_3.0_projects_page'
  | 'vtac'
  | 'enable_battery_wizard'
  | 'enable_eoe_energy_tab'
  | 'outlet_custom_pricing'
  | 'enable_solar_juice_checkout'
  | 'hardware_manage_downloadBOM'
  | 'hdm'
  | 'nav_3'
  | 'enable_city_plumbing_checkout'
  | 'change_order'
  | 'dsm_terrain_wall_blurring'
  | 'enable_multi_distributor_shop'
  | 'enable_cogs_dialog'
  | 'hardware_3_0_projects_tab_checkout'
  | 'design_multi_distributor'
  | 'enable_rhs_bom_v3'
  | 'project_thumbnails'
  | 'mounting_GseInRoof'
  | 'apple_google_pay'

type ReduxSplitType = {
  is_initialized: boolean
  client: SplitIO.IClient | undefined
  // enabled_features: string[]
  // disabled_features: string[]
}

const initialState: ReduxSplitType = {
  is_initialized: false,
  client: undefined,
  // enabled_features: [],
  // disabled_features: [],
}

export default (state = initialState, action: ReduxActionType) => {
  switch (action.type) {
    case SPLIT_INIT_LOADING:
      return {
        ...state,
        is_initialized: false,
        client: undefined,
      }
    case SPLIT_INIT_SUCCESS:
      return {
        ...state,
        is_initialized: true,
        client: action.payload.client,
      }
    // case SAVE_ENABLED_FEATURES:
    //   let newDisabledFeatures = state.disabled_features?.filter((feat) => !action.payload.split_names?.includes(feat))
    //   return {
    //     ...state,
    //     enabled_features: [...state.enabled_features, ...action.payload.split_names],
    //     disabled_features: newDisabledFeatures,
    //   }
    // case SAVE_DISABLED_FEATURES:
    //   let newEnabledFeatures = state.enabled_features?.filter((feat) => !action.payload.split_names?.includes(feat))
    //   return {
    //     ...state,
    //     enabled_features: newEnabledFeatures,
    //     disabled_features: [...state.disabled_features, ...action.payload.split_names],
    //   }
    default:
      return { ...state }
  }
}

export const initializeSplit = (orgId: number | undefined, isStaff: boolean, isAdmin: boolean) => {
  const client = initSplit(orgId, isStaff, isAdmin)

  client.on(client.Event.SDK_READY, () => {
    window.reduxStore.dispatch({
      type: SPLIT_INIT_SUCCESS,
      payload: {
        client: client,
      },
    })
  })
  return {
    type: SPLIT_INIT_LOADING,
  }
}

// should only be called from the selector since it requires split to be initialized first which is very important to avoid false inactives
// const getFeatureFlagFromClient = (
//   client: SplitIO.IClient,
//   split_name: SPLIT_NAMES,
//   target_value: string,
//   attributeMap: AttributeMapType,
//   isEnabledInState: boolean,
//   isDisabledInState: boolean
// ): boolean => {
//   // if we have a saved result use it before calling out to split
//   if (isEnabledInState) return true
//   else if (isDisabledInState) return false
//   // when internet dropped client.getTreatment was not a function, avoid crash in that case
//   else if (!client || !client.getTreatment) return false
//   const treatment: SplitIO.Treatment = client.getTreatment(split_name, attributeMap)
//   if (treatment === target_value) {
//     // if enabled, save into enabled array in state
//     window.reduxStore.dispatch({
//       type: SAVE_ENABLED_FEATURES,
//       payload: {
//         split_names: [split_name],
//       },
//     })
//   } else {
//     // otherwise save to disabled array
//     window.reduxStore.dispatch({
//       type: SAVE_DISABLED_FEATURES,
//       payload: {
//         split_names: [split_name],
//       },
//     })
//   }
//   return treatment === target_value
// }

export const splitSelectors = {
  // getSingleFeatureFlagEnabled: (
  //   state: any,
  //   split_name: SPLIT_NAMES,
  //   target_value: string,
  //   attributeMap: AttributeMapType
  // ) => {
  //   const client = splitSelectors.getClient(state)
  //   const isInit = splitSelectors.getIsInitialized(state)
  //   const isEnabledInState = state.split.enabled_features.includes(split_name)
  //   const isDisabledInState = state.split.disabled_features.includes(split_name)
  //   if (!client || !isInit) return false
  //   return getFeatureFlagFromClient(client, split_name, target_value, attributeMap, isEnabledInState, isDisabledInState)
  // },
  getClient: (state: any) => state?.split?.client,
  getIsInitialized: (state: any) => state?.split?.is_initialized,
  getSplitIsReady: (state: any) => {
    return !!splitSelectors.getClient(state) && splitSelectors.getIsInitialized(state)
  },
  getSplitAttributeMap: (state: any): AttributeMapType => {
    const roleId = rolesSelectors.getCurrentRoleId(state) || -1
    const userId = authSelectors.getCurrentUserId(state) || -1
    const orgId = authSelectors.getOrgId(state) || -1
    const orgCountry = authSelectors.getCurrentOrgCountry(state) || ''
    const isStaff = authSelectors.getIsStaff(state)
    const isAdmin = authSelectors.getIsAdmin(state)
    const isCustomer = !roleId

    return {
      roleId,
      userId,
      orgId,
      orgCountry,
      isStaff,
      isAdmin,
      isCustomer,
    }
  },

  // This is commented out as it isn't correctly reactive, and wasn't in use
  // If we need this functionality, we should re-implement in a more reactive manner (or use the hook)
  // getFeatureEnabledForClassComponent: (state: any, split_name: SPLIT_NAMES, target_value: string) => {
  //   // as the name suggests this is only to be used for class components, functional components should use the useFeatureFlag hook

  //   // first check saved enabled features, if found just return true
  //   if (state?.split?.enabled_features?.includes(split_name)) return true
  //   else if (state?.split?.disabled_features?.includes(split_name)) return false
  //   else {
  //     const result = splitSelectors.getSingleFeatureFlagEnabled(
  //       state,
  //       split_name,
  //       target_value,
  //       splitSelectors.getSplitAttributeMap(state)
  //     )
  //     return result
  //   }
  // },
}
