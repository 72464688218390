var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import createStandardIcon from './createStandardIcon';
var WarningOutlineIconSVG = function (props) {
    var fill = props.fill;
    return (_jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }, props, { children: _jsx("path", { d: "M2.85644 17.0834C2.71519 17.0834 2.58832 17.0488 2.47582 16.9798C2.36332 16.9108 2.27589 16.8197 2.21352 16.7067C2.14852 16.5945 2.11276 16.4729 2.10623 16.3421C2.09971 16.2113 2.13498 16.0817 2.21207 15.9534L9.34602 3.63002C9.42325 3.50169 9.51978 3.40683 9.63561 3.34544C9.75158 3.28405 9.87366 3.25336 10.0019 3.25336C10.1301 3.25336 10.2521 3.28405 10.3681 3.34544C10.4839 3.40683 10.5805 3.50169 10.6577 3.63002L17.7916 15.9534C17.8687 16.0817 17.904 16.2113 17.8975 16.3421C17.891 16.4729 17.8552 16.5945 17.7902 16.7067C17.7278 16.8197 17.6404 16.9108 17.5279 16.9798C17.4154 17.0488 17.2885 17.0834 17.1473 17.0834H2.85644ZM3.71019 15.8334H16.2935L10.0019 5.00002L3.71019 15.8334ZM10.0019 14.8398C10.1926 14.8398 10.3524 14.7753 10.4814 14.6463C10.6105 14.5172 10.675 14.3574 10.675 14.1667C10.675 13.976 10.6105 13.8161 10.4814 13.6871C10.3524 13.5581 10.1926 13.4936 10.0019 13.4936C9.81116 13.4936 9.6513 13.5581 9.52228 13.6871C9.39325 13.8161 9.32873 13.976 9.32873 14.1667C9.32873 14.3574 9.39325 14.5172 9.52228 14.6463C9.6513 14.7753 9.81116 14.8398 10.0019 14.8398ZM10.0021 12.6602C10.1793 12.6602 10.3277 12.6004 10.4473 12.4807C10.567 12.3608 10.6269 12.2123 10.6269 12.0352V9.11857C10.6269 8.94148 10.5669 8.79308 10.4471 8.67336C10.3272 8.5535 10.1787 8.49357 10.0016 8.49357C9.82443 8.49357 9.67602 8.5535 9.55644 8.67336C9.43672 8.79308 9.37686 8.94148 9.37686 9.11857V12.0352C9.37686 12.2123 9.43679 12.3608 9.55665 12.4807C9.67651 12.6004 9.82498 12.6602 10.0021 12.6602Z", fill: fill }) })));
};
export var WarningOutlineIcon = createStandardIcon(WarningOutlineIconSVG);
