import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { addAccordionFormsAndSort } from 'customForm/card/addAccordionFormsAndSort'
import { permissionsSelectors } from 'ducks/permissions'
import FullScreenLoading from 'layout/widgets/FullScreenLoading'
import { isEmpty } from 'lodash'
import { ComponentVersionsInherit, styled } from 'opensolar-ui'
import { ContentBg } from 'pages/ordering/checkoutv3/preOrder/styles'
import ResendOrderPage from 'pages/ordering/checkoutv3/resendOrder'
import { createContext, useEffect, useLayoutEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Transfer } from 'resources/inventoryTransfer/type'
import { RootState } from 'types/state'
import { useFeatureFlag } from 'util/split'
import useGetOrderDataByProject from '../manage3/hardware/hooks/useGetOrderDataByProject'
import SectionContainer from '../SectionContainer'
import OrderInfoSection from './postOrdering/orderInfo/OrderInfoSection'
import OrderPartsSection from './postOrdering/orderParts/OrderPartsSection'
import ProjectInfoSection from './postOrdering/projectInfo/ProjectInfoSection'
import PreOrder from './preOrder'

export const PREORDER = 'preOrder'
export const POSTORDER = 'postOrder'
export type HardwareComponentType = 'preOrder' | 'postOrder'

const HARDWARE_ACCORDIONS = [
  {
    component: ProjectInfoSection,
    shouldRender: true,
    priority: 10,
    props: {},
  },
  {
    component: OrderInfoSection,
    shouldRender: true,
    priority: 20,
    props: {},
  },
  {
    component: OrderPartsSection,
    shouldRender: true,
    priority: 30,
    props: {},
  },
]

const SectionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  padding: 40,
  width: '100%',
  maxWidth: 1440,
  boxSizing: 'border-box',
  gap: 30,
  '& > *': {
    maxWidth: '100%',
  },
})

export const OrderContext = createContext<{
  orderData: Transfer[]
  orderId: number | undefined
  setOrderId: (order: number | undefined) => void
  reloadOrderData: () => void
  updateHardwareComponent: (component: HardwareComponentType) => void
}>({
  orderData: [],
  orderId: undefined,
  setOrderId: () => {},
  reloadOrderData: () => {},
  updateHardwareComponent: () => {},
})

const HardwarePage = () => {
  const location = useLocation()
  const locationData: any = location.state
  const { allowView } = useSelector(permissionsSelectors.getProjectPermissionByKey('manage'))
  const components = addAccordionFormsAndSort(HARDWARE_ACCORDIONS)
  const enableHardwareCheckout = useFeatureFlag('hardware_3_0_projects_tab_checkout', 'on')
  const enablePostOrder = useFeatureFlag('hardware_3_0_projects_tab_post_order', 'on')
  const [initialized, setIsInitialized] = useState<boolean>(false)
  const projectId = useSelector((state: RootState) => state.projectId)
  const [orderData, setOrderData] = useState<Transfer[]>([])
  const { loadedOrderData, loadingOrder, loadOrderData } = useGetOrderDataByProject()
  const [hardwareComponent, setHardwareComponent] = useState<HardwareComponentType>(PREORDER)
  const [orderId, setOrderId] = useState<number | undefined>(locationData?.orderId || undefined)

  const init = async () => {
    await loadOrderData(projectId)
    setIsInitialized(true)
  }

  useLayoutEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (!isEmpty(loadedOrderData)) {
      setOrderData(loadedOrderData)
      setHardwareComponent(POSTORDER)
    }
  }, [loadedOrderData])

  const reloadOrderData = () => {
    loadOrderData(projectId)
  }

  const updateHardwareComponent = (component: HardwareComponentType) => {
    setHardwareComponent(component)
    if (component === POSTORDER) {
      reloadOrderData()
    }
  }

  if (!allowView) {
    return null
  }

  if (loadingOrder) return <FullScreenLoading />

  return (
    <SectionContainer section="manage" mode="background">
      <ComponentVersionsInherit versions={ComponentVersions_3_0}>
        <ContentBg>
          <OrderContext.Provider value={{ orderData, reloadOrderData, updateHardwareComponent, orderId, setOrderId }}>
            {enablePostOrder && !orderId && hardwareComponent === POSTORDER && (
              <SectionWrapper>{components}</SectionWrapper>
            )}
            {enableHardwareCheckout && !orderId && hardwareComponent !== POSTORDER && <PreOrder />}
            {orderId && <ResendOrderPage orderId={orderId} source="project" />}
          </OrderContext.Provider>
        </ContentBg>
      </ComponentVersionsInherit>
    </SectionContainer>
  )
}

export default HardwarePage
