import { makeStyles, withStyles } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/HelpOutlined'
import ActionHelpOutline from '@material-ui/icons/HelpOutlineOutlined'
import { authSelectors } from 'ducks/auth'
import { helpCenterActions, helpCenterSelectors } from 'ducks/helpCenter'
import { viewModeSelectors } from 'ducks/viewMode'
import Tooltip from 'elements/tooltip/Tooltip'
import { TransitionNone } from 'elements/TransitionNone'
import { IconButton, IconButtonProps } from 'opensolar-ui'
import React, { useEffect, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import type { OpenSolarThemeType } from 'Themes'
import { RootState } from 'types/state'

const BigTooltip = withStyles((theme: any) => ({
  popper: {
    top: '-10px !important',
  },
  tooltip: {
    color: theme.greyDark2,
    fontSize: '12px',
    fontWeight: 400,
    backgroundColor: theme.greyLight1,
    padding: '6px 8px',
    borderRadius: '6px',
    boxShadow: theme.boxShadow.default,
    cursor: 'pointer',
    whiteSpace: 'pre-wrap',
  },

  arrow: {
    color: theme.greyLight1,
  },
}))(Tooltip)

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  icon: {
    height: 24,
    width: 24,
    float: 'right',
    padding: 0,
    margin: 0,
    marginLeft: 0,
  },
  iconButtonRoot: {
    minWidth: 36,
    padding: 0,
    height: 36,
  },
  iconButtonPrimary: {
    color: theme.helpColor,
  },
}))

const SupportButton = ({ iconColor = 'inherit' }: { iconColor?: IconButtonProps['color'] }) => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const location = useLocation()
  const homeDrawerState = useSelector(viewModeSelectors.homeDrawerState)
  const [bigTooltipText, setBigTooltipText] = React.useState<string | undefined>(undefined)
  const tourBanner = useSelector((state: RootState) => authSelectors.getShowTourBanner(state, 'main'))
  const tourHidden = useMemo(() => tourBanner?.userHidIt, [tourBanner])
  const helpCenterOpen = useSelector(helpCenterSelectors.getHelpCenterOpen)
  const atHome = useMemo(() => location.pathname.includes('home'), [location.pathname])
  const classes = useStyles()
  const [justArrived, setJustArrived] = React.useState(true)

  useEffect(() => {
    const timer = setTimeout(() => setJustArrived(false), 5000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (tourHidden && justArrived) {
      setBigTooltipText(translate('Take the OpenSolar 2.0 Tour'))
    } else if (atHome) {
      setBigTooltipText(translate('Need help? Got feedback?\nWe would love to hear from you!'))
    } else {
      setBigTooltipText(undefined)
    }
  }, [tourHidden, justArrived, atHome])

  const toggleHelpCenter = () => {
    dispatch(helpCenterActions.setHelpCenterOpen(!helpCenterOpen))
  }

  const Icon = helpCenterOpen ? HelpIcon : ActionHelpOutline

  return (
    <BigTooltip
      title={bigTooltipText || ''}
      open={!!bigTooltipText && !helpCenterOpen && !homeDrawerState}
      PopperProps={{
        disablePortal: true,
        onClick: toggleHelpCenter,
      }}
      interactive={true}
      TransitionComponent={TransitionNone}
    >
      <IconButton
        color={iconColor}
        className="HelpCenterBottomButton"
        classes={{
          root: classes.iconButtonRoot,
          colorPrimary: classes.iconButtonPrimary,
        }}
        onClick={() => toggleHelpCenter()}
      >
        <Tooltip title={translate('Support')} enterDelay={300} TransitionComponent={TransitionNone}>
          <Icon className={classes.icon} />
        </Tooltip>
      </IconButton>
    </BigTooltip>
  )
}

export default SupportButton
