import { pick } from 'util/misc'
import { OrgEdit } from './OrgEdit'
const orgFields = [
  'invitation_email_custom_subject',
  'invitation_email_custom_message',
  'invitation_email_smtp_host',
  'invitation_email_smtp_port',
  'invitation_email_smtp_username',
  'invitation_email_smtp_password',
  'default_project_access',
  'api_key_google',
  'api_key_bing',
  'api_key_cyclomedia',
  'api_key_nearmap',
  'api_key_utility_api',
  'api_key_pvsell',
  'api_key_chat',
  'api_key_sunlight_email',
  'api_key_sunlight_password',
  'api_key_plenti_partner_id',
  'api_key_energy_ease_token',
  'api_key_plenti_staff_partner_id',
  'api_key_metromap',
  'enable_exhibit',
  'exhibit_activations',
  'exhibit_activation_codes',
  'exhibit_data_sharing_agreement_file_contents',
  'exhibit_is_public',
  'enable_checkout',
  'enable_google_calendar',
  'accept_credit_card_stripe',
  'credit_card_stripe_publishable_key',
  'credit_card_stripe_secret_key',
  'credit_card_stripe_surcharge_percentage',
  'credit_card_stripe_surcharge_fixed',
  'accept_offline',
  'hide_loan_application_until_prequal',
  'offline_surcharge_percentage',
  'offline_surcharge_fixed',
  'offline_title',
  'offline_content',
  'quote_acceptance_heading',
  'quote_acceptance_content',
  'measurement_units_override',
  'enable_other_imagery',
  'docusign_config.docusign_account_id',
  'docusign_config.enable_contracts_in_docusign',
  'docusign_config.docusign_contract_kba_enabled',
  'manufacturers',
]
export const getDefaultSettings = (overrideValue) => {
  return function (recordData) {
    const data = { ...recordData, ...overrideValue }
    // Handle legacy quote_acceptance_content without adding migrations.
    // Convert '\n' delimited text to array to support JsonFormInput's expected value.
    data.quote_acceptance_content = data.quote_acceptance_content?.split('\n') || []
    return pick(data, orgFields)
  }
}
export const formatSubmitValues = (values) => {
  // Handle legacy quote_acceptance_content without adding migrations.
  // Convert array to '\n' delimited text to comply with BE's expected format.
  return {
    ...values,
    quote_acceptance_content: values.quote_acceptance_content?.join('\n'),
  }
}
export default {
  edit: OrgEdit,
  options: {
    edit: {
      title: 'Settings',
      subtitle: null,
      breadCrumb: 'Organisation Settings',
    },
  },
}
