import { useTranslate } from 'react-admin'
import ChartistGraph from 'react-chartist'
import { convert24AmPm } from 'util/time'

const maxMonthlyOutputForSystem = (system) => Math.max.apply(null, system.output.monthly)

export const maxAxisYForAllSystems = (systems, selectedProject) => {
  const maxMonthlyOutputForAllSystems = systems.map((system) => maxMonthlyOutputForSystem(system))

  const maxMonthlyOutput = Math.max.apply(null, maxMonthlyOutputForAllSystems)

  const maxMonthlyGeneration = Math.max.apply(null, selectedProject.usage_normalized.monthly)

  return Math.max(maxMonthlyOutput, maxMonthlyGeneration) * 1.1
}

const SelfConsumptionTable = ({ hourly_energy_flow_for_day }) => {
  const translate = useTranslate()

  const generationTotal = hourly_energy_flow_for_day['generation'].reduce((a, b) => a + b, 0)
  const hasBatteryExport = hourly_energy_flow_for_day?.has_battery_export

  var exportToGridFraction = 0
  var selfConsumptionFraction = 0
  var exportLimitLossFraction = 0

  if (hasBatteryExport) {
    selfConsumptionFraction = hourly_energy_flow_for_day.self_consumption_fraction
    exportToGridFraction = 1 - selfConsumptionFraction
    exportLimitLossFraction = 0
  } else {
    const exportTotal = hourly_energy_flow_for_day['export'].reduce((a, b) => a + b, 0)
    const exportBeforeLimitingTotal = hourly_energy_flow_for_day[
      'export_to_grid_before_limiting_losses_profile'
    ].reduce((a, b) => a + b, 0)
    exportToGridFraction = exportTotal / generationTotal
    selfConsumptionFraction = 1 - exportBeforeLimitingTotal / generationTotal
    exportLimitLossFraction = (exportBeforeLimitingTotal - exportTotal) / generationTotal
  }

  var hasExportLimiting = false
  if (exportLimitLossFraction > 0) {
    hasExportLimiting = true
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        margin: '0px auto 15px auto',
        border: '1px solid #c9c9c9',
      }}
    >
      <div
        style={{
          flex: '1 0 33.33%',
          textAlign: 'center',
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        {translate('Self-consumption')}: {Math.round(100 * selfConsumptionFraction)}%
      </div>
      <div
        style={{
          flex: '1 0 33.33%',
          textAlign: 'center',
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        {translate('Export to Grid')}: {Math.round(100 * exportToGridFraction)}%
      </div>
      {hasExportLimiting && (
        <div
          style={{
            flex: '1 0 33.33%',
            textAlign: 'center',
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          {translate('Export Limited')}: {Math.round(100 * exportLimitLossFraction)}%
        </div>
      )}
    </div>
  )
}

const DailyEnergyFlowsChart = (props) => {
  const translate = useTranslate()
  var hasBattery = props.data.battery.filter((v) => v !== 0).length > 0
  var isVirtualStorage = props.data.virtual_storage_usage_profile.filter((v) => v !== 0).length > 0
  var hasExportLimiting = false
  for (var i = 0; i < 24; i++) {
    if (props.data.export[i] !== props.data.export_to_grid_before_limiting_losses_profile[i]) {
      hasExportLimiting = true
    }
  }

  return (
    <div>
      <div style={{ position: 'relative', height: 30 }}>
        <h2 style={{ width: 150 }}>{props.label}</h2>

        <SelfConsumptionTable hourly_energy_flow_for_day={props.data} />

        <div
          style={{ display: 'flex', width: '100%', flexWrap: 'wrap', textAlign: 'center', justifyContent: 'center' }}
        >
          {[
            { label: 'CONSUMPTION', color: '#333333' },
            { label: 'GENERATION', color: '#ffd502', opacity: 0.5 },
            { label: 'NET CONSUMPTION', color: '#9B9B9B', opacity: 0.5 },
            { label: 'EXPORT TO GRID', color: '#D0021B' },
          ]
            .concat(hasBattery ? { label: 'BATTERY', color: '#4A90E2', opacity: 0.5 } : [])
            .concat(hasExportLimiting ? { label: 'EXPORT LIMITING', color: '#750000' } : [])
            .map((item, i) => (
              <div
                key={i}
                style={{
                  position: 'relative',
                  height: 20,
                  marginRight: 20,
                  display: 'inline-flex',
                }}
              >
                <div
                  style={{
                    width: 13,
                    marginRight: 10,
                    height: 10,
                    backgroundColor: item.color,
                    opacity: item.opacity ? item.opacity : 1.0,
                  }}
                />
                <div style={{ fontSize: 10 }} className="small">
                  {translate(item.label)}
                  {` (kWh)`}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div style={{ marginTop: 100 }}>
        <ChartistGraph
          style={{
            marginTop: 0,
            marginLeft: 0,
            marginBottom: 0,
            marginRight: 0,
            width: '100%',
          }}
          data={{
            labels: props.data.hour,
            series: [
              {
                data: props.data.generation,
                className: 'DailyEnergyFlowsGeneration',
              },
            ]
              .concat(hasBattery ? [{ data: props.data.battery, className: 'DailyEnergyFlowsBattery' }] : [])
              .concat([
                { data: props.data.net, className: 'DailyEnergyFlowsNet' },
                {
                  data: props.data.consumption,
                  className: 'DailyEnergyFlowsConsumption',
                },
              ])
              .concat([
                {
                  data: props.data.export_to_grid_before_limiting_losses_profile,
                  className: hasExportLimiting
                    ? 'DailyEnergyFlowsBeforeExportLimitingLosses'
                    : 'DailyEnergyFlowsExport',
                },
              ])
              .concat(
                hasExportLimiting
                  ? [
                      {
                        data: props.data.export,
                        className: 'DailyEnergyFlowsExport',
                      },
                    ]
                  : []
              )
              .concat(
                isVirtualStorage
                  ? [
                      {
                        data: props.data.battery.virtual_storage_usage_profile,
                        className: 'DailyEnergyFlowsVirtualStorageUsage',
                      },
                    ]
                  : []
              ),
          }}
          options={{
            chartPadding: 20,
            showLabel: true,
            showLine: true,
            showArea: true,
            height: '250px',
            width: '100%',
            showPoint: false,
            lineSmooth: true,
            scaleMinSpace: 50,
            onlyInteger: true,
            referenceValue: 0,
            fullWidth: true,
            axisY: { showGrid: true },
            axisX: {
              showLabel: true,
              showGrid: false,
              labelInterpolationFnc: function (value, index) {
                return index < 23 && index % 4 === 3 ? convert24AmPm(value) : null
              },
            },
            // low: 0,
            // high: props.maxMonthlyEnergy
            //   ? props.maxMonthlyEnergy
            //   : maxAxisYForAllSystems(
            //       [props.selectedSystem],
            //       props.selectedProject
            //     ),
          }}
          type={'Line'}
          plugins={
            [
              // Chartist.plugins.legend({
              //     position: 'bottom',
              //     classNames: ['ct-blue', 'ct-hidden'],
              // })
            ]
          }
        />
        <div
          style={{
            position: 'relative',
            top: -225,
            left: props.layout === 1 ? 60 : 70,
            fontWeight: 600,
            fontSize: props.layout === 1 ? 8 : 10,
            width: '50%',
          }}
          className="small"
        >
          ↑ {translate('Generation')} ({translate('kW')})
        </div>
        <div
          style={{
            position: 'relative',
            top: -85,
            left: props.layout === 1 ? 60 : 70,
            fontWeight: 600,
            fontSize: props.layout === 1 ? 8 : 10,
            width: '50%',
          }}
          className="small"
        >
          ↓ {translate('Consumption')} ({translate('kW')})
        </div>
      </div>
    </div>
  )
}

export default DailyEnergyFlowsChart
