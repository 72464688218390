import { useMediaQuery } from '@material-ui/core'
import useElementBox from 'hooks/useElementBox'
import useWindowSize from 'hooks/useWindowSize'
import { styled } from 'opensolar-ui'
import { Identifier } from 'ra-core'
import { ReactNode } from 'react'
import type {
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import ReactDOM from 'react-dom'
import { List } from 'react-virtualized'
import { Theme } from 'types/themes'
const getBackgroundColor = (isDraggingOver: boolean, isDraggingFrom: boolean) => {
  if (isDraggingOver) {
    return '#f0f4fc'
  }
  if (isDraggingFrom) {
    return '#fffaf2'
  }
}

interface ColumnProps<T> {
  columnId: string
  getItem: (index: number) => { id: Identifier; data: T } | undefined
  renderChild: (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
    item: T | undefined,
    index: number,
    style?
  ) => void
  colHeader?: ReactNode
  isDragDisabled?: (item: T) => boolean
  total: number
  onRowsRendered?: (info: {
    overscanStartIndex: number
    overscanStopIndex: number
    startIndex: number
    stopIndex: number
  }) => void
  cardHeight?: number
  cardWidth?: number
}

const Column = styled('div', { name: 'Column' })({
  display: 'flex',
  flexDirection: 'column',
  gap: 15,
  height: '100%',
})
const BoardColumn = <T,>({
  columnId,
  colHeader,
  getItem,
  renderChild,
  isDragDisabled,
  total,
  onRowsRendered,
  cardHeight = 335,
  cardWidth = 325,
}: ColumnProps<T>) => {
  const windowSize = useWindowSize()
  const { setTarget, viewportBox } = useElementBox<HTMLElement>()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const padding = isMobile ? 90 : 40
  const height = Math.max(100, windowSize.innerHeight - viewportBox.y - padding)

  return (
    <Column>
      {colHeader ? colHeader : <h1>{columnId}</h1>}
      <Droppable
        droppableId={columnId}
        mode="virtual"
        renderClone={(provided, snapshot, rubric) => {
          const item = getItem(rubric.source.index)
          return item ? renderChild(provided, snapshot, item.data, rubric.source.index) : <></>
        }}
      >
        {(droppableProvided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
          const itemCount: number = snapshot.isUsingPlaceholder ? total + 1 : total

          return (
            <List
              className="os-scroll"
              width={cardWidth}
              rowHeight={cardHeight}
              height={height}
              rowCount={itemCount}
              overscanRowCount={3}
              onRowsRendered={onRowsRendered}
              ref={(ref) => {
                // react-virtualized has no way to get the list's ref that I can so
                // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                if (ref) {
                  const findRef = ReactDOM.findDOMNode(ref)
                  if (findRef instanceof HTMLElement) {
                    droppableProvided.innerRef(findRef)
                    setTarget(findRef)
                  }
                }
              }}
              backgroundColor={
                getBackgroundColor(snapshot.isDraggingOver, Boolean(snapshot.draggingFromThisWith)) || undefined
              }
              style={{
                backgroundColor:
                  getBackgroundColor(snapshot.isDraggingOver, Boolean(snapshot.draggingFromThisWith)) || undefined,
                transition: 'background-color 0.2s ease',
              }}
              rowRenderer={({ index, style }) => {
                const item = getItem(index)
                if (!item) return <></>

                const key = item ? item.id + '' : 'empty-' + index

                return (
                  <Draggable
                    draggableId={key}
                    index={index}
                    key={key}
                    isDragDisabled={!item || isDragDisabled?.(item.data)}
                  >
                    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) =>
                      renderChild(provided, snapshot, item?.data, index, style)
                    }
                  </Draggable>
                )
              }}
            />
          )
        }}
      </Droppable>
    </Column>
  )
}

export default BoardColumn
