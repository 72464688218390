import { Backdrop, Drawer } from '@material-ui/core'
import { projectListViewSelectors, ProjectView, updateProjectView } from 'ducks/projectListViews'
import CustomField from 'elements/field/CustomField'
import { FilterValuesType } from 'elements/hardwareFilter/type'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContentWrapper, FormStyled, Header, SectionHeader } from '../styles'
import { mapFilterObjectToFilterArray } from '../utils'
import CustomToolbar from './CustomToolbar'
import { parseFilterValues } from './fields'
import FilterSection from './FilterSection'
import SortSection from './SortSection'
import SuggestedFilterSection from './SuggestedFilterSection'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 600,
    maxWidth: 600,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}))

interface PropTypes {
  open: boolean
  onClose: () => void
  filterValues: FilterValuesType
  setFilters: Function
  isTableView: boolean
  viewSettings: any
}

const FilterDrawer: React.FC<PropTypes> = ({ open, onClose, filterValues, setFilters, isTableView, viewSettings }) => {
  const views: ProjectView[] = useSelector(projectListViewSelectors.getProjectListViewData)
  const translate = useTranslate()
  const dispatch = useDispatch()
  const [key, setKey] = useState(0)

  // Function to save unsaved filters
  const saveFilterValuesToView = (filters) => {
    const filterArray = mapFilterObjectToFilterArray(filters)
    const copyOfViews = [...views]
    let newView = { ...copyOfViews[viewSettings?.selectedView] }
    if (newView.not_saved) {
      let newViewSettings = newView.view_settings || {}
      newViewSettings.filters = filterArray
      newView.view_settings = newViewSettings
      dispatch(updateProjectView(newView))
      onClose()
    } else {
      onClose()
    }
  }

  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
  }, [filterValues])
  return (
    <>
      <Backdrop open={open} style={{ zIndex: 1100 }} onClick={onClose}></Backdrop>
      <StyledDrawer id={'Project-List-Sort-Section'} variant="persistent" anchor="right" open={open}>
        <FormStyled
          id={'Project-List-Sort-Section-Inner'}
          key={key}
          toolbar={<CustomToolbar onClose={onClose} />}
          initialValues={{
            ordering: filterValues.ordering,
            filters: parseFilterValues(filterValues),
          }}
          save={(values: any) => {
            // Extract new filter values from the form
            let newFilterValues = values.filters.reduce((result: any, filter: any) => {
              if (filter.value) {
                return { ...result, ...filter.value }
              }
              return result
            }, {})

            if (filterValues.q) newFilterValues.q = filterValues.q
            if (values.ordering) newFilterValues.ordering = values.ordering
            setFilters(newFilterValues)
            saveFilterValuesToView(newFilterValues)
          }}
        >
          <ContentWrapper>
            <Header>{translate('Sort & Filter')}</Header>
            <CustomField source="ordering" name="ordering" component={SortSection} />
            <SectionHeader>{translate('Filter')}</SectionHeader>
            <CustomField source="filters" name="filters" component={FilterSection} isTableView={isTableView} />
            <SectionHeader>{translate('Suggested Filters')}</SectionHeader>
            <CustomField source="suggestedfilters" name="suggestedfilters" component={SuggestedFilterSection} />
          </ContentWrapper>
        </FormStyled>
      </StyledDrawer>
    </>
  )
}

export default FilterDrawer
