import { useViewShow } from 'ducks/viewMode'
import { Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ModuleGridType } from 'types/global'

const useStyles = makeOpenSolarStyles((theme) => ({
  chip: {
    margin: 4,
    border: '1px solid transparent',
  },
}))

type PropType = {
  moduleGrids: ModuleGridType[]
  allowSelect?: boolean
  allowDelete?: boolean
}

const ModuleGridsList = ({ moduleGrids, allowSelect = true, allowDelete = true }: PropType) => {
  const translate = useTranslate()
  const styles = useStyles()
  const showDeleteIconButton = useViewShow('studio.widgets.module_grids_chip.DeleteIconButton')

  return (
    <>
      {moduleGrids.map((moduleGrid, index) => {
        const panels = moduleGrid.moduleQuantity()
        return (
          <Chip
            id={'ModuleGridButton' + index}
            key={'ModuleGridButton' + index}
            disabled={!allowSelect && !allowDelete}
            className={styles.chip}
            onClick={
              allowSelect
                ? () => {
                    if (!window.editor?.selectByUuid) return
                    window.editor.selectByUuid(moduleGrid.uuid)
                  }
                : undefined
            }
            onDelete={
              allowDelete && showDeleteIconButton
                ? () => {
                    if (!window.editor?.deleteObjectByUuid) return
                    window.editor.deleteObjectByUuid(moduleGrid.uuid)
                  }
                : undefined
            }
            label={panels === 1 ? translate('1 Panel') : translate('%{panels} Panels', { panels })}
          />
        )
      })}
    </>
  )
}

export default ModuleGridsList
