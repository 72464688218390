import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { StyledChip } from '../styles'

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const ProjectType = ({ record }) => {
  const translate = useTranslate()
  const soldInstalledChip =
    record.project_installed !== null
      ? translate('Installed')
      : record.project_sold !== null
      ? translate('Sold')
      : undefined
  if (!record) return null
  return (
    <Row>
      {record.is_lite && <StyledChip label={'OS Lite'} variant="outlined" />}
      <StyledChip label={translate(record.is_residential ? 'Residential' : 'Commercial')} variant="outlined" />
      {soldInstalledChip && <StyledChip label={soldInstalledChip} variant="outlined" />}
    </Row>
  )
}

export default ProjectType
