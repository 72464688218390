import { Add } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import { projectListViewSelectors, ProjectView, saveProjectView, ViewType } from 'ducks/projectListViews'
import { BarChartSquareIcon, Button, styled, TableIcon } from 'opensolar-ui'
import { COLOR_PALETTE } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import { ListControllerProps } from 'ra-core/src/controller/useListController'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generateUUID } from 'util/misc'
import ViewButtonCount from './elements/ViewButtonCount'
import ViewButtonDropdown from './elements/ViewButtonDropdown'
import { mapFilterArrayToFilterObject } from './utils'

const Wrapper = styled('div')({
  width: '100%',
  margin: '10px 0',
  padding: '10px 0',
  overflowX: 'auto',
  borderBottom: '1px solid ' + COLOR_PALETTE.lightGrey,
  display: 'flex',
  alignItems: 'center',
})

const ViewButton = styled(Button)(({ theme }) => ({
  fontWeight: 450,
  color: COLOR_PALETTE.iconColor,
  '&.active': {
    color: theme.palette.info.contrastText,
  },
  '& .btnLabel': {
    margin: '0 5px',
  },
}))

const ButtonContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  marginRight: 15,
  flex: '0 0 auto',
}))

const Highlight = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: -10,
  width: '100%',
  borderBottom: '2px solid ' + theme.palette.info.contrastText,
}))

type Props = ListControllerProps & {
  handleViewChange: (view: number) => void
  viewSettings: {
    selectedView: number
    isTableView: boolean
  }
}

const ViewToolbar = (props: Props) => {
  const translate = useTranslate()
  const viewSelected = useMemo(() => props.viewSettings?.selectedView || 0, [props.viewSettings?.selectedView])
  const [selectedViewCount, setSelectedViewCount] = useState<number | null>(null)
  const [addedNewView, setAddedNewView] = useState<boolean>(false)
  const orgId = useSelector(authSelectors.getOrgId)
  const dispatch = useDispatch()
  const views: ProjectView[] = useSelector(projectListViewSelectors.getProjectListViewData)
  const isUnSavedViewAvailable: boolean = useSelector(projectListViewSelectors.getIsUnsavedViewExist)

  useEffect(() => {
    if (addedNewView) {
      props.handleViewChange(views.length - 1)
      setAddedNewView(false)
    }
  }, [views])

  useEffect(() => {
    if (views.length > 0) {
      let filter = mapFilterArrayToFilterObject(views[viewSelected]?.view_settings?.filters)
      props.setFilters(filter || {})
    }
  }, [views, viewSelected])

  const handleFilterChange = (filter) => {
    props.setFilters({ ...filter }, undefined, true)
  }

  const addNewCustomView = () => {
    const copyOfViews = [...views]
    let newView = { ...copyOfViews[viewSelected || 0] }
    newView.name = 'New View'
    newView.order = (copyOfViews[views.length - 1]?.order || 0) + 1
    newView.id = generateUUID()
    newView.not_saved = true
    newView.view_count = selectedViewCount
    dispatch(saveProjectView(newView))
    setAddedNewView(true)
  }

  return (
    <Wrapper className="os-scroll">
      {views?.map((value, i) => {
        return (
          <ButtonContainer key={'filter-' + i}>
            <ViewButton
              onClick={() => {
                props.handleViewChange(i)
                handleFilterChange(mapFilterArrayToFilterObject(value.view_settings.filters))
              }}
              variant="text"
              color="secondary"
              startIcon={
                value.view_type === ViewType.Table ? <TableIcon /> : <BarChartSquareIcon transform={'rotate(180deg)'} />
              }
              className={i === viewSelected ? 'active' : undefined}
            >
              <span className="btnLabel">{translate(value.name)}</span>
              <ViewButtonCount
                orgId={orgId}
                viewIndex={i}
                viewSelected={viewSelected || 0}
                onChangeCount={setSelectedViewCount}
                filters={value.view_settings.filters}
              />
            </ViewButton>
            {i === viewSelected && (
              <>
                <Highlight />
              </>
            )}
            <ViewButtonDropdown
              isUnsavedView={!!value.not_saved}
              onViewDropdown={() => props.handleViewChange(i)}
              isSelected={i === viewSelected}
              selectedView={i}
              views={views}
              handleViewChange={props.handleViewChange}
            />
          </ButtonContainer>
        )
      })}
      <Button
        disabled={isUnSavedViewAvailable}
        startIcon={<Add />}
        variant="text"
        color="default"
        onClick={addNewCustomView}
      >
        {translate('Add View')}
      </Button>
    </Wrapper>
  )
}

export default ViewToolbar
