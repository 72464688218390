import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { getPremiumProducts, premiumProductsSelectors } from 'ducks/premiumProducts'
import { useNotify } from 'ra-core'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { PremiumProductDetailType } from 'types/global'

interface PremiumProductsProps {
  productsLoading: boolean
  availableProducts: PremiumProductDetailType[] | null
}

export const usePremiumProducts = () => {
  const dispatch = useDispatch()
  const productsLoading = useSelector(premiumProductsSelectors.getIsLoading)
  const availableProducts = useSelector(premiumProductsSelectors.getProducts)

  useEffect(() => {
    if (!productsLoading && !availableProducts) {
      // only dispatch getPremiumProducts() if availableProducts in state is null AND they're not currently loading
      console.info('Loading Premium wallet products', { productsLoading, availableProducts })
      dispatch(getPremiumProducts())
    }
  }, [productsLoading, availableProducts])

  return useMemo((): PremiumProductsProps => {
    return { productsLoading, availableProducts }
  }, [productsLoading, availableProducts])
}

export const useGetPremiumProductByTitle = () => {
  const { productsLoading, availableProducts } = usePremiumProducts()
  return useMemo(() => {
    return (targetProductTitle: string): PremiumProductDetailType | null => {
      if (productsLoading || !availableProducts) {
        console.info('Premium wallet products not ready', { productsLoading, availableProducts })
        return null
      }
      const prod = availableProducts.find((prod) => prod.title === String(targetProductTitle))
      if (!prod) {
        console.error(`Product with ID ${targetProductTitle} not found.`, {
          productsLoading,
          availableProducts,
        })
        return null
      }
      return prod
    }
  }, [productsLoading, availableProducts])
}

export const useUpdatePremiumProducts = () => {
  const notify = useNotify()
  const restClientInstance = restClient(window.API_ROOT + '/api')
  const dispatch = useDispatch()
  const orgId = useSelector(authSelectors.getOrgId)
  return (wallet_products: string[], onSuccess?: () => void) => {
    return restClientInstance('CUSTOM_PUT', 'custom', {
      url: `org_enable_products/${orgId}/`,
      data: { wallet_products },
    })
      .then((response: any) => {
        notify('Your change has been saved', 'success')
        // clear cache, changes to premium imagery subscriptions should force re-fetching of available imagery on next trip to design page
        window.AccountHelper.terrainUrlsCache.deleteAll()
        dispatch(getPremiumProducts())
        onSuccess?.()
      })
      .catch((err: any) => {
        notify('We were unable to save your changes', 'error')
        console.error('Error:', err, { orgId, wallet_products })
        logAmplitudeEvent('premium_product_change_error', {})
      })
  }
}

export const useSendPvfActivationEmail = () => {
  const orgId = useSelector(authSelectors.getOrgId)
  const restClientInstance = restClient(window.API_ROOT + '/api')
  return () => {
    return restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/activate_pvf_integration/`,
    })
      .then((response) => {
        if (response.data.status === true) {
          return response
        }
      })
      .catch((err) => {
        console.error('Error sending PVF enabled emails', err)
      })
  }
}

export const useAcceptTermsAndActivateProduct = () => {
  const orgId = useSelector(authSelectors.getOrgId)
  const updatePremiumProducts = useUpdatePremiumProducts()
  logAmplitudeEvent('premium_product_enabled')
  logAmplitudeEvent('premium_product_terms_agreed')
  const notify = useNotify()

  const { productsLoading, availableProducts } = usePremiumProducts()

  return (targetProduct: PremiumProductDetailType, onSuccess?: () => void) => {
    if (productsLoading || !availableProducts) {
      notify('Something went wrong', 'error')
      return
    } else {
      const activeProductsUrls: string[] = availableProducts
        .filter((p) => targetProduct.url !== p.url && p.is_active)
        .map((p) => p.url)
      if (targetProduct.is_active) {
        console.warn('Product was already activated according to state.', { targetProduct, activeProductsUrls })
      }
      updatePremiumProducts([...activeProductsUrls, targetProduct.url], onSuccess)
    }
  }
}
