import { UI } from 'contexts/uiKey/UiContext'
import { Route, RouteProps } from 'react-router-dom'

const DEFAULT_SLUG = 'pages'
const generateSlug = (path: string | undefined): string => {
  if (!path || path === '/') return ''

  const segments = path.split('/').filter(Boolean)

  const sanitizedSegments = segments
    .map((segment, index) => {
      const isLast = index === segments.length - 1
      return segment.startsWith(':') && !isLast ? null : segment.replace(/^:/, '')
    })
    .filter(Boolean)

  switch (sanitizedSegments.length) {
    case 1:
      return `${DEFAULT_SLUG}.${sanitizedSegments[0]}`
    case 2:
      return `${DEFAULT_SLUG}.${sanitizedSegments[0]}.${sanitizedSegments[1]}`
    default:
      const [first, second, ...rest] = sanitizedSegments
      return `${DEFAULT_SLUG}.${first}.${second}.${rest.pop()}`
  }
}

interface UiRouteProps extends RouteProps {
  slug?: string
  uiPath?: string
}

const UiRoute: React.FC<UiRouteProps> = ({ path, slug, uiPath, ...rest }) => {
  const generatedSlug = typeof path === 'string' ? generateSlug(path) : DEFAULT_SLUG
  const routeSlug = slug || generatedSlug

  return (
    <UI slug={routeSlug} path={uiPath}>
      <Route path={path} {...rest} />
    </UI>
  )
}

export default UiRoute
