import { useEffect, useState } from 'react'

const useWindowSize = () => {
  const [screenSize, setScreenSize] = useState({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return screenSize
}

export default useWindowSize
