import { LineItem } from './types'

export const DEFAULT_LINE_ITEM: LineItem = {
  product_name: '',
  product_description: '',
  quantity: 1,
  price: 0,
  discount: 0,
  tax_rate: 0,
}

export const steps = [
  {
    step: 1,
    stepId: 'configure',
    label: 'Create Custom Invoice',
  },
  {
    step: 2,
    stepId: 'preview',
    label: 'Preview Invoice',
  },
  {
    step: 3,
    stepId: 'email',
    label: 'Review & Send Invoice',
  },
]
