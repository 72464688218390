import { Typography, useMediaQuery } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import { MenuBookOutlined } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import ContactSupportIcon from '@material-ui/icons/ContactSupportOutlined'
import ExploreIcon from '@material-ui/icons/ExploreOutlined'
import GroupIcon from '@material-ui/icons/GroupOutlined'
import LanguageIcon from '@material-ui/icons/LanguageOutlined'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOverOutlined'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { osLiteSupportLink } from 'constants/links'
import { addTourBanner, authSelectors } from 'ducks/auth'
import { helpCenterActions, helpCenterSelectors } from 'ducks/helpCenter'
import { viewModeSelectors } from 'ducks/viewMode'
import { useUxVersion } from 'hooks/useUxVersion'
import { Button, IconButton, styled } from 'opensolar-ui'
import { useMemo } from 'react'
import { useLocale, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  getCommunityLinkByLocale,
  getContactUsLinkByLocale,
  getHelpCenterByLocale,
  getWebinarLinkByCountry,
} from 'util/misc'

const drawerWidth = 322

const BetaLabel = styled('h3')({
  margin: 0,
  marginLeft: 10,
  marginBottom: 5,
})

const paperBorder = {
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderWidth: '1px 0 0 1px',
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaperOrg: {
    ...paperBorder,
    width: drawerWidth,
    top: 51,
    zIndex: 1499,
  },
  drawerPaperProject: {
    ...paperBorder,
    width: drawerWidth,
    top: 100,
    zIndex: 1499,
  },
  drawerPaperProjectuX3: {
    ...paperBorder,
    width: drawerWidth,
    top: 106,
    zIndex: 1499,
  },
  drawerPaperMobile: {
    width: '100%',
    zIndex: 1499,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'space-between',
  },
  cardContainer: {
    margin: '5px 0',
    padding: 10,
    position: 'relative',
    borderRadius: 10,
    backgroundColor: '#fff',
    width: '100%',
  },
  betaCard: {
    borderRadius: 0,
    backgroundColor: '#FFE9BA',
    margin: 0,
  },
  cardTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 5px',
    borderRadius: 5,
    background: '#f4f4f4',
    alignSelf: 'stretch',
  },
  cardTitleSection: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    maxWidth: 133,
  },
  icon: {
    display: 'flex',
  },
  title: {
    fontSize: 14,
    color: 'rgb(0,0,0)',
    fontWeight: 400,
    lineHeight: '16px',
    padding: '10px 10px',
    paddingRight: 4,
  },
  label: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    minWidth: 133,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    fontFamily: 'Roboto',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 400,
    marginTop: 10,
  },
}))

interface HelpCenterItem {
  Title: string
  Content: string
  Link: string
  Icon: React.ReactNode
  Label: string
  Include: boolean
  Beta?: boolean
}

function getHelpCenterItems(
  isUI3Manage: boolean,
  isUI3Projects: boolean,
  ui3Enabled: boolean,
  isUi3Page: boolean,
  isMobile: boolean
) {
  const HelpCenter: HelpCenterItem[] = [
    {
      Title: 'New UI Help Centre',
      Content: 'Discover detailed guides and tips for navigating the new Project Management beta pages.',
      Link: 'https://docs.google.com/document/d/1l-Y6mmQRQaCthiEQ77YmDFsezlAh1Hxl5g-trke-qJA/edit?usp=sharing',
      Icon: <MenuBookOutlined />,
      Label: 'Help Centre',
      Include: ui3Enabled && isUi3Page,
      Beta: true,
    },
    {
      Title: 'Take the Tour',
      Content:
        'The best way to quickly get up to speed. This 30 seconds tour will familiarise you with the new Project Management features.',
      Link: '',
      Icon: <ExploreIcon />,
      Label: 'Begin Tour Now',
      Include: isUI3Manage && !isMobile,
      Beta: true,
    },
    {
      Title: 'Take the Tour',
      Content:
        'The best way to quickly get up to speed. This 30 seconds tour will familiarise you with the new Project List features.',
      Link: '',
      Icon: <ExploreIcon />,
      Label: 'Begin Tour Now',
      Include: isUI3Projects && !isMobile,
      Beta: true,
    },
    {
      Title: 'New UI Feedback',
      Content:
        'Share your thoughts and help us improve the new project management experience. We appreciate your input!',
      Link: 'https://forms.gle/aTTx7BeVsjByNStR6',
      Icon: <ExploreIcon />,
      Label: 'Submit Feedback',
      Include: ui3Enabled && isUi3Page,
      Beta: true,
    },
    {
      Title: 'Help Center',
      Content: 'Everything you need to know about OpenSolar, including instructional videos and step-by-step guides.',
      Link: 'https://support.opensolar.com',
      Icon: <LanguageIcon />,
      Label: 'Visit Help Centre',
      Include: true,
    },
    {
      Title: 'Contact Support',
      Content: 'Submit a ticket and our team will respond as soon as possible.',
      Link: 'https://support.opensolar.com/hc/en-us/requests/new',
      Icon: <ContactSupportIcon />,
      Label: 'Submit a Ticket',
      Include: true,
    },

    {
      Title: 'Take the Tour',
      Content:
        'The best way to quickly get up to speed. This 4 minute tour will walk you through the process from entering an address to a finished proposal.',
      Link: window.location.origin + '/#/home?tour=fuji&step=start',
      Icon: <ExploreIcon />,
      Label: 'Begin Tour Now',
      Include: !ui3Enabled,
    },
    {
      Title: 'Weekly Webinars',
      Content:
        'Join us for our weekly webinar series, covering an OpenSolar General Overview, Advanced Design and Control Centre Deep Dive.',
      Link: 'https://www.opensolar.com/webinars',
      Icon: <RecordVoiceOverIcon />,
      Label: 'Go to Webinars',
      Include: true,
    },
    {
      Title: 'Community',
      Content:
        'Chat and connect with other OpenSolar professionals around the world - questions, tips, best practices.',
      Link: 'https://support.opensolar.com/hc/en-us/community/topics',
      Icon: <GroupIcon />,
      Label: 'Go to Community',
      Include: true,
    },
  ]

  return HelpCenter.filter((x) => !!x.Include)
}

const HelpCenterLite: HelpCenterItem[] = [
  {
    Title: 'Help Center',
    Content: 'Everything you need to know about OpenSolar, including instructional videos and step-by-step guides.',
    Link: osLiteSupportLink,
    Icon: <LanguageIcon />,
    Label: 'Visit Help Centre',
    Include: true,
  },
  {
    Title: 'Contact Support',
    Content: 'Submit a ticket and our team will respond as soon as possible.',
    Link: 'https://opensolarlite.zendesk.com/hc/en-us/requests/new',
    Icon: <ContactSupportIcon />,
    Label: 'Submit a Ticket',
    Include: true,
  },
  {
    Title: 'Monthly Webinar',
    Content:
      'Get all your OpenSolar Lite questions answered in our live, monthly sessions where we will show you how create a design and generate a report in just a few clicks.',
    Link: 'https://us06web.zoom.us/webinar/register/6416781353625/WN_2K-v6-9WQYOS0jKoAia3KA',
    Icon: <RecordVoiceOverIcon />,
    Label: 'Go to Webinar',
    Include: true,
  },
]

const HelpCenterButton = ({ cardData, locale, country_iso2, isUI3Manage, isUI3Projects }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const isUserLite = useSelector(viewModeSelectors.isUserLite)
  return (
    <div className={`${classes.cardContainer} ${cardData.Beta ? classes.betaCard : ''}`}>
      <div>
        <div className={classes.cardTitleWrapper}>
          <div className={classes.cardTitleSection}>
            <div className={classes.icon}>{cardData.Icon}</div>
            <div className={classes.title}>{translate(cardData.Title)}</div>
          </div>
          <Button
            className={classes.button}
            size="small"
            variant="outlined"
            fullWidth
            onClick={() => {
              if (isUserLite) {
                window.open(cardData.Link, '_blank')
                return
              }
              switch (cardData.Title) {
                case 'Help Center':
                  window.open(getHelpCenterByLocale(locale), '_blank')
                  break
                case 'Take the Tour':
                  if (isUI3Manage) {
                    dispatch(addTourBanner('manage'))
                    dispatch(helpCenterActions.setHelpCenterOpen(false))
                  } else if (isUI3Projects) {
                    dispatch(addTourBanner('project_list'))
                    dispatch(helpCenterActions.setHelpCenterOpen(false))
                  } else {
                    window.location.href = cardData.Link
                    logAmplitudeEvent('fuji_tour_started_from_help', {})
                  }
                  break
                case 'Contact Support':
                  window.open(getContactUsLinkByLocale(locale), '_blank')
                  break
                case 'Community':
                  window.open(getCommunityLinkByLocale(locale), '_blank')
                  break
                case 'Weekly Webinars':
                  window.open(getWebinarLinkByCountry(country_iso2), '_blank')
                  break
                default:
                  window.open(cardData.Link, '_blank')
              }
            }}
          >
            <span className={classes.label}>{translate(cardData.Label)}</span>
          </Button>
        </div>
        <div>
          <div className={classes.content}>{translate(cardData.Content)}</div>
        </div>
      </div>
    </div>
  )
}

const HelpCenterDrawer = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const locale = useLocale()
  const country_iso2 = useSelector(authSelectors.getCurrentOrgCountry)
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const isUserLite = useSelector(viewModeSelectors.isUserLite)
  const isProjectLite = useSelector(viewModeSelectors.isProjectLite)
  const location = useLocation()
  const ui3Enabled = useUxVersion() === 3
  const isUi3Page = useMemo(
    () =>
      location.pathname?.includes('manage') ||
      location.pathname?.includes('energy') ||
      location.pathname.includes('/projects/view'),
    [location.pathname]
  )
  const isUI3Manage = useMemo(() => location.pathname?.includes('manage') && ui3Enabled, [
    location.pathname,
    ui3Enabled,
  ])
  const isUI3Projects = useMemo(() => location.pathname?.includes('/projects/view') && ui3Enabled, [
    location.pathname,
    ui3Enabled,
  ])

  const HelpCenter = useMemo(() => getHelpCenterItems(isUI3Manage, isUI3Projects, ui3Enabled, isUi3Page, isMobile), [
    isUI3Manage,
    isUI3Projects,
    ui3Enabled,
    isUi3Page,
    isMobile,
  ])
  const helpCenterData = useMemo(() => (isUserLite ? HelpCenterLite : HelpCenter), [
    isUserLite,
    HelpCenterLite,
    HelpCenter,
  ])
  const betaItems = useMemo(() => helpCenterData.filter((x) => x.Beta), [helpCenterData])
  const nonBetaItems = useMemo(() => helpCenterData.filter((x) => !x.Beta), [helpCenterData])
  const helpCenterOpen = useSelector(helpCenterSelectors.getHelpCenterOpen)

  const drawerStyle = (loc) => {
    if (
      loc.includes('explore') ||
      loc.includes('info') ||
      loc.includes('energy') ||
      loc.includes('design') ||
      loc.includes('proposal') ||
      loc.includes('preview') ||
      loc.includes('manage') ||
      loc.includes('sld')
    )
      return true
  }

  const getDrawerStyle = (loc) => {
    switch (loc) {
      case true:
        return ui3Enabled ? classes.drawerPaperProjectuX3 : classes.drawerPaperProject
      default:
        return classes.drawerPaperOrg
    }
  }

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={helpCenterOpen}
        classes={{
          paper: isMobile
            ? classes.drawerPaperMobile
            : (isUserLite && isProjectLite) || isProjectLite
            ? classes.drawerPaperOrg
            : getDrawerStyle(drawerStyle(location.pathname)),
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h5">{translate('Support')}</Typography>
          <IconButton onClick={() => dispatch(helpCenterActions.setHelpCenterOpen(false))}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          {!!betaItems?.length && (
            <>
              <BetaLabel>{translate('Early Access')}</BetaLabel>
              {betaItems.map((cardData, index) => (
                <HelpCenterButton
                  cardData={cardData}
                  locale={locale}
                  country_iso2={country_iso2}
                  isUI3Manage={isUI3Manage}
                  isUI3Projects={isUI3Projects}
                  key={index}
                />
              ))}
            </>
          )}
          {nonBetaItems?.map((cardData, index) => (
            <HelpCenterButton
              cardData={cardData}
              locale={locale}
              country_iso2={country_iso2}
              isUI3Manage={isUI3Manage}
              isUI3Projects={isUI3Projects}
              key={index}
            />
          ))}
        </div>
      </Drawer>
    </div>
  )
}

export default HelpCenterDrawer
