import { styled } from 'opensolar-ui'
import { FC, useEffect, useState } from 'react'
import { ContactsBlock } from 'resources/projects/projectsList/ContactsBlock'
import { ProjectType as PT } from 'types/projects'
import { formatDate } from 'util/date'
import DefaultThumbnail from '../../../../projectSections/sections/manage3/details/elements/thumbnail/default_thumbnail.jpg'
import AddressDisplay from '../elements/AddressDisplay'
import PriorityDropdown from '../elements/PriorityDropdown'
import ProjectType from '../elements/ProjectType'
import TagsDisplay from '../elements/TagsDisplay'
import TeamDisplay from '../elements/TeamDisplay'

const Card = styled('div', { name: 'Card' })<{
  withImage: boolean
}>(({ withImage }) => ({
  backgroundColor: 'white',
  borderRadius: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 10,
  height: withImage ? 300 : 170,
  width: 290,
}))

const ThumbnailImg = styled('img', { name: 'ThumbnailImg' })({
  width: '100%',
  height: 130,
  borderRadius: 4,
  objectFit: 'cover',
})

const Content = styled('div', { name: 'Content' })({
  margin: '10px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})

const MidContent = styled('div', { name: 'MidContent' })({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 5,
})

const EndContent = styled('div', { name: 'EndContent' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.grey[700],
  fontSize: 12,
}))

const TeamWrapper = styled('div', { name: 'TeamWrapper' })({
  display: 'flex',
  alignItems: 'center',
  gap: 5,
})

type ProjectWithThumbnail = PT & { thumbnail?: string }

const ProjectCard: FC<{ item: ProjectWithThumbnail; colsEnabled: string[] }> = (props) => {
  const { item, colsEnabled } = props
  const { id, created_date, contacts_data, assigned_role_data, tags_data } = item

  const [thumbnail, setThumbnail] = useState(item.thumbnail || DefaultThumbnail)

  useEffect(() => {
    // Attempt load of project thumbnail if hasn't been done yet
    if (!item.thumbnail) {
      fetch(`${window.API_ROOT}/api/orgs/${item.org_id}/projects/${item.id}/thumbnail/`, { redirect: 'follow' })
        .then(async (res) => {
          if (res.ok) {
            const blob = await res.blob()
            item.thumbnail = URL.createObjectURL(blob)
            setThumbnail(item.thumbnail)
          } else {
            item.thumbnail = DefaultThumbnail
            setThumbnail(DefaultThumbnail)
          }
        })
        .catch(() => {
          item.thumbnail = DefaultThumbnail
          setThumbnail(DefaultThumbnail)
        })
    }
  }, [])
  if (!colsEnabled) return null
  return (
    <Card withImage={colsEnabled.includes('site_photo')} key={'project-' + id}>
      {colsEnabled.includes('site_photo') && <ThumbnailImg src={thumbnail} />}
      <Content>
        <MidContent>
          {colsEnabled.includes('address') && <AddressDisplay record={item} />}
          {colsEnabled.includes('priority') && <PriorityDropdown record={item} />}
        </MidContent>
        {colsEnabled.includes('contact') && <ContactsBlock contactsData={contacts_data} />}
        {colsEnabled.includes('project_type') && <ProjectType record={item} />}
        {colsEnabled.includes('tags') && <TagsDisplay tags={tags_data} />}
        <EndContent>
          {colsEnabled.includes('id') && <span>#{id}</span>}
          <TeamWrapper>
            {colsEnabled.includes('created_date') && <span>{formatDate(created_date)}</span>}
            {colsEnabled.includes('assignee') && assigned_role_data && <TeamDisplay teamMember={assigned_role_data} />}
          </TeamWrapper>
        </EndContent>
      </Content>
    </Card>
  )
}
export default ProjectCard
