import { Box, Chip } from 'opensolar-ui'
import { useTranslate } from 'ra-core'

// @TODO: Standardize this so the mapping is consistent across the entire UI
const getDisplayStringForObject = (object, translate) => {

  if (object.getName) {
    return translate(object.getName(translate))
  } else {
    return object.type || translate('Object')
  }

}

const SelectionChips = ({ objects }: { objects: any[] }) => {
  const translate = useTranslate()
  return (
    <Box display="flex" gridColumnGap={4} gridRowGap={4} flexWrap={'wrap'}>
      {objects.map((object, index) => {
        return <Chip
          id={`Object-${index}`}
          key={object.uuid}
          label={getDisplayStringForObject(object, translate)}
          onClick={() => {
            if (!window.editor?.selectByUuid) return
            window.editor.selectByUuid(object.uuid)
          }}
        />
      })}
    </Box>
  )
}

export default SelectionChips
