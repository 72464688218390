import { DraggableList } from 'elements/dnd/DraggableList'
import { Button, ColumnVerticalIcon, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { BooleanInput } from 'ra-ui-materialui'
import React, { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { SectionHeader } from '../styles'

const ButtonStyled = styled(Button)({
  justifyContent: 'flex-start',
})

const BooleanStyled = styled(BooleanInput)({
  marginLeft: 'auto',
})
interface PropTypes {
  kanbanCols: string[]
}
const ColumnSettings: React.FC<PropTypes> = ({ kanbanCols }) => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const columns = useMemo(() => formValues.columns, [formValues.columns])
  const allSelected = useMemo(() => columns.every((x) => x.enabled), [columns])
  const handleToggleAll = (checked) => {
    const updatedCols = columns.map((x) => ({ ...x, enabled: x.required || checked }))
    form.change('columns', updatedCols)
  }
  const onDragComplete = (oldOrder, newOrder) => {
    //restrict drag on required items
    let newColumns = newOrder.map((x) => columns.find((y) => x === y.id))
    newColumns = [...(newColumns.filter((x) => x.required) || []), ...(newColumns.filter((x) => !x.required) || [])]

    form.change('columns', newColumns)
  }
  return (
    <>
      <SectionHeader>{translate(formValues.isTableView ? 'Column Settings' : 'Card Settings')}</SectionHeader>
      <ButtonStyled
        startIcon={<ColumnVerticalIcon />}
        disableRipple
        variant="text"
        color="secondary"
        onClick={() => {
          handleToggleAll(!allSelected)
        }}
      >
        {translate(
          formValues.isTableView
            ? allSelected
              ? 'Hide All Columns'
              : 'Show All Columns'
            : allSelected
            ? 'Hide All Information'
            : 'Show All Information'
        )}
      </ButtonStyled>
      <DraggableList
        handleReorder={onDragComplete}
        optionId="id"
        render={(item, { index, dragHandle }) => {
          if (
            (!formValues.isTableView && kanbanCols?.includes(item.id)) ||
            (formValues.isTableView && item.hideOnTable)
          )
            return null
          return (
            <ColumnToggleView
              source={'columns.' + index}
              dragHandle={dragHandle}
              {...item}
              isTableView={formValues.isTableView}
            />
          )
        }}
        dragMode="drag-handle"
        isDragDisabled={(item: any) => (formValues.isTableView ? !!item.required || item.hideOnTable : true)}
        list={columns}
      />
    </>
  )
}

const ToggleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: '5px 0',
})
const DragPlaceholder = styled('div', { name: 'DragPlaceholder' })({
  width: 20,
})
const ColumnToggleView = (props) => {
  const { display, source, required, dragHandle, isTableView } = props
  const translate = useTranslate()
  return (
    <ToggleWrapper key={'column-' + props.id}>
      {isTableView ? dragHandle : <DragPlaceholder />}
      <span style={{ color: required ? 'grey' : undefined }}>{translate(display)}</span>
      <BooleanStyled disabled={required} source={source + '.enabled'} label={''} options={{ color: 'primary' }} />
    </ToggleWrapper>
  )
}

export default ColumnSettings
