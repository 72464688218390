import { authSelectors } from 'ducks/auth'
import Tooltip from 'elements/tooltip/Tooltip'
import { Chip, EditOutlineIcon, IconButton, MenuItem, Select, Stepper, StepType, styled } from 'opensolar-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { ProjectType } from 'types/projects'
import { formatDate } from 'util/date'
import { doNotTranslate } from 'util/misc'
import IntegrationRow from '../IntegrationRow'
import { EnaSubmissionStep } from './ENADialog/consts'
import { EnaDialog } from './ENADialog/EnaDialog'
interface PropTypes {
  project: ProjectType
}

const STATUS_TOOLTIP = {
  'Awaiting Assessment': 'Connect Direct is assessing the application.',
  'Scanning Cut-Out Image': 'The cut-out image is being assessed.',
  'Under Assessment': 'Connect Direct is assessing the application.',
  'Escalated to DNO': 'The application has been escalated to a DNO and may not proceed until a response is received.',
  'Auto-Approved': 'The application has been auto-approved by Connect Direct and can proceed.',
  'DNO Approved': 'The application has been approved by the DNO and can proceed.',
  'DNO Approved with Limit':
    'The application has been approved by the DNO but with conditions. Conditions can be accessed by querying the GET Installer applications endpoint.',
  'Further Information Required':
    'The DNO requires further information in order to be able to make a decision on this application. They will contact the installer directly to collect this information. No further information is required to be submitted via Connect Direct.',
  'Failed - Too Large':
    'The application is too large for Connect Direct. Applications must be within the following limits: 1. Total Aggregate Generation Capacity less than 17 kW per phase. 2. Total Property Demand less than 130 Amps.',
  Failed:
    'Connect Direct Failed to Process the Application. Please raise a support ticket via the Connect Direct website if this occurs.',
  'Failed - Invalid Cut-Out Image':
    'The cut out image provide is invalid. Please resubmit the application with a valid image. Images must be within a meter of the cutout.',
  'Image Scan Failed':
    'The image scan failed. Please raise a support ticket via the Connect Direct website if this occurs.',
  'Commissioning Submitted': 'Commissioning information has been sent by the installer to the DNO.',
  'Commissioning Accepted': 'The DNO has accepted the commissioning information.',
  'Commissioning Update Required':
    'The DNO has not accepted the commissioning information. The installer must review DNO feedback and re-submit.',
  '(Re) Auto-Approved':
    'The application was resubmitted due to a change in details post the initial approval. It has been auto-approved by Connect Direct and can proceed.',
  '(Re) Escalated to DNO':
    'The application was resubmitted due to a change in details post the initial approval. The application has been escalated to a DNO, who will re-review and get back to you.',
}

const StepInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

type ChipColor = 'default' | 'info' | 'error' | 'success'

type ChipVariant = 'outlined' | 'default'

interface SingleStepContentProps {
  title: string
  showChip: boolean
  tooltip: string
  chipLabel: string
  chipColor: ChipColor
  chipVariant: ChipVariant
}

const SingleStepContent: React.FC<SingleStepContentProps> = ({
  title,
  showChip,
  tooltip,
  chipLabel,
  chipVariant,
  chipColor,
}) => {
  return (
    <div>
      <StepInfo>
        {title}
        {showChip && (
          <Tooltip title={tooltip}>
            <StyledChip label={chipLabel} variant={chipVariant} color={chipColor} size="small" />
          </Tooltip>
        )}
      </StepInfo>
    </div>
  )
}

const ENACta: React.FC<PropTypes> = ({ project }) => {
  const orgId = useSelector(authSelectors.getOrgId)
  const [showDialog, setShowDialog] = useState(false)
  const [savedApplications, setSavedApplications] = useState<any[]>([])
  const [selectedApplication, setSelectedApplication] = useState<any>(null)
  const [isNewApplication, setIsnewApplication] = useState(false)

  const openNewApplication = () => {
    setIsnewApplication(true)
    setShowDialog(true)
  }

  useEffect(() => {
    retrieveApplicationsData()
  }, [])

  const onClose = () => {
    setShowDialog(false)
  }

  const onSave = () => {
    retrieveApplicationsData()
  }

  const retrieveApplicationsData = async () => {
    try {
      const url = `${window.API_ROOT}/api/orgs/${orgId}/projects/${project.id}/get_ena_applications_with_status/`
      const res = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + appStorage.getToken(),
        },
      })
      if (!res.ok) {
        const errorDetails = await res.text()
        console.error(`Request failed with status: ${res.status} - ${res.statusText}, Details: ${errorDetails}`)
        throw new Error(`Bad request: ${errorDetails}`)
      }
      const contentType = res.headers.get('content-type')
      if (contentType && contentType.includes('application/json')) {
        const data = await res.json()
        if (Array.isArray(data.applications)) {
          setSavedApplications([...data.applications])
          if (data.applications.length) {
            setSelectedApplication(data.applications[0])
          }
          if (showDialog) {
            setShowDialog(false)
          }
        }
      } else {
        throw new Error('Unexpected response format, expected JSON.')
      }
    } catch (e) {
      console.error('Error retrieveApplicationData application:', e)
    }
  }

  const editDraft = () => {
    setIsnewApplication(false)
    setShowDialog(true)
  }

  const isTerminalState = (status) =>
    [
      'Auto-Approved',
      'DNO Approved',
      'DNO Approved with Limit',
      'Further Information Required',
      'Commissioning Accepted',
      'Failed',
      'Failed - Too Large',
      'Failed - Invalid Cut-Out Image',
      'Image Scan Failed',
    ].includes(status)

  const getStep = (status) => {
    return status === 'draft' ? 0 : isTerminalState(status) ? 2 : 1
  }

  const stepsData = useMemo((): StepType[] => {
    if (!selectedApplication) {
      return []
    }
    const activeStep = getStep(selectedApplication.status)

    const getStepContent = ({
      activeStep,
      stepId,
      selectedApplication,
    }: {
      activeStep: number
      stepId: number
      selectedApplication: any
    }) => {
      const complete = activeStep > stepId
      const showChip = activeStep === stepId
      const chipLabel =
        stepId === EnaSubmissionStep['Draft Started']
          ? selectedApplication?.created_at.split('T')[0]
          : selectedApplication.status
      const chipColor: ChipColor =
        // if step is 0 (draft) chip colour will always be grey (color "default")
        stepId === EnaSubmissionStep['Draft Started']
          ? 'default'
          : // if step is 1 (reviewing) chip colour will always be blue (color "info")
          stepId === EnaSubmissionStep['In Review']
          ? 'info'
          : // else step is 2 (outcome)
          selectedApplication.status.includes('Failed')
          ? 'error' // if the application failed chip colour will be red (color "error")
          : 'success' // if the application succeded chip colour will be green (color "success")
      const chipVariant: ChipVariant = stepId === 0 ? 'outlined' : 'default'
      const tooltip = stepId === 0 ? 'Application draft' : STATUS_TOOLTIP?.[selectedApplication.status]
      return {
        complete,
        showChip,
        chipLabel,
        chipColor,
        chipVariant,
        tooltip,
      }
    }

    const stepTitles = Object.values(EnaSubmissionStep).filter((value) => typeof value === 'string') as string[]

    const stepsFormatted = stepTitles.map((stepTitle, index) => {
      const stepContent = getStepContent({ activeStep, stepId: index, selectedApplication })
      const { complete, showChip, chipLabel, chipColor, chipVariant, tooltip } = stepContent
      const step = {
        isComplete: complete,
        id: EnaSubmissionStep[stepTitle],
        content: (
          <SingleStepContent
            title={stepTitle}
            showChip={showChip}
            tooltip={tooltip}
            chipLabel={chipLabel}
            chipVariant={chipVariant}
            chipColor={chipColor}
            key={stepTitle}
          />
        ),
      }
      return step
    })

    return stepsFormatted
  }, [selectedApplication?.status])

  return (
    <Wrapper style={{ marginTop: 15 }}>
      <IntegrationRow
        title={'ENA Connect Direct'}
        description={doNotTranslate(
          'Effortlessly submit connection applications and manage documentation with our ENA Connect Direct integration. Simplify your workflow, accelerate your project timelines, and ensure compliance with grid connection requirements, all in one solution.	'
        )}
        btnLabel={doNotTranslate('Send Application to ENA')}
        onClick={openNewApplication}
      />
      {selectedApplication && (
        <Wrapper>
          <Header>ENA Connect Direct Applications Submitted</Header>

          <ApplicationSelectRow>
            <div>
              <Select
                value={selectedApplication}
                onChange={(e: any) => {
                  setSelectedApplication(e.target.value)
                }}
                id="ena-select-draft"
                fullWidth
              >
                {savedApplications.map((app, i) => (
                  <MenuItem key={app?.created_at} value={app}>
                    {app.status === 'draft'
                      ? `[Draft] ${formatDate(app?.created_at, true)}`
                      : `[Application] ${formatDate(app?.created_at, true)} - ID: ${app?.application_id}`}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <IconButton onClick={editDraft} disabled={selectedApplication?.status !== 'draft'}>
                <EditOutlineIcon variant={2} />
              </IconButton>
            </div>
          </ApplicationSelectRow>
        </Wrapper>
      )}
      {selectedApplication && (
        <Stepper
          steps={stepsData}
          activeStep={getStep(selectedApplication.status)}
          orientation={'horizontal'}
          alternativeLabel
        />
      )}

      {showDialog && (
        <EnaDialog
          isOpen={showDialog}
          onClose={onClose}
          onSave={onSave}
          existingAppInfo={isNewApplication ? null : selectedApplication}
        />
      )}
    </Wrapper>
  )
}

export default ENACta

export const StyledChip = styled(Chip)(({ theme }) => ({
  minHeight: 'fit-content',
  width: 'fit-content',
  maxWidth: '100%',
  '& span': {
    whiteSpace: 'normal',
    paddingTop: 4,
    paddingBottom: 4,
  },
  cursor: 'help',
}))

export const WrapperMargin = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  marginTop: 15,
}))

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  marginTop: 5,
}))

export const Header = styled('div')(({ theme }) => ({
  fontSize: 14,
  margin: 0,
  fontWeight: 600,
}))

export const ApplicationSelectRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
  '& > div': {
    display: 'flex',
  },
  '& > div:nth-of-type(1)': {
    overflow: 'hidden',
    flex: 1,
  },
}))
