import BoardColumn from 'elements/dnd/board/BoardColumn'
import { useEffect, useMemo, useState } from 'react'
import { ProjectType } from 'types/projects'
import { WorkflowStageType } from 'types/workflows'
import { ProjectStageUpdateFinal, ProjectStageUpdateInitial } from '.'
import StageCard from './StageCard'
import { useGetVirtualList } from './useGetVirtualList'
export interface ColorGroup {
  main: string
  secondary: string
}

const colorGrps: ColorGroup[] = [
  {
    main: '#113B98',
    secondary: '#EBF0FB',
  },
  {
    main: '#C44518',
    secondary: '#FFEBE2',
  },
  {
    main: '#7B238D',
    secondary: '#F0DFF4',
  },
  {
    main: '#559B93',
    secondary: '#E9F7F5',
  },
  {
    main: '#018030',
    secondary: '#E1FAEA',
  },
]
interface PropTypes<T> {
  renderCard: Function //TODO: better type
  isDragDisabled?: (item: T) => boolean
  workflowStages: WorkflowStageType[]
  filterValues?: Object
  updateFields?: {
    stageUpdateInitial?: ProjectStageUpdateInitial
    setStageUpdateInitial?: (value?: ProjectStageUpdateInitial) => void
    stageUpdateFinal?: ProjectStageUpdateFinal
    setStageUpdateFinal?: (value?: ProjectStageUpdateFinal) => void
  }
  imageEnabled?: boolean
}
const StageColumns = <T,>({ workflowStages, ...props }: PropTypes<T>) => {
  const formattedStages = useMemo(() => {
    let stages: any[] = []
    let colorWheel = 0
    workflowStages?.forEach((stage) => {
      if (colorWheel === colorGrps.length) colorWheel = 0
      stages.push({ ...stage, colors: colorGrps[colorWheel] })
      colorWheel = colorWheel + 1
    })
    return stages
  }, [workflowStages])
  const [forAdd, setForAdd] = useState(null)
  return (
    <>
      {formattedStages.map((stage, i) => (
        <StageColumn key={stage.id} stage={stage} {...props} forAdd={forAdd} setForAdd={setForAdd} />
      ))}
    </>
  )
}

const StageColumn = (props) => {
  const { stage, renderCard, isDragDisabled, filterValues, updateFields, imageEnabled } = props
  const { stageUpdateInitial, setStageUpdateInitial, stageUpdateFinal, setStageUpdateFinal } = updateFields || {}

  const { setWindow, getItem, total, removeItemById, sortItemIntoList } = useGetVirtualList<ProjectType>({
    resource: 'projects',
    blockRequest: !stage,
    filterValues: { ...(filterValues || {}), stage_id: stage?.id },
  })

  useEffect(() => {
    if (stageUpdateInitial?.prevStageId === stage?.id) {
      //remove project from previous column
      const project = removeItemById(stageUpdateInitial.projectId)
      if (project) {
        setStageUpdateFinal({ ...stageUpdateInitial, project: project.data })
        setStageUpdateInitial(undefined)
      }
    }
  }, [stageUpdateInitial])

  useEffect(() => {
    if (stageUpdateFinal?.stageId === stage?.id) {
      //add project to new column
      sortItemIntoList(stageUpdateFinal.project.id, stageUpdateFinal.project)
      setStageUpdateFinal(undefined)
    }
  }, [stageUpdateFinal])

  const onRowsRendered = ({ overscanStartIndex, overscanStopIndex }) => {
    setWindow(overscanStartIndex, overscanStopIndex)
  }

  if (!stage) return null
  return (
    <BoardColumn
      key={'stage-' + stage.id + (imageEnabled ? '-image' : '-noimage')}
      columnId={stage.id?.toString()}
      renderChild={renderCard}
      isDragDisabled={isDragDisabled}
      getItem={getItem}
      total={total}
      colHeader={<StageCard {...stage} count={total} />}
      onRowsRendered={onRowsRendered}
      cardHeight={imageEnabled ? undefined : 205}
    />
  )
}
export default StageColumns
