import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'

type PropTypes = {
  buttonSize?: 'small' | 'medium' | 'large'
}

const RecalculateBtn: React.FC<PropTypes> = ({ buttonSize }) => {
  const translate = useTranslate()

  return (
    <Button
      onClick={() => {
        window.editor.filter('type', 'OsSystem').forEach((system: any) => {
          window.Designer.requestSystemCalculations(system)
        }, this)
      }}
      variant="contained"
      color="default"
      size={buttonSize}
    >
      {translate('Recalculate')}
    </Button>
  )
}

export default RecalculateBtn
