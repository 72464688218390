import { Grid, styled, Typography } from 'opensolar-ui'
import DeliveryDetailSection from 'pages/ordering/checkoutv3/preOrder/DeliveryDetailSection'
import { CheckoutSourceType } from 'pages/ordering/checkoutv3/preOrder/types'
import { POSTORDER } from 'projectSections/sections/hardware'
import { DISTRIBUTOR_CART_MAPPING as PROJECT_DISTRIBUTOR_CART_MAPPING } from 'projectSections/sections/hardware/preOrder'
import { useTranslate } from 'ra-core'
import { createElement, memo, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { DISTRIBUTOR_CART_MAPPING as GLOBAL_DISTRIBUTOR_CART_MAPPING } from '../preOrder'
import AccountManagerSection from '../preOrder/AccountManagerSection'
import { SOURCE_CONTEXT_MAPPING } from '../preOrder/constants'
import OrderSummarySection from '../preOrder/OrderSummarySection'
import ResendOrderProvider, { DistributorDataContext, TotalItemCountContext } from './ResendOrderProvider'

type ResendOrderSourceType = 'project' | 'global'

const HeaderContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '100vw',
  padding: '24px 0px',
  backgroundColor: 'white',
  alignItems: 'center',
  justifyContent: 'center',
})

const GridContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  padding: '40px 28px',
  width: '100%',
  maxWidth: 1440,
  boxSizing: 'border-box',
  flexGrow: 1,
})

const LeftGridContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  flexGrow: 1,
  gap: '12px',
})

const RightGridContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  flexGrow: 1,
  gap: '24px',
})

const DistributorGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
})

const ResendOrder = ({ source }: { source: ResendOrderSourceType }) => {
  const translate = useTranslate()
  const history = useHistory()
  const distributordata = useContext(DistributorDataContext)
  const checkoutSource: CheckoutSourceType = 'resend_order'
  let componentSortOrder: number = 1
  const itemCount = useContext(TotalItemCountContext)
  const orderContext = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].orderContext)
  const distributorCartMapping =
    source === 'global' ? GLOBAL_DISTRIBUTOR_CART_MAPPING : PROJECT_DISTRIBUTOR_CART_MAPPING

  const updateHardwareComponent = () => {
    if (source === 'global') {
      history.push('/manage_orders/incoming_transfer')
    } else if (source === 'project') {
      orderContext.setOrderId(undefined)
      orderContext.updateHardwareComponent(POSTORDER)
    }
  }

  return (
    <>
      <HeaderContainer>
        <Typography textVariant="titleSemiBold">{translate(`Review and Place Order (${itemCount} items)`)}</Typography>
      </HeaderContainer>
      <GridContainer container spacing={3}>
        <Grid item xs={8}>
          <LeftGridContainer container>
            <Grid item xs={12}>
              <DeliveryDetailSection sortOrder={componentSortOrder} checkoutSource={checkoutSource} />
            </Grid>
            <DistributorGrid item xs={12}>
              {Object.keys(distributordata).map((item) => {
                return createElement(distributorCartMapping[item], {
                  distributorKey: item,
                  key: item,
                  sortOrder: componentSortOrder + 1,
                  checkoutSource,
                })
              })}
            </DistributorGrid>
          </LeftGridContainer>
        </Grid>
        <Grid item xs={4}>
          <RightGridContainer container>
            <Grid item xs={12}>
              <OrderSummarySection checkoutSource={checkoutSource} updateHardwareComponent={updateHardwareComponent} />
            </Grid>
            <Grid item xs={12}>
              <AccountManagerSection checkoutSource={checkoutSource} />
            </Grid>
          </RightGridContainer>
        </Grid>
      </GridContainer>
    </>
  )
}
const ResendOrderPage = ({ orderId, source }: { orderId: number | undefined; source: ResendOrderSourceType }) => (
  <ResendOrderProvider orderId={orderId}>
    <ResendOrder source={source} />
  </ResendOrderProvider>
)

export default memo(ResendOrderPage)
