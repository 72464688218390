import { Identifier, useGetList } from 'ra-core'
import { useCallback, useEffect, useRef, useState } from 'react'

const pageSize = 6

export interface CellInfo<T> {
  id: Identifier
  data?: T
}

const sort = { field: 'id', order: 'DESC' }

export const useGetVirtualList = <T>(options: { resource: string; blockRequest?: boolean; filterValues: any }) => {
  const [forceCheckPages, setForceCheckPages] = useState(0)
  const [allData, setAllData] = useState<CellInfo<T>[]>([])
  const [page, setPage] = useState(0)

  const indexWindow = useRef<[number, number]>([0, 1])

  const allDataRef = useRef(allData)
  allDataRef.current = allData

  const { ids, data = {}, total: requestTotal = 0, loading } = useGetList<T>(
    options.resource,
    { page: page + 1, perPage: pageSize },
    sort,
    options.filterValues,

    { blockRequest: options.blockRequest }
  )

  const [total, setTotal] = useState(requestTotal)

  useEffect(() => {
    setTotal(requestTotal)
  }, [requestTotal])

  useEffect(() => {
    if (ids && Object.keys(data).length) {
      setAllData((prev) => {
        const newData = [...prev]
        const startIndex = page * pageSize
        ids.forEach((id, index) => {
          newData[startIndex + index] = {
            id: id,
            data: data[id],
          }
        })
        return newData
      })
    }
  }, [ids, data])

  useEffect(() => {
    if (loading) return

    for (let i = indexWindow.current[0]; i < indexWindow.current[1]; i++) {
      const cell = allData[i]
      if (!cell?.data) {
        setPage(Math.floor(i / pageSize))
        break
      }
    }
  }, [forceCheckPages, loading])

  const getItem = useCallback(
    (index: number) => {
      //   addPendingPage(Math.floor(index / pageSize))
      const data = allData[index]
      return data?.data
        ? {
            id: data.id,
            data: data.data,
          }
        : undefined
    },
    [allData]
  )

  const removeItemById = useCallback(
    (id: Identifier): CellInfo<T> | undefined => {
      const found = allData.find((x) => x?.id === id)
      if (!found) return undefined

      const newData = allData.filter((x) => x?.id !== id)
      setAllData(newData)
      setTotal((prev) => prev - 1)
      return found
    },
    [allData]
  )

  const sortItemIntoList = useCallback((id: Identifier, item: T) => {
    const data = { id, data: item }
    setAllData((prev) => {
      let newData = [...prev, data]
      newData = newData.sort((a, b) =>
        a && b
          ? (a[sort.field] + '').localeCompare(b[sort.field] + '', undefined, { numeric: true, sensitivity: 'base' })
          : a
          ? -1
          : 1
      )
      if (sort.order === 'DESC') {
        newData = newData.reverse()
      }
      const index = newData.findIndex((x) => x?.id === id)
      // Rebuild the array in case this sort method isn't 100% accurate
      newData = prev.slice(0, index).concat([data], prev.slice(index))
      return newData
    })
    setTotal((prev) => prev + 1)
  }, [])

  const setWindow = useCallback((start: number, end: number) => {
    indexWindow.current = [start, end]
    setForceCheckPages((prev) => prev + 1)
  }, [])

  return {
    total,
    setWindow,
    getItem,
    removeItemById,
    sortItemIntoList,
  }
}
