import { Grid } from '@material-ui/core'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import Button from 'elements/button/Button'
import { ComponentVersionsInherit, styled } from 'opensolar-ui'
import { useState } from 'react'
import { List, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { PrivateFileType } from 'types/privateFile'
import { GridViewDatagrid } from './datagrids/GridViewDatagrid'
import { TableViewDatagrid } from './datagrids/TableViewDatagrid'
import { MainToolBar } from './elements/mainToolBar/MainToolBar'
import { ViewMode } from './FilesPage'

const Wrapper = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
}))

const Breadcrumbs = styled('div')(({ theme }) => ({
  '& button': {
    padding: '5px 0px',
  },
}))

const StyledList = styled(List)(({ theme }) => ({
  width: '100%',
  padding: 0,
  '& .MuiToolbar-root': {
    height: 'min-content',
    minHeight: 0,
    '& .MuiTypography-subtitle1': {
      display: 'none',
    },
  },
  '& .Ra-List-Toolbar-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch !important',
    gap: 15,
  },
  '& .MuiTablePagination-root': {
    backgroundColor: '#F6F9FC',
    '& .OSUI-Button-textPrimary': {
      color: theme.palette.grey[500],
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: 70,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '0px 0px 8px 8px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 44,
    },
  },

  '& .MuiPaper-elevation1': {
    border: 'none !important',
  },
}))

type RouteComponentProps = {
  hitory: History
  location: Location
  staticContext: unknown
  match: {
    isExact: boolean
    path: string
    url: string
    params: {
      id: string
    }
  }
}

export type EditabledFieldSelectedType = null | {
  fieldName: string
  record: PrivateFileType
}

export const ProjectFilesList: React.FC<RouteComponentProps> = (props) => {
  const projectId = useSelector((state: any) => state.projectId)
  const [viewMode, setViewMode] = useState<ViewMode>('tableView')
  const [editableFieldSelected, setEditableFieldSelected] = useState<EditabledFieldSelectedType>(null)
  const translate = useTranslate()
  return (
    <ComponentVersionsInherit versions={{ ...ComponentVersions_3_0, table: 1 }}>
      <Wrapper>
        <Breadcrumbs>
          <Link to={`/projects/${projectId}/info`}>
            <Button
              variant="text"
              color="info"
              disabled={!!editableFieldSelected}
              label={`← ${translate('Back to %{page}', { page: 'Manage' })}`}
              translate="no"
            />
          </Link>
        </Breadcrumbs>
        <Grid container spacing={0}>
          <StyledList
            id={'project_files_page'}
            basePath={`/private_files`}
            resource="private_files"
            title={'Project Documentation'}
            hasSearch={false}
            hasCreate={false}
            filter={{ project: projectId, file_tags_exclude: 'Design Artifact' }}
            hideBreadCrumbs={true}
            actions={
              <MainToolBar
                viewMode={viewMode}
                setViewMode={setViewMode}
                disabled={!!editableFieldSelected}
                hasSearch={false}
                setEditableFieldSelected={setEditableFieldSelected}
                {...props}
              />
            }
            bulkActionButtons={<></>}
            hasBulkActions={true}
          >
            {viewMode === 'tableView' ? (
              <TableViewDatagrid
                editableFieldSelected={editableFieldSelected}
                setEditableFieldSelected={setEditableFieldSelected}
                {...props}
              />
            ) : (
              <GridViewDatagrid {...props} />
            )}
          </StyledList>
        </Grid>
      </Wrapper>
    </ComponentVersionsInherit>
  )
}
