import { Grid } from '@material-ui/core'
import { useCountrySpecificTariffFields } from 'projectSections/sections/energy/ElectricityUtility/hooks'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useFormState } from 'react-final-form'
import { isCalifornianNEM3Utility } from 'resources/utilityTariffs/common'
import { tableStyles } from '../../styles'
import { InputWithToolTip } from '../../TariffInputs'
import PricingRates from './PricingRates'

type PropTypes = {
  disabled?: boolean
  countryIso2?: string
}

enum ChargeCategoryId {
  Generation = 'generation_charge',
  NonGeneration = 'non_generation_charge',
  Combined = 'null',
}

const nem3_energyCategories = [
  {
    id: ChargeCategoryId.Generation,
    name: 'Generation Charge',
  },
  {
    id: ChargeCategoryId.NonGeneration,
    name: 'Non-Generation Charge',
  },
  {
    id: ChargeCategoryId.Combined,
    name: 'Combined Charge',
  },
]

const nem3ExportCategories = [
  {
    id: 'generation_charge',
    name: 'Generation Credit',
  },
  {
    id: 'non_generation_charge',
    name: 'Non-Generation Credit',
  },
  {
    id: ChargeCategoryId.Combined,
    name: 'Combined Credit',
  },
]

const PricingTable: React.FC<PropTypes> = ({ disabled, countryIso2 }) => {
  const classes = tableStyles()
  const translate = useTranslate()
  const formState = useFormState()
  const solarCompensationSettings = formState?.values?.tariffData?.solar_compensation_settings
  const hideExport =
    solarCompensationSettings?.distributed_generation_rules === 'Net Metering' ||
    solarCompensationSettings?.apply_nem3_export_rates ||
    solarCompensationSettings?.distributed_generation_rules === 'virtual_storage'

  const hideShippingCost = solarCompensationSettings?.distributed_generation_rules !== 'virtual_storage'

  const showCountrySpecificFields = useCountrySpecificTariffFields(countryIso2)

  const isNem3 =
    (isCalifornianNEM3Utility(formState.values.utility_name) &&
      formState.values.tariffData?.solar_compensation_settings?.apply_nem3_export_rates) ||
    (!isCalifornianNEM3Utility(formState.values.utility_name) &&
      formState.values.tariffData.solar_compensation_settings.apply_nem_modified_with_defined_export_rates)

  const energyCategories = isNem3 ? nem3_energyCategories : undefined
  const energyCategoriesExport = isNem3 ? nem3ExportCategories : undefined

  return (
    <div className={classes.tableContainer}>
      <Grid container spacing={1} alignItems="flex-start" justify="space-between" className={classes.tableHeader}>
        <Grid item xs={12} xl={showCountrySpecificFields ? 10 : 12}>
          <Grid container alignItems="flex-start">
            <Grid item className={classes.rateName}>
              <span>{translate('Rate Name')}</span>
            </Grid>
            <Grid item xl={5}>
              <Grid container>
                <Grid item xs={4}>
                  <span>{translate('Price')}</span>
                </Grid>
                <Grid item xs={4}>
                  <span>{translate('Tier Quantity')}</span>
                </Grid>
                <Grid item xs={4}>
                  <span>{translate('Units')}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={4} className={classes.scheduleHeaders}>
              <span>{translate('Applicable Date & Time')}</span>
              <div className={`${classes.row} ${classes.subHeaders}`}>
                <div className={classes.row}>
                  <span>{translate('Hours')}</span>
                  <span>{translate('Weekdays')}</span>
                </div>
                <span>{translate('Date Range')}</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
        {showCountrySpecificFields && (
          <Grid item xl={2} className={classes.row}>
            <span>{translate('Criteria')}</span>
          </Grid>
        )}
        <div style={{ width: 44 }} />
      </Grid>
      <Grid container spacing={1} justify="space-between" className={classes.tableRow}>
        <InputWithToolTip message={'Charge based on quantity of energy used. Units are in kWh.'}>
          <p>{translate('ENERGY CHARGES')}</p>
        </InputWithToolTip>
        <PricingRates
          source="tariffData.energy_charges"
          disabled={disabled}
          countryIso2={countryIso2}
          chargeCategories={energyCategories}
        />
        <hr className="light" />
      </Grid>
      {!hideShippingCost && (
        <Grid container spacing={1} justify="space-between" className={classes.tableRow}>
          <InputWithToolTip
            message={
              'The fee for the “shipping” virtually stored energy back to user, rather than the typical utility usage rate.'
            }
          >
            <p>{translate('Shipping Costs')}</p>
          </InputWithToolTip>
          <PricingRates
            source="tariffData.shipping_charges"
            disabled={disabled}
            countryIso2={countryIso2}
            chargeCategories={energyCategories}
          />
          <hr className="light" />
        </Grid>
      )}
      <Grid container spacing={1} justify="space-between" className={classes.tableRow}>
        <InputWithToolTip message={'Charge that does not vary based on quantity of energy use or demand.'}>
          <p>{translate('FIXED CHARGES')}</p>
        </InputWithToolTip>
        <PricingRates source="tariffData.fixed_charges" disabled={disabled} countryIso2={countryIso2} />
        <hr className="light" />
      </Grid>
      <Grid container spacing={1} justify="space-between" className={classes.tableRow}>
        <InputWithToolTip
          message={
            'Charge based on the maximum power drawn from the grid for a given time period. Units are typically in kW, kVA or kVAR.'
          }
        >
          <p>{translate('DEMAND CHARGES')}</p>
        </InputWithToolTip>
        <PricingRates source="tariffData.demand_charges" disabled={disabled} countryIso2={countryIso2} />
        <hr className="light" />
      </Grid>
      {!hideExport && (
        <Grid container spacing={1} className={classes.tableRow}>
          <InputWithToolTip
            message={'Credit or compensation for exporting (selling) energy back to the grid (utility).'}
          >
            <p>{translate('EXPORT CREDITS')}</p>
          </InputWithToolTip>
          <PricingRates
            source="tariffData.export_credits"
            disabled={disabled}
            countryIso2={countryIso2}
            chargeCategories={energyCategoriesExport}
          />
          <hr className="light" />
        </Grid>
      )}
      <Grid container spacing={1} justify="space-between" className={classes.tableRow}>
        <InputWithToolTip
          message={
            'Charge based on quantity of energy used for additional controlled load meters installed on the property.'
          }
        >
          <p>{translate('CONTROLLED LOAD CHARGES')}</p>
        </InputWithToolTip>
        <PricingRates source="tariffData.controlled_load_charges" disabled={disabled} countryIso2={countryIso2} />
        <hr className="light" />
      </Grid>
      <Grid container spacing={1} justify="space-between" className={`${classes.tableRow} ${classes.lastRow}`}>
        <InputWithToolTip
          message={
            'Additional fixed charges for having an installed PV system. These charges can either be a fixed amount or based on the size of the PV system.'
          }
        >
          <p>{translate('PV CAPACITY CHARGES')}</p>
        </InputWithToolTip>
        <PricingRates source="tariffData.pv_capacity_charges" disabled={disabled} countryIso2={countryIso2} />
      </Grid>
    </div>
  )
}

export default PricingTable
