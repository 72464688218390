import { Grid, useMediaQuery } from '@material-ui/core'
import StudioErrorPrompt from 'Designer/StudioErrorPrompts'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { useUserActionsContext } from 'contexts/userActions/useUserActionsContext'
import { authSelectors } from 'ducks/auth'
import { myEnergySelectors } from 'ducks/myEnergy'
import { orgSelectors } from 'ducks/orgs'
import { paymentOptionSelectionSelectors, updateAvailablePaymentOptions } from 'ducks/paymentOptionSelection'
import { clearProjectMilestones, projectMilestonesSelectors } from 'ducks/projectMilestones'
import CreditDecisionDialog from 'myenergy/selectionComponent/loanApplicationButton/CreditDecisionDialog'
import { useGetIsCashFlowVisible } from 'pages/cashFlow/utils'
import { findGenericUserAction } from 'persistentContent/tours/tools/common'
import { eventEmitter } from 'projectSections/errors/Emitter'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import appStorage from 'storage/appStorage'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { StudioSystemType } from 'types/global'
import { PaymentOptionDataType } from 'types/paymentOptions'
import { ProjectType } from 'types/projects'
import { Theme } from 'types/themes'
import { deleteInitiatedChangeOrder, getInitiatedChangeOrder } from 'util/changeOrder'
import { useFeatureFlag } from 'util/split'
import SectionContainer from '../SectionContainer'
import ChangeOrderBanner from '../common/ChangeOrderBanner'
import PaymentOptionRefreshChecklist from '../design/systems/tabs/paymentOptions/PaymentOptionRefreshReminder'
import EditInvoiceDetailsDialog from '../manage/cashFlowTransactions/invoices/EditInvoiceDetailsDialog'
import NoSystems from './NoSystems'
import PaymentsPageTitle from './PaymentsPageTitle'
import CashFlowTransactions from './cashFlowTransactions'
import { useGetAllPaymentRequestsForProject } from './cashFlowTransactions/utils'
import CashFlowEvents from './cashflowEvents'
import { FORCE_TOUR_KEY, TOUR_KEY } from './constants'
import ProjectProgressAccordion from './projectProgress'
import RequiredCustomerInfoAlert from './projectProgress/cashFlow/RequiredCustomerInfoAlert'
import PaymentsPromoBanner from './promoBanner'
import CashFlowBannerAd from './promoBanner/CashFlowBannerAd'
import SystemRow from './systemRow'

const restClientInstance = restClient(window.API_ROOT + '/api')
type PropTypes = {}

const PaymentsPage: React.FC<PropTypes> = (props) => {
  const [availablePaymentOptions, setAvailablePaymentOptions] = useState<PaymentOptionDataType[]>(
    window.AccountHelper.getPaymentOptionAvailable()
  )

  const [showEditInvoiceDialog, setShowInvoiceDialog] = useState(false)
  const [showTour, setShowTour] = useState<boolean>(false)
  const fetchSystems = () => window.editor?.getSystems()

  const [systems, setSystems] = useState<StudioSystemType[]>(fetchSystems())
  const hasPaymentOptions = useMemo(() => systems?.length && systems[0]?.payment_options?.length, [systems])

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const paymentsTabEnabled = useFeatureFlag('payments_tab', 'on')
  const dispatch = useDispatch()
  const form = useForm()
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const project = useFormState().values
  const soldPaymentTitle = project.payment_option_sold_title ?? ''
  const projectId = project?.id
  const projectSold = project?.project_sold
  const userId = useSelector(authSelectors.getCurrentUserId)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const showCreditDecisionDialog = useSelector(myEnergySelectors.getShowCreditDecisionDialog)
  const isCashFlowEnabled = useGetIsCashFlowVisible()
  const cashflowOnboardingIsSubmitted = useSelector(orgSelectors.getBlueSnapOnboardingIsSubmitted)
  const userActions = useUserActionsContext()
  const savedMilestones = useSelector(projectMilestonesSelectors.getProjectMiletones)

  const [hasTaxIdError, setHasTaxIdError] = useState(false)

  const handleTaxIdErrorChange = (errorState: boolean) => {
    setHasTaxIdError(errorState)
  }

  useEffect(() => {
    const handleOpenDialog = () => {
      setShowInvoiceDialog(true)
    }

    eventEmitter.on('openEditInvoiceDialog', handleOpenDialog)

    return () => {
      eventEmitter.off('openEditInvoiceDialog', handleOpenDialog)
    }
  }, [])

  const handleCloseDialog = () => {
    setShowInvoiceDialog(false)
  }
  const paymentRequests = useGetAllPaymentRequestsForProject(
    projectId,
    window.editor.selectedSystem?.payment_options?.[0]?.org_id
  )
  const initiatedChangeOrder = getInitiatedChangeOrder(project as ProjectType)
  const isChangeOrderInitiated = !!initiatedChangeOrder

  const useStyles = makeOpenSolarStyles<{ isChangeOrderInitiated: boolean; projectSold: boolean }>(
    {
      wrapper: ({ isChangeOrderInitiated }) => ({
        padding: isChangeOrderInitiated ? '50px 20px 60px 20px' : '20px 20px 60px 20px',
        backgroundColor: 'rgb(252, 252, 252)',
        minHeight: '800px',
      }),
      cashFlowWrapper: {
        marginBottom: projectSold ? '20px' : '0',
      },
    },
    { name: 'ChangeOrder' }
  )
  const classes = useStyles({ isChangeOrderInitiated, projectSold })

  useEffect(() => {
    logAmplitudeEvent('payments_page_viewed', { project_id: projectId, system_count: systems?.length })
  }, [])

  // clean up milestone redux store on dismount
  useEffect(() => {
    return () => {
      dispatch(clearProjectMilestones())
    }
  }, [])

  useEffect(() => {
    if (refreshSystemsTrigger) {
      setSystems([...fetchSystems()])
    }
  }, [refreshSystemsTrigger])

  useEffect(() => {
    if (paymentsTabEnabled && userId && hasPaymentOptions && !showTour) {
      if (appStorage.getBool(FORCE_TOUR_KEY)) {
        setShowTour(true)
      } else {
        if (!appStorage.getBool(TOUR_KEY)) {
          findGenericUserAction(userId, TOUR_KEY)
            .then((res) => {
              if (!res.data?.exists) {
                setShowTour(true)
              }
            })
            .catch((err) => null)
        }
      }
    }
  }, [hasPaymentOptions])

  // clean up the popular payment options local storage items that are no longer needed
  // this hook will be removed soon after it's deployed
  useEffect(() => {
    appStorage.keys().forEach((key) => {
      if (key?.includes('-popular-pmts')) {
        appStorage.clear(key)
      }
    })
  }, [])

  useEffect(() => {
    if (showTour) {
      setTimeout(() => {
        if (
          !window.location.href.includes('?tour=payments&step=start') &&
          !window.location.href.includes('?tour=fuji')
        ) {
          window.location.href = window.location.href + '?tour=payments&step=start'
        }
      }, 2000)
      appStorage.setBool(TOUR_KEY, true)
      if (userId) userActions.recordAction(TOUR_KEY)
    }
  }, [showTour])

  useEffect(() => {
    if (window.location.href.includes('?tour=fuji&step=payments_tab')) {
      window.location.href = window.location.href + '?tour=fuji&step=cards'
    }
  }, [])

  useEffect(() => {
    saveAvailablePaymentOptionsInRedux()
  }, [orgId, project?.shared_with])

  const saveAvailablePaymentOptionsInRedux = useCallback(() => {
    if (orgId) {
      let sharedWithOrgIds = [orgId]
      project.shared_with?.forEach((share) => {
        if (share?.org_id !== orgId) sharedWithOrgIds.push(share.org_id)
      })
      restClientInstance('CUSTOM_GET', 'CUSTOM', {
        url: `payment_options/?fieldset=list&limit=10000&ordering=-id&visible_to=${orgId}&owned_by=${sharedWithOrgIds}&show_archived=1`,
      })
        .then((res) => {
          if (res?.data) {
            dispatch(updateAvailablePaymentOptions(res.data))
          }
        })
        .catch((err) => {
          console.warn('Unable to fetch payment options', err)
        })
    }
  }, [orgId, project?.shared_with])

  if (!paymentsTabEnabled) return null
  // if CashFlow is enabled, allow the initial request for payment requests to resolve before rendering all of the CashFlow content
  if (savedMilestones === undefined && isCashFlowEnabled) return null
  else
    return (
      <SectionContainer section="payments" mode="hidden">
        {isChangeOrderInitiated && (
          <ChangeOrderBanner
            handleCancelChangeOrder={() =>
              deleteInitiatedChangeOrder(initiatedChangeOrder?.id, orgId!, project.id, restClientInstance, form)
            }
          />
        )}
        {projectSold === null &&
          (isCashFlowEnabled && !cashflowOnboardingIsSubmitted ? <CashFlowBannerAd /> : <PaymentsPromoBanner />)}

        <div className={classes.wrapper}>
          {isCashFlowEnabled && projectSold && (
            <Grid container spacing={1} className={classes.cashFlowWrapper}>
              <RequiredCustomerInfoAlert />
              <Grid item xs={12}>
                <ProjectProgressAccordion />
              </Grid>
              <Grid item xs={12}>
                <CashFlowTransactions />
              </Grid>
              <Grid item xs={12}>
                <CashFlowEvents />
              </Grid>
            </Grid>
          )}
          <PaymentsPageTitle />
          {!showTour && !window.location.href.includes('?tour=') && !isMobile && (
            <PaymentOptionRefreshChecklist availablePaymentOptions={availablePaymentOptions} />
          )}
          {!systems?.length && <NoSystems />}
          {systems?.map((system, i) => (
            <SystemRow system={system} key={system.uuid} systemIndex={i} systemsCount={systems.length} />
          ))}
        </div>
        {showCreditDecisionDialog && orgId && projectId && <CreditDecisionDialog orgId={orgId} projectId={projectId} />}
        <StudioErrorPrompt />
        {showEditInvoiceDialog && (
          <EditInvoiceDetailsDialog
            projectId={projectId}
            onClose={handleCloseDialog}
            onTaxIdErrorChange={handleTaxIdErrorChange}
          />
        )}
      </SectionContainer>
    )
}
export default PaymentsPage
