import { EventIdType } from 'amplitude/amplitude'

export enum EventCategoryEnum {
  system_activity,
  customer_activity,
  note,
  task,
  finance,
  payments,
  hardware_inventory,
  workflow,
}

export enum EventFormVariant {
  project_action = 2,
  task = 3,
  special_action = 4,
  note = 6,
}

export enum EventIconVariant {
  email = 1,
  customer = 2,
  check = 3,
  file = 4,
  alert = 5,
  arrow = 6,
}

export enum EventActions {
  archive = 0,
  edit = 1,
  delete = 2,
}

export interface FormVariations {
  [key: number]: FormVariation
}
type FormVariation = {
  label?: string
  content: React.ReactNode
  actions: number[]
  validate?: (values) => object | undefined
}

export type BackendLogger = (eventKey: EventIdType, eventType: string, data: any, eventName: string) => void
export type BackendKey = 'AMPLITUDE' | 'GA4'

export const EVENT_CONFIG_SCHEMA = {
  type: 'object',
  properties: {
    name: { type: 'string' },
    namespace: { type: 'string' },
    sampling_rate: {
      type: 'number',
      minimum: 0,
      maximum: 1,
    },
    type: { type: 'string' },
    description: { type: 'string' },
    backends: {
      type: 'array',
      items: {
        type: 'string',
        enum: ['AMPLITUDE', 'GA4'],
      },
    },
    disabled: { type: 'boolean' },
  },
  required: ['namespace'],
  additionalProperties: false,
} as const

export const EVENTS_CONFIG_SCHEMA = {
  type: 'object',
  additionalProperties: EVENT_CONFIG_SCHEMA,
} as const

export interface EventConfig {
  name?: string
  namespace: string
  sampling_rate?: number
  type?: string
  description?: string
  backends?: Array<'AMPLITUDE' | 'GA4'>
  disabled?: boolean
}

export interface EventsConfig {
  [key: string]: EventConfig
}
