import { useNotify } from 'ra-core'
import type { ElementRef, RefObject } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ApplePaymentDataType, PaymentExtraFields, PaymentRequestType, PaymentStaticCopy } from '../types'
import { getBlueSnapAppleWalletToken } from '../utils'

declare global {
  interface Window {
    ApplePaySession?: any
  }
}

type ButtonType =
  | 'plain'
  | 'add-money'
  | 'book'
  | 'buy'
  | 'check-out'
  | 'continue'
  | 'contribute'
  | 'donate'
  | 'order'
  | 'pay'
  | 'reload'
  | 'rent'
  | 'set-up'
  | 'subscribe'
  | 'support'
  | 'tip'
  | 'top-up'

type PaymentProps = {
  paymentRequestData: PaymentRequestType
  projectId: string
  countryIso2: string
  orgName: string
  paymentStaticCopy: PaymentStaticCopy
  showGooglePay?: boolean
  showApplePay?: boolean | null
  doSubmitPayment: (args: PaymentExtraFields) => void
}

interface ApplePayButtonProps extends PaymentProps {
  buttonStyle?: 'black' | 'white' | 'white-outline'
  type?: ButtonType
  locale?: string
  style?: {
    width?: string
    height?: string
    borderRadius?: string
    padding?: string
    boxSizing?: string
  }
}

const ApplePayButton: React.FC<ApplePayButtonProps> = ({
  buttonStyle = 'black',
  type = 'plain',
  locale = 'en-US',
  style,
  paymentRequestData,
  projectId,
  orgName,
  doSubmitPayment,
  countryIso2,
  paymentStaticCopy,
  showGooglePay,
  showApplePay,
}) => {
  const [isApplePaySupported, setIsApplePaySupported] = useState(false)
  const buttonRef = useRef<ElementRef<'div'>>(null)
  const notify = useNotify()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js'
    script.async = true
    document.body.appendChild(script)

    script.onload = async () => {
      if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
        setIsApplePaySupported(true)
      } else {
        setIsApplePaySupported(false)
      }
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const handlePaymentSubmission = async (paymentData: any) => {
    try {
      const result = await doSubmitPayment({
        payment_method_data: paymentData,
        payment_method_type: 'apple_google_pay',
        wallet_type: 'apple_pay',
      })
      return result
    } catch (err) {
      // Handle the error if necessary
      throw err
    }
  }

  const handleApplePayClick = useCallback(async () => {
    if (!isApplePaySupported) return

    try {
      // @ts-ignore
      const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
        countryCode: countryIso2 || 'US',
        currencyCode: paymentRequestData.currency,
        supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
        merchantCapabilities: ['supports3DS'], // from apple pay docs: supports3DS - Required. This value must be supplied.
        total: {
          label: orgName,
          amount: parseFloat(paymentRequestData.payment_amount.toString()).toFixed(2),
          type: 'final',
        },
      }

      // @ts-ignore
      const session = new window.ApplePaySession(3, paymentRequest)

      session.onvalidatemerchant = async (event) => {
        try {
          const validationURL = event.validationURL

          const appleWalletToken = await getBlueSnapAppleWalletToken(
            projectId,
            paymentRequestData.payment_request_id,
            paymentRequestData?.org_id,
            validationURL
          )
          const decodedToken = atob(appleWalletToken)
          const merchantSession = JSON.parse(decodedToken)
          session.completeMerchantValidation(merchantSession)
        } catch (error) {
          console.error('Apple Pay error when validating the merchant:', error)
          notify('Failed to validate merchant. Please try again later.', 'error')
          try {
            session.abort()
          } catch (e) {
            console.error('Apple Pay error when aborting the session:', e)
          }
        }
      }

      session.onpaymentauthorized = async (event) => {
        try {
          const payment = event.payment

          const tokenAsString = JSON.stringify(payment)
          const encodedToken = btoa(tokenAsString)

          const applePaymentData: ApplePaymentDataType = {
            payment_method_data: payment,
            token: encodedToken,
          }

          await handlePaymentSubmission(applePaymentData)

          // Payment success
          // @ts-ignore
          session.completePayment(ApplePaySession.STATUS_SUCCESS)
        } catch (error) {
          // Payment failed
          console.error('Apple Pay error when authorizing the payment:', error)
          // @ts-ignore
          session.completePayment(ApplePaySession.STATUS_FAILURE)
        }
      }

      session.begin()
    } catch (e) {
      console.error('Apple Pay error:', e)
    }
  }, [isApplePaySupported, countryIso2, paymentRequestData, orgName, projectId, doSubmitPayment, notify])

  useEffect(() => {
    if (buttonRef.current !== null) {
      buttonRef.current.addEventListener('click', handleApplePayClick)
    }

    return () => {
      if (buttonRef.current !== null) {
        buttonRef.current.removeEventListener('click', handleApplePayClick)
      }
    }
  }, [handleApplePayClick])

  const updateButtonStyle = (button?: Element | null): void => {
    const cursor = isApplePaySupported ? 'pointer' : 'not-allowed'
    const opacity = isApplePaySupported ? '1' : '0.5'
    button?.setAttribute('style', `cursor: ${cursor}; opacity: ${opacity};`)
  }

  useEffect(() => {
    if (buttonRef.current?.shadowRoot !== null || buttonRef.current?.shadowRoot !== undefined) {
      const button = buttonRef.current?.shadowRoot?.querySelector('div > button')
      updateButtonStyle(button)
    }
  }, [isApplePaySupported, buttonRef.current?.shadowRoot])

  const createApplePayButtonStyle = (style?: ApplePayButtonProps['style']): string => `
    apple-pay-button {
        --apple-pay-button-width: ${style?.width ?? '240px'};
        --apple-pay-button-height: ${style?.height ?? '40px'};
        --apple-pay-button-border-radius: ${style?.borderRadius ?? '5px'};
        --apple-pay-button-padding: ${style?.padding ?? '0px 0px'};
        --apple-pay-button-box-sizing: ${style?.boxSizing ?? 'border-box'};
        display: inline-block;
    }
  `

  const createApplePayButton = (
    buttonStyle: string,
    type: string,
    locale: string,
    buttonRef: RefObject<ElementRef<'div'>>
  ): React.ReactElement =>
    React.createElement('apple-pay-button', {
      buttonstyle: buttonStyle,
      type,
      locale,
      ref: buttonRef,
    })

  return (
    <>
      <style>{createApplePayButtonStyle(style)}</style>
      {createApplePayButton(buttonStyle, type, locale, buttonRef)}
    </>
  )
}

export default ApplePayButton
