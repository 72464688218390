import { getAccessRightsByKey, permissionsSelectors } from 'ducks/permissions'
import lodash from 'lodash'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import type { AccessRightsType } from 'types/global'
import {
  DESIGN_PERMISSION_KEYS,
  DESIGN_PERMISSION_TO_ROLE_PERMISSION_KEY_MAP,
  DesignPermissionOverrides,
  ROOT_DESIGN_PERMISSION_KEY,
} from 'types/permissions'
import { PermissionSet, PermissionType } from 'types/roles'

const getDesignPermissionsRights = ({
  rootPermissionsRights,
  rolePermission,
  overridePermission,
}: {
  rootPermissionsRights: AccessRightsType
  rolePermission?: PermissionType
  overridePermission?: PermissionType
}): AccessRightsType => {
  // We don't want to over commit to an architecture for 'permissions in studio' or add too much complexity to the permissions system, at this point in time.
  // Hence we introduce this design permission overrides system to allow Sunnova to override the default permissions system for studio.
  // This is a temporary solution until we have a more robust permissions system in place.
  if (!rolePermission && !overridePermission) {
    // fall back to the root design permissions
    return rootPermissionsRights
  }

  const aggregatedPermissions = lodash.defaultsDeep({}, overridePermission, rolePermission)

  // 1. override permission
  // 2. project permission
  // 3. fall back to root permission
  return {
    allowCreate: aggregatedPermissions?.create ?? rootPermissionsRights.allowCreate,
    allowDelete: aggregatedPermissions?.delete ?? rootPermissionsRights.allowDelete,
    allowEdit: aggregatedPermissions?.edit ?? rootPermissionsRights.allowEdit,
    allowView: aggregatedPermissions?.view ?? rootPermissionsRights.allowView,
  }
}

const getRootDesignPermissions = ({
  rolePermissions,
  overridePermissions,
}: {
  rolePermissions?: PermissionSet
  overridePermissions?: DesignPermissionOverrides
}): AccessRightsType => {
  if (overridePermissions?.design) {
    return getAccessRightsByKey({ permissions: overridePermissions, key: ROOT_DESIGN_PERMISSION_KEY })
  }
  return getAccessRightsByKey({
    permissions: rolePermissions,
    key: DESIGN_PERMISSION_TO_ROLE_PERMISSION_KEY_MAP[ROOT_DESIGN_PERMISSION_KEY],
  })
}

const useRefreshDesignPermissions = () => {
  const projectPermissionLoaded = useSelector(permissionsSelectors.getProjectPermissionsLoaded)
  const projectPermissions = useSelector(permissionsSelectors.getSafeProjectPermissionsSetting)
  const designPermissionsOverrides = useSelector(permissionsSelectors.getDesignPermissionOverrides)

  useEffect(() => {
    if (projectPermissionLoaded === false) {
      // set all permissions to false if project permissions are not loaded
      window.Designer.permissions.setRootPermissions(false, false, false, false)
      DESIGN_PERMISSION_KEYS.forEach((key) => {
        window.Designer.permissions.updatePermissionsByKeyString(key, false, false, false, false)
      })
    } else {
      const designRootPermissionsRights = getRootDesignPermissions({
        rolePermissions: projectPermissions,
        overridePermissions: designPermissionsOverrides,
      })

      window.Designer.permissions.setRootPermissions(
        designRootPermissionsRights.allowCreate,
        designRootPermissionsRights.allowDelete,
        designRootPermissionsRights.allowEdit,
        designRootPermissionsRights.allowView
      )

      DESIGN_PERMISSION_KEYS.forEach((designPermissionKey) => {
        const rolePermissionKey = DESIGN_PERMISSION_TO_ROLE_PERMISSION_KEY_MAP[designPermissionKey]
        const permissions = getDesignPermissionsRights({
          rootPermissionsRights: designRootPermissionsRights,
          rolePermission: projectPermissions && rolePermissionKey && projectPermissions[rolePermissionKey],
          overridePermission: designPermissionsOverrides[designPermissionKey],
        })
        window.Designer.permissions.updatePermissionsByKeyString(
          designPermissionKey,
          permissions.allowCreate,
          permissions.allowDelete,
          permissions.allowEdit,
          permissions.allowView
        )
      })
    }
  }, [projectPermissions, designPermissionsOverrides, projectPermissionLoaded])
}

export default useRefreshDesignPermissions
