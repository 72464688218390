import _ from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { StudioSignalTypes } from 'types/studio/signals'
import { applyStudioSignals, removeStudioSignals } from './utils'

export interface UseStudioSignalsOpts {
  debounce?: number
  maxWait?: number
  deps?: unknown[]
  trackHandler?: boolean
}

export function useStudioSignals(
  handler: Function,
  signals: StudioSignalTypes[],
  scope?: object,
  opts?: UseStudioSignalsOpts
) {
  const mounted = useRef(true)
  const handlerRef = useRef(handler)
  useEffect(() => {
    handlerRef.current = handler
  }, [opts?.trackHandler ? handler : ''])

  const debounce = (opts?.debounce || 0) * 1000
  const maxWait = (opts?.maxWait || 0) * 1000
  const handlerMemo = useMemo<() => void>(() => {
    let ret = function () {
      if (!mounted.current) {
        console.debug('skipping handler called after unmount')
        return
      }
      handlerRef.current.apply(scope, arguments)
    }
    if (debounce > 0)
      ret = _.debounce(ret, debounce, {
        leading: false,
        trailing: true,
        maxWait,
      })
    return ret
  }, [debounce, maxWait, scope])

  useEffect(() => {
    applyStudioSignals(signals, handlerMemo)

    return () => {
      mounted.current = false
      removeStudioSignals(signals, handlerMemo)
    }
  }, [signals.join(' '), handlerMemo])
}
