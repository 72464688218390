import { Chip, styled } from 'opensolar-ui'
import { FC } from 'react'
import { WorkflowStageType } from 'types/workflows'
import { ColorGroup } from './StageColumns'

const Container = styled('div', { name: 'Container' })<{
  borderColor: string
}>(({ borderColor, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  gap: 10,
  padding: 15,
  fontWeight: 500,
  color: theme.palette.grey[900],
  borderRadius: 4,
  borderTop: '4px solid ' + borderColor,
}))

const StageCounter = styled(Chip, { name: 'StageCounter' })<{
  backgroundColor: string
  colorMain: string
}>(({ backgroundColor, colorMain }) => ({
  padding: '0 3px',
  backgroundColor,
  color: colorMain,
}))

interface PropTypes {
  colors: ColorGroup
  count: number
}

const StageCard: FC<PropTypes & WorkflowStageType> = ({ colors, title, count }) => {
  return (
    <Container borderColor={colors?.main}>
      {title}
      <StageCounter backgroundColor={colors?.secondary} colorMain={colors?.main} label={count} variant="rounded" />
    </Container>
  )
}

export default StageCard
