import { orgSelectors } from 'ducks/orgs'
import React, { useMemo } from 'react'
import { SelectArrayInput } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { getHasPaymentCap, showCreditCardTooltipCap } from '../utils'
import CreditCardCapTooltip from './CreditCardCapTooltip'

type PropTypes = {
  paymentIndex: number
  paymentMethods: { id: string; name: string; max_payment_amount: number }[]
  selectedPaymentMethods: any
}

const useStyles = makeOpenSolarStyles((theme) => ({
  fieldRow: {
    margin: '5px 0',
  },
}))

const AcceptedPaymentMethodsField: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const orgCountry = useSelector(orgSelectors.getOrgIso2)

  const paymentMethodsLabel = useMemo(() => {
    if (props.paymentMethods.length > 0) {
      const hasPaymentCap = getHasPaymentCap(props.paymentMethods, orgCountry)

      if (props.selectedPaymentMethods && showCreditCardTooltipCap(props.selectedPaymentMethods, hasPaymentCap)) {
        return <CreditCardCapTooltip label="Accepted Payment Methods" />
      }
    }
    return 'Accepted Payment Methods'
  }, [props.selectedPaymentMethods, props.paymentMethods])

  return (
    <SelectArrayInput
      label={paymentMethodsLabel}
      source={`payment_milestone_configurations[${props.paymentIndex}].accepted_payment_methods`}
      choices={props.paymentMethods}
      fullWidth={true}
      className={classes.fieldRow}
    />
  )
}

export default AcceptedPaymentMethodsField
