import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { AddOutlined, ChevronRight } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import { Button } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import restClient from 'restClient'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import BlueSnapOnboardingFormContents from './BlueSnapOnboardingFormContents'
import routes from './onboardingFormRoutes'
import { FieldPrefixFilterType, OnboardingFormPageInfoType, PSPOnboardingFieldsMapType } from './types'
import { setValidationError } from './utils'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeOpenSolarStyles((theme) => ({
  icon: {
    top: 15,
    right: 10,
    position: 'absolute',
    minWidth: 40,
    backgroundColor: theme.headerBackgroundColor,
  },
  closeIcon: {
    color: '#B4B4B4',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    padding: '0px 10px',
  },
  backButtonBox: {
    padding: '10px 0',
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  continueButtonsBox: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonWrapper: {
    padding: '10px 0 15px 10px',
  },
  submitButton: {
    width: '100%',
    fontSize: 13,
  },
  cancelButton: {
    width: '100%',
    fontSize: 13,
    color: '#4272DD',
    border: '1px solid #4272DD !important',
  },
  addButton: {
    background: '#4272DD',
    color: theme.white,
    fontSize: 13,
    border: '1px solid #4272DD',

    '&:hover': {
      background: '#4272DD !important',
      color: theme.white,
      fontSize: 13,
      border: '1px solid #4272DD',
    },
  },
  spinner: {},
}))

const useDialogStyles = makeStyles((theme) => ({
  root: {
    '&.MuiDialog-root': {
      zIndex: '10 !important',
    },
  },
}))

type PropTypes = {
  initialPageNumber: number
  fieldMap: PSPOnboardingFieldsMapType
  initialValues: object
  closeDialog: () => void
}

const BlueSnapOnboardingForm: React.FC<PropTypes> = (props) => {
  const getInitialPageInfo = useCallback(() => {
    if (props.initialPageNumber) {
      let matchedOnPageNum = Object.values(routes)?.find((route) => route.fieldPageNumber === props.initialPageNumber)
      if (matchedOnPageNum) return matchedOnPageNum
    }
    return routes.intro
  }, [routes, props.initialPageNumber])

  const [pageInfo, setPageInfo] = useState<OnboardingFormPageInfoType>(getInitialPageInfo())
  const [errorPage, setErrorPage] = useState<number>()
  const [fieldPrefixFilter, setFieldPrefixFilter] = useState<FieldPrefixFilterType>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showCloseConfirmation, setShowCloseConfirmation] = useState<boolean>(false)

  const org = useSelector(orgSelectors.getOrg)
  const notify = useNotify()
  const translate = useTranslate()
  const classes = useStyles()
  const dialogClasses = useDialogStyles()
  const formState = useFormState()
  const form = useForm()
  const history = useHistory()

  useEffect(() => {
    if (pageInfo) {
      let pageNum: number | null = null
      Object.values(routes)?.forEach((route, i) => {
        if (route.sectionName === pageInfo.sectionName) {
          pageNum = i + 1
        }
      })
      logAmplitudeEvent('psp_onboarding_page_viewed', {
        page_title: pageInfo.sectionName,
        page_number: pageNum,
        org_country: org?.country?.iso2,
      })
    }

    if (pageInfo.nextKey === 'verifyBusiness') {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${org?.id}/registrations_started/`,
      })
    }
  }, [pageInfo])

  const hasAtLeastOneContact = useMemo(() => {
    if (formState.values?.companyRep) return true
    else if (formState.values?.ownershipInfoArr?.length) return true
    return false
  }, [formState.values?.companyRep, formState.values?.ownershipInfoArr])

  const goToNextPage = useCallback(() => {
    let nextPageInfo = pageInfo.nextKey ? routes[pageInfo.nextKey] : getInitialPageInfo()

    // if they are leaving the contact section of the app we need to do some quick validation to ensure we have a company rep
    // this validation happens on the back-end as well but not until the app is being submitted
    if (pageInfo.nextKey === 'bankInformation') {
      if (!formState.values?.companyRep || !Object.keys(formState.values?.companyRep).length) {
        if (formState?.values?.ownershipInfoArr?.length) {
          notify(translate('Please mark one of the owners as the company representative before continuing.'), 'warning')
        } else {
          notify(translate('Please add a company representative before continuing.'), 'warning')
        }
        return
      }
    }

    // if the next page is the decisionDisplay then we need to submit the form so we can await BlueSnap's decision
    if (nextPageInfo?.nextKey === null) {
      setIsLoading(true)
      const requestStart = new Date()
      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${org?.id}/bluesnap/create_account/`,
        data: {
          form_vals: formState.values,
        },
      })
        .then((res) => {
          const requestEnd = new Date()
          let secondsToOpen = 'error'
          try {
            // @ts-ignore
            secondsToOpen = `${((requestEnd - requestStart) / 1000).toFixed(0)}`
          } catch (ex) {}
          logAmplitudeEvent('psp_onboarding_application_submitted', {
            org_id: org?.id,
            org_name: org?.name,
            seconds_to_resolve: secondsToOpen,
          })
          setPageInfo(nextPageInfo)
        })
        .catch((err) => {
          let isTimeOut = err.status === 504
          try {
            const requestEnd = new Date()
            // @ts-ignore
            let secondsToOpen = (requestEnd - requestStart) / 1000
            if (!isTimeOut && secondsToOpen > 29) {
              isTimeOut = true
            }
          } catch (ex) {}
          if (isTimeOut) {
            logAmplitudeEvent('psp_onboarding_application_timeout_hit', { org_id: org?.id, org_name: org?.name })
            setPageInfo(nextPageInfo)
          } else if (err?.body?.message) {
            notify(err.body.message, 'warning')
            logAmplitudeEvent('psp_onboarding_submit_error', { error_message: err.body.message })
          }
        })
        .finally(() => setIsLoading(false))
    } else {
      setPageInfo(nextPageInfo)
    }
  }, [routes, pageInfo, props.fieldMap, formState.values])

  const goBack = useCallback(() => {
    setFieldPrefixFilter(null)
    let prevPageInfo = pageInfo.prevKey ? routes[pageInfo.prevKey] : pageInfo
    setPageInfo(prevPageInfo)
  }, [pageInfo])

  const onPageSubmit = useCallback(
    (closeAfterSave?: boolean) => {
      if (pageInfo.hasFormFields && pageInfo.fieldPageNumber) {
        restClientInstance('CUSTOM_POST', 'custom', {
          url: `orgs/${org?.id}/bluesnap/onboarding/save_progress/`,
          data: {
            form_vals: formState.values,
            page_number: pageInfo.fieldPageNumber,
          },
        })
          .then((res) => {
            if (res?.data?.success) {
              if (closeAfterSave) {
                notify(
                  "Your progress has been saved. You can pick up where you left off when you're ready to finish.",
                  'success'
                )
                history.push('/')
              } else {
                goToNextPage()
              }
              setFieldPrefixFilter(null)
              setValidationError('')
            }
          })
          .catch((err) => {
            if (!errorPage || (pageInfo.fieldPageNumber !== null && pageInfo.fieldPageNumber > errorPage)) {
              pageInfo.fieldPageNumber && setErrorPage(pageInfo.fieldPageNumber)
            }
            // if there's a validation error and the current page # is less than the error page, move to the next page.
            if (errorPage && pageInfo.fieldPageNumber !== null && pageInfo.fieldPageNumber < errorPage) {
              goToNextPage()
            } else {
              setValidationError(err?.body?.message)
              notify(err?.body?.message, 'warning')
              logAmplitudeEvent('psp_onboarding_next_page_error', { error_message: err?.body?.message })
            }
          })
      } else {
        goToNextPage()
      }
    },
    [goToNextPage, pageInfo, props.fieldMap, formState.values, errorPage]
  )

  const navigateToPage = (routeKey: string) => {
    if (routes?.[routeKey]) setPageInfo(routes[routeKey])
  }

  const saveAndContinue = () => {
    onPageSubmit(true)
  }

  const cancel = () => {
    navigateToPage('controlDescription')
    if (fieldPrefixFilter) form.change(fieldPrefixFilter, {})
  }

  const closeDialog = () => {
    if (isLoading) {
      props.closeDialog()
    } else {
      if (pageInfo.nextKey) {
        setShowCloseConfirmation(true)
      } else {
        props.closeDialog()
      }
    }
  }

  return (
    <>
      <Dialog open={true} onClose={closeDialog} maxWidth="lg" classes={dialogClasses}>
        <DialogTitle>
          <div>
            {pageInfo?.title?.component ? (
              <pageInfo.title.component />
            ) : (
              pageInfo?.sectionName !== 'decisionDisplay' && <div>{translate('Activate CashFlow')}</div>
            )}
            {/* <IconButton className={classes.icon} onClick={closeDialog} disabled={isLoading}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton> */}
          </div>
        </DialogTitle>
        <DialogContent>
          <div data-testid="bluesnap-onboarding-form">
            <BlueSnapOnboardingFormContents
              {...props}
              pageInfo={pageInfo}
              onPageSubmit={onPageSubmit}
              goBack={goBack}
              navigateToPage={navigateToPage}
              fieldPrefixFilter={fieldPrefixFilter}
              setFieldPrefixFilter={setFieldPrefixFilter}
              closeDialog={closeDialog}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.buttonRow}>
            <div className={classes.backButtonBox}>
              <div>
                {!!pageInfo.prevKey && (
                  <Button disabled={isLoading} onClick={goBack}>
                    {translate('Go Back')}
                  </Button>
                )}
              </div>
            </div>
            <div className={classes.continueButtonsBox}>
              {pageInfo.showSaveAndContinueLater && (
                <div className={classes.buttonWrapper}>
                  <Button onClick={saveAndContinue} className={classes.submitButton} variant="outlined" color="primary">
                    {translate('Save & Continue Later')}
                  </Button>
                </div>
              )}
              {pageInfo.showCancelButton && fieldPrefixFilter !== null && (
                <div className={classes.buttonWrapper}>
                  <Button
                    onClick={cancel}
                    className={classes.cancelButton}
                    variant="outlined"
                    color="inherit"
                    disabled={isLoading}
                  >
                    {translate('Cancel')}
                  </Button>
                </div>
              )}

              {pageInfo.showAddButton && (
                <div className={classes.buttonWrapper}>
                  <Button
                    onClick={() => onPageSubmit()}
                    className={classes.addButton}
                    variant="outlined"
                    color="inherit"
                    disabled={fieldPrefixFilter === null}
                  >
                    <AddOutlined /> {translate('Save')}
                  </Button>
                </div>
              )}
              {pageInfo?.showNextButton && (
                <div className={classes.buttonWrapper} data-testid="bluesnap-next-page-btn-wrapper">
                  <Button
                    onClick={() => onPageSubmit()}
                    variant={pageInfo.nextButtonVariant || 'contained'}
                    color={pageInfo.nextButtonColor || 'primary'}
                    className={classes.submitButton}
                    disabled={isLoading}
                    data-testid="bluesnap-next-page-btn"
                  >
                    {isLoading ? (
                      <div style={{ position: 'absolute', left: 'calc(50% - 12px)', top: 'calc(50% - 12px)' }}>
                        <CircularProgress classes={{ colorPrimary: classes.spinner }} size={24} />
                      </div>
                    ) : (
                      <>
                        {pageInfo.nextButtonLabel ? translate(pageInfo.nextButtonLabel) : translate('Next')}
                        {pageInfo?.showChevronInNextButton ? <ChevronRight /> : null}
                      </>
                    )}
                  </Button>
                </div>
              )}
              {/* If the user is on controlDescription and they've already given us a contact they should be able to move forward without editing/adding a contact */}
              {pageInfo.nextKey === 'personInformation' && hasAtLeastOneContact && (
                <div className={classes.buttonWrapper}>
                  <Button
                    onClick={() => navigateToPage('bankInformation')}
                    variant={'contained'}
                    color={'primary'}
                    className={classes.submitButton}
                  >
                    {translate('Next')}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
      {/* {showCloseConfirmation && (
        <Dialog open={showCloseConfirmation} onClose={() => setShowCloseConfirmation(false)}>
          <DialogTitle>Exit Application?</DialogTitle>
          <DialogContent>
            <p>
              Are you sure you want to close this application for CashFlow? Exiting now may result in needing to
              re-enter some data when you continue later.
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.closeDialog()} variant="contained" color="default">
              Yes, Exit Application
            </Button>
            <Button onClick={() => setShowCloseConfirmation(false)} variant="contained" color="primary">
              No, Continue with Application
            </Button>
          </DialogActions>
        </Dialog>
      )} */}
    </>
  )
}

const BlueSnapOnbordingFormWrapper: React.FC<PropTypes> = (props) => {
  // this function isn't called since the buttons in the dialog have their own functions, but we need it to keep the Form component happy
  const onSubmit = () => {}
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={props.initialValues}
      render={(formProps) => <BlueSnapOnboardingForm {...formProps} {...props} />}
    />
  )
}
export default BlueSnapOnbordingFormWrapper
