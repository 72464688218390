import { CircularProgress, makeStyles } from '@material-ui/core'
import { PaymentExtraFields, PaymentRequestType, PaymentStaticCopy } from '../types'
import ApplePayButton from './ApplePayButton'
import GooglePayButton from './GooglePayButton'

type PaymentProps = {
  paymentRequestData: PaymentRequestType
  projectId: string
  countryIso2: string
  orgName: string
  paymentStaticCopy: PaymentStaticCopy
  showGooglePay?: boolean
  showApplePay?: boolean | null
  doSubmitPayment: (args: PaymentExtraFields) => void
  isSubmitting: boolean
}

const useStyles = makeStyles({
  buttonStyle: {
    display: 'flex',
    flexDirection: 'row' as const,
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  buttonWrapper: {
    width: '240px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  singleButtonWrapper: {
    width: '240px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
})

const WalletButtons: React.FC<PaymentProps> = (props) => {
  const classes = useStyles()
  const showApple = props.showApplePay
  const showGoogle = props.showGooglePay

  const wrapperClass = !showApple || !showGoogle ? classes.singleButtonWrapper : classes.buttonWrapper

  return (
    <>
      {props.isSubmitting ? (
        <CircularProgress size={24} />
      ) : (
        <div className={classes.buttonStyle} data-testid="wallet-buttons">
          {showGoogle && (
            <div className={wrapperClass} data-testid="button-wrapper">
              <GooglePayButton {...props} />
            </div>
          )}
          {showApple && (
            <div className={wrapperClass} data-testid="button-wrapper">
              <ApplePayButton {...props} />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default WalletButtons
