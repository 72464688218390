//TODO: break this component down into smaller components
import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { orgSelectors } from 'ducks/orgs'
import ComponentOverviewIcon from 'elements/hardwareSelector/ComponentOverview'
import ListResultNotFound from 'elements/list/ListResultNotFound'
import SelectableCard from 'elements/SelectableCard'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Box, Button, Chip, ComponentVersionsInherit, DatasheetIcon, Typography } from 'opensolar-ui'
import useAvailableSponsoredContents from 'persistentContent/inAppPromo/hooks/useAvailableSponsoredContents'
import type { SponsoredContentType } from 'persistentContent/inAppPromo/types'
import { useTranslate } from 'ra-core'
import React, { MouseEvent, useCallback, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { currencySymbolForCountry, formatDecimalPlaces } from 'util/misc'
import BatterySalesAssistantContainer from '../components/BatterySalesAssistantContainer'
import useRecommendedBatterySets from '../hooks/useRecommendedBatterySets'
import useSelectBatteryDesignAssistant from '../hooks/useSelectBatteryDesignAssistant'
import { BSA_BACKUP_APPLIANCES_NODE_KEY, BSA_MODE_NODE_KEY } from '../utility/constants'
import { BatterySalesAssistantFlowNodeComponentStandardPropsType, RecommendBatterySetType } from '../utility/types'

const useBatteryCardStyles = makeOpenSolarStyles(() => ({
  sponsoredChip: {
    left: '8px',
    position: 'absolute',
    top: '8px',
  },
  manufacturerLogo: {
    height: '32px',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  detailsLink: {
    '& p': {
      textDecoration: 'underline',
    },
  },
}))

const useRecommendedBatteryDetails = () => {
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = useMemo(() => currencySymbolForCountry(countryIso2), [countryIso2])
  const translate = useTranslate()

  return useCallback((batterySet: RecommendBatterySetType) => {
    const [batteryComponent] = batterySet.batteries

    const technicalDetails: { [key: string]: string } = {
      // Price: `${currencySymbol}${batterySet.price}`,
      Price: batterySet.price ? `${currencySymbol}${batterySet.price}` : '',
      Rating: `${formatDecimalPlaces(batterySet.total_usable_capacity, 2)} ${translate('kWh')}`,
      // Warranty: `${batterySet.warranty} years`,
      Warranty: batterySet.warranty_for_set ? `${batterySet.warranty_for_set} ${translate('years')}` : '',
    }

    const productDetails = {
      manufacturer: batteryComponent.manufacturer_name,
      product_name: batteryComponent.code,
      savings: batterySet.estimated_extra_first_year_savings,
      rating: technicalDetails.Rating,
      price: technicalDetails.Price,
      warranty: technicalDetails.Warranty,
    }

    return { technicalDetails, productDetails }
  }, [])
}

const BatteryCard = ({
  batterySet,
  sponsoredContent,
}: {
  batterySet: RecommendBatterySetType
  sponsoredContent?: SponsoredContentType
}) => {
  const [isBatteryLogoBroken, setIsBatteryLogoBroken] = useState(false)

  const classes = useBatteryCardStyles()
  const translate = useTranslate()

  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = useMemo(() => currencySymbolForCountry(countryIso2), [countryIso2])
  const [batteryComponent] = batterySet.batteries
  const isSponsored = batterySet.show_is_sponsored

  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.8,
    onChange: (inView) => {
      if (inView && isSponsored) {
        if (!sponsoredContent) {
          Sentry.captureException(new Error('error: sponsoredContent is undefined for sponsored battery'))
        }
        var eventParameters = {
          campaign_name: sponsoredContent ? sponsoredContent.campaign_name : 'unknown',
          creative_name: sponsoredContent ? sponsoredContent.creative_name : 'unknown',
        }
        logAmplitudeEvent('ad_impression', eventParameters)
      }
    },
  })

  const getBatteryDetails = useRecommendedBatteryDetails()
  const { productDetails, technicalDetails } = getBatteryDetails(batterySet)

  const handleSeeDetails = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      flex={1}
      flexDirection="column"
      gridGap={16}
      marginTop={isSponsored ? 3 : 0}
    >
      {isSponsored && (
        <div className={classes.sponsoredChip} ref={ref}>
          <ComponentVersionsInherit versions={ComponentVersions_3_0}>
            <Chip label="Sponsored" color="info" />
          </ComponentVersionsInherit>
        </div>
      )}
      <Box alignItems="center" display="flex" flexDirection="column" gridGap={8}>
        {batterySet.logo && !isBatteryLogoBroken ? (
          <img
            alt={batteryComponent.manufacturer_name}
            className={classes.manufacturerLogo}
            onError={() => setIsBatteryLogoBroken(true)}
            src={batterySet.logo}
          />
        ) : (
          <Typography colorHex="#0A090B" textVariant="h4">
            {batteryComponent.manufacturer_name}
          </Typography>
        )}
        <Box alignItems="center" display="flex" gridGap={8} justifyContent="center" textAlign="center">
          <Typography textVariant="h5">
            ({batteryComponent.quantity}) {batteryComponent.code}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography
          align="center"
          colorHex="#018030"
          textVariant="h3"
        >{`${currencySymbol}${batterySet.estimated_extra_first_year_savings}`}</Typography>
        <Typography align="center" colorHex="#757575" textVariant="body2">
          {translate('Year 1 Savings')}
        </Typography>
      </Box>
      <Box display="flex" gridGap={32} width="100%">
        {Object.entries(technicalDetails).map(
          ([technicalDetailLabel, technicalDetail]) =>
            technicalDetail && (
              <Box key={technicalDetailLabel} display="flex" flex={1} flexDirection="column" textAlign="center">
                <Typography colorHex="#000" textVariant="subtitle1">
                  {technicalDetail}
                </Typography>
                <Typography colorHex="#757575" textVariant="body1">
                  {technicalDetailLabel}
                </Typography>
              </Box>
            )
        )}
      </Box>
      <Box margin="-6px 0px">
        <ComponentOverviewIcon
          // @ts-ignore
          component={batteryComponent}
          customIcon={
            <Button classes={{ root: classes.detailsLink }} onClick={handleSeeDetails}>
              <Box alignItems="center" display="flex" gridGap={8}>
                <DatasheetIcon fill="#0094FF" height="24" width="24" />
                <Typography colorHex="#0094FF" textVariant="body1">
                  {translate('See details')}
                </Typography>
              </Box>
            </Button>
          }
        />
      </Box>
    </Box>
  )
}

interface BSASelectorProps extends BatterySalesAssistantFlowNodeComponentStandardPropsType {}

const BSASelectorNode: React.FC<BSASelectorProps> = ({ flowController, flowStore }) => {
  const [selectedBatterySetId, setSelectedBatterySetId] = useState<string | undefined>()
  const { availableConfigs, promosLoadingState, sponsoredContents } = useAvailableSponsoredContents()

  const { isLoading, recommendedBatterySets } = useRecommendedBatterySets({
    flowStore,
    sponsoredContents,
    promosLoadingState,
  })

  const selectBatterySet = useSelectBatteryDesignAssistant({ onClose: flowStore.onFinish })
  const getBatteryDetails = useRecommendedBatteryDetails()

  const translate = useTranslate()

  const handleBack = () => {
    if (flowStore.limitedCodes && flowStore.limitedCodes.length === 1) {
      flowController.goTo({
        title: translate('Add Battery'),
        currentNodeKey: BSA_MODE_NODE_KEY,
        options: {},
      })
    } else {
      flowController.goTo({
        currentNodeKey: BSA_BACKUP_APPLIANCES_NODE_KEY,
        options: {},
      })
    }
  }

  const handleSelect = (v) => {
    setSelectedBatterySetId((prevBatteryId) => {
      let batterySetIndex
      const batterySet = recommendedBatterySets.find(({ id }, index) => {
        if (id === v) batterySetIndex = index
        return id === v
      })
      const prevBatterySet = recommendedBatterySets.find(({ id }) => id === prevBatteryId)

      if (batterySet) {
        const previousSelectedOption = prevBatterySet
          ? getBatteryDetails(prevBatterySet)?.productDetails?.product_name
          : null
        const { productDetails } = getBatteryDetails(batterySet)

        logAmplitudeEvent('bda_product_card_selected', {
          product_details: JSON.stringify(productDetails),
          previous_selected_option: previousSelectedOption || null,
          sponsored_product: batterySet.show_is_sponsored ? 'yes' : 'no',
          product_card_position: batterySetIndex,
        })
      }

      return v as string
    })
  }

  const handleFinish = () => {
    let batterySetIndex
    const recommendedBatterySet = recommendedBatterySets.find(({ id }, index) => {
      if (id === selectedBatterySetId) batterySetIndex = index
      return id === selectedBatterySetId
    })

    if (recommendedBatterySet) {
      const [batteryComponent] = recommendedBatterySet.batteries
      const { productDetails } = getBatteryDetails(recommendedBatterySet)
      const sponsoredContentConfig = sponsoredContents.find((sponsoredContent) =>
        sponsoredContent.component_codes?.includes(batteryComponent.code)
      )

      logAmplitudeEvent('bda_add_battery_clicked', {
        product_added: JSON.stringify(productDetails),
        sponsored_product: recommendedBatterySet.show_is_sponsored ? 'yes' : 'no',
        product_card_position: batterySetIndex,
      })

      if (sponsoredContentConfig) {
        var eventParameters = {
          campaign_name: sponsoredContentConfig.campaign_name,
          creative_name: sponsoredContentConfig.creative_name,
        }
        logAmplitudeEvent('ad_click', eventParameters)
      }

      selectBatterySet({
        systemUuid: window.editor.selectedSystem.uuid,
        componentsSet: recommendedBatterySet.batteries,
        customerPriority: flowStore.customerPriority,
        essentialBackupMetrics: flowStore.essentialBackupMetrics,
      })
    }
  }

  const renderResults = () => {
    if (recommendedBatterySets.length === 0) {
      return <ListResultNotFound />
    }
    return (
      <Box display="grid" gridGap="16px 24px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
        {recommendedBatterySets.map((batterySet) => {
          const { id: batterySetId } = batterySet

          return (
            <SelectableCard
              alignItems="center"
              cardKey={batterySetId as string}
              isSelected={selectedBatterySetId === batterySetId}
              key={batterySetId}
              onSelect={handleSelect}
              render={
                <BatteryCard
                  batterySet={batterySet}
                  sponsoredContent={sponsoredContents.find((config) =>
                    config.component_codes.includes(batterySet.batteries[0]?.code)
                  )}
                />
              }
            />
          )
        })}
      </Box>
    )
  }

  return (
    <BatterySalesAssistantContainer
      footerActions={
        <>
          <Button color="default" onClick={handleBack} variant="contained">
            {translate('Back')}
          </Button>
          <Button disabled={!selectedBatterySetId} color="primary" variant="contained" onClick={handleFinish}>
            {translate('Add Battery')}
          </Button>
        </>
      }
      isAdBannerShown
      title={translate('Battery sets shown are best matched to project and client requirements.')}
    >
      {isLoading ? (
        <Box display="flex" justifyContent="center" margin="24px 0px">
          <LoadingDots />
        </Box>
      ) : (
        renderResults()
      )}
    </BatterySalesAssistantContainer>
  )
}

export default BSASelectorNode
