import { Grid } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import { BooleanInput, NumberInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import useStyle from './styles'

const SelfGenerationPBIInputs = () => {
  const formState = useFormState()
  const classes = useStyle()
  const isEnabled = formState.values.is_paid_pbi

  return (
    <div className={Boolean(isEnabled) ? classes.containerWithBoxShadow : classes.container}>
      <BooleanInput className={classes.toggleField} label="Is Paid PBI" name={'is_paid_pbi'} source="is_paid_pbi" />
      <DependentInput dependsOn={'is_paid_pbi'} value={true}>
        <Grid container direction="row">
          <Grid item xs={12} lg={6} xl={3}>
            <CustomField
              className={classes.defaultFieldStyle}
              label="Base Incentive Paid in PBI"
              name={'percentage_paid_in_pbi'}
              source="percentage_paid_in_pbi"
              endAdornment="%"
              component={NumberInput}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <CustomField
              className={classes.defaultFieldStyle}
              label="Maximum PBI period"
              name={'max_pbi_years'}
              source="max_pbi_years"
              endAdornment="years"
              component={NumberInput}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <CustomField
              className={classes.defaultFieldStyle}
              label="Full Battery Discharges"
              name={'full_discharges_per_year'}
              endAdornment="per year"
              source="full_discharges_per_year"
              component={NumberInput}
            />
          </Grid>
          <Grid item xs={12} lg={6} xl={3}>
            <BooleanInput
              className={classes.toggleField}
              label="Use non discounted value in quotation"
              name={'use_non_discounted_value_in_quotation'}
              source="use_non_discounted_value_in_quotation"
            />
          </Grid>
        </Grid>
      </DependentInput>
    </div>
  )
}

export default SelfGenerationPBIInputs
