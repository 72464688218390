import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import React from 'react'
import { BooleanInput, NumberInput, SelectInput, useTranslate } from 'react-admin'
import {
  CREDIT_ROLLOVER_TYPES_CHOICES,
  isCalifornianNEM3Utility,
  NEM_TRUE_UP_DATE_CHOICES,
} from 'resources/utilityTariffs/common'
import { useStyles } from 'resources/utilityTariffs/styles'
import { InputWithToolTip } from 'resources/utilityTariffs/TariffInputs'

type PropTypes = {
  solarCompensationMechanism: string
  currencySymbol: string
  countryCode: string
  simpleFlatInput: boolean
  source: string
  disabled?: boolean
}

const CustomInputs: React.FC<PropTypes> = ({
  solarCompensationMechanism,
  currencySymbol,
  countryCode,
  simpleFlatInput,
  source,
  disabled,
}) => {
  const inputFields = inputFieldsBySolarCompensation(
    solarCompensationMechanism,
    currencySymbol,
    countryCode,
    simpleFlatInput,
    source
  )

  return inputFields.map((field) => {
    if (field.dependsOn) {
      return (
        <DependentInput dependsOn={field.dependsOn.source} resolve={field.dependsOn.resolve}>
          <InputFieldByType fieldAttributes={{ ...field, disabled: disabled }} />
        </DependentInput>
      )
    } else {
      return <InputFieldByType fieldAttributes={{ ...field, disabled: disabled }} />
    }
  })
}

const nemTrueUpDateFieldAttributes = (source) => {
  return {
    inputType: 'select',
    name: `${source}.nem_true_up_date`,
    source: `${source}.nem_true_up_date`,
    label: 'Credit True-up Date',
    choices: NEM_TRUE_UP_DATE_CHOICES,
    defaultValue: 'annually_in_month_11',
    dependsOn: false,
    toolTip: 'The frequency or period in which carryover export credits are reset back to 0.',
  }
}

const netSurplusCompensationFieldAttributes = (source, currencySymbol) => {
  return {
    inputType: 'number',
    name: `${source}.net_surplus_compensation`,
    source: `${source}.net_surplus_compensation`,
    label: 'Net Surplus Compensation (Optional)',
    startAdornment: currencySymbol,
    endAdornment: 'per kwh',
    defaultValue: 0,
    dependsOn: false,
    countryCodeFilter: ['US', 'BD', 'IN', 'ID', 'KR', 'LK', 'MM', 'MY', 'PH', 'TH', 'ZA', 'CA'],
    toolTip:
      "Compensation for electricity produced in excess of their on-site load over the course of the 'Credit True-up' period.",
  }
}

const nonBypassableChargesFieldAttributes = (source, currencySymbol) => {
  return {
    inputType: 'number',
    name: `${source}.non_bypassable_charges`,
    source: `${source}.non_bypassable_charges`,
    label: 'Non Bypassable Charges',
    startAdornment: currencySymbol,
    endAdornment: 'per kwh',
    defaultValue: 0,
    dependsOn: false,
    countryCodeFilter: ['US'],
    toolTip:
      'Charges that must be paid by the customer for every kWh of electricity they buy (import) from the grid. This charge cannot be offset by generation credits.',
  }
}

const inputFieldsBySolarCompensation = (
  solarCompensationMechanism,
  currencySymbol,
  countryCode,
  simpleFlatInput,
  source
) => {
  const inputFields = {
    'Net Metering': [
      nemTrueUpDateFieldAttributes(source),
      {
        inputType: 'select',
        name: `${source}.credit_rollover_type`,
        source: `${source}.credit_rollover_type`,
        label: 'Credit Rollover Type',
        choices: CREDIT_ROLLOVER_TYPES_CHOICES,
        defaultValue: CREDIT_ROLLOVER_TYPES_CHOICES[0].id,
        dependsOn: false,
        hideOnSimpleInput: true,
        toolTip: 'Determines how unused excess credits are carried over from each billing cycle.',
      },
      {
        inputType: 'boolean',
        name: `${source}.nem_bill_settled_annually`,
        source: `${source}.nem_bill_settled_annually`,
        label: 'NEM Bill Settled Annually',
        defaultValue: false,
        dependsOn: {
          source: 'tariffData',
          resolve: (value) => value?.solar_compensation_settings?.nem_true_up_date?.includes('annually_in_month_'),
        },
        toolTip:
          'Set to true if the total energy bill is paid once annually at some pre-determine bill settlement date.',
      },
      {
        inputType: 'number',
        name: `${source}.nem_credit_percentage_full_rate`,
        source: `${source}.nem_credit_percentage_full_rate`,
        label: 'NEM Credit % of Retail Rate',
        endAdornment: '%',
        defaultValue: 100,
        dependsOn: {
          source: 'tariffData',
          resolve: (value) => value?.solar_compensation_settings?.credit_rollover_type === 'dollar_rollover',
        },
        hideOnSimpleInput: true,
        toolTip: 'The percentage of the full retail rate that each kWh of excess generation sold to the grid is worth.',
      },
      {
        inputType: 'number',
        name: `${source}.nem_duration_in_years`,
        source: `${source}.nem_duration_in_years`,
        label: 'NEM Duration (Years)',
        endAdornment: 'year',
        defaultValue: 20,
        hideOnSimpleInput: true,
        toolTip:
          'Number of years customers can remain on Net Energy Metering (NEM) before switching over to Net Billing with Credit Carryover.',
      },
      {
        inputType: 'number',
        name: `${source}.export_rate_when_nem_ends`,
        source: `${source}.export_rate_when_nem_ends`,
        label: 'Export Rate after NEM Ends',
        startAdornment: currencySymbol,
        endAdornment: 'per kWh',
        defaultValue: 0,
        hideOnSimpleInput: true,
        toolTip: 'The credit value for each kWh sold (exported) back to the grid after Net Energy Metering (NEM) ends.',
      },
      netSurplusCompensationFieldAttributes(source, currencySymbol),
      nonBypassableChargesFieldAttributes(source, currencySymbol),
    ],
    virtual_storage: [
      {
        inputType: 'boolean',
        name: `${source}.annual_rollover_enabled`,
        source: `${source}.annual_rollover_enabled`,
        label: 'Annual Rollover Enabled',
        defaultValue: false,
        toolTip: 'If this is enabled, user is able to rollover any remaining stored energy to the next year.',
      },
      {
        inputType: 'number',
        name: `${source}.rollover_expiration_years`,
        source: `${source}.rollover_expiration_years`,
        label: 'Rollover Expiration Years',
        endAdornment: 'Years',
        defaultValue: 1,
        dependsOn: {
          source: 'tariffData',
          resolve: (value) => value?.solar_compensation_settings?.annual_rollover_enabled === true,
        },
        hideOnSimpleInput: true,
        toolTip:
          'Each year the stored energy will rollover to the next year, after this many years (post rollover) the stored energy will expire.',
      },
    ],
    'Gross Metering with Feed-in-tariff': [],
    /* In California, the export rates are fixed based off a pre-defined table, but not in other US States
    We need a way of distinguishing between the two while still enabling both of them to have the NEM3 UI 
    We can assume its okay to show this for all non-California NEM3 utilities, as net billing with credit carry over
    only applies to the US*/
    net_billing_with_credit_carryover: [
      nemTrueUpDateFieldAttributes(source),
      nonBypassableChargesFieldAttributes(source, currencySymbol),
      netSurplusCompensationFieldAttributes(source, currencySymbol),
      {
        inputType: 'boolean',
        name: `${source}.apply_nem3_export_rates`,
        source: `${source}.apply_nem3_export_rates`,
        label: 'Apply NEM3.0 Export Rates (California)',
        defaultValue: false,
        dependsOn: {
          source: 'utility_name',
          resolve: (value) => isCalifornianNEM3Utility(value),
        },
        hideOnSimpleInput: true,
      },
      {
        inputType: 'boolean',
        name: `${source}.apply_nem_modified_with_defined_export_rates`,
        source: `${source}.apply_nem_modified_with_defined_export_rates`,
        label: 'Apply NEM Modified Export Rates',
        defaultValue: false,
        dependsOn: {
          source: 'utility_name',
          resolve: (value) => !isCalifornianNEM3Utility(value),
        },
        countryCodeFilter: ['US'],
        hideOnSimpleInput: true,
      },
    ],
    heco_customer_grid_supply: [nemTrueUpDateFieldAttributes(source)],
    'Gross Metering': [],
    netherland_nem_phase_out_2031: [],
  }

  return solarCompensationMechanism
    ? countryCode
      ? inputFields[solarCompensationMechanism]?.filter(
          (x) =>
            (!x.countryCodeFilter || x.countryCodeFilter.includes(countryCode)) &&
            (simpleFlatInput ? !x.hideOnSimpleInput : true)
        )
      : inputFields[solarCompensationMechanism]
    : []
}

const InputFieldByType = ({ fieldAttributes }) => {
  const classes = useStyles()
  const translate = useTranslate()

  if (fieldAttributes.toolTip && fieldAttributes.inputType === 'boolean') {
    return (
      <div className={classes.row}>
        <InputWithToolTip message={fieldAttributes.toolTip}>
          <span>{translate(fieldAttributes?.label)}</span>
        </InputWithToolTip>
        <BooleanInput label="" source={fieldAttributes?.source} disabled={fieldAttributes?.disabled} />
      </div>
    )
  } else if (fieldAttributes.toolTip) {
    return (
      <InputWithToolTip message={fieldAttributes.toolTip}>
        <RenderInput fieldAttributes={fieldAttributes} />
      </InputWithToolTip>
    )
  } else {
    return <RenderInput fieldAttributes={fieldAttributes} />
  }
}

const RenderInput = ({ fieldAttributes }) => {
  const classes = useStyles()

  if (fieldAttributes.inputType === 'select') {
    return (
      <CustomField
        variant="outlined"
        options={{
          InputLabelProps: {
            shrink: true,
          },
        }}
        component={SelectInput}
        choices={fieldAttributes?.choices}
        name={fieldAttributes?.name}
        source={fieldAttributes?.source}
        label={fieldAttributes?.label}
        defaultValue={fieldAttributes?.defaultValue}
        fullWidth={true}
        className={classes.textInput}
        disabled={fieldAttributes?.disabled}
      />
    )
  } else if (fieldAttributes.inputType === 'number') {
    return (
      <CustomField
        variant="outlined"
        options={{
          InputLabelProps: {
            shrink: true,
          },
        }}
        component={NumberInput}
        name={fieldAttributes?.name}
        source={fieldAttributes?.source}
        label={fieldAttributes?.label}
        startAdornment={fieldAttributes?.startAdornment}
        endAdornment={fieldAttributes?.endAdornment}
        defaultValue={fieldAttributes?.defaultValue}
        fullWidth={true}
        className={classes.numInput}
        disabled={fieldAttributes?.disabled}
      />
    )
  } else {
    return (
      <CustomField
        component={BooleanInput}
        name={fieldAttributes?.name}
        source={fieldAttributes?.source}
        label={fieldAttributes?.label}
        defaultValue={fieldAttributes?.defaultValue}
        fullWidth={true}
        disabled={fieldAttributes?.disabled}
      />
    )
  }
}

export default CustomInputs
