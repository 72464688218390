type EventHandler = (...args: any[]) => void

class EventEmitter {
  private events: { [event: string]: EventHandler[] } = {}

  on(event: string, handler: EventHandler) {
    if (!this.events[event]) {
      this.events[event] = []
    }

    this.events[event].push(handler)
  }

  off(event: string, handler: EventHandler) {
    this.events[event] = this.events[event]?.filter((h) => h !== handler)
  }

  emit(event: string, ...args: any[]) {
    if (this.events[event]) {
      this.events[event].forEach((handler) => handler(...args))
    }
  }
}

export const eventEmitter = new EventEmitter()
