import { TableContainer } from '@material-ui/core'
import { Box, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from 'opensolar-ui'
import { ComponentCost, CostSource } from 'projectSections/hooks/useComponentCost'
import { getComponentDetail } from 'projectSections/sections/design/sideDrawer/bom/useMapHardwareDetailsToBomLineItems'
import { HardwareDetailType } from 'projectSections/sections/design/systems/tabs/summary/hardware/getHardwareDetailFromSystem'
import { isMountingComponent } from 'util/misc'
import CustomiseHardwareCostTableRow from './CustomiseHardwareCostTableRow'

type AnnotatedHardwareDetails = {
  hardwareDetail: HardwareDetailType
  sortOrder: number
  effectiveComponentType: string
}

const CustomiseHardwareCostTable = ({
  hardwareDetails,
  componentPricing,
  onCostSourceChange,
  onEditChange,
  loading,
}: {
  hardwareDetails: HardwareDetailType[]
  componentPricing: ComponentCost[]
  onCostSourceChange: (code: string, costSource: CostSource, value: number) => void
  onEditChange: (isEditing: boolean) => void
  loading: boolean
}) => {
  const theme = useTheme()

  const getEffectiveComponetType = (hardwareDetail: HardwareDetailType) => {
    const loadedComponentDetail = getComponentDetail({ hardwareDetail })
    return isMountingComponent(loadedComponentDetail?.other_component_type) ? 'mounting' : hardwareDetail.type
  }

  const sortOrderForType = (type: string) => {
    switch (type) {
      case 'module':
        return 1
      case 'mounting':
        return 2
      case 'inverter':
        return 3
      case 'battery':
        return 4
      case 'other':
        return 5
      default:
        return 6
    }
  }

  const sortableHardwareDetails: AnnotatedHardwareDetails[] = []
  hardwareDetails.forEach((hardwareDetail: HardwareDetailType) => {
    const existingHardware = sortableHardwareDetails.find((item) => item.hardwareDetail.code === hardwareDetail.code)
    if (existingHardware) {
      const existingIndex = sortableHardwareDetails.indexOf(existingHardware)
      sortableHardwareDetails.splice(existingIndex, 1)
    }
    sortableHardwareDetails.push({
      hardwareDetail,
      sortOrder: sortOrderForType(getEffectiveComponetType(hardwareDetail)),
      effectiveComponentType: getEffectiveComponetType(hardwareDetail),
    })
  })

  /**
   * There's some funky style stuff going on here, because MUI stickyHeader seems to wipe the colour
   * from the table header. There might be a cleaner way of handling this.
   */
  return (
    <TableContainer style={{ maxHeight: 400, height: 400 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ backgroundColor: theme.palette.grey[200] }}>Category</TableCell>
            <TableCell style={{ backgroundColor: theme.palette.grey[200] }}>Component Code</TableCell>
            <TableCell style={{ backgroundColor: theme.palette.grey[200], minWidth: 300 }}>Source</TableCell>
            <TableCell style={{ backgroundColor: theme.palette.grey[200], minWidth: 180 }}>Cost Per Unit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <Box>Loading... </Box>
          ) : (
            sortableHardwareDetails
              .filter((hd) => hd.hardwareDetail.quantity > 0)
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((hd, index) => {
                const pricing = componentPricing?.find((pricing) => pricing.code === hd.hardwareDetail.code)
                return (
                  <CustomiseHardwareCostTableRow
                    key={hd.hardwareDetail.code}
                    effectiveComponentType={hd.effectiveComponentType}
                    hardwareDetail={hd.hardwareDetail}
                    pricing={pricing}
                    onCostSourceChange={(code, costSource, value) => onCostSourceChange(code, costSource, value)}
                    isLastRow={index === hardwareDetails.length - 1}
                    onEditChange={onEditChange}
                  />
                )
              })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default CustomiseHardwareCostTable
