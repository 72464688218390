import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DragIndicatorIcon from '@material-ui/icons/DragIndicatorOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import { useViewShowAsDict } from 'ducks/viewMode'
import { DraggableList } from 'elements/dnd/DraggableList'
import { TransitionNone } from 'elements/TransitionNone'
import WithPermissionsCheck from 'elements/WithPermissionsCheck'
import { IconButton } from 'opensolar-ui'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedSystem, systemsSelectors } from 'reducer/designer/systems'
import { useStudioSignals } from 'Studio/signals/useStudioSignals'
import SystemProgressBar from '../../SystemProgressBar'
import AddPanelButton from './AddPanelButton'
import AddSystemButton from './AddSystemButton'
import SystemAccordionMobile from './SystemAccordionMobile'
import SystemOverview from './SystemOverview'
export const selectedFontColor = 'rgba(0, 0, 0, 0.87)'
export const unselectedFontColor = 'rgb(151,151,151)'

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
  },
  headingTitle: {
    margin: '0 10px',
  },
  accordion: {
    position: 'relative',
    width: 330,
  },
  dragButton: {
    cursor: 'move',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    padding: 5,
    zIndex: 1,
  },
  expandIcon: {
    margin: 0,
    padding: 0,
  },
  expanded: {},
  focused: {},
  summary: {
    padding: '0px 16px 0px 16px',
    minHeight: 65,
    '&$expanded': {
      minHeight: 65,
    },
    '&$focused': {
      background: 'rgb(255,255,255)',
    },
  },
  summaryWhenDragIconVisible: {
    padding: '0px 16px 0px 30px',
    minHeight: 65,
    '&$expanded': {
      minHeight: 65,
    },
    '&$focused': {
      background: 'rgb(255,255,255)',
    },
  },
  summaryContent: {
    margin: 'auto 0',
    '&$expanded': {
      margin: 'auto 0',
    },
  },
}))

export const SystemAccordion = ({
  content,
  handleChange,
  setEditingSystemUuid,
  editingSystemUuid,
  systemLength,
  state,
  isDragging,
  dragHandleProps,
  item: system,
  index,
  expanded,
  disableAddPanelButton,
}) => {
  const { systemsLoadingUuids, systemCalculatingUuids } = state
  const classes = useStyles()
  const translate = useTranslate()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const isSelectedSystem = window.editor.selectedSystem?.uuid === system.uuid
  const isExpanded = Boolean(expanded === system.uuid) && isSelectedSystem
  const isEditing = editingSystemUuid === system.uuid
  const isLoading = systemsLoadingUuids.indexOf(system.uuid) !== -1
  const isCalculating = systemCalculatingUuids.indexOf(system.uuid) !== -1
  const isFirstChild = index === 0
  const isLastChild = index === systemLength - 1

  return (
    // This 'translate' here fixes a bug where the IconButton isn't positioned correctly when the accordion is collapsed
    // It is done conditionally because it causes an issue with draggable components nested inside the accordion
    <div key={system.uuid} style={{ transform: !expanded ? 'translate(0px, 0px)' : undefined }}>
      <IconButton
        style={{ display: expanded ? 'none' : undefined }}
        classes={{ root: classes.dragButton }}
        onClick={(e) => e.preventDefault()}
        {...dragHandleProps}
      >
        <DragIndicatorIcon style={{ color: isSelectedSystem ? selectedFontColor : unselectedFontColor }} />
      </IconButton>
      <Accordion
        style={{
          background: isSelectedSystem ? 'rgb(255,255,255)' : '#E1E1E1',
          borderRadius: 0,
          border: 0,
          borderTopLeftRadius: isFirstChild ? 4 : 0,
          borderTopRightRadius: isFirstChild ? 4 : 0,
          borderBottomLeftRadius: isLastChild ? 4 : 0,
          borderBottomRightRadius: isLastChild ? 4 : 0,
          marginTop: isExpanded && !isFirstChild ? 10 : 0,
          marginBottom: isExpanded && !isLastChild ? 10 : 0,
        }}
        key={system.uuid}
        TransitionComponent={TransitionNone}
        expanded={isExpanded}
        classes={{ root: classes.accordion, expanded: classes.expanded }}
        onChange={handleChange(system.uuid)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: isSelectedSystem ? selectedFontColor : unselectedFontColor }} />}
          aria-controls={'expansion-content-system'}
          id="expansion-content-system"
          classes={{
            root: expanded ? classes.summary : classes.summaryWhenDragIconVisible,
            content: classes.summaryContent,
            expanded: classes.expanded,
            expandIcon: classes.expandIcon,
            focused: classes.focused,
          }}
        >
          <WithPermissionsCheck
            permissionToCheck="design"
            permissions={['allowEdit']}
            disabledProp="disabled"
            disabledTooltip={translate('No permission to edit design')}
          >
            {isSelectedSystem && !isDragging && !isMobile && <AddPanelButton disabled={disableAddPanelButton} />}
          </WithPermissionsCheck>
          <SystemProgressBar
            isLoading={isLoading}
            isCalculating={isCalculating}
            style={{ position: 'absolute', width: '100%', left: 0, top: 0 }}
          />
          <SystemOverview
            system={system}
            isEditing={isEditing}
            isLoading={isLoading || isCalculating}
            isSelectedSystem={isSelectedSystem}
            setEditingSystemUuid={setEditingSystemUuid}
            hasShadingCalcsAwaitingTrigger={window.ShadeHelper.systemUuidsAwaitingTrigger().includes(system?.uuid)}
          />
        </AccordionSummary>
        <AccordionDetails
          style={{
            padding: 0,
          }}
        >
          {isExpanded && content && React.cloneElement(content)}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const SystemAccordionController = memo(({ content, disableAddPanelButton }) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const systems = window.editor.getSystems()
  const [designReady, setDesignReady] = useState(!window.editor.waiting.views)
  const [showAllSystemsInMobileLayout, setShowAllSystemsInMobileLayout] = useState(false)
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'), { noSsr: true })
  const [state, setState] = React.useState({
    systemsLoadingUuids: [],
    systemCalculatingUuids: [],
    //expanded: isMobile ? false : selectedObject?.uuid, //disabled auto expanded in mobile
  }) //managed directly by Designer queue

  let selectedSystem = useSelector(systemsSelectors.selectedSystem)

  useEffect(() => {
    if (selectedSystem) {
      // Validate that this uuid actually exists in the project
      var system = window.editor.getSystems().find((system) => system.uuid === selectedSystem)
      if (!system) selectedSystem = undefined
    }
    if (selectedSystem) return
    // Initially select system based on studio selection
    const studioSelected = window.editor.selectedSystem?.uuid
    if (studioSelected) dispatch(setSelectedSystem(studioSelected))
  }, [])

  useEffect(() => {
    if (showAllSystemsInMobileLayout && selectedSystem) {
      setShowAllSystemsInMobileLayout(false)
    }
  }, [selectedSystem, showAllSystemsInMobileLayout])

  const [editingSystemUuid, setEditingSystemUuid] = useState(null)

  const loadingIds = state.systemsLoadingUuids.join(',')
  const calculatingIds = state.systemCalculatingUuids.join(',')
  const checkState = useCallback(
    (info) => {
      if (info.systemsLoadingUuids.join(',') === loadingIds && info.systemCalculatingUuids.join(',') === calculatingIds)
        return
      setState({ ...state, ...info })
    },
    [loadingIds, calculatingIds]
  )

  useStudioSignals(checkState, ['queueProcessed'], undefined, { trackHandler: true })

  useStudioSignals(() => {
    setDesignReady(!window.editor.waiting.views)
  }, ['sceneLoaded'])

  const handleChange = (uuid) => (event, isExpanded) => {
    const isCurrentSelectedSystem = uuid === window.editor.selectedSystem?.uuid
    if (!!isExpanded && !isCurrentSelectedSystem) {
      window.editor.selectByUuid(uuid)
    }
    dispatch(setSelectedSystem(isExpanded ? uuid : undefined))
  }

  const uiShow = useViewShowAsDict([
    'studio.systems',
    'studio.systems.add_new_system',
    'studio.systems.duplicate_system',
  ])

  if (!uiShow['studio.systems']) {
    return null
  }

  if (isMobile && !showAllSystemsInMobileLayout) {
    return (
      <SystemAccordionMobile
        editingSystemUuid={editingSystemUuid}
        setEditingSystemUuid={setEditingSystemUuid}
        setShowAllSystemsInMobileLayout={setShowAllSystemsInMobileLayout}
        handleChange={handleChange}
        content={content}
        systems={systems}
        expanded={selectedSystem}
        {...state}
      />
    )
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: isMobile ? 60 : 0,
        left: 0,
        width: 330,
        margin: 10,
        maxHeight: 'calc(100vh - 120px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <DraggableList
          handleReorder={(oldOrder, newOrder) => {
            const commandId = window.Utils.generateCommandUUIDOrUseGlobal()
            for (let i = 0; i < newOrder.length; i++) {
              const system = window.editor.objectByUuid(newOrder[i])
              system && window.editor.execute(new window.SetValueCommand(system, 'order', i, commandId))
            }
          }}
          list={systems}
          render={(item, { dragHandleProps, isDragging, index }) => (
            <SystemAccordion
              index={index}
              item={item}
              state={state}
              editingSystemUuid={editingSystemUuid}
              setEditingSystemUuid={setEditingSystemUuid}
              handleChange={handleChange}
              content={content}
              systemLength={systems.length}
              expanded={selectedSystem}
              dragHandleProps={dragHandleProps}
              isDragging={isDragging}
              disableAddPanelButton={disableAddPanelButton}
            />
          )}
          dragMode="drag-handle"
        />
      </div>
      <WithPermissionsCheck
        permissionToCheck="design"
        permissions={['allowEdit']}
        disabledProp="disabled"
        disabledTooltip={translate('No permission to add/create systems')}
      >
        {uiShow['studio.systems.add_new_system'] && designReady && <AddSystemButton />}
      </WithPermissionsCheck>
    </div>
  )
})

export default SystemAccordionController
