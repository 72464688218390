import { makeStyles } from '@material-ui/core'
import { useEffect, useRef, useState } from 'react'
import { THREE_DS_ENABLED_COUNTRIES } from '../constants'
import { GooglePaymentDataType, PaymentExtraFields, PaymentRequestType, PaymentStaticCopy } from '../types'
import { getBlueSnapMerchantId } from '../utils'
type GooglePayButtonProps = {
  paymentRequestData: PaymentRequestType
  projectId: string
  countryIso2: string
  orgName: string
  paymentStaticCopy: PaymentStaticCopy
  showGooglePay?: boolean
  showApplePay?: boolean | null
  doSubmitPayment: (args: PaymentExtraFields) => void
}

const useStyles = makeStyles({
  googleContainer: {
    width: '240px',
    height: '40px',
    maxHeight: '40px',
    minHeight: '40px',
    borderRadius: '5px',
    margin: '10px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
})

export const GooglePayButton: React.FC<GooglePayButtonProps> = ({
  paymentRequestData,
  orgName,
  countryIso2,
  projectId,
  doSubmitPayment,
}) => {
  const classes = useStyles()
  const scriptLoaded = useRef(false)
  const buttonContainer = useRef<HTMLDivElement | null>(null)
  const paymentsClient = useRef<any>(null)
  const [merchantId, setMerchantId] = useState<string | undefined>(undefined)
  const [merchantEnv, setMerchantEnv] = useState<string | undefined>(undefined)
  const [isGooglePayReady, setIsGooglePayReady] = useState<boolean>(false)

  const loadGooglePayScript = () => {
    return new Promise<void>((resolve, reject) => {
      const existingScript = document.querySelector(
        'script[src="https://pay.google.com/gp/p/js/pay.js"]'
      ) as HTMLScriptElement

      if (existingScript) {
        if (existingScript.hasAttribute('data-loaded')) {
          resolve()
        } else {
          existingScript.addEventListener('load', () => {
            existingScript.setAttribute('data-loaded', 'true')
            resolve()
          })
          existingScript.addEventListener('error', reject)
        }
      } else {
        const script = document.createElement('script')
        script.src = 'https://pay.google.com/gp/p/js/pay.js'
        script.async = true
        script.id = 'google-pay-script'

        script.onload = () => {
          script.setAttribute('data-loaded', 'true')
          resolve()
        }

        script.onerror = reject
        document.body.appendChild(script)
      }
    })
  }

  const onPaymentAuthorized = (paymentData: any) => {
    return new Promise((resolve, reject) => {
      resolve({ transactionState: 'SUCCESS' }) // Call resolve with a success response, this can be a place to verify card data and perhaps reject if needed
    })
  }

  useEffect(() => {
    if (paymentRequestData?.org_id && !merchantId) {
      getBlueSnapMerchantId(projectId, paymentRequestData.payment_request_id, paymentRequestData.org_id)
        .then((data) => {
          setMerchantEnv(data.merchantEnv)
          setMerchantId(data.merchantId)
        })
        .catch((error) => {
          console.error('Error getting merchantId:', error)
        })
    }
  }, [paymentRequestData?.org_id])

  const processPayment = (paymentData: GooglePaymentDataType) => {
    const paymentToken = b64EncodeUnicode(JSON.stringify(paymentData))
    paymentData.token = paymentToken

    doSubmitPayment({
      payment_method_data: paymentData,
      payment_method_type: 'apple_google_pay',
      wallet_type: 'google_pay',
    })
  }

  const b64EncodeUnicode = (str: string) => {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
      })
    )
  }

  const initializeGooglePay = async () => {
    // @ts-ignore
    if (!window.google?.payments?.api || !merchantId || !merchantEnv) return

    try {
      // @ts-ignore
      paymentsClient.current = new window.google.payments.api.PaymentsClient({
        environment: merchantEnv, // 'TEST' or 'PRODUCTION'
        paymentDataCallbacks: {
          onPaymentAuthorized: onPaymentAuthorized,
        },
        existingPaymentMethodRequired: false,
      })

      const allowedAuthMethods = THREE_DS_ENABLED_COUNTRIES.includes(countryIso2)
        ? ['PAN_ONLY', 'CRYPTOGRAM_3DS']
        : ['PAN_ONLY']

      // Base payment request for availability check
      const baseRequest = {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: allowedAuthMethods,
              allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'],
              billingAddressRequired: true,
              billingAddressParameters: {
                format: 'FULL',
                phoneNumberRequired: true,
              },
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'bluesnap',
                gatewayMerchantId: merchantId,
              },
            },
          },
        ],
      }

      // Full payment data request
      const paymentDataRequest = {
        ...baseRequest,
        merchantInfo: {
          merchantId: 'BCR2DN4T2OAIJTDQ',
          merchantName: orgName,
        },
        transactionInfo: {
          countryCode: countryIso2,
          currencyCode: paymentRequestData.currency,
          totalPriceStatus: 'FINAL',
          totalPrice: parseFloat(paymentRequestData.payment_amount.toString()).toFixed(2),
        },
        callbackIntents: ['PAYMENT_AUTHORIZATION'],
      }

      const isReadyToPay = await paymentsClient.current.isReadyToPay(baseRequest)

      if (isReadyToPay.result) {
        setIsGooglePayReady(true)
        const button = paymentsClient.current.createButton({
          buttonColor: 'black',
          buttonType: 'pay',
          onClick: () => {
            paymentsClient.current
              .loadPaymentData(paymentDataRequest)
              .then((paymentData: any) => {
                processPayment(paymentData)
              })
              .catch((err: Error) => {
                console.error('Error loading payment data:', err)
              })
          },
        })

        if (buttonContainer.current) {
          buttonContainer.current.innerHTML = ''
          buttonContainer.current.appendChild(button)
        }
      }
    } catch (error) {
      console.error('Error initializing Google Pay:', error)
    }
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        await loadGooglePayScript()
        scriptLoaded.current = true
        await initializeGooglePay()
      } catch (error) {
        console.error('Error loading Google Pay script:', error)
      }
    }

    if (!scriptLoaded.current && merchantId) {
      initialize()
    }
  }, [merchantId, merchantEnv])

  return <div ref={buttonContainer} id="google-pay-container" className={classes.googleContainer} />
}

export default GooglePayButton
