import { myEnergySelectors } from 'ducks/myEnergy'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const useGetProposalHasCashFlowDeposit = () => {
  const [hasCashFlowDeposit, setHasCashFlowDeposit] = useState(false)

  const availableActions = useSelector(myEnergySelectors.getProposalAvailableActions)

  useEffect(() => {
    if (!availableActions?.length) setHasCashFlowDeposit(false)
    else {
      let foundCashFlow = false
      availableActions.forEach((systemActions) => {
        systemActions?.actions_available?.forEach((action) => {
          if (action.payment_method === 'cashflow' && action.payment_amount) foundCashFlow = true
        })
      })
      setHasCashFlowDeposit(foundCashFlow)
    }
  }, [availableActions])

  return hasCashFlowDeposit
}
