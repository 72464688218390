import { useMediaQuery } from '@material-ui/core'
import { Add, ArrowBackIosOutlined } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import StyledNavLink from 'layout/headers/elements/StyledNavLink'
import useNavigationLinks, { HOME_NAVIGATION_LINK } from 'layout/hooks/useNavigationLinks'
import { BaseThemeType, Box, Button, ComponentVersionsInherit, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DialogHelper } from 'util/misc'
import NavigationIconButtons, { NavigationIconButton } from './NavigationIconButtons'
import NavigationSettingsMenu from './NavigationSettingsMenu'

const NavContainer = styled(Box)(() => ({
  position: 'relative',
  '&::after': {
    background: 'rgb(236, 236, 237)',
    bottom: '0px',
    content: "''",
    height: '1px',
    left: '0',
    position: 'absolute',
    width: '100%',
  },
}))

const OpensolarLogoImage = styled('img')({
  height: '30px',
})

const NearmapLogoImage = styled('img')({
  borderRight: '1px solid #E6E6E6',
  height: '20px',
  marginRight: 8,
  paddingRight: 8,
})

const getLogos = (isIcons) =>
  isIcons
    ? {
        nearmapLogo: window.PUBLIC_URL + '/images/nearmap-3-icon.png',
        opensolarLogo: `${window.PUBLIC_URL}/images/logo-icon.svg`,
      }
    : {
        nearmapLogo: window.PUBLIC_URL + '/images/nearmap-3.png',
        opensolarLogo: `${window.PUBLIC_URL}/images/logo.svg`,
      }

interface TopNavigationHeader3Props {
  navigationBackUrl: string
  setAvailableOrgs: (v) => void
  setSelectOrgVisible: (v) => void
}

const TopNavigationHeader3: React.FC<TopNavigationHeader3Props> = ({
  navigationBackUrl,
  setAvailableOrgs,
  setSelectOrgVisible,
}) => {
  const isLogoIcon = useMediaQuery('(max-width: 768px)')
  const isNearmapSkin = useSelector((state: any) => state.theme === 'nearmap')
  const briefOrgs = useSelector(authSelectors.getBriefOrgs)
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.up('sm'))

  const { nearmapLogo, opensolarLogo } = getLogos(isLogoIcon)

  const navigationLinks = useNavigationLinks()
  const history = useHistory()
  const translate = useTranslate()

  return (
    <ComponentVersionsInherit versions={{ button: 3, icon_button: 2 }}>
      <NavContainer
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        padding={isMobile ? '0 16px' : '0 24px'}
      >
        <Box alignItems="center" display="flex" gridColumnGap={12}>
          {navigationBackUrl && (
            <NavigationIconButton
              onClick={() => {
                window.location.href = navigationBackUrl
              }}
              size="small"
              variant="squared"
            >
              <ArrowBackIosOutlined />
            </NavigationIconButton>
          )}
          <StyledNavLink to={`/${HOME_NAVIGATION_LINK.key}`} label={HOME_NAVIGATION_LINK.label}>
            {isNearmapSkin && <NearmapLogoImage alt="co-branding" src={nearmapLogo} />}
            <OpensolarLogoImage src={opensolarLogo} />
          </StyledNavLink>
          {!isMobile &&
            navigationLinks.map(({ badgeSeverity, label, key, uiKey }) => (
              <StyledNavLink badgeSeverity={badgeSeverity} key={key} uiKey={uiKey} to={`/${key}`} label={label} />
            ))}
        </Box>
        <Box display="flex" gridGap={12} padding="8px 4px">
          <NavigationIconButtons />
          {isTablet && (
            <Button
              color="secondary"
              onClick={() => history.push('/home')}
              startIcon={<Add />}
              variant="contained"
              style={{ lineHeight: 1 }}
            >
              {translate('Project')}
            </Button>
          )}
          <NavigationSettingsMenu
            showOrgSelector={() => {
              DialogHelper.afterOpen()
              setSelectOrgVisible(true)
              setAvailableOrgs(briefOrgs)
            }}
          />
        </Box>
      </NavContainer>
    </ComponentVersionsInherit>
  )
}

// @ts-ignore
export default TopNavigationHeader3
