import { orgSelectors } from 'ducks/orgs'
import DatasheetDocIcon from 'icons/DatasheetDocIcon'
import { uniqBy } from 'lodash'
import { Box, Button } from 'opensolar-ui'
import { getSupplierEnumByFilterKey } from 'pages/ordering/configs'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { DistributorDataTypeV2 } from '../types'
import useCommonStyles from './useCommonStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    gap: '16px',
    padding: '2px 0px',
  },
  manufacturerText: {
    alignSelf: 'flex-start',
    fontWeight: 400,
    whiteSpace: 'normal',
  },
  logo: {
    height: '36px',
    objectFit: 'contain',
    width: '48px',
  },
}))

const ManufacturerBlock = ({
  logoUrl,
  name,
  isExhibitor,
  distributors,
}: {
  logoUrl?: string
  name: string
  isExhibitor: boolean
  distributors: DistributorDataTypeV2[]
}) => {
  const commonClasses = useCommonStyles()
  const classes = useStyles()

  const [isLogoUrlBroken, setIsBrokenLogoUrl] = useState(false)

  /// TODO refactor. This is a copy from the distributors block.
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const filteredDistributors = uniqBy(
    distributors.filter((item: DistributorDataTypeV2, index, self) => {
      const supplierEnum = getSupplierEnumByFilterKey(item.distributor)
      return enabledDistributors.includes(supplierEnum) && self.indexOf(item) === index
    }),
    'distributor'
  )

  const showLogo = isExhibitor || filteredDistributors.length > 0

  const logo =
    !showLogo || isLogoUrlBroken || !logoUrl ? (
      <span className={`${commonClasses.mainText} ${classes.manufacturerText}`}>{name}</span>
    ) : (
      <img
        className={classes.logo}
        src={logoUrl}
        alt={name}
        onError={(e) => {
          setIsBrokenLogoUrl(true)
        }}
      />
    )

  const hasDatasheet = false

  return (
    <Box alignItems="center" className={classes.container} display="flex" flexDirection="column">
      {logo}
      {hasDatasheet && (
        <Button onClick={() => alert('datasheet')} variant="text">
          <DatasheetDocIcon />
        </Button>
      )}
    </Box>
  )
}

export default ManufacturerBlock
