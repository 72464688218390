import { UiSwitch } from 'elements/UiSwitch'
import { createContext, ReactNode, useContext, useMemo } from 'react'

interface UiContextValue {
  path: string
}

type UiProps = ({ slug: string } | { path: string }) & {
  children: ReactNode
}

const UiContext = createContext<UiContextValue>({
  path: '',
})

export const useUiPath = () => {
  const context = useContext(UiContext)
  if (!context) {
    throw new Error('useUi must be used within a UI component')
  }
  return context
}

export const UI: React.FC<UiProps> = ({ children, ...rest }) => {
  const parentContext = useContext(UiContext)

  const path = 'path' in rest ? rest.path : parentContext?.path ? `${parentContext.path}.${rest.slug}` : rest.slug

  const value = useMemo(() => ({ path }), [path])

  return (
    <UiContext.Provider value={value}>
      <UiSwitch uiKey={path}>{children}</UiSwitch>
    </UiContext.Provider>
  )
}
