import { EventIdType } from 'amplitude/amplitude'
import { getBackendLogger } from 'loggers/registry'
import { LogEventParams } from 'loggers/types'
import { Logger } from 'opensolar-sdk'
import { createContext, useCallback, useContext } from 'react'

const TrackingContext = createContext<(params: LogEventParams) => void>(() => {})

export const TrackingProvider = ({ children }: { children: React.ReactNode }) => {
  const logEvent = useCallback(
    ({
      eventKey,
      eventType,
      eventName,
      additionalData,
      description,
      backends = ['amplitude'],
      eventConfig = {},
    }: LogEventParams) => {
      backends.forEach((backend) => {
        const config = eventConfig[backend.toLowerCase()]
        const whitelist = config?.whitelist || []

        // Validate eventKey against the backend-specific whitelist
        const isWhitelisted = whitelist.some((pattern) => {
          const regex = new RegExp(`^${pattern.replace(/\*/g, '.*')}$`)
          return regex.test(eventKey)
        })

        const trackingLogger = new Logger('OS.Tracking')

        if (!isWhitelisted) {
          trackingLogger.debug(`Event "${eventKey}" is not whitelisted for backend "${backend}". Skipping.`)
          return
        }

        const eventData = {
          eventKey,
          eventType,
          eventName: eventName,
          description: description || 'No description provided',
          ...additionalData,
        }

        const logger = getBackendLogger(backend)
        if (logger) {
          logger(eventKey as EventIdType, eventType, eventData, eventName || eventKey)
        } else {
          throw new Error(`Logger not found for backend: ${backend}`)
        }
      })
    },
    []
  )

  return <TrackingContext.Provider value={logEvent}>{children}</TrackingContext.Provider>
}

export const useTracking = () => {
  const context = useContext(TrackingContext)
  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider')
  }
  return context
}
