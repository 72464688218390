import { Popover, Tooltip } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { triggerMilestoneRefresh } from 'ducks/projectMilestones'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { cancelPaymentRequest } from 'pages/cashFlow/utils'
import EditInvoiceDetailsDialog from 'projectSections/sections/manage/cashFlowTransactions/invoices/EditInvoiceDetailsDialog'
import { useNotify, useTranslate } from 'ra-core'
import React, { useMemo, useRef, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import CancelInvoiceConfirmation from '../cashFlowTransactions/invoiceActions/CancelInvoiceConfirmation'
import EditPaymentOptionsDialog from '../cashFlowTransactions/invoiceActions/EditPaymentOptionsDialog'
import { useGetMilestones } from '../hooks'
import MilestoneOverrideForm from '../projectProgress/cashFlow/milestoneOverride/MilestoneOverrideDialog'
import { useGetOrgIdFromPaymentOptions, useGetPaymentOptionDataFromSystem } from '../utils'
import LoadingDialog from './cardActions/InvoiceLoadingDialog'

type PropTypes = {
  paymentRequests: PaymentRequestType[]
  deletePaymentOption: () => void
  systemUuid: string | null
  paymentOptionId: number
  isPendingSave: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  icon: {
    color: theme.greyMid1,
  },
  menuWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    flex: 1,
    padding: '8px 15px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.greyLight1,
    },
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
    color: '#999',
  },
}))

const PaymentOptionCashFlowDropDown: React.FC<PropTypes> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showOverrideDialog, setShowOverrideDialog] = useState<boolean>(false)
  const [showEditInvoiceDialog, setShowInvoiceDialog] = useState<boolean>(false)
  const [invoiceIsBeingCancelled, setInvoiceIsBeingCancelled] = useState<boolean>(false)
  const [showCancelConfirmation, setShowCancelConfirmation] = useState<boolean>(false)
  const [showEditPaymentSettings, setShowEditPaymentSettings] = useState<boolean>(false)

  const popoverRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const dispatch = useDispatch()
  const project = useFormState()?.values
  const orgId = useGetOrgIdFromPaymentOptions(props.systemUuid, project.org_id, props.paymentOptionId)

  const milestones = useGetMilestones(props.paymentOptionId, props.systemUuid, project.id)

  const firstMilestone = useMemo(() => {
    if (props.paymentRequests?.length) {
      return props.paymentRequests?.filter((milestone) => {
        return milestone.payment_number === 1 && milestone.status !== 'cancelled'
      })?.[0]
    }
    return null
  }, [props.paymentRequests])

  const showCancel = useMemo(() => {
    return (
      firstMilestone?.id &&
      ['requested', 'viewed', 'partially paid', 'expired', 'payment failed'].includes(firstMilestone.status) &&
      orgId &&
      props.systemUuid
    )
  }, [firstMilestone, orgId, props.systemUuid])

  const paymentOption = useGetPaymentOptionDataFromSystem(props.systemUuid, props.paymentOptionId)

  const startCancelConfirmation = () => {
    setShowCancelConfirmation(true)
  }

  const startEditPaymentSettings = () => {
    setShowEditPaymentSettings(true)
  }

  const onCancel = () => {
    setShowCancelConfirmation(false)
    if (orgId && props.systemUuid && firstMilestone?.id) {
      setInvoiceIsBeingCancelled(true)
      cancelPaymentRequest(orgId, project?.id, props.systemUuid, props.paymentOptionId, firstMilestone?.id)
        .then((res) => {
          notify(translate('Your invoice has been cancelled', 'success'))
          dispatch(triggerMilestoneRefresh())
          setIsOpen(false)
          logAmplitudeEvent('cashflow_invoice_cancelled', {
            source: 'payment option card',
            org_id: orgId,
            project_id: project?.id,
          })
        })
        .finally(() => {
          setInvoiceIsBeingCancelled(false)
        })
    }
  }

  const allowOverrides = useMemo(() => {
    return (
      props.systemUuid &&
      orgId &&
      paymentOption?.pricing?.system_price_payable &&
      paymentOption?.payment_type === 'cash'
    )
  }, [props.systemUuid, orgId, paymentOption?.pricing?.system_price_payable])

  const onOverrideOpen = () => {
    setIsOpen(false)
    setShowOverrideDialog(true)
    logAmplitudeEvent('cashflow_adjust_payments_dialog_launched', { source: 'payment option card' })
  }

  const onDelete = () => {
    props.deletePaymentOption()
  }

  const onEditInvoiceOpen = () => {
    setIsOpen(false)
    setShowInvoiceDialog(true)
    logAmplitudeEvent('cashflow_edit_invoice_details_launched', {
      project_id: project?.id,
      source: 'payment option card',
    })
  }

  const closeEditInvoiceDialog = () => {
    setShowInvoiceDialog(false)
  }

  return (
    <div>
      <div ref={popoverRef} onClick={() => setIsOpen(true)}>
        <MoreVertOutlined className={classes.icon} />
      </div>
      <Popover
        id={'cashflow-actions-popover'}
        open={isOpen}
        anchorEl={popoverRef?.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.menuWrapper}>
          {showCancel && (
            <div className={classes.row} onClick={startCancelConfirmation}>
              {translate('Cancel %{milestoneName} Invoice', { milestoneName: firstMilestone?.title })}
            </div>
          )}
          {showCancel && (
            <div className={classes.row} onClick={startEditPaymentSettings}>
              {translate('Adjust Payment Settings')}
            </div>
          )}
          {allowOverrides && (
            <Tooltip
              title={translate('Project must be saved before this action can be taken')}
              disableHoverListener={!props.isPendingSave}
              placement="right"
            >
              <span>
                <div
                  className={`${classes.row} ${props.isPendingSave ? classes.disabled : ''}`}
                  onClick={onOverrideOpen}
                >
                  {translate('Adjust Payments')}
                </div>
              </span>
            </Tooltip>
          )}
          {!showCancel && (
            <Tooltip
              title={translate('Project must be saved before this action can be taken')}
              disableHoverListener={!props.isPendingSave}
              placement="right"
            >
              <span>
                <div
                  className={`${classes.row} ${props.isPendingSave ? classes.disabled : ''}`}
                  onClick={onEditInvoiceOpen}
                >
                  {translate('Edit Invoice Details')}
                </div>
              </span>
            </Tooltip>
          )}
          <div className={classes.row} onClick={onDelete}>
            {translate('Remove Payment Option')}
          </div>
        </div>
      </Popover>
      {showEditInvoiceDialog && (
        <EditInvoiceDetailsDialog projectId={project?.id} onClose={closeEditInvoiceDialog} {...props} />
      )}
      {showCancelConfirmation && firstMilestone && (
        <CancelInvoiceConfirmation
          paymentRequest={firstMilestone}
          cancelInvoice={onCancel}
          closeDialog={() => setShowCancelConfirmation(false)}
          disableButtons={false}
        />
      )}
      {showEditPaymentSettings && firstMilestone?.id && (
        <EditPaymentOptionsDialog
          closeDialog={() => setShowEditPaymentSettings(false)}
          paymentRequestId={firstMilestone?.id}
          projectId={project.id}
        />
      )}
      {showOverrideDialog &&
        props.systemUuid &&
        orgId &&
        paymentOption?.pricing?.system_price_payable &&
        milestones && (
          <MilestoneOverrideForm
            milestones={milestones}
            systemUuid={props.systemUuid}
            paymentOptionId={props.paymentOptionId}
            projectId={project?.id}
            orgId={orgId}
            onClose={() => setShowOverrideDialog(false)}
            pricePayable={paymentOption?.pricing?.system_price_payable}
          />
        )}
      {invoiceIsBeingCancelled && (
        <LoadingDialog
          intervalMs={3 * 1000}
          title={translate('Cancelling Invoice')}
          messages={[
            translate('Cancelling existing invoice...'),
            translate('Issuing credit note...'),
            translate('Notifying customer...'),
          ]}
        />
      )}
    </div>
  )
}
export default PaymentOptionCashFlowDropDown
