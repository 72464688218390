import { logAmplitudeEvent } from 'amplitude/amplitude'
import { PaymentMethodType } from 'myenergy/dialogs/checkout/cashFlow/types'
import { Alert } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo } from 'react'
import { showAppleGooglePaySurchargeWarning, showDebitCardSurchargeWarning } from '../utils'

type PropTypes = {
  surchargingEnabled: boolean
  acceptedPaymentMethods: PaymentMethodType[]
  orgCountry: string | undefined
}

const SurchargingNotSupportedWarning: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()

  const debitCardSurchargingWarning = useMemo(() => {
    return showDebitCardSurchargeWarning(props.surchargingEnabled, props.acceptedPaymentMethods, props.orgCountry)
  }, [props.surchargingEnabled, props.acceptedPaymentMethods, props.orgCountry])

  const appleGooglePaySurchargingWarning = useMemo(() => {
    return showAppleGooglePaySurchargeWarning(props.surchargingEnabled, props.acceptedPaymentMethods)
  }, [props.surchargingEnabled, props.acceptedPaymentMethods])

  useEffect(() => {
    if (debitCardSurchargingWarning || appleGooglePaySurchargingWarning) {
      let warningTypes: string[] = []
      if (debitCardSurchargingWarning) {
        warningTypes.push('debit_card')
      }
      if (appleGooglePaySurchargingWarning) {
        warningTypes.push('apple_google_pay')
      }
      logAmplitudeEvent('cashflow_surcharge_ineligible_warning_shown', { warningTypes: warningTypes })
    }
  }, [debitCardSurchargingWarning, appleGooglePaySurchargingWarning])
  return (
    <>
      {debitCardSurchargingWarning && appleGooglePaySurchargingWarning ? (
        <Alert severity="info">
          <span>
            {translate(
              'Apple/Google Pay and debit cards are not eligible for surcharging. If the customer chooses to pay with Apple/Google Pay or a debit card, then all fees will be taken out of the payment amount.'
            )}
          </span>
        </Alert>
      ) : debitCardSurchargingWarning ? (
        <Alert severity="info">
          <span>
            {translate(
              'Debit cards are not eligible for surcharging. If the customer chooses to pay with a debit card then all fees will be taken out of the payment amount.'
            )}
          </span>
        </Alert>
      ) : appleGooglePaySurchargingWarning ? (
        <Alert severity="info">
          <span>
            {translate(
              'Apple/Google Pay are not eligible for surcharging. If the customer chooses to pay with Apple/Google Pay, then all fees will be taken out of the payment amount.'
            )}
          </span>
        </Alert>
      ) : null}
    </>
  )
}
export default SurchargingNotSupportedWarning
