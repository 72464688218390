import { Box, Typography } from 'opensolar-ui'
import { useTranslate } from 'ra-core'

import { StudioSystemType } from 'types/global'
import { formatDecimalPlaces, trimDecimalPlaces } from 'util/misc'
import { getProposedSelfConsumption } from '../summary/Summary'

type BatteryMetricsProps = {
  system: StudioSystemType
  enableBatterySalesAssistant: Boolean | undefined
}

export const BatteryMetrics = ({ system, enableBatterySalesAssistant }: BatteryMetricsProps) => {
  const translate = useTranslate()

  const proposedSystemKey = Object.keys(system.bills?.proposed || {})[0]
  const proposedSystem = system.bills?.proposed?.[proposedSystemKey]
  const selfConsumption = system.bills && system.bills.proposed ? getProposedSelfConsumption(system.bills.proposed) : 0

  return (
    <>
      {(system.consumption || (enableBatterySalesAssistant && proposedSystem)) && (
        <Box display="flex" flexDirection="column" gridRowGap={8} padding={1}>
          {enableBatterySalesAssistant && proposedSystem && (
            <>
              <Box display="flex" justifyContent="space-between">
                <Typography textVariant="caption1">{translate('Whole Site Backup Hours')}</Typography>
                <Typography textVariant="caption2">
                  {proposedSystem.bills_yearly[0].annual.whole_site_backup_hours_without_solar != null
                    ? `${trimDecimalPlaces(
                        proposedSystem.bills_yearly[0].annual.whole_site_backup_hours_without_solar,
                        0
                      )} ${translate('hours')}`
                    : '-'}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography textVariant="caption1">{translate('Peak Demand Reduction')}</Typography>
                <Typography textVariant="caption2">
                  {proposedSystem.bills_yearly[0].annual.peak_reduction_results?.average_monthly_peak_reduction_kw !=
                  null
                    ? `${formatDecimalPlaces(
                        proposedSystem.bills_yearly[0].annual.peak_reduction_results.average_monthly_peak_reduction_kw,
                        2
                      )} ${translate('kW')}`
                    : '-'}
                </Typography>
              </Box>
            </>
          )}
          {system.consumption && (
            <>
              <Box display="flex" justifyContent="space-between">
                <Typography textVariant="caption1">{translate('Grid Independence')}</Typography>
                <Typography textVariant="caption2">
                  {`${trimDecimalPlaces(system.consumption.grid_independence_percentage_with_battery, '-')}%`}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography textVariant="caption1">{translate('Self-consumption')}</Typography>
                <Typography textVariant="caption2">{`${trimDecimalPlaces(selfConsumption, '-')}%`}</Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  )
}
