import { Badge, InputAdornment } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import {
  loadProjectViews,
  projectListViewSelectors,
  ProjectView,
  removeProjectView,
  updateProjectView,
  ViewType,
} from 'ducks/projectListViews'
import BulkDeleteWithConfirmButton from 'elements/button/BulkDeleteWithConfirmButton'
import UiContainer from 'elements/UiContainer'
import lodashDebounce from 'lodash/debounce'
import { Button, DeleteOutlineIcon, FilterIcon, SearchOutlineIcon, SettingsIcon, styled, TextField } from 'opensolar-ui'
import ConfirmModal from 'projectSections/sections/manage3/common/ConfirmModal'
import { COLOR_PALETTE, getProjectSectionStyles } from 'projectSections/sections/manage3/styles'
import { useNotify, useTranslate } from 'ra-core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WorkflowDropdown from './elements/WorkflowDropdown'
import FilterDrawer from './filtering/FilterDrawer'
import { checkOSProjectFiltersEqual, editViews, mapFilterArrayToFilterObject, saveViews } from './utils'
import SettingsDrawer from './views/SettingsDrawer'

const uiKey = 'projects.search'
const DEFAULT_DEBOUNCE = 500

const FilterBadge = styled(Badge, { name: 'SearchToolbarFilterBatch' })({
  '& .MuiBadge-badge': { background: COLOR_PALETTE.darkBlue2 },
})

const Wrapper = styled('div', { name: 'SearchToolbarWrapper' })({
  width: '100%',
  margin: '10px 0',
  flexWrap: 'wrap',
  rowGap: 10,
})

const LeftContainer = styled('div', { name: 'SearchToolbarLeftContainer' })({
  marginRight: 'auto',
  flexWrap: 'wrap',
  rowGap: 10,
})

const SearchField = styled(TextField, { name: 'SearchToolbarSearchField' })({
  margin: 0,
  width: 400,
  maxWidth: '90%',
  marginRight: 10,
})

const SettingsButton = styled(Button, { name: 'SearchToolbarSettingsButton' })({
  marginRight: 10,
})

const ButtonWrapper = styled('div', { name: 'SearchToolbarFilterBatch' })({
  flexDirection: 'row',
  display: 'flex',
  alignSelf: 'flex-end',
  gap: 10,
})

const SearchToolbar = (props) => {
  const { setFilters, filterValues, selectedIds, viewSettings, handleViewChange } = props
  const notify = useNotify()
  const translate = useTranslate()
  const classes = getProjectSectionStyles()
  const [searchText, setSearchText] = useState('')
  const [filterOpen, setFilterOpen] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [isVisibleDiscardModal, setIsVisibleDiscardModal] = useState(false)
  const [isCurrentViewHasUnsavedFilters, setIsCurrentViewHasUnsavedFilters] = useState(false)
  const [currentViewUnsavedFilters, setCurrentViewUnsavedFilters] = useState<{ filters: any[]; viewType?: number }>({
    filters: [],
  })

  const dispatch = useDispatch()
  const { allowDelete } = useSelector(permissionsSelectors.getPermissionByKey('project'))
  const orgId = useSelector(authSelectors.getOrgId)
  const views: ProjectView[] = useSelector(projectListViewSelectors.getProjectListViewData)
  const isUnSavedViewAvailable: boolean = useSelector(projectListViewSelectors.getIsUnsavedViewExist)
  const unSavedViewIndex: number = useSelector(projectListViewSelectors.getUnsavedViewIndex)
  const currentView = useMemo(() => views?.[viewSettings?.selectedView], [views, viewSettings?.selectedView])

  useEffect(() => {
    if (orgId && (!views || !views.length)) {
      dispatch(loadProjectViews(orgId))
    }
  }, [orgId])

  const trackValueChanged = useCallback(() => {
    logAmplitudeEvent('generic_filter_interacted', {
      action: 'updated',
      key: 'q',
    })
  }, [])

  const debouncedTrackValueChanged = useMemo(() => {
    return lodashDebounce(trackValueChanged, DEFAULT_DEBOUNCE)
  }, [trackValueChanged])

  const handleSearch = (e) => {
    const queryVal = e?.target?.value
    debouncedTrackValueChanged()
    setFilters({ ...filterValues, q: queryVal })
    setSearchText(e.target.value)
  }
  useEffect(() => {
    // Initialise searchfield Text from the URL and remove search on view change
    if (filterValues?.q) {
      setSearchText(filterValues.q)
    }
    if (searchText) {
      setSearchText('')
    }
  }, [viewSettings.selectedView, filterValues])

  useEffect(() => {
    const viewTypeEqual =
      (viewSettings?.isTableView === true && currentView?.view_type === ViewType.Table) ||
      (viewSettings?.isTableView === false && currentView?.view_type === ViewType.Kanban)
    const currentViewfilters = currentView?.view_settings?.filters
    const equalData = checkOSProjectFiltersEqual(filterValues, currentViewfilters)
    const unequal = !equalData.equal || viewTypeEqual === false
    setIsCurrentViewHasUnsavedFilters(unequal)
    if (unequal) {
      let unsavedFilters = { filters: equalData.newFilters }
      if (viewTypeEqual === false)
        unsavedFilters['viewType'] = viewSettings?.isTableView ? ViewType.Table : ViewType.Kanban
      setCurrentViewUnsavedFilters(unsavedFilters)
    }
  }, [viewSettings.selectedView, filterValues, viewSettings.isTableView])
  useEffect(() => {
    // This is to handle unsaved view data
    const handleBeforeUnload = (event) => {
      if (isUnSavedViewAvailable) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [isUnSavedViewAvailable])

  function saveUnsavedView() {
    const copyOfViews = [...views]
    let newView = copyOfViews.filter((item) => !!item.not_saved)[0]
    delete newView.not_saved
    saveNewViews(newView)
  }

  function deleteView() {
    let newView = views.filter((item) => !!item.not_saved)
    if (newView?.length > 0) {
      dispatch(removeProjectView(newView[0].id))
      handleViewChange(0)
    }
    setIsVisibleDiscardModal(false)
  }

  const saveNewViews = (payload) => {
    saveViews(payload, orgId)
      .then((response) => {
        dispatch(loadProjectViews(orgId))
      })
      .catch((error) => {
        notify('Failed to save view. Please try again later.', 'error')
      })
  }

  const editView = (payload, viewId) => {
    editViews(payload, viewId, orgId)
      .then((response) => {
        dispatch(loadProjectViews(orgId))
      })
      .catch((error) => {
        notify('Failed to save filters. Please try again later.', 'error')
      })
  }

  const saveFiltersToView = () => {
    const copyOfViews = [...views]
    let newView = { ...copyOfViews[viewSettings?.selectedView] }
    const viewId = newView.id
    let newViewSettings = newView.view_settings || {}
    newViewSettings.filters = currentViewUnsavedFilters.filters
    newView.view_settings = newViewSettings
    if (currentViewUnsavedFilters.viewType !== undefined) newView.view_type = currentViewUnsavedFilters.viewType
    if (newView.not_saved) {
      dispatch(updateProjectView(newView))
    } else {
      editView(newView, viewId)
    }
  }

  const revertSelectedFilters = () => {
    const currentViewfilters = currentView?.view_settings?.filters
    viewSettings.setTableView(currentView.view_type === ViewType.Table)
    setFilters({ ...mapFilterArrayToFilterObject(currentViewfilters) })
  }

  const activeFiltersCount = useMemo(() => {
    if (filterValues?.q) return Object.keys(filterValues).length - 1
    else return Object.keys(filterValues).length
  }, [filterValues])

  const handleSetWorkflow = (id) => {
    props.setFilters({ ...filterValues, workflow_id: id.toString() })
  }

  return (
    <UiContainer uiKey={uiKey}>
      <Wrapper className={classes.row}>
        <LeftContainer className={classes.row}>
          <SearchField
            label={false}
            size="small"
            value={searchText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlineIcon color={COLOR_PALETTE.iconColor} />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            variant="outlined"
            placeholder={translate('Search')}
          />
          {currentView?.view_type === ViewType.Kanban && (
            <WorkflowDropdown workflow={Number(filterValues?.workflow_id)} setWorkflow={handleSetWorkflow} />
          )}
          <FilterBadge badgeContent={activeFiltersCount} color="primary" invisible={activeFiltersCount === 0}>
            <SettingsButton
              variant="outlined"
              color="secondary"
              startIcon={<SettingsIcon />}
              onClick={() => {
                setSettingsOpen(!settingsOpen)
              }}
            >
              {translate('View Settings')}
            </SettingsButton>
            <Button
              variant="outlined"
              color={activeFiltersCount > 0 ? 'primary' : 'secondary'}
              startIcon={<FilterIcon />}
              onClick={() => {
                setFilterOpen(!filterOpen)
              }}
              style={{
                borderColor: activeFiltersCount > 0 ? COLOR_PALETTE.darkBlue2 : undefined,
              }}
            >
              {translate('Sort & Filter')}
            </Button>
          </FilterBadge>
        </LeftContainer>
        {allowDelete && !!selectedIds?.length && (
          <BulkDeleteWithConfirmButton
            label="Delete Projects"
            variant={'outlined'}
            color={'secondary'}
            startIcon={<DeleteOutlineIcon />}
            {...props}
            style={{ marginRight: 10 }}
          />
        )}
        {isUnSavedViewAvailable && unSavedViewIndex === viewSettings.selectedView && (
          <ButtonWrapper>
            <Button variant="outlined" color="secondary" onClick={() => setIsVisibleDiscardModal(true)}>
              {translate('Discard')}
            </Button>
            <Button variant="contained" color="primary" onClick={saveUnsavedView}>
              {translate('Save')}
            </Button>
          </ButtonWrapper>
        )}
        {isCurrentViewHasUnsavedFilters && (
          <ButtonWrapper>
            <Button variant="outlined" color="secondary" onClick={revertSelectedFilters}>
              {translate('Discard')}
            </Button>
            <Button variant="contained" color="primary" onClick={saveFiltersToView}>
              {translate('Save')}
            </Button>
          </ButtonWrapper>
        )}
      </Wrapper>
      <ConfirmModal
        open={isVisibleDiscardModal}
        setOpen={setIsVisibleDiscardModal}
        handleSubmit={deleteView}
        title={translate('Discard Changes')}
        mainText={translate('Are you sure you want to discard changes?')}
        subText={translate('You will lose all your unapplied changes. Do you wish to continue?')}
        submitBtnLabel={translate('Yes, discard')}
        submitBtnProps={{ variant: 'contained', color: 'error' }}
      />
      <FilterDrawer
        open={filterOpen}
        onClose={() => {
          setFilterOpen(false)
        }}
        filterValues={filterValues}
        setFilters={setFilters}
        isTableView={viewSettings.isTableView}
        viewSettings={viewSettings}
      />
      <SettingsDrawer
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false)
        }}
        {...viewSettings}
      />
    </UiContainer>
  )
}

export default SearchToolbar
