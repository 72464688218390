import { makeStyles } from '@material-ui/core'
import useBrandingConfig from 'branding/useBrandingConfig'
import { push as pushAction } from 'connected-react-router'
import { authSelectors } from 'ducks/auth'
import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import { viewModeSelectors } from 'ducks/viewMode'
import UiContainer from 'elements/UiContainer'
import TopNavigationHeader3 from 'elements/navigation/TopNavigationHeader3'
import withMediaQuery from 'layout/withMediaQuery'
import { memo, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import compose from 'recompose/compose'
import appStorage from 'storage/appStorage'
import { DialogHelper, parseQueryStringToDictionary } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import IronRidgeHeader from './headers/IronRidgeHeader'
import OSHeader from './headers/OSHeader'
import OrderCartButton from './widgets/OrderCartButton'
import SelectOrg from './widgets/SelectOrg'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    color: theme.headerFontColor + ' !important',
    background: (args) => !args.isNav3Enabled ? theme.headerBackgroundColor : '#FFFFFF',
  },
  main: {
    display: 'flex',
    minHeight: '100vh',
  },
  body: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flex: 1,
    overflowY: 'hidden',
    overflowX: 'scroll',
  },
  content: {
    flex: 1,
    padding: '0 24px 24px 24px',
    overflow: 'hidden',
  },
}))

// TODO: remove, not used
export const SegenCartButton = memo(({ uiKey, layout }) => {
  const zone = useSelector((state) => state.zone)
  const enableSegen = useSelector(orgSelectors.getEnableSegen)
  const lineItems = useSelector(orderSelectors.getOrderLineItems)

  if (zone === 'shop') {
    return null
  }

  if (!enableSegen) {
    return null
  }

  return (
    <UiContainer uiKey={uiKey + '.segen'} inline={true}>
      {lineItems.length > 0 && (
        <span style={{ display: 'inline-block' }}>
          <OrderCartButton layout={layout} />
        </span>
      )}
    </UiContainer>
  )
})

// TODO: move elsewhere
export const CartButton = memo(({ uiKey, layout }) => {
  const zone = useSelector((state) => state.zone)
  const enabledHardwareProviders = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const lineItems = useSelector(orderSelectors.getOrderLineItems)

  if (enabledHardwareProviders.length === 0) {
    return null
  }

  if (zone === 'shop') {
    return null
  }

  return (
    <UiContainer uiKey={uiKey + '.cityPlumbing'} inline={true}>
      {lineItems.length > 0 && (
        <span style={{ display: 'inline-block' }}>
          <OrderCartButton layout={layout} />
        </span>
      )}
    </UiContainer>
  )
})

const MainHeader = memo((props) => {
  const { isNearmapSkin, push } = props
  const isNav3Enabled = useFeatureFlag('nav_3', 'on')
  const classes = useStyles({ isNav3Enabled })
  const location = useLocation()
  const briefOrgs = useSelector(authSelectors.getBriefOrgs)
  const [selectOrgVisible, setSelectOrgVisible] = useState(false)
  const [availableOrgs, setAvailableOrgs] = useState([])
  const [selectOrgTitle, setTitle] = useState('')
  const [pvsellKey, setPvsellKey] = useState('')
  const brandingConfig = useBrandingConfig()

  const showSunWizPopulationSelectOrgDialog = (api_key_pvsell) => {
    setPvsellKey(api_key_pvsell)
    const orgsAu = briefOrgs.filter((o) => o.country?.iso2 === 'AU')
    if (orgsAu.length === 1) {
      push(getRedirectUrl(orgsAu[0].id))
    } else {
      setAvailableOrgs(orgsAu)
      setTitle('Please choose a organisation to inject the api key')
      setSelectOrgVisible(true)
    }
  }
  useEffect(() => {
    const params = parseQueryStringToDictionary(location.search)
    if (location.pathname === '/control' && params.api_key_pvsell) {
      if (appStorage.getAuth()) {
        showSunWizPopulationSelectOrgDialog(params.api_key_pvsell)
      }
    }
  }, [])

  const getRedirectUrl = (orgId) => {
    if (pvsellKey) {
      return { pathname: `/external_api_keys/${orgId}`, state: { api_key_pvsell: pvsellKey } }
    } else {
      return '/projects'
    }
  }
  const clearState = () => {
    setSelectOrgVisible(false)
    setTitle('')
    setPvsellKey('')
    setSelectOrgVisible([])
  }
  const headerState = brandingConfig.header.headerState
  return (
    <div className={classes.wrapper}>
      {isNav3Enabled
        ? (
          <TopNavigationHeader3
            setAvailableOrgs={setAvailableOrgs}
            setSelectOrgVisible={setSelectOrgVisible}
            {...headerState === 'ironridge' && {
              navigationBackUrl: `${window.LYRA_BASE_URL}/projects?project_scope_filter=company_projects`
            }}
          />
        )
        : (
          <>
            {(headerState === 'standard' || isNearmapSkin) && (
              <OSHeader {...props} setSelectOrgVisible={setSelectOrgVisible} setAvailableOrgs={setAvailableOrgs} />
            )}
            {headerState === 'ironridge' && (
              <IronRidgeHeader {...props} setSelectOrgVisible={setSelectOrgVisible} setAvailableOrgs={setAvailableOrgs} />
            )}
          </>
        )
      }

      {selectOrgVisible === true && (
        <SelectOrg
          availableOrgs={availableOrgs}
          redirectUrl={getRedirectUrl}
          title={selectOrgTitle}
          close={() => {
            DialogHelper.beforeClose()
            clearState()
          }}
        />
      )}
    </div>
  )
})
const mapStateToProps = (state) => {
  return {
    isNearmapSkin: state.theme === 'nearmap',
    projectId: state.projectId,
    isUserLite: viewModeSelectors.isUserLite(state),
    org: orgSelectors.getOrg(state),
    orgId: authSelectors.getOrgId(state),
    isAdmin: authSelectors.getIsAdmin(state),
    enableExhibit: orgSelectors.getEnableExhibit(state),
    zone: state.zone,
  }
}

export default compose(withMediaQuery, connect(mapStateToProps, { push: pushAction }))(MainHeader)
