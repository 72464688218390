import Ajv, { JSONSchemaType } from 'ajv'

export const validateConfig = <T>(json: string, schema: JSONSchemaType<T>): { valid: boolean; errors?: string[] } => {
  try {
    const parsed = JSON.parse(json)

    const ajv = new Ajv()
    const validate = ajv.compile(schema)
    const valid = validate(parsed)
    return {
      valid: !!valid,
      errors: validate.errors?.map((err) => `${err.instancePath} ${err.message}`),
    }
  } catch (error) {
    return {
      valid: false,
      errors: ['Invalid JSON format'],
    }
  }
}
