import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { List } from 'elements/react-admin/List'
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  Edit,
  EditButton,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
} from 'react-admin'
import { useSelector } from 'react-redux'

export const PaymentMethodList = (props) => {
  const isStaff = useSelector(authSelectors.getIsStaff)
  let isCashflowEnabled = useSelector(orgSelectors.getCashFlowIsActive)

  if (!isCashflowEnabled)
    return (
      <div>
        <h2>CashFlow is not enabled on this org</h2>
        <p>Payment methods can only be edited for orgs that are fully approved for CashFlow</p>
      </div>
    )
  if (!isStaff) return null

  return (
    <List hasSearch={true} {...props}>
      <Datagrid>
        <TextField source="payment_method_type" />
        <TextField source="psp_integration" />
        <TextField source="country_iso2" />
        <BooleanField source="is_active" />
        <BooleanField source="is_available_for_configurations" />
        <NumberField source="min_payment_amount" />
        <NumberField source="max_payment_amount" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const LenderEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="payment_method_type" />
      <TextField source="psp_integration" />
      <TextField source="country_iso2" />
      <BooleanInput source="is_active" label="Available for customers" />
      <span>
        Note: editing Available for customers will immediately impact any outstanding payment requests for this org
      </span>
      <BooleanInput source="is_available_for_configurations" />
      <h2>Min/Max Amounts</h2>
      <p>
        The min/max fields below are meant to be in the org's currency (as determined by the org's country). If left
        blank, no min or max will be applied
      </p>
      <NumberInput source="min_payment_amount" />
      <NumberInput source="max_payment_amount" />
    </SimpleForm>
  </Edit>
)
